import { WarningWrapperProps } from "components/BulkUpdateOptionalFieldsModal/types";
import React from "react";
import { Row } from "components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

const UpdateInformationText = styled.div(
  ({ theme, backgroundColor }) => `
    background-color: ${theme.colors[backgroundColor || "gray95"]};
    color: ${theme.colors.black};
    padding:  2px ${theme.space[1]}px; 
    font-size: ${theme.fontSizes.alpha}px;
    margin: 2px 0;
    border-radius: ${theme.radii[1]}px; 
    `,
);

export function UpdateInformationWrapper({
  children,
  icon,
  backgroundColor,
}: WarningWrapperProps): React.JSX.Element {
  return (
    <Row>
      <UpdateInformationText backgroundColor={backgroundColor}>
        {icon ? (
          <>
            <FontAwesomeIcon icon={icon} size="xs" />
            &nbsp;
          </>
        ) : null}
        {children}
      </UpdateInformationText>
    </Row>
  );
}
