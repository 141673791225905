import { isEmpty } from "lodash";
import maxBy from "lodash/maxBy";
import { createSelector } from "reselect";

import {
  BillingDocumentIntegrationStatus,
  BillingDocumentStatus,
  BillingDocumentType,
} from "constants/billingDocuments";
import { IntegrationTypes } from "constants/integrations";

import { EMPTY_ARRAY } from "lib";

import { calculateInterestChargeCentsAndDaysToCharge } from "lib/interest";
import { dateTimeStringToDateTime, getDaysDifferent } from "lib/timeFormats";

import {
  getActiveLivestockAgentDeployment,
  getBillingDocuments,
  getBillingRuns,
  getContactBillingDocuments,
  getEmails,
  getSales,
  getSaleSubTypes,
  selectActiveInterestRateSettingsByBusinessIdLookup,
  selectBusinessForBillingDocumentByIdLookup,
  selectDefaultVendorCommissionByDeploymentSaleId,
  selectPaymentsByBillingDocumentIdLookup,
  selectVendorCommissionBandsByDeploymentBusinessIdLookup,
  selectWarningsByBillingDocumentIdLookup,
} from "selectors";

export const selectContactBillingDocumentsAggridData = createSelector(
  [getContactBillingDocuments, getSales],
  (billingDocumentByIdLookup, saleByIdLookup) => {
    return Object.values(billingDocumentByIdLookup).map(billingDocument => {
      const livestockSales = billingDocument.livestockSaleIds.map(
        livestockSaleId => saleByIdLookup[livestockSaleId],
      );

      return {
        billingDocument,
        livestockSales,
      };
    });
  },
);

const getDeploymentSaleCommissionForVendor = (
  deploymentSaleCommissionValue,
  sale,
  vendorCommissionBands,
) => {
  const commission = {
    deploymentSaleCommissionValue,
    customCommissionValue: null,
  };

  vendorCommissionBands.forEach(band => {
    const differsFromDefault =
      band.commissionValue !== commission.deploymentSaleCommissionValue;
    if (differsFromDefault) {
      if (band.saleSubTypeId === sale.sale_sub_type_id) {
        commission.customCommissionValue = band.commissionValue;
      }
      if (
        isEmpty(commission.customCommissionValue) &&
        band.saleSubTypeId === null
      ) {
        commission.customCommissionValue = band.commissionValue;
      }
    }
  });
  return commission;
};

function showOnOutstandingSettlementReport(
  billingDocument,
  business,
  payments,
) {
  // Only type invoice.
  if (billingDocument.type !== BillingDocumentType.INVOICE) {
    return false;
  }
  // Filtered for Businesses that are included in the DINS grouping
  if (!business.hasDebtorInsurance) {
    return false;
  }

  // We need to show any paid accounts that had been paid later than the due date within the last 7 days (only)
  // We need to show any open accounts (unpaid tax invoices) due over the last 21 days

  // Note - a paid date does NOT mean fully paid.  It means "the last payment" - which could have been partial.

  const mostRecentPayment = maxBy(payments, "paymentDate");
  const paidDateStr = mostRecentPayment ? mostRecentPayment.paymentDate : null;

  const { dueDate: dueDateStr, status, integrationStatus } = billingDocument;
  if (dueDateStr) {
    const dueDate = dateTimeStringToDateTime(dueDateStr);
    const now = new Date();

    // Any payment implicates a sent-and-not-voided-etc status.
    if (
      paidDateStr &&
      (status === BillingDocumentStatus.PAID ||
        integrationStatus === BillingDocumentIntegrationStatus.PAID)
    ) {
      const paidDate = dateTimeStringToDateTime(paidDateStr);
      // A negative days late is early; 0 is on time, positive is overdue.
      // Use this method, vs > < etc as payments are TIMES - so on the due date at 12.01AM could be flagged as "overdue"
      const daysLate = getDaysDifferent(paidDate, dueDate);
      const daysSincePaid = getDaysDifferent(now, paidDate);
      if (daysLate > 0 && daysSincePaid <= 7) {
        return true;
      }
    }
    // Struggling to define the logic of "has the client seen it?"
    // This is going to find everything past draft, unpaid, as there's many possibilities
    // of having shared the invoice (sent from AgriNous, sent from Xero, downloaded and emailed, downloaded printed and sent via post, etc)
    const daysSinceDue = getDaysDifferent(now, dueDate);
    if (
      daysSinceDue <= 21 &&
      daysSinceDue >= 0 &&
      ![BillingDocumentStatus.PAID, BillingDocumentStatus.DRAFT].includes(
        status,
      ) &&
      ![
        BillingDocumentIntegrationStatus.PAID,
        BillingDocumentIntegrationStatus.DELETED,
        BillingDocumentIntegrationStatus.VOIDED,
      ].includes(integrationStatus)
    ) {
      return true;
    }
  }

  return false;
}

export const selectBillingDocumentsAggridDataById = createSelector(
  [
    getBillingDocuments,
    getEmails,
    selectWarningsByBillingDocumentIdLookup,
    getSales,
    getBillingRuns,
    selectDefaultVendorCommissionByDeploymentSaleId,
    selectVendorCommissionBandsByDeploymentBusinessIdLookup,
    selectBusinessForBillingDocumentByIdLookup,
    selectActiveInterestRateSettingsByBusinessIdLookup,
    getActiveLivestockAgentDeployment,
    getSaleSubTypes,
    selectPaymentsByBillingDocumentIdLookup,
  ],
  (
    billingDocumentByIdLookup,
    emailByIdLookup,
    warningsByBillingDocumentIdLookup,
    saleByIdLookup,
    billingRunByIdLookup,
    defaultVendorCommissionByDeploymentSaleIdLookup,
    vendorCommissionBandsByDeploymentBusinessIdLookup,
    businessForBillingDocumentByIdLookup,
    activeInterestRateSettingsByBusinessIdLookup,
    activeDeployment,
    saleSubTypeByIdLookup,
    paymentsByBillingDocumentIdLookup,
  ) =>
    Object.values(billingDocumentByIdLookup).reduce((acc, billingDocument) => {
      const business = businessForBillingDocumentByIdLookup[billingDocument.id];
      const xeroIntegrationBusiness = business.integrationBusinesses?.find(
        ib => ib.type === IntegrationTypes.Xero,
      );
      const interestSettings =
        activeInterestRateSettingsByBusinessIdLookup[business.id]?.[0] || {};

      const payments =
        paymentsByBillingDocumentIdLookup[billingDocument.id] || EMPTY_ARRAY;

      // Billing documents at the moment generally only have one related livestock sale, but if they have more, we only need to check the first one
      const livestockSale = saleByIdLookup[billingDocument.livestockSaleIds[0]];
      const saleSubType =
        saleSubTypeByIdLookup[livestockSale?.sale_sub_type_id];
      const calculatedInterest = calculateInterestChargeCentsAndDaysToCharge(
        billingDocument,
        interestSettings,
        null,
        new Date(),
        activeDeployment,
        saleSubType,
        payments,
      ).interestToChargeCents;
      const warnings = warningsByBillingDocumentIdLookup[billingDocument.id];

      // Find the vendor commission band for this business and the default vendor commission band (for this sale sub type).
      let commission = {
        deploymentSaleCommissionValue: null,
        customCommissionValue: null,
      };

      if (billingDocument.livestockSaleIds?.length === 1) {
        const sale = saleByIdLookup[billingDocument.livestockSaleIds[0]];
        const billingRun = billingRunByIdLookup[billingDocument.billingRunId];

        if (sale && billingRun) {
          const deploymentSaleId = sale.deployment_sales.find(
            deploymentSale =>
              deploymentSale.deployment_id === billingRun.deploymentId,
          ).deployment_sale_id;

          // We are in the context of a single sale, so determine the commission information for this vendor in this sale.
          // We use this information to determine whether the commission value is the default or custom value.
          commission = getDeploymentSaleCommissionForVendor(
            defaultVendorCommissionByDeploymentSaleIdLookup[deploymentSaleId],
            sale,
            vendorCommissionBandsByDeploymentBusinessIdLookup[
              business.deploymentBusinessId
            ] || [],
          );
        }
      }

      billingDocument.warnings = warnings;
      const livestockSales = billingDocument.livestockSaleIds.map(
        livestockSaleId => saleByIdLookup[livestockSaleId],
      );

      acc[billingDocument.id] = {
        billingDocument,
        business: Object.assign({}, business, {
          xeroIntegrationBusiness,
        }),
        commission,
        emails:
          billingDocument.emailIds?.map(id => emailByIdLookup[id]) ||
          EMPTY_ARRAY,
        interestSettings: {
          ...interestSettings,
          calculatedInterest,
        },
        livestockSales,
        payments,
        showOnOutstandingSettlementReport: showOnOutstandingSettlementReport(
          billingDocument,
          business,
          payments,
        ),
        warnings,
      };
      return acc;
    }, {}),
);

export const getBillingDocumentsAggridDataById = billingDocumentId => state =>
  selectBillingDocumentsAggridDataById(state)[billingDocumentId];

export const selectBillingDocumentsAggridData = createSelector(
  [selectBillingDocumentsAggridDataById],
  billingDocumentsAggridDataById =>
    Object.values(billingDocumentsAggridDataById),
);

export const selectEmailRecipientsByBillingDocumentIdLookup = createSelector(
  [selectBillingDocumentsAggridDataById],
  billingDocumentsById => {
    return Object.values(billingDocumentsById).reduce((acc, cur) => {
      const { billingDocument, business } = cur;
      acc[billingDocument.id] = {
        emailRecipients: business.emailRecipients || EMPTY_ARRAY,
        businessUsers: business.businessUsers || EMPTY_ARRAY,
      };
      return acc;
    }, {});
  },
);

export const selectBillingDocumentsWithWarningsAggridDataById = createSelector(
  [selectBillingDocumentsAggridDataById],
  billingDocumentsAggridDataById =>
    Object.values(billingDocumentsAggridDataById).filter(
      doc => doc.warnings.length > 0,
    ),
);
