import React from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { BillingDocumentActivityAction } from "actions/billingDocumentActivity";

import { Chip } from "components/Chip";
import { LinkButton, SecondaryButton } from "components/Form";
import { CenteredGreedy, Row } from "components/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import {
  DialogTitle,
  ZoomyDialog,
  DialogContent,
  DialogActions,
} from "components/MaterialDialog";

import {
  activityDetailsMap,
  billingDocumentActivityTitleAndColorMap,
} from "lib/billingDocumentActivity";
import { formatUTCToLocalDateTimeString } from "lib/timeFormats";

import {
  getBillingDocumentById,
  selectSortedBillingDocumentActivityByIdLookup,
} from "selectors";

const LoadingWrapper = styled(CenteredGreedy)`
  padding: ${({ theme }) => theme.space[2]}px;
`;

const ActivityRow = ({ activity }) => {
  const { date, key, billingDocumentId } = activity;

  const activityBillingDocument = useSelector(
    getBillingDocumentById(billingDocumentId),
  );

  // if we have a billing document linked to an activity, grab the file off the report jobs
  const file = activityBillingDocument?.reportJobs.filter(
    reportJob => !!reportJob.file,
  )[0]?.file;

  return (
    <>
      <Row fullWidth minHeight="50px" alignCenter padding={1}>
        {formatUTCToLocalDateTimeString(date)}
      </Row>
      <Row
        fullWidth
        minHeight="40px"
        alignCenter
        background="grayF3"
        padding={1}
      >
        <Chip
          className="rounded-lg mr-12"
          backgroundColor={billingDocumentActivityTitleAndColorMap[key]?.color}
          color={billingDocumentActivityTitleAndColorMap[key]?.textColor}
        >
          {activity.title}{" "}
        </Chip>
        {activityDetailsMap(activity)?.[key]}{" "}
        {file && (
          <LinkButton className="ml-12" onClick={() => window.open(file)}>
            View
          </LinkButton>
        )}
      </Row>
    </>
  );
};

const ModalInner = ({ billingDocumentId }) => {
  const sortedBillingDocumentActivity = useSelector(
    selectSortedBillingDocumentActivityByIdLookup,
  );

  const sortedActivity =
    sortedBillingDocumentActivity?.[billingDocumentId] || [];

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      BillingDocumentActivityAction.requestOne({ id: billingDocumentId }),
    );
  }, [billingDocumentId, dispatch]);

  if (!sortedActivity.length) {
    return (
      <LoadingWrapper>
        <LoadingSpinner subjectName="Activity" />
      </LoadingWrapper>
    );
  } else {
    return (
      <>
        {sortedActivity.map((activity, idx) => (
          <ActivityRow key={idx} activity={activity} />
        ))}
      </>
    );
  }
};

const BillingDocumentActivityModal = ({ billingDocumentId, onClose }) => {
  const billingDocument =
    useSelector(getBillingDocumentById(billingDocumentId)) || {};

  return (
    <ZoomyDialog open onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>
        Activity for {billingDocument.invoiceNumber}
      </DialogTitle>
      <DialogContent dividers>
        <ModalInner billingDocumentId={billingDocumentId} />
      </DialogContent>

      <DialogActions>
        <SecondaryButton onClick={onClose}>Close</SecondaryButton>
      </DialogActions>
    </ZoomyDialog>
  );
};

export default BillingDocumentActivityModal;
