import React from "react";

import { DialogActions } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  BillingDocumentAction,
  closeConfirmModal,
  openConfirmModal,
} from "actions";

import { LedgerEntriesTable } from "components/BillingWorkFlow/LedgerEntriesTable";
import { PDFPreview } from "components/BillingWorkFlow/PDFPreview";
import { Button } from "components/Form";
import { Column, Row } from "components/Layout";
import { Dialog, DialogContent, DialogTitle } from "components/MaterialDialog";
import { AbsolutePaper } from "components/Paper";

import {
  BillingDocumentIntegrationStatus,
  BillingDocumentReportJobStatus,
} from "constants/billingDocuments";
import { BillingLedgerEntryStatus } from "constants/billingLedgerEntries";
import { DeploymentPermissions } from "constants/permissions";

import { getReportJobUrl } from "lib/navigation";

import {
  getActiveRole,
  getBillingDocumentById,
  getPaymentIdsByBillingDocumentId,
} from "selectors";

import { useHasDeploymentPermission } from "hooks";

const Heading = styled.h1(
  ({ theme }) => `
  flex: 1;
  text-align: center;
  border-bottom: 1px solid ${theme.colors.controlBorder};
  margin: 0;
`,
);

const viewChoices = {
  BOTH: 0,
  PDF_ONLY: 1,
  LEDGER_ENTRIES_ONLY: 2,
};

export const ResolveChanges = ({ onClose, id }) => {
  const dispatch = useDispatch();

  const billingDocument = useSelector(getBillingDocumentById(id)) || {};
  const { reportJobs = [] } = billingDocument;
  const paymentIds = useSelector(getPaymentIdsByBillingDocumentId(id)) || [];

  const disabledAcceptWithoutReversalReasons = [];
  if (paymentIds.length > 0) {
    disabledAcceptWithoutReversalReasons.push(
      "This document has a payment assigned to it in Xero, to accept changes without a reversal, undo the payment.",
    );
  }

  // TODO Confirm if this statement is true!
  // if (billingDocument.proceedsRetainedByBillingDocument) {
  //   disabledAcceptWithoutReversalReasons.push(
  //     "This document has retained proceeds, to accept changes without a reversal, first remove all retained proceeds.",
  //   );
  // }

  const disabledAcceptWithReversalReasons = [];
  if (
    ![
      BillingDocumentIntegrationStatus.AUTHORISED,
      BillingDocumentIntegrationStatus.PAID,
    ].includes(billingDocument.integrationStatus)
  ) {
    disabledAcceptWithReversalReasons.push(
      "Reversals are only possible on documents in a PAID or AUTHORISED state in Xero.",
    );
  }
  if (billingDocument.proceedsRetainedByBillingDocument) {
    disabledAcceptWithReversalReasons.push(
      "Reversals are only possible on documents without Retained Proceeds, you must remove all retained proceeds first.",
    );
  }

  const allowWithoutReversal =
    disabledAcceptWithoutReversalReasons.length === 0;

  const userRoleSlug = useSelector(state => getActiveRole(state).slug);

  const hasXeroPermission = useHasDeploymentPermission(
    DeploymentPermissions.featureXeroIntegration,
  );

  const showReversal = hasXeroPermission;
  const allowReversal = disabledAcceptWithReversalReasons.length === 0;

  const acceptChanges = () => {
    dispatch(BillingDocumentAction.acceptChanges({ id }));
    onClose();
  };

  const acceptAndReverse = () => {
    dispatch(BillingDocumentAction.acceptAndReverse({ id }));
    onClose();
  };

  const confirmReversal = () => {
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message: "Are you sure you want to reverse this document?",
        actions: [
          {
            label: "No",
            secondary: true,
            onClick: () => {
              dispatch(closeConfirmModal());
            },
          },
          {
            label: "Yes",
            onClick: () => {
              dispatch(closeConfirmModal());
              acceptAndReverse();
            },
          },
        ],
      }),
    );
  };

  const rejectChanges = () => {
    dispatch(BillingDocumentAction.rejectChanges({ id }));
    onClose();
  };

  const getDocumentPreviewByStatus = status => {
    const id = reportJobs.find(rj => rj.documentStatus === status)?.id;
    return getReportJobUrl(id, userRoleSlug);
  };

  const [viewIndex, setViewIndex] = React.useState(0);
  const changeView = () => {
    const next =
      viewIndex + 1 === Object.keys(viewChoices).length ? 0 : viewIndex + 1;
    setViewIndex(next);
  };

  const view = Object.values(viewChoices).indexOf(viewIndex);

  return (
    <Dialog open onClose={onClose} maxWidth="lg" fullWidth fullScreen>
      <DialogTitle onClose={onClose}>
        <Row justifyBetween alignCenter>
          <span>Review Changes</span>
          <div>
            <Button onClick={changeView}>Toggle View</Button>
          </div>
        </Row>
      </DialogTitle>
      <DialogContent dividers>
        <Column fullHeight>
          <Row>
            <Heading>Current</Heading>
            <Heading>Changes</Heading>
          </Row>
          {view !== viewChoices.LEDGER_ENTRIES_ONLY && (
            <Row full>
              <Row relative full className="border-r-1 border-b-1">
                <PDFPreview
                  url={getDocumentPreviewByStatus(
                    BillingDocumentReportJobStatus.COMMITTED,
                  )}
                />
              </Row>
              <Row relative full className="border-b-1">
                <PDFPreview
                  url={getDocumentPreviewByStatus(
                    BillingDocumentReportJobStatus.TEMPORARY,
                  )}
                />
              </Row>
            </Row>
          )}
          {view !== viewChoices.PDF_ONLY && (
            <Row full>
              <Row relative full className="border-r-1">
                <AbsolutePaper>
                  <LedgerEntriesTable
                    billingDocumentId={id}
                    status={BillingLedgerEntryStatus.COMMITTED}
                  />
                </AbsolutePaper>
              </Row>
              <Row relative full>
                <AbsolutePaper>
                  <LedgerEntriesTable
                    billingDocumentId={id}
                    status={BillingLedgerEntryStatus.TEMPORARY}
                  />
                </AbsolutePaper>
              </Row>
            </Row>
          )}
        </Column>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>

        <Button onClick={rejectChanges}>Reject Changes</Button>

        <Button
          onClick={acceptChanges}
          disabled={!allowWithoutReversal}
          title={
            allowWithoutReversal
              ? ""
              : disabledAcceptWithoutReversalReasons.join("\n")
          }
        >
          {showReversal ? "Accept Without Reversal" : "Accept Changes"}
        </Button>
        {showReversal ? (
          <Button
            onClick={confirmReversal}
            disabled={!allowReversal}
            title={
              allowReversal
                ? "Reversals for sent or exported documents will clearly show revisions in a statement section within this document and will automatically resolve changes via the Xero integration."
                : disabledAcceptWithReversalReasons.join("\n")
            }
          >
            Accept &amp; Run Reversal
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
