import queryString from "query-string";

import { MLA_SUPPLEMENTARY_DATA } from "constants/actionTypes";
import { URLCreatorProps } from "actions/types";
import { serializeMLASupplementaryData } from "lib/serializers/mlaSupplementaryData";
import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

function urlCreator(props: URLCreatorProps = {}): string {
  const { action, changesSince, livestockSaleId, id } = props;
  return queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/mla-supplementary-data/", id, action),
    query: {
      changesSince,
      livestock_sale_id: livestockSaleId,
    },
  });
}

export const MLASupplementaryDataAction = offlineActionCreator(
  MLA_SUPPLEMENTARY_DATA,
  serializeMLASupplementaryData,
  urlCreator,
  true,
);
