import * as React from "react";

import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { useField, useFormikContext } from "formik";
import { isObject } from "lodash";
import styled from "styled-components/macro";

import IconTextButton from "components/Button/IconTextButton";
import { useSuggestedValue } from "components/Form/FormikControls/lib";
import { Column, Row } from "components/Layout";
import { OptionToggler } from "components/OptionToggler";

import { Error } from "./Error";
import { Label } from "./Label";

export const StyledRow = styled(Row)(
  ({ labelPosition, theme }) => `
  flex-direction: ${labelPosition === "top" ? "column" : "row"};
  align-items: stretch;
  &:focus-within {
    outline: 1px solid ${theme.colors.primary};
  }
  transition: ${theme.transitions[0]};
`,
);

export const OptionTogglerField = ({
  name,
  label = undefined,
  options,
  required = false,
  labelPosition = "left",
  onChangeExtra = undefined,
  tooltip = "",
  allowToggleOff = false,
  suggestedValueFieldName = undefined,
  disabled = false,
  optionIcons = [],
  forceTabStop = false,
  skipTab = false,
}) => {
  const { setFieldTouched, setFieldValue, submitCount } = useFormikContext();

  const [{ value }, meta] = useField(name);

  const suggestedValue = useSuggestedValue(suggestedValueFieldName, value);
  const suggestedLabel =
    suggestedValue === undefined
      ? ""
      : options.find(o => o.value === suggestedValue)?.label;

  const dropdownOptions = React.useMemo(
    () =>
      options.map(option => {
        // Handle the simple scenario of just recieving an array of unique strings.
        if (!isObject(option)) {
          return {
            value: option,
            label: option,
          };
        } else {
          return option;
        }
      }),
    [options],
  );

  function onChange(value) {
    setFieldTouched(name, true, false).then(() =>
      setFieldValue(name, value, true),
    );
    onChangeExtra && onChangeExtra(value);
  }

  const handleClickUseSuggestedValue = () => {
    onChange(suggestedValue);
  };

  const error = (meta.touched || submitCount > 0) && meta.error;

  const hasLabel = !!label;
  return (
    <StyledRow alignCenter labelPosition={labelPosition} error={!!error}>
      {hasLabel && (
        <Label error={!!error} id={name} required={required} tooltip={tooltip}>
          {label}
        </Label>
      )}

      <Column data-tour={name} full>
        <OptionToggler
          error={error}
          options={dropdownOptions}
          name={name}
          onChange={onChange}
          value={value}
          allowToggleOff={allowToggleOff}
          disabled={disabled}
          optionIcons={optionIcons}
          forceTabStop={forceTabStop}
          skipTab={skipTab}
        />
        {suggestedLabel ? (
          <IconTextButton
            icon={faQuestionCircle}
            color="success"
            onClick={handleClickUseSuggestedValue}
          >
            Use Suggested: {suggestedLabel}
          </IconTextButton>
        ) : null}
        {error && <Error>{error}</Error>}
      </Column>
    </StyledRow>
  );
};
