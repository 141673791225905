import { BillingDocumentException } from "constants/billingDocuments";
import { BUYER, TRANSPORTER, VENDOR } from "constants/businesses";

/**
 * Compares the Deployment Business name to the given name.
 * Note: This does not take in to account Deployments/Agency linkages
 *
 * @param {string} businessName A Deployment Business Name
 * @returns {function(*): boolean}
 */
export function findBusinessWithName(businessName) {
  return business => business.name === businessName;
}

export const setBusinessRoleAndInsuranceFlag = (
  business,
  roles,
  value = true,
) => {
  // Set a businesses role, and other relevant fields.
  if (Array.isArray(roles) && roles.length === 1) {
    const role = roles[0];
    if (role === VENDOR) {
      business.is_vendor = value;
      business.has_transit_insurance_for_sales = value;
    } else if (role === TRANSPORTER) {
      business.is_transporter = value;
    } else if (role === BUYER) {
      business.is_buyer = value;
    }
  }
};

export function getBusinessSubType(business) {
  if (business.businessLivestockAgentId) {
    return "Livestock Agent";
  } else if (business.businessDeploymentId) {
    return "Livestock Agency";
  }
  return "Business";
}

export const getGSTStatusText = (
  gstStatus,
  abrLastChecked,
  shouldChargeGSTOverride = null,
) => {
  const result = [];
  if (abrLastChecked) {
    result.push(gstStatus === true ? "Registered" : "Not Registered");
  } else {
    result.push("Unknown");
  }
  if (shouldChargeGSTOverride !== null) {
    result.push(
      `(Overridden to ${shouldChargeGSTOverride ? "Charge GST" : "No GST"})`,
    );
  }
  return result.join(" ");
};

export const getBuyerInformationString = (
  buyerId,
  destinationPropertyId,
  buyerWayName,
) => [buyerId, destinationPropertyId, buyerWayName].join("-");

// A business is considered active when it's:
// - got the isActive flag Tue
// AND
// - is not temporary OR is temporary and added in the current sale  (ie - they're only useful for a selected sale
// and should not be shown outside that context)
export const isBusinessActive = (business, currentLivestockSaleId) => {
  return (
    business.isActive &&
    (!business.isTemporary ||
      business.addedInLivestockSaleId === currentLivestockSaleId)
  );
};

export const getPrimaryContactFromBusiness = business => {
  const { primaryContactEmail, emailRecipients, businessUsers } = business;
  if (primaryContactEmail) {
    const primaryEmailRecipient = emailRecipients.find(
      er => er.email === primaryContactEmail,
    );
    const primaryBusinessUser = businessUsers.find(
      bu => bu.email === primaryContactEmail,
    );
    return primaryEmailRecipient || primaryBusinessUser;
  } else {
    if (emailRecipients?.length) {
      return emailRecipients[0];
    } else if (businessUsers?.length) {
      return businessUsers[0];
    }
    return null;
  }
};

export const getAbnWarningsListFromBusiness = business => {
  const warningsList = [];

  const { abn, isHobbyFarmer, shouldChargeGSTOverride, isGSTRegistered } =
    business;

  if (!isHobbyFarmer) {
    if (abn) {
      if (!isGSTRegistered) {
        warningsList.push(BillingDocumentException.VENDOR_NOT_GST_REGISTERED);
      }
    } else {
      warningsList.push(BillingDocumentException.VENDOR_MISSING_ABN);
    }

    // need to explicity check true vs false on override as null means no override has been applied
    if (shouldChargeGSTOverride === true) {
      warningsList.push(BillingDocumentException.VENDOR_GST_OVERRIDDEN_GST);
    }
    if (shouldChargeGSTOverride === false) {
      warningsList.push(BillingDocumentException.VENDOR_GST_OVERRIDDEN_NO_GST);
    }
  }
  return warningsList;
};
