import React from "react";

import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks";

import BillingDocumentActivityModal from ".";

function BillingDocumentActivityModalAdapter(props) {
  const [hashParams, ignored, onClose] = useModalAdapter(
    ModalTypes.BillingDocumentActivity,
    props,
  );
  const { billingDocumentId = null } = hashParams;

  return (
    <BillingDocumentActivityModal
      billingDocumentId={billingDocumentId}
      onClose={onClose}
    />
  );
}

export default BillingDocumentActivityModalAdapter;
