import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const restrictedDraftingAttributesSerializeMap = {
  dressingPercent: "dressing_percent",
  estCarcassWeight: "est_carcass_weight",
  fatScore1: "fat_score_1",
  fatScore2: "fat_score_2",
  fatScore3: "fat_score_3",
  fatScore4: "fat_score_4",
  fatScore5: "fat_score_5",
  fatScore6: "fat_score_6",
  frameStructure: "frame_structure",
  joinedEnd: "joined_end",
  joinedStart: "joined_start",
  muscleScore1: "muscle_score_1",
  muscleScore2: "muscle_score_2",
  muscleScore3: "muscle_score_3",
  muscleScore4: "muscle_score_4",
  muscleScore5: "muscle_score_5",
  maxMassGrams: "max_mass_grams",
  minMassGrams: "min_mass_grams",
  shearing: "shearing",
  shearingStatus: "shearing_status",
};

const restrictedSaleLotSerializeMap = {
  id: "id",
  created: "created",
  lastModified: "last_modified",
  ageId: "age_id",
  breedId: "breed_id",
  sexId: "sex_id",
  quantity: "quantity",
  auctionPenId: "auction_pen_id",
  dressingRangeId: "dressing_range_id",
  estimatedAverageWeightId: "estimated_average_weight_id",
  estimatedAverageMassGrams: "estimated_average_mass_grams",
  totalMassGrams: "total_mass_grams",
  totalPriceCents: "total_price_cents",
  pricingTypeId: "pricing_type_id",
  draftingAttributes: restrictedDraftingAttributesSerializeMap,
};

const mlaSupplementaryDataSerializeMap = {
  ageId: "age_id",
  breedId: "breed_id",
  created: "created",
  dressingRangeId: "dressing_range_id",
  estCarcassWeight: "est_carcass_weight",
  estimatedAverageWeightId: "estimated_average_weight_id",
  fatScore: "fat_score",
  frameStructure: "frame_structure",
  id: "id",
  joinedEnd: "joined_end",
  joinedStart: "joined_start",
  lastModified: "last_modified",
  mlaSupplementaryDataId: "mla_supplementary_data_id",
  muscleScore: "muscle_score",
  permissions: "permissions",
  saleLot: restrictedSaleLotSerializeMap,
  sexId: "sex_id",
  shearing: "shearing",
  shearingStatus: "shearing_status",
};

const nestedObjectMap = {
  sale_lot: "saleLot",
  drafting_attributes: "draftingAttributes",
};

// TODO - this shouldn't serialize sale lot - it should remove it...
export const serializeMLASupplementaryData = buildSerializer(
  mlaSupplementaryDataSerializeMap,
  "MLASupplementaryData",
  nestedObjectMap,
);

export const deserializeMLASupplementaryData = buildDeserializer(
  mlaSupplementaryDataSerializeMap,
  "MLASupplementaryData",
  nestedObjectMap,
);
