import React from "react";

import Grid from "@material-ui/core/Grid";

import { CollapseHeader, FormCollapse } from "components/Form";
import {
  OptionTogglerField,
  PercentageInput,
  withNamespace,
  withNamespaces,
} from "components/Form/FormikControls";

import { InsuranceApplicationOptions } from "constants/billing";
import { FinanceSubModalSection } from "constants/settings";

import { useSectionHelper } from "hooks";

export const InsuranceSettings = ({ sectionHelper, namespace: ns }) => {
  const { isOpen, onToggle } = useSectionHelper(
    sectionHelper,
    FinanceSubModalSection.INSURANCE,
  );
  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<CollapseHeader title="Insurance" />}
    >
      <Grid item xs={12} md={12}>
        <h1>Debtor Insurance</h1>
        <PercentageInput
          label="Default Rate"
          name="defaultDebtorInsurance"
          required
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <h1>Transit Insurance</h1>
      </Grid>
      <Grid item xs={12} md={12}>
        <OptionTogglerField
          options={InsuranceApplicationOptions}
          name={withNamespace(ns, "transitInsuranceApplicationDefault")}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <h2>Livestock Sales</h2>
        <Grid container spacing={2} xs={12} md={12}>
          <Grid item xs={12} md={6}>
            <PercentageInput
              label="Default Sell Rate"
              name={withNamespaces(
                [ns, "transitInsuranceForSales"],
                "standardRate",
              )}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PercentageInput
              label="Default Extended Sell Rate"
              name={withNamespaces(
                [ns, "transitInsuranceForSales"],
                "extendedRate",
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12} md={12}>
          <Grid item xs={12} md={6}>
            <PercentageInput
              label="Default Provider Sell Rate"
              name={withNamespaces(
                [ns, "transitInsuranceForSales"],
                "providerStandardRate",
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PercentageInput
              label="Default Extended Provider Sell Rate"
              name={withNamespaces(
                [ns, "transitInsuranceForSales"],
                "providerExtendedRate",
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <h2>Livestock Purchase</h2>
        <Grid container spacing={1} xs={12} md={12}>
          <Grid item xs={12} md={6}>
            <PercentageInput
              label="Default Purchase Rate"
              name={withNamespaces(
                [ns, "transitInsuranceForPurchases"],
                "standardRate",
              )}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PercentageInput
              label="Default Extended Purchase Rate"
              name={withNamespaces(
                [ns, "transitInsuranceForPurchases"],
                "extendedRate",
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} md={12}>
          <Grid item xs={12} md={6}>
            <PercentageInput
              label="Default Provider Purchase Rate"
              name={withNamespaces(
                [ns, "transitInsuranceForPurchases"],
                "providerStandardRate",
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PercentageInput
              label="Default Extended Provider Purchase Rate"
              name={withNamespaces(
                [ns, "transitInsuranceForPurchases"],
                "providerExtendedRate",
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormCollapse>
  );
};
