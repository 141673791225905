import React, { useRef, useState } from "react";

import { DialogActions } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { patchSaleLot } from "actions";

import { LotCard } from "components/ClearingSaleCards/LotCard";
import { Button, SecondaryButton } from "components/Form";
import {
  SelectField,
  useSubmitHandler,
  withNamespace,
} from "components/Form/FormikControls";
import { Dialog, DialogContent, DialogTitle } from "components/MaterialDialog";

import { LotStatusAsOptions } from "constants/clearingSaleAttributes";
import { SaleLotNamespaces } from "constants/saleLots";

const validationSchema = Yup.object().shape({
  [SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES]: Yup.object().shape({
    status: Yup.string().required("Status is required."),
  }),
});

function BulkUpdateClearingSaleSaleLotsForm({ setIsSubmitEnabled }) {
  useSubmitHandler(false, setIsSubmitEnabled);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SelectField
          label="Status"
          name={withNamespace(
            SaleLotNamespaces.CLEARING_SALE_ATTRIBUTES,
            "status",
          )}
          options={LotStatusAsOptions}
          menuPortalTarget={document.body}
          required
        />
      </Grid>
    </Grid>
  );
}

function BulkUpdateClearingSaleSaleLotsModal({ saleLotIds, onClose }) {
  const formRef = useRef(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = values => {
    // TODO - bulk update this...
    saleLotIds.forEach(saleLotId => {
      dispatch(
        patchSaleLot(
          { id: saleLotId, ...values },
          { changeReason: "Bulk update" },
        ),
      );
    });
    onClose();
  };

  function onClickSubmit() {
    formRef.current.submitForm();
  }

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>
        Editing {saleLotIds.length} Sale Lot
        {saleLotIds.length !== 1 && "s"}{" "}
      </DialogTitle>
      <DialogContent dividers shrink={0}>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          innerRef={formRef}
          initialValues={{ clearingSaleAttributes: { status: "" } }}
        >
          <BulkUpdateClearingSaleSaleLotsForm
            handleSubmit={handleSubmit}
            setIsSubmitEnabled={setIsSubmitEnabled}
            formRef={formRef}
          />
        </Formik>
      </DialogContent>
      <DialogContent dividers>
        <Grid container spacing={1}>
          {saleLotIds.map(saleLotId => (
            <Grid item xs={12} key={saleLotId}>
              <LotCard saleLotId={saleLotId} showVendor readOnly />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions shrink={0}>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <Button
          data-tour="update"
          onClick={onClickSubmit}
          disabled={!isSubmitEnabled}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BulkUpdateClearingSaleSaleLotsModal;
