import React from "react";

import { faRepeat } from "@fortawesome/pro-duotone-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { SaleAction, setSetting } from "actions";

import AuditLogModalAdapter from "components/AuditLog/adapter";
import BillingDocumentActivityModalAdapter from "components/Billing/BillingDocument/Activity/Modal/Adapter";
import { BillingDocumentModalAdapter } from "components/Billing/BillingDocument/Modal";
import { BillingRunModalAdapter } from "components/Billing/BillingRun/Modal";
import { LedgerEntryModalAdapter } from "components/Billing/LedgerEntry/Modal";
import { BillingDocumentReviewModal } from "components/BillingWorkFlow/BillingDocumentReviewModal";
import { BusinessTable } from "components/BusinessTable/BusinessesList";
import { IconTextButton } from "components/Button";
import CommentsModalAdapter from "components/Comments/Modal/adapter";
import ConsignToSaleModalAdapter from "components/ConsignToSaleModal/Adapter";
import DeploymentSaleOptionsAdapter from "components/DeploymentSaleOptions/adapter";
import { DevicePlayground } from "components/DevicePlayground";
import { EContractModalAdapter } from "components/EContracts/eContractModal";
import HashRoute from "components/HashRoute";
import { ImportSummaryAdapter } from "components/Importer/ImporterSummaryAdatper";
import { Inbox } from "components/Inbox";
import { CreateIntegrationBusinessModalAdapter } from "components/IntegrationBusinesses/CreateModal";
import { EditIntegrationBusinessModalAdapter } from "components/IntegrationBusinesses/Modal";
import { Column, Row } from "components/Layout";
import { LedgerAccountModalAdapter } from "components/LedgerAccount/Modal";
import LoadingSpinner from "components/LoadingSpinner";
import MapBusinessModal from "components/MapBusinessModal";
import MessageBox from "components/MessageBox";
import DispatchingHashModalAdapter from "components/Modal/DispatchingAdapter";
import { InterestModalAdapter } from "components/MoneyTable/InterestModal";
import { MoneyTableWrapper } from "components/MoneyTable/MoneyTable";
import { MultiSaleReportsModal } from "components/MultiSaleReports";
import { EditNominationModalAdapter } from "components/Nominations/Modal";
import { NLISReceiptModalAdapter } from "components/ReceiptModals/NLISReceiptModalAdapter";
import EmailReportJobModalAdapter from "components/ReportJob/SendEmailModal/adapter";
import { ReportJobList } from "components/ReportJobList/ReportJobList";
import { RetainProceedsModalAdapter } from "components/RetainProceeds/Modal/Adapter";
import { RuleBookModalAdapter } from "components/RuleBookTable/modal";
import RuleTesterModal from "components/RuleTester";
import { SelectSyncDataAdapter } from "components/SelectSyncData/Adapter";
import { SundryTemplateModalAdapter } from "components/SundryTemplate";
import EditUserRoleModalAdapter from "components/UserRoles/Modal/adapter";
import VendorSplitModalAdapter from "components/VendorSplits/NewVendorSplitModal/adapter";

import { ModalTypes } from "constants/navigation";

import { EditBusinessAdapter } from "containers/Businesses/Adapter";
import LivestockSales from "containers/LivestockSales";
import SaleModalAdapter from "containers/SaleModal/Adapter";
import Settings from "containers/Settings";
import { RuleLibraryModalAdapter } from "containers/Settings/RuleBooks/Library/Adapter";
import { RuleModal } from "containers/Settings/RuleBooks/Rules/Modal";
import { MasterRuleModal } from "containers/Settings/RuleBooks/Rules/Modal/MasterRule";
import SingleWeighSettingsModalAdapter from "containers/SingleWeighSettingsModal/Adapter";
import UserDashboard from "containers/UserDashboard/index";
import UserOverview from "containers/UserOverview";

import {
  getDashboardRoute,
  getInboxRoute,
  getManageBusinessRoute,
  getMoneyTableRoute,
  getReportJobList,
  getSaleEntryRoute,
  getSaleyardAuctionRoute,
  getSettingsRoute,
  getUserOverviewRoute,
} from "lib/navigation";

import {
  getIsBusinessUser,
  getIsFetchingSales,
  getIsSaleWatcher,
  getIsStaff,
  getSetting,
} from "selectors";

import { useIsMobile } from "hooks";

const NoSalesText = ({ isBusinessUser = false, followOn = false }) =>
  isBusinessUser ? null : (
    <Row justifyCenter textAlignCenter>
      {followOn ? "Or get" : "Get"} started by adding a new sale with the
      &quot;New Sale Day&quot; button in the menu.
    </Row>
  );

export function SaleyardRoutes(props) {
  const { currentSale, hasNoSales } = props;

  const match = useRouteMatch();
  const isBusinessUser = useSelector(getIsBusinessUser);
  const isSaleWatcher = useSelector(getIsSaleWatcher);
  const isStaff = useSelector(getIsStaff);
  const allSalesLoaded = useSelector(getSetting(Settings.allSalesLoaded));
  const isUpdatingSales = useSelector(getIsFetchingSales);

  const isMobile = useIsMobile();

  const dispatch = useDispatch();

  const onLoadMoreSales = () => {
    dispatch(setSetting(Settings.allSalesLoaded, true));
    dispatch(SaleAction.request({ fetchAll: true }));
  };

  const { saleyard } = match.params;

  const getCurrentSaleEntryRoute = () => {
    const {
      saleyard_name: saleyardName,
      livestocksale_id: livestockSaleId,
      sale_type: saleType,
    } = currentSale;
    let saleUrl;
    if (isBusinessUser) {
      saleUrl = `${getSaleyardAuctionRoute(
        saleyardName,
        livestockSaleId,
      )}/reports`;
    } else if (isSaleWatcher) {
      saleUrl = `${getSaleyardAuctionRoute(
        saleyardName,
        livestockSaleId,
      )}/watch`;
    } else {
      saleUrl = getSaleEntryRoute(saleyardName, livestockSaleId, saleType);
    }

    return saleUrl;
  };

  const renderNoneOrMostRecent = () => {
    if (isUpdatingSales) {
      return (
        <MessageBox>
          <LoadingSpinner subjectName="Sales" size={24} />
        </MessageBox>
      );
    } else if (hasNoSales) {
      const ButtonWrapper = isMobile ? Column : Row;
      return (
        <MessageBox>
          <Column alignCenter>
            <Row justifyCenter>There are no sales available to display.</Row>

            {allSalesLoaded ? (
              <NoSalesText isBusinessUser={isBusinessUser} />
            ) : (
              <>
                <ButtonWrapper alignCenter justifyCenter textAlignCenter>
                  Only the last 3 months of sales are loaded - click to{" "}
                  <IconTextButton
                    icon={faRepeat}
                    iconSize={25}
                    textSize={16}
                    onClick={onLoadMoreSales}
                  >
                    load more sales
                  </IconTextButton>
                </ButtonWrapper>
                <NoSalesText isBusinessUser={isBusinessUser} followOn />
              </>
            )}
          </Column>
        </MessageBox>
      );
    } else if (!currentSale) {
      return (
        <MessageBox>
          Please select the sale you would like to work with today.
        </MessageBox>
      );
    } else if (currentSale) {
      return <Redirect to={getCurrentSaleEntryRoute()} />;
    }
  };

  // When loading sales on the user overview page, we're probably going to
  // be pushing off to a saleyard after this, so show loading.
  if (match.path === getUserOverviewRoute() && isUpdatingSales) {
    return (
      <MessageBox>
        <LoadingSpinner subjectName="Sales" size={24} />
      </MessageBox>
    );
  }

  return (
    <>
      <Switch>
        {isStaff && (
          <Route exact path="/devices/" component={DevicePlayground} />
        )}
        {/* Specific direct routes */}
        <Route exact path={getDashboardRoute()} component={UserDashboard} />
        <Route path={getInboxRoute()} component={Inbox} />

        <Route
          exact
          path={getMoneyTableRoute()}
          component={MoneyTableWrapper}
        />
        <Route exact path={getReportJobList()} component={ReportJobList} />
        <Route
          exact
          path={getManageBusinessRoute()}
          component={BusinessTable}
        />
        <Route path={getSettingsRoute()} component={Settings} />
        {/* A specific sale */}
        <Route path={`${match.path}/sale/:saleId`} component={LivestockSales} />
        {/* The role picker */}
        <Route path={getUserOverviewRoute()} exact component={UserOverview} />
        {/* "Please select the sale you would like to work with today */}
        <Route exact path={match.path} render={renderNoneOrMostRecent} />
      </Switch>
      <HashRoute
        component={MultiSaleReportsModal}
        hash={ModalTypes.MultiSaleReports}
      />
      <HashRoute
        component={BillingDocumentModalAdapter}
        hash={ModalTypes.EditBillingDocument}
      />
      <HashRoute
        component={BillingDocumentActivityModalAdapter}
        hash={ModalTypes.BillingDocumentActivity}
      />
      <HashRoute component={RuleBookModalAdapter} hash={ModalTypes.RuleBook} />
      <HashRoute
        component={BillingRunModalAdapter}
        hash={ModalTypes.EditBillingRun}
      />
      <HashRoute
        component={LedgerAccountModalAdapter}
        hash={ModalTypes.EditLedgerAccount}
      />

      <HashRoute
        component={SundryTemplateModalAdapter}
        hash={ModalTypes.SundryTemplate}
      />

      <HashRoute
        component={LedgerEntryModalAdapter}
        hash={ModalTypes.EditLedgerEntry}
      />
      <HashRoute
        component={EditBusinessAdapter}
        hash={ModalTypes.EditBusiness}
        componentProps={{ saleyard }}
      />
      <HashRoute
        component={MapBusinessModal}
        hash={ModalTypes.MapBusiness}
        componentProps={{ saleyard }}
      />
      <HashRoute
        component={SaleModalAdapter}
        hash={ModalTypes.Sale}
        componentProps={{ saleyard }}
      />
      <HashRoute
        component={SingleWeighSettingsModalAdapter}
        hash={ModalTypes.SingleWeighSettings}
        componentProps={{ saleyard }}
      />
      <HashRoute
        component={ConsignToSaleModalAdapter}
        hash={ModalTypes.ConsignToSale}
      />
      <HashRoute component={CommentsModalAdapter} hash={ModalTypes.Comments} />
      <HashRoute component={AuditLogModalAdapter} hash={ModalTypes.AuditLog} />
      <HashRoute
        component={NLISReceiptModalAdapter}
        hash={ModalTypes.NLISTransferReceipt}
      />
      <HashRoute
        component={DeploymentSaleOptionsAdapter}
        hash={ModalTypes.DeploymentSaleOptions}
      />
      <HashRoute
        component={EContractModalAdapter}
        hash={ModalTypes.EContract}
      />
      <HashRoute
        component={EditUserRoleModalAdapter}
        hash={ModalTypes.EditUserRole}
      />
      <HashRoute
        component={EditIntegrationBusinessModalAdapter}
        hash={ModalTypes.EditIntegrationBusiness}
      />
      <HashRoute
        component={CreateIntegrationBusinessModalAdapter}
        hash={ModalTypes.CreateIntegrationBusiness}
      />
      <HashRoute
        component={EditNominationModalAdapter}
        hash={ModalTypes.EditNomination}
      />
      <HashRoute
        component={SelectSyncDataAdapter}
        hash={ModalTypes.SelectSyncData}
      />
      <HashRoute
        component={BillingDocumentReviewModal}
        hash={ModalTypes.BillingDocumentReview}
      />

      <HashRoute component={RuleTesterModal} hash={ModalTypes.RuleTester} />
      <HashRoute
        component={DispatchingHashModalAdapter}
        componentProps={{ modalComponent: RuleModal }}
        hash={ModalTypes.EditRule}
      />
      <HashRoute
        component={VendorSplitModalAdapter}
        hash={ModalTypes.VendorSplit}
      />

      <HashRoute
        component={DispatchingHashModalAdapter}
        componentProps={{ modalComponent: MasterRuleModal }}
        hash={ModalTypes.EditMasterRule}
      />

      <HashRoute
        component={DispatchingHashModalAdapter}
        componentProps={{ modalComponent: RuleLibraryModalAdapter }}
        hash={ModalTypes.RuleLibrary}
      />
      <HashRoute
        component={ImportSummaryAdapter}
        hash={ModalTypes.ImportSummary}
      />
      <HashRoute component={InterestModalAdapter} hash={ModalTypes.Interest} />

      <HashRoute
        component={DispatchingHashModalAdapter}
        componentProps={{ modalComponent: RetainProceedsModalAdapter }}
        hash={ModalTypes.RetainProceeds}
      />

      <HashRoute
        component={EmailReportJobModalAdapter}
        hash={ModalTypes.EmailReportJobModal}
      />
    </>
  );
}
