import React, { memo, useCallback } from "react";

import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Column } from "components/Layout";
import TabRow from "components/TabSelector/TabRow";

import { DeploymentPermissions } from "constants/permissions";
import { userTypes } from "constants/users";

import { SecurityTrimmedNavigationTab } from "containers/LivestockSales/SecurityTrimmedNavigationTab";

import { isUserOfType } from "lib/auth";
import {
  getLivestockSaleId,
  getSaleyardAuctionRoute,
  getSaleyardName,
} from "lib/navigation";
import { someHasPermission } from "lib/permissions";

import { getAuth, selectRoleCurrentDeployments } from "selectors";

const getTabs = auth => [
  {
    id: "billing",
    title: "Billing",
    display: Boolean(isUserOfType([userTypes.LIVESTOCK_AGENT], auth)),
    component: SecurityTrimmedNavigationTab,
    hasPermissionSelector: state =>
      someHasPermission(selectRoleCurrentDeployments(state), [
        DeploymentPermissions.featureBilling,
        DeploymentPermissions.featureManualCharges,
      ]),
  },
];

function ReferenceSaleTabsComponent() {
  const auth = useSelector(getAuth);

  const { params } = useRouteMatch();
  const history = useHistory();

  const setSection = useCallback(
    section => {
      history.push(
        `${getSaleyardAuctionRoute(
          getSaleyardName(),
          getLivestockSaleId(),
        )}/${section}`,
      );
    },
    [history],
  );

  const { section } = params;

  return (
    <Column printHidden>
      <TabRow
        tabs={getTabs(auth)}
        selectedTab={section}
        setSelectedTab={setSection}
        backgroundColor="gray95"
      />
    </Column>
  );
}

export default memo(ReferenceSaleTabsComponent);
