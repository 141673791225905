import PropTypes from "prop-types";

import { useChangeHandler } from "./FormikControls/lib";

export function ChangeHandler(props) {
  const { onChange, getIsFormDirty } = props;
  useChangeHandler(onChange, getIsFormDirty);
  return null;
}

ChangeHandler.propTypes = {
  onChange: PropTypes.func.isRequired,
  getIsFormDirty: PropTypes.func,
};
