import React from "react";

import { faBaby } from "@fortawesome/free-solid-svg-icons";
import {
  faCut,
  faFileCertificate,
  faSheep,
  faWeight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  CheckBox,
  DatePicker,
  Input,
  IntegerPriceInput,
  OptionTogglerField,
  SelectField,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Row } from "components/Layout";
import { Paper } from "components/Paper";
import { useResponsiveText } from "components/ResponsiveText";
import { MediumText as Heading } from "components/Text";

import {
  Accreditation,
  FrameStructure,
  PregStatusOptions,
  ShearingStatusOptions,
  VendorBredOptions,
  WeighLocations,
} from "constants/draftingAttributes";
import { ExportSites } from "constants/exportSites";

import { getGrossSkinPrice } from "lib";

import {
  breedAllocation,
  dentitionAllocation,
  fatScoresAllocation,
  hornAllocation,
} from "lib/advancedDrafting";

import {
  getSpeciesAttributeOptions,
  selectAreStudFeaturesEnabled,
} from "selectors";

import { BreedSection } from "./Breed";
import { Error } from "./components";
import { DentitionSection } from "./Dentition";
import { FatScoreSection } from "./FatScore";
import { HornSection } from "./Horn";
import { toErrorMessage } from "./util";
import { VisibilitySection } from "./Visibility";

const Icon = styled(FontAwesomeIcon)`
  ${({ theme, color }) => `
    color: ${
      color && theme.colors[color] ? theme.colors[color] : theme.colors.white
    };
    font-size: ${theme.fontSizes.delta}px;
    margin-right: ${theme.space[1]}px;
  `};
`;

const TooltipHeading = styled(Heading)`
  margin-right: 5px;
`;

const withAttributeNamespace = name =>
  withNamespace("draftingAttributes", name);

const PregStatusSection = ({ readOnly }) => {
  const breedOptions = useSelector(
    state => getSpeciesAttributeOptions(state).breeds,
  );
  return (
    <Grid item xs={12}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Row flexGrow>
              <Icon icon={faBaby} color="gray44" />
              <Heading>Preg Status</Heading>
            </Row>
          </Grid>
          <Grid item xs={12}>
            <OptionTogglerField
              options={PregStatusOptions}
              name={withAttributeNamespace("pregStatus")}
              allowToggleOff
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="SIL"
              name={withAttributeNamespace("sil")}
              type="number"
              tooltip="Lamb scanning as a %"
              afterSymbol="%"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              name={withAttributeNamespace("joinedStart")}
              views={["year", "month"]}
              openTo="month"
              label="Joined Start"
              tooltip="When ram(s) were introduced to the breeding ewes"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              name={withAttributeNamespace("joinedEnd")}
              views={["year", "month"]}
              openTo="month"
              label="Joined End"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              label="Depastured"
              name={withAttributeNamespace("depasturedId")}
              tooltip="The ram breed introduced"
              options={breedOptions}
              isClearable
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const SheepBreedSection = ({
  errorMessage,
  showFullDrafting,
  onChangeBreed,
  readOnly,
}) => {
  return (
    <BreedSection
      errorMessage={errorMessage}
      showFullDrafting={showFullDrafting}
      onChangeBreed={onChangeBreed}
      readOnly={readOnly}
    >
      <Grid item xs={12}>
        <Input
          label="X-Bloodline"
          name={withAttributeNamespace("xBloodline")}
          tooltip="Breeding Bloodline"
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label="By - Sire"
          name={withAttributeNamespace("bySire")}
          tooltip="Breeding Ram"
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <TooltipHeading>Drop</TooltipHeading>
        <Tooltip title="Lambing birth range" />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          name={withAttributeNamespace("dropStart")}
          views={["year", "month"]}
          openTo="month"
          label="Drop Start"
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          name={withAttributeNamespace("dropEnd")}
          views={["year", "month"]}
          openTo="month"
          label="Drop End"
          disabled={readOnly}
        />
      </Grid>
    </BreedSection>
  );
};

export const ConditionSection = ({ readOnly }) => (
  <Grid item xs={12}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Row flexGrow>
          <Heading>Condition</Heading>&nbsp;
          <Tooltip title="Condition of these animals best suited for market" />
        </Row>
      </Grid>
      <Grid item xs={6} sm={4}>
        <CheckBox
          name={withAttributeNamespace("conditionStore")}
          label="Store"
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <CheckBox
          name={withAttributeNamespace("conditionForward")}
          label="Forward"
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <CheckBox
          name={withAttributeNamespace("conditionPrime")}
          label="Prime"
          disabled={readOnly}
        />
      </Grid>
    </Grid>
  </Grid>
);

export const sheepAttributes = [
  {
    fieldName: "animalHealthStatementOverride",
    label: "Animal Health Statement",
    tooltip: "Are the animals being consigned with an Animal Health Statement?",
    trueValue: true,
    falseValue: false,
  },
  {
    fieldName: "sixInOne",
    label: "6 in 1",
    tooltip: "Animals have been treated with 6 in 1 Vaccine",
    trueValue: Accreditation.ALL,
    falseValue: Accreditation.NONE,
  },
  {
    fieldName: "drenched",
    label: "Drenched",
    tooltip: "Animals have been drenched",
    trueValue: Accreditation.ALL,
    falseValue: Accreditation.NONE,
  },
  {
    fieldName: "ojdVac",
    label: "OJD Vac (Gudair)",
    tooltip: "Animals have been treated with OJD Vaccine",
    trueValue: Accreditation.ALL,
    falseValue: Accreditation.NONE,
  },
  {
    fieldName: "gudairApproved",
    label: "Gudair Approved",
    tooltip: "Animals have been subject to the Gudair approval process",
    trueValue: Accreditation.ALL,
    falseValue: Accreditation.NONE,
  },
  {
    fieldName: "b12Vac",
    label: "B12 Vac",
    tooltip: "Animals have been treated with B12 Vaccine",
    trueValue: Accreditation.ALL,
    falseValue: Accreditation.NONE,
  },
  {
    fieldName: "backline",
    label: "Backline / Dipped",
    tooltip: "Animals have been backlined or dipped",
    trueValue: Accreditation.ALL,
    falseValue: Accreditation.NONE,
  },
  {
    fieldName: "MAndTS",
    label: "M & TS",
    tooltip: "Animals have been mulesed and Tails Docked",
    trueValue: Accreditation.ALL,
    falseValue: Accreditation.NONE,
  },
  {
    fieldName: "SAEligible",
    label: "SA Eligible",
    tooltip: "Animals are SA eligible",
    trueValue: Accreditation.ALL,
    falseValue: Accreditation.NONE,
  },
  {
    fieldName: "painRelief",
    label: "Pain Relief",
    tooltip: "Animals have received pain relief @ mulesing",
    trueValue: Accreditation.ALL,
    falseValue: Accreditation.NONE,
  },
  {
    fieldName: "accreditationOrganic",
    label: "Organic",
    tooltip: "Refer to Additional Information on NVD",
    trueValue: Accreditation.ALL,
    falseValue: Accreditation.NONE,
  },
];

const AttributesSection = ({ readOnly }) => {
  const areStudFeaturesEnabled = useSelector(selectAreStudFeaturesEnabled);
  return (
    <Grid item xs={12}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Icon icon={faFileCertificate} color="gray44" />
            <Heading>Attributes</Heading>
          </Grid>
          {!areStudFeaturesEnabled && (
            <Grid item xs={12}>
              <Input
                label="Tag #"
                name={withAttributeNamespace("tagNumber")}
                disabled={readOnly}
              />
            </Grid>
          )}

          {sheepAttributes.map(attribute => (
            <Grid item xs={6} sm={6} key={attribute.fieldName}>
              <CheckBox
                name={withAttributeNamespace(attribute.fieldName)}
                label={attribute.label}
                tooltip={attribute.tooltip}
                trueValue={attribute.trueValue}
                falseValue={attribute.falseValue}
                disabled={readOnly}
              />
            </Grid>
          ))}

          <ConditionSection readOnly={readOnly} />
          <Grid item xs={12}>
            <TextArea
              label="Comments (Placard Text)"
              name={withAttributeNamespace("publicDescription")}
              tooltip="Any other details you wish to provide. This will display on placards"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              label="Fault Description"
              name={withAttributeNamespace("publicFaultDescription")}
              tooltip="Anything you would declare in an assessment."
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              label="Pen may be sold as part of a run"
              name={withAttributeNamespace("partOfRun")}
              tooltip="If this pen is part of a run, please state the pen numbers in the run."
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <OptionTogglerField
              label="Vendor Bred"
              labelPosition="top"
              name={withAttributeNamespace("vendorBredOverride")}
              options={VendorBredOptions}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const isEstimatedWeightOptions = [
  {
    label: "Estimate",
    value: true,
  },
  { label: "Actual", value: false },
];

const SheepWeightSection = ({ showFullDrafting, readOnly }) => {
  const estCarcassWeightLabel = useResponsiveText({
    mobile: "Cwt",
    tablet: "Est Carcass Weight",
    desktop: "Est Carcass Weight",
  });
  const estLiveWeightLabel = useResponsiveText({
    mobile: "Lwt",
    tablet: "Est Live Weight",
    desktop: "Est Live Weight",
  });
  const dressingPercentLabel = useResponsiveText({
    mobile: "Dressing %",
    tablet: "Dressing Percent",
    desktop: "Dressing Percent",
  });

  return (
    <Grid item xs={12}>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Icon icon={faWeight} color="gray44" />
            <TooltipHeading>Weight</TooltipHeading>
            <Tooltip title="Declare the weight, location and accuracy of your weighing" />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Minimum"
              name={withAttributeNamespace("minMassGrams")}
              type="number"
              afterSymbol="Kg"
              multiplier={1000}
              decimalPlaces={3}
              decimal
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Maximum"
              name={withAttributeNamespace("maxMassGrams")}
              type="number"
              afterSymbol="Kg"
              multiplier={1000}
              decimalPlaces={3}
              decimal
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Average"
              name="estimatedAverageMassGrams"
              type="number"
              afterSymbol="Kg"
              multiplier={1000}
              decimalPlaces={3}
              decimal
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <OptionTogglerField
              options={isEstimatedWeightOptions}
              name={withAttributeNamespace("isEstimatedWeight")}
              disabled={readOnly}
            />
          </Grid>
          {showFullDrafting && (
            <Grid item xs={12}>
              <OptionTogglerField
                options={Object.values(WeighLocations)}
                name={withAttributeNamespace("weighLocation")}
                disabled={readOnly}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <Input
              label={estCarcassWeightLabel}
              name={withAttributeNamespace("estCarcassWeight")}
              type="number"
              afterSymbol="Kg"
              multiplier={1000}
              decimalPlaces={2}
              decimal
              tooltip="Estimated Carcass Weight"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label={estLiveWeightLabel}
              name="estimatedAverageMassGrams"
              type="number"
              afterSymbol="Kg"
              multiplier={1000}
              decimalPlaces={3}
              decimal
              tooltip="Estimated Live Weight"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label={dressingPercentLabel}
              name={withAttributeNamespace("dressingPercent")}
              type="number"
              afterSymbol="%"
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const ShearingSection = ({ skinCost, pricingEntryExists, readOnly }) => (
  <Grid item xs={12}>
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Icon icon={faCut} color="gray44" />
          <Heading>Shearing</Heading>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          name={withAttributeNamespace("shearing")}
          views={["year", "month"]}
          openTo="month"
          label="Shearing Date"
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          label="Shearing Status"
          name={withAttributeNamespace("shearingStatus")}
          options={ShearingStatusOptions}
          isClearable
          disabled={readOnly}
        />
      </Grid>
      {pricingEntryExists && (
        <Grid item xs={12}>
          <IntegerPriceInput
            label="Skin Value"
            name="skin_price"
            decimalPlaces={2}
            placeholder={skinCost}
            disabled={readOnly}
          />
        </Grid>
      )}
    </Paper>
  </Grid>
);

const FrameSection = ({ errorMessage, readOnly }) => (
  <Grid item xs={12}>
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Row flexGrow>
            <Icon icon={faSheep} color="gray44" />
            <Heading>Frame Structure</Heading>
            {errorMessage && <Error>{errorMessage}</Error>}
          </Row>
        </Grid>
        <Grid item xs={12}>
          <OptionTogglerField
            name={withAttributeNamespace("frameStructure")}
            options={Object.values(FrameStructure)}
            allowToggleOff
            disabled={readOnly}
          />
        </Grid>
      </Grid>
    </Paper>
  </Grid>
);

export const Sheep = ({ saleLot, onChangeBreed, readOnly }) => {
  const allocations = {
    fatScores: fatScoresAllocation(saleLot),
    dentition: dentitionAllocation(saleLot),
    breed: breedAllocation(saleLot),
    horn: hornAllocation(saleLot),
  };

  const pricingEntryExists = saleLot.pricing_entries?.length > 0;
  const skinCost = getGrossSkinPrice(saleLot);

  const showFullDrafting =
    !saleLot.exportSites?.every(site => site === ExportSites.STOCK_LIVE) ||
    saleLot.exportSites.length === 0;

  return (
    <>
      <VisibilitySection
        consignmentId={saleLot.consignment_id}
        readOnly={readOnly}
      />
      <SheepBreedSection
        errorMessage={toErrorMessage(allocations.breed)}
        showFullDrafting={showFullDrafting}
        onChangeBreed={onChangeBreed}
        readOnly={readOnly}
      />
      {showFullDrafting && (
        <DentitionSection
          errorMessage={toErrorMessage(allocations.dentition)}
          zeroLabel="Lamb"
          readOnly={readOnly}
        />
      )}
      <SheepWeightSection
        showFullDrafting={showFullDrafting}
        readOnly={readOnly}
      />
      {showFullDrafting && (
        <FatScoreSection
          errorMessage={toErrorMessage(allocations.fatScores)}
          maxScore={5}
          readOnly={readOnly}
        />
      )}
      {showFullDrafting && (
        <HornSection
          errorMessage={toErrorMessage(allocations.horn)}
          quantity={saleLot.quantity}
          onlyHornedOrPolled
          readOnly={readOnly}
        />
      )}
      <PregStatusSection readOnly={readOnly} />
      <ShearingSection
        skinCost={skinCost}
        pricingEntryExists={pricingEntryExists}
        readOnly={readOnly}
      />
      {showFullDrafting && <FrameSection readOnly={readOnly} />}
      <AttributesSection readOnly={readOnly} />
    </>
  );
};
