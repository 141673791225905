import { serializeMap as billingDocumentSerializeMap } from "lib/serializers/billingDocuments";
import { manualAdjustmentSerializeMap } from "lib/serializers/manualAdjustments";
import { serializeMap as paymentSerializeMap } from "lib/serializers/payments";
import { buildDeserializer } from "lib/serializerUtils";

const billingDocumentModifiedActivityMap = {
  historyChangeReason: "history_change_reason",
  historyDate: "history_date",
  description: "description",
};

const activityPaymentSerializeMap = {
  invoiceNumber: "invoice_number",
  ...paymentSerializeMap,
};

const activitySerializeMap = {
  created: {
    billingDocumentId: "id",
    date: "date",
    invoiceNumber: "invoice_number",
  },
  payments: activityPaymentSerializeMap,
  retainedProceeds: billingDocumentSerializeMap,
  reversal: billingDocumentSerializeMap,
  interest: manualAdjustmentSerializeMap,
  modified: billingDocumentModifiedActivityMap,
  creditNotes: activityPaymentSerializeMap,
};

const serializeMap = {
  activity: activitySerializeMap,
};

const nestedObjectMap = {
  retained_proceeds: "retainedProceeds",
  credit_notes: "creditNotes",
};

export const deserializeBillingDocumentActivity = buildDeserializer(
  serializeMap,
  "billingDocumentActivity",
  nestedObjectMap,
);
