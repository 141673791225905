import React from "react";

import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";
import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";

import { BusinessModalSection } from "constants/navigation";

import { openEditBusinessModal } from "lib/navigation";

export const BusinessAbnWarningsCellRenderer = ({
  value: warningsList,
  data,
}) => {
  if (!data) {
    return null;
  }

  const { business } = data;

  const onClickEdit = () =>
    openEditBusinessModal(
      business.id,
      null,
      BusinessModalSection.FINANCE_AND_ACCOUNTING,
    );

  if (warningsList.length) {
    return (
      <ActionButtonContainer>
        <ActionButton onClick={onClickEdit} className="flex items-center">
          <FaIcon icon={faExclamationCircle} color="warning" />
          &nbsp; {warningsList.join(", ")}
        </ActionButton>
      </ActionButtonContainer>
    );
  }
};
