import { createSelector } from "reselect";

import { DocumentTypes } from "constants/documentTypes";
import { PricingTypes } from "constants/pricingTypes";
import {
  auctionPenStatuses,
  auctionPenStatusesOrder,
  saleLotStatuses,
} from "constants/sale";

import {
  calculateUnitPriceCents,
  formatDecimal,
  getUnitPriceString,
} from "lib";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { isFileOfTypeMedia } from "lib/media";
import {
  getAverageWeightFormatted,
  getCombinedLotNumber,
  getSaleLotScannedStatus,
  getSaleLotStatus,
  getScanStatusThreshold,
  getTotalWeightFormatted,
} from "lib/saleLot";
import { scanHasIssues } from "lib/scans";
import { isUUID } from "lib/uuid";

// Default to 1 hr (milliseconds) between cache expiry
const DefaultCacheExpiry = 3600000;

function buildIndexValueChangeDiff(aMap, bMap, idKey, valueKey) {
  const added = new Set();
  // by default, flag everything as dirty, and in need of removing
  const removed = new Set(Object.values(bMap));

  const aValues = Object.values(aMap);
  const valueCount = aValues.length;

  for (let i = 0; i < valueCount; i++) {
    const aValue = aValues[i];
    const id = aValue[idKey];
    const bValue = bMap[id];
    if (aValue === bValue) {
      // If they are the same
      // remove it from the unresolved list and continue to the next item (the most likely scenario)
      removed.delete(bValue);
    } else if (bValue && bValue !== aValue) {
      // The object may have had a referential change, but the indexed value may still be the same
      // e.g. a Sale Lot has had its quantity changed, but this is indexed on consignmentId, so ignore that change
      if (aValue[valueKey] !== bValue[valueKey]) {
        // The indexed value has changed, invalidate this one.
        // We are implicitly removing the previous value by not removing it from the unresolved list
        added.add(aValue);
      } else {
        // Referential equality fails, however the indexed value hasn't changed, so there are no effective changes
        // remove it from the unresolved list
        removed.delete(bValue);
      }
    } else {
      // when bMap does not contain the corresponding value from aMap
      // flag it as added/new
      added.add(aValue);
    }
  }
  return [added, removed];
}

/**
 * Indexes one to many relationships
 * @param {function(state: State): SelectedState} idIndexSelector
 * @param {string} aggregateKey used to determine the key to cache the value in
 * @param {string} [idKey="id"] used to determine the value which is cached in the array
 * @param {number} [cacheExpiry=DefaultCacheExpiry] time in milliseconds between hard cache flushes, defaults to 1 hour
 * @template State, SelectedState
 * @returns {function(State): Object<string,array<string>>}
 */
export function createAggregateIdsByKeySelector(
  idIndexSelector,
  aggregateKey,
  idKey = "id",
  cacheExpiry = DefaultCacheExpiry,
) {
  let lastCacheTime = 0;
  let lastSelected = {};
  let lastResult = {};
  return function aggregateIdsByKey(state) {
    const selected = idIndexSelector(state);
    const isCacheExpired = Date.now() > lastCacheTime + cacheExpiry;
    // When the state is the same, and the cache hasn't expired, return the last cached value
    if (lastSelected === selected && !isCacheExpired) {
      return lastResult;
    }

    if (isCacheExpired) {
      lastResult = {};
      lastSelected = {};
      lastCacheTime = Date.now();
    }

    const [addedItems, removedItems] = buildIndexValueChangeDiff(
      selected,
      lastSelected,
      idKey,
      aggregateKey,
    );

    if (addedItems.size === 0 && removedItems.size === 0) {
      lastSelected = selected;
      return lastResult;
    }

    const result = Object.assign({}, lastResult);

    for (const value of removedItems) {
      const aggregateOnValue = value[aggregateKey];
      const indexedValue = value[idKey];
      const indexList = result[aggregateOnValue].slice();
      if (indexList.length === 1) {
        delete result[aggregateOnValue];
      } else {
        indexList.splice(indexList.indexOf(indexedValue), 1);
        result[aggregateOnValue] = indexList;
      }
    }
    for (const value of addedItems) {
      const aggregateOnValue = value[aggregateKey];
      const origiDigiIndexList = lastResult[aggregateOnValue];
      let indexList = result[aggregateOnValue];
      if (!Array.isArray(indexList)) {
        indexList = [value[idKey]];
      } else if (origiDigiIndexList === indexList) {
        // Make sure a new array is returned.
        indexList = indexList.concat(value[idKey]);
      } else {
        // Push to array after we are sure its a new one and will
        indexList.push(value[idKey]);
      }
      result[aggregateOnValue] = indexList;
    }

    lastResult = result;
    lastSelected = selected;
    return lastResult;
  };
}

/**
 * Indexes one to one relationships
 * @param {function(state: State): SelectedState} idIndexSelector
 * @param {string} indexKey used to determine the key to cache the value on
 * @param {string} [valueKey="id"] used to determine the value which is cached
 * @param {number} [cacheExpiry=DefaultCacheExpiry] time in milliseconds between hard cache flushes, defaults to 1 hour
 * @template State, SelectedState, ResultType
 * @returns {function: Object<string,ResultType>}
 */
export function createIdByKeySelector(
  idIndexSelector,
  indexKey,
  valueKey = "id",
  cacheExpiry = DefaultCacheExpiry,
) {
  let lastCacheTime = 0;
  let lastSelected = {};
  let lastResult = {};
  return function idByKey(state) {
    const selected = idIndexSelector(state);
    const isCacheExpired = Date.now() - lastCacheTime < cacheExpiry;
    // When the state is the same, and the cache hasn't expired, return the last cached value
    if (lastSelected === selected && !isCacheExpired) {
      return lastResult;
    }

    if (isCacheExpired) {
      lastResult = {};
      lastSelected = {};
      lastCacheTime = Date.now();
    }

    const [addedItems, removedItems] = buildIndexValueChangeDiff(
      selected,
      lastSelected,
      indexKey,
      valueKey,
    );

    if (addedItems.size === 0 && removedItems.size === 0) {
      lastSelected = selected;
      return lastResult;
    }

    const result = { ...lastResult };
    lastResult = result;
    lastSelected = selected;

    for (const value of removedItems) {
      delete result[value[indexKey]];
    }

    for (const value of addedItems) {
      result[value[indexKey]] = value[valueKey];
    }

    return lastResult;
  };
}

export const createIdByKeySelectorAndGetter = (
  idIndexSelector,
  indexKey,
  valueKey = "id",
  cacheExpiry = DefaultCacheExpiry,
) => {
  const lookup = createIdByKeySelector(
    idIndexSelector,
    indexKey,
    valueKey,
    cacheExpiry,
  );

  const getter = id => state => lookup(state)[id];
  return [lookup, getter];
};

/**
 * Recombines indexes across two levels of indirection.
 * Example: getConsignmentsByAuctionPenId, where the two level of indirection are Auction Pen to Sale Lot, and Sale Lot to Consignment
 * arguments for example would be consignmentsBySaleLotId, saleLotIdsByAuctionPenId
 * @param {Object.<yId, xId>} xByYId
 * @param {Object.<zId, yId>} yByZId
 * @returns {Object.<zId, Type>}
 * @template Type, xId, yId, zId
 */
export function reduceXByZId(xByYId, yByZId) {
  return Object.entries(yByZId).reduce((acc, [zId, yId]) => {
    acc[zId] = xByYId[yId];
    return acc;
  }, {});
}

/**
 * Recombines indexes across three levels of indirection.
 * Example: getConsignmentsByAgencyId, where the three level of indirection are Consignment to Deployment Sale, Deployment Sale to Deployment, and Deployment to Agency
 * arguments for example would be consignmentsByDeploymentSaleId, deploymentIdsByDeploymentSaleId, agencyIdByDeploymentId
 * @param {Object.<xId, Type>} wByXId
 * @param {Object.<yId, xId>} xByYId
 * @param {Object.<zId, yId>} yByZId
 * @returns {Object.<zId, Type>}
 * @template Type, xId, yId, zId
 */
export function reduceWByZId(wByXId, xByYId, yByZId) {
  return Object.entries(yByZId).reduce((lookup, [zId, yId]) => {
    const xId = xByYId[yId];
    lookup[zId] = wByXId[xId];
    return lookup;
  }, {});
}

export const scansWithHasIssue = (saleLot, scans) => {
  return scans.map(scan => ({
    ...scan,
    hasIssues: scanHasIssues(scan, saleLot.consignment?.vendor_property?.PIC),
  }));
};

export const isAttachmentCompleted = (attachment, consignment) => {
  if (!attachment) {
    return false;
  }
  switch (attachment.type) {
    case DocumentTypes.NVD:
      return !!consignment.declaration;
    case DocumentTypes.OTHER:
    case DocumentTypes.ANIMAL_HEALTH_STATEMENT:
      return true;
    case null:
    default:
      return false;
  }
};

export const formatSaleLot = (
  saleLot,
  scansBySaleLot,
  agencyByConsignmentIdLookup = {},
  markOrderBySaleLotIdLookup = {},
  speciesId,
  isWeighedBySaleLotIdLookup,
  saleyard,
) => {
  const saleLotScans = scansBySaleLot[saleLot.id] || [];
  const scannedCount = saleLotScans ? saleLotScans.length : 0;
  const startPen = saleLot.auction_pen ? saleLot.auction_pen.start_pen : "";
  const endPen = saleLot.auction_pen ? saleLot.auction_pen.end_pen : "";
  const penOrder = saleLot.auction_pen ? saleLot.auction_pen.order : "";
  const hasAgriNousUser = Boolean(saleLot.buyer?.businessUsers?.length);
  const agency = agencyByConsignmentIdLookup[saleLot.consignment_id];
  const markOrder = markOrderBySaleLotIdLookup[saleLot.id];
  const vendorDisplayName = saleLot.vendor.publicDisplayName || "";
  const isWeighed = isWeighedBySaleLotIdLookup?.[saleLot.id];

  return {
    draftingAttributes: saleLot.draftingAttributes,
    agency,
    agencyName: agency?.name || "",
    agencyShortName: agency?.shortName || "",
    attachments: saleLot.attachments || [],

    bidder: saleLot.bidder || {},
    buyer: saleLot.buyer,
    buyerName: saleLot.buyer ? saleLot.buyer.name : "",
    buyerId: saleLot.buyer_id,
    buyerWayName: saleLot.buyer_way ? saleLot.buyer_way.name : "",
    buyerWayId: saleLot.buyer_way_id || "",
    centsPerKilo: saleLot.centsPerKilo,

    consignmentId: saleLot.consignment_id,
    dollarsPerHead: saleLot.dollarsPerHead,
    EIDNLISDetails: saleLot.EIDNLISDetails,
    endPen,

    firstSold: saleLot.first_sold,
    id: saleLot.id,
    imageCount: saleLot.imageCount,
    indCentsPerKilo: saleLot.indCentsPerKilo,

    isLaneStart: saleLot.auction_pen ? saleLot.auction_pen.isLaneStart : false,

    livestocksale_id: saleLot.livestocksale_id,
    lotNumber: saleLot.lot_number,
    lotNumberCombined: getCombinedLotNumber(saleLot),
    lotNumberSuffix: saleLot.lotNumberSuffix,

    markOrder,

    overflowPen: saleLot.overflowPen ? saleLot.overflowPen : "",
    overflowQuantity: saleLot.overflowQuantity,

    permissions: saleLot.permissions,

    penName: getAuctionPenDisplayName(saleLot.auction_pen),
    penOrder,
    quantityProgeny: saleLot.quantityProgeny ? saleLot.quantityProgeny : 0,

    quantity: saleLot.quantity,
    quantityDelivered: saleLot.quantity_delivered,
    quantityUndelivered:
      saleLot.quantity + saleLot.quantityProgeny - saleLot.quantity_delivered,

    saleRoundId: saleLot.sale_round_id,
    saleRoundName: saleLot.sale_round ? saleLot.sale_round.name : "",
    saleyardId: saleLot.saleyardId,
    scannedCount,
    scannedPercentage:
      saleLot.quantity > 0 || saleLot.quantityProgeny > 0
        ? scannedCount / (saleLot.quantity + saleLot.quantityProgeny)
        : 0,
    scannedStatus: getSaleLotScannedStatus(
      saleLot,
      saleLotScans.length,
      getScanStatusThreshold(speciesId, saleyard),
    ),
    scans: saleLotScans,
    startPen,
    status: getSaleLotStatus(saleLot, saleLot.consignment),

    thirdPartyId: saleLot.thirdPartyId,
    thirdPartyName: saleLot.thirdPartyName,

    vendorName: saleLot.vendor ? saleLot.vendor.name : "",
    vendorPublicDisplayName: vendorDisplayName || "",

    breedName: saleLot.breed.name || "",
    ageName: saleLot.age.name || "",
    sexName: saleLot.sex.name || "",
    gradeName: saleLot.grade.name || "",
    categoryName: saleLot.category.name || "",
    estimatedAverageMassGrams: saleLot.estimatedAverageMassGrams || 0,
    marks: saleLot.marks || [],
    note: saleLot.note || "",
    price: getUnitPriceString(saleLot),
    priceCents: calculateUnitPriceCents(saleLot),
    priceUnits: PricingTypes.toString(saleLot.pricing_type_id),
    auctionPenId: saleLot.auction_pen_id,
    destinationPropertyId:
      saleLot.destinationProperty && saleLot.destinationProperty.id,
    destinationProperty:
      (saleLot.destinationProperty && saleLot.destinationProperty.PIC) || "",
    nlisTakeFileStatus: saleLot.nlisTakeFileStatus,
    nlisSellFileStatus: saleLot.nlisSellFileStatus,
    justSynced: saleLot.justSynced,
    totalMassGrams: saleLot.total_mass_grams,
    averageWeight: formatDecimal(
      saleLot.quantity > 0
        ? saleLot.total_mass_grams / saleLot.quantity / 1000
        : 0,
    ),
    weight: formatDecimal(saleLot.total_mass_grams / 1000),
    pricePerHead: formatDecimal(
      saleLot.quantity > 0
        ? saleLot.total_price_cents / saleLot.quantity / 100
        : 0,
    ),
    totalPrice: saleLot.total_price_cents / 100,
    pricingType: saleLot.pricing_type_id,
    hasAgriNousUser,
    averageWeightFormatted: getAverageWeightFormatted(saleLot),
    totalWeightFormatted: getTotalWeightFormatted(saleLot),
    labels: saleLot.labels || [],
    youtube_link: saleLot.youtube_link,
    isWeighed,
  };
};

export const getConsignmentId = (state, consignmentId) => consignmentId;

export const getStatusForListOfStatuses = statuses => {
  if (statuses.every(status => status === statuses[0])) {
    if (statuses[0] === saleLotStatuses.NOT_COUNTED) {
      return auctionPenStatuses.NOT_COUNTED;
    } else if (statuses[0] === saleLotStatuses.NOT_PENNED) {
      return auctionPenStatuses.NOT_PENNED;
    }
    return statuses[0];
  }
  if (statuses.includes(saleLotStatuses.NOT_COUNTED)) {
    return auctionPenStatuses.NOT_COUNTED;
  }
  if (statuses.includes(saleLotStatuses.DELIVERED)) {
    return auctionPenStatuses.PARTIALLY_DELIVERED;
  }
  if (statuses.includes(saleLotStatuses.SOLD)) {
    return auctionPenStatuses.PARTIALLY_SOLD;
  }
  statuses.sort(
    (a, b) =>
      auctionPenStatusesOrder.indexOf(a) - auctionPenStatusesOrder.indexOf(b),
  );
  return statuses[0];
};

export const getStatusForSaleLots = saleLots =>
  getStatusForListOfStatuses(saleLots.map(saleLot => saleLot.status));

export function createLookupSelectors(
  selectors,
  combiner,
  defaultGetterValue = null,
) {
  const lookupSelector = createSelector(selectors, combiner);

  const lookupGetter = id => state => {
    const value = lookupSelector(state)[id];
    return typeof value === "undefined" ? defaultGetterValue : value;
  };

  return [lookupSelector, lookupGetter];
}

const defaultFilterFunc = () => true;
export const createLookupCombiner =
  (reducer, filterFunc = defaultFilterFunc) =>
  (rootValue, ...otherValues) =>
    Object.entries(rootValue)
      .filter(filterFunc)
      .reduce((acc, [rootId, rootValue]) => {
        acc[rootId] = reducer.apply(undefined, [rootValue, ...otherValues]);
        return acc;
      }, {});

// check to see whether media attachments exist on any object, and return the
// object ID if true
export const selectObjectHasMediaAttachmentLookup = (
  objectTypeSelector,
  fileSelector,
  attachmentIdsSelector,
  fileTypeExtensions,
  additionalValueCheck,
) => {
  return createSelector(
    [objectTypeSelector, fileSelector, attachmentIdsSelector],
    (objects, files, attachmentIds) => {
      return Object.values(objects).reduce((acc, object) => {
        const value =
          attachmentIds[object.id]
            ?.map(id => isFileOfTypeMedia(files[id], fileTypeExtensions))
            .some(value => value === true) || false;
        if (additionalValueCheck) {
          acc[object.id] = additionalValueCheck(object, value);
        } else {
          acc[object.id] = value;
        }
        return acc;
      }, {});
    },
  );
};

const isNumberRegex = /\d+/;

export const getIsNumberId = ([id, ignored]) => isNumberRegex.test(id);

export const getEntryKeyIsUUID = ([id, ignored]) => isUUID(id);

export const objectMapper = (objectCbids, objects) =>
  objectCbids?.map(cbId => objects[cbId]) || [];
