import { ColumnType } from "components/AgGrid/constants";

const SaleDataIds = {
  age: {
    name: "d49e30f7-d5c6-4b84-88ac-af087158fcc2",
  },
  animal: {
    calculated_weight_grams: "4fab2f00-6eef-4d42-b9c3-2e38d15a7fba",
    eid: "efaf9b09-30d9-4a42-8b80-0a25feb8a700",
    is_weighed: "3845562c-840b-4f63-93a3-33b920e3dbd5",
    total_weight_grams: "8c2aa177-03b0-490a-b120-d41e53cfe82d",
  },
  auction_pen: {
    end_pen: "cd57f045-7aa9-42c8-9f3f-fe75badd2e21",
    start_pen: "062eb9d2-7248-401b-83a7-1ab56fac870a",
    name: "a67de508-4b7d-4534-a4b9-6cdefae6ccdb",
  },

  breed: {
    name: "75791519-b406-4bb1-8737-b446d736235f",
  },
  buyer: {
    buyer_way: "86fb52f4-3443-4288-b6a0-f63c6f31e3cd",
    commission_offset: "8d962bb9-81e7-490f-bc0d-f13a2214f579",
    effective_commission_rate: "f4830008-f125-4dae-8f08-3a3e803df616",
    has_debtor_insurance: "74ad57d6-71e9-4750-901b-bd6df1bd5e33",
    has_transit_insurance: "fef78e81-77d2-40d9-b0f2-14484921b576",
    is_gst_registered: "f65d3e5f-9e17-4adb-a72d-055305382157",
    is_hobby_farmer: "df41efa9-ce83-4233-ad0e-85b8c65fa10c",
    is_meat_buyer: "8998afb7-53e6-4fa8-859a-71091a487fd8",
    name: "b1882bcf-d6bd-487f-b0b1-9fb59a7ac8bc",
    registration_number: "82b6f202-aeda-427a-94f5-9ebd38e74ef7",
    should_charge_gst: "399531bd-ad03-4706-8985-8fdad8e42686",
  },
  buyer_property: {
    pic: "abc29e51-53c1-4cfd-a08c-586ec76eacd4",
  },
  consignment: {
    total_hd_nominated: "a518e45c-7ca2-4d18-942b-b6ea7b8b42fe",
  },

  deployment: {
    commission_offset: "ccfe3a69-b9c6-4a28-a027-7607b70d5c1c",
    default_commission: "4441fb17-d9e4-4e9c-86a4-3284d2e6e4c6",
    default_debtor_insurance: "0590235b-ebc1-41b2-a7c9-b628e55c64a7",
    default_interstate_transit_insurance:
      "2928c194-94d1-4ddf-b910-51ba18d0f3f7",
    default_transit_insurance: "614504c1-4395-416f-9a5f-ccfd5e2fb4b5",
    effective_commission_rate: "b1d405ee-5a79-47e4-89db-1395b81db50f",
    has_debtor_insurance: "0b6deb6e-53ae-4672-90ee-f3dd53488c13",
    has_transit_insurance: "348a17eb-9615-4626-b128-8d2cea9af0ff",
    is_gst_registered: "7433d837-8ca4-4756-b3b0-dc5ff80ae4e4",
    is_hobby_farmer: "8bc6f559-bf7c-48a1-8b30-d8cafcfea1a0",
    name: "56b82d78-4fe2-46e5-a2e1-71247ff3627d",
    portion_of_sale_by_agencies_involved:
      "75558895-d4e7-4af8-ac61-a698b63d38fd",
    portion_of_sale_by_quantity_exc_progeny:
      "27a90f39-5390-491e-92f8-64e4125d8940",
    should_charge_gst: "69c2ae9e-b804-465e-b413-c002afd64877",
  },
  lot: {
    animal_name: "3154afd4-5af2-4dad-9700-405e57b91e65",
    average_weight_grams: "796fa310-0d85-469c-aec7-20e28f4705e4",
    cents_per_head: "5bf606bd-38d0-4851-b66a-def5d3aef01b",
    clearing_sale_status: "8eba007a-c879-4c5a-b9d8-5f7d972d0f2f",
    commercial_category: "80285643-6548-4e42-955d-24b606cf0fd3",
    effective_buyers_premium_cents: "30fc2f1e-5f3f-40bd-974b-cdc0fcce9446",
    effective_listing_fee_cents: "ddb4dce7-61d7-4c9f-a727-d83a3defe6ef",
    effective_vendor_commission_cents: "2438b9ae-18a0-438d-9010-dff84dc5fbc2",
    is_gst_exempt: "0824685f-75ea-45b0-9952-c8beb15996dd",
    is_no_sale: "5f4fe179-d1f4-49b4-993f-d37c4f74e7af",
    is_sold: "876da7b9-bec4-41d7-92a8-8f75c04ae032",
    is_weighed: "11d20a8c-b8f4-4bdb-b076-834d8f5cf005",
    labels: "802e48df-145b-47f5-8823-fc705fdb0290",
    lot_number: "64319a07-900c-4450-b716-06e240df3194",
    should_charge_gst: "96b11ae5-52e6-4d3b-a8a5-cd1703e93eb0",
    tag_number: "30de5527-f9a9-4493-8147-9f26ff3122f7",
    title: "07c373db-c08b-4e02-9118-dee1389e4fc9",
    total_hd_exc_progeny: "fd88219f-68c3-4bc1-b3ca-fb8ec8a7421d",
    total_hd_inc_progeny: "f7b13989-3768-480b-9550-1c83e5c22881",
    total_hd_progeny: "fbc8610d-0bc1-4729-a397-30c1af71e612",
    total_price_cents: "1525ba08-d998-405b-959e-a1fd83c0e4ed",
    total_tags_used: "352d121d-0610-459e-80f9-f90eaae06815",
    total_weight_grams: "#gen.uuid#",
  },

  round: {
    name: "91ed4521-fc0b-4c6c-a5ff-cb2d91a7cced",
  },
  sale: {
    code: "d26e2e8a-3dcd-4ee5-8b40-1ac9184d0162",
    date: "d939e9d8-3b75-48e8-b441-5e75e81854a4",
    display_name: "3e796e4f-eec1-4a7b-acb6-820c929cf011",
    export_sites: "fa20561c-1de0-4826-b700-645dd8f52c46",
    sub_type: "50869aca-e56d-4df5-a43c-ba88eac1b674",
    type: "d33aa93f-fce4-4096-b87b-01b298796758",
  },
  sex: {
    name: "8d261377-405e-44af-b308-3b14dac20c36",
  },
  species: {
    name: "84d0a95c-e1f2-4c15-a191-d02e5a0569b0",
  },
  vendor: {
    buyer_way: "3ca71ca5-6678-4e37-96e1-99b3353e6311",
    commission_offset: "78fa47b1-6b99-47ac-9183-791483c28cfc",
    effective_commission_per_head: "39b39731-2f41-4fb3-a322-881040dd4177",
    effective_commission_percentage: "b27c61e9-b490-4bff-8883-44c20861040f",
    effective_commission_rate: "22bd6435-aa00-469b-94e2-2f496842a93c",
    has_debtor_insurance: "3d5c03e9-f420-4cee-a988-92477881db48",
    has_transit_insurance_for_sales: "9515a4fc-2db7-4197-87b5-0bd6b2ab28e0",
    has_transit_insurance_for_purchases: "8a94860a-bf29-4059-bdf5-c1541c507aa5",
    is_gst_registered: "a8430d51-7f37-4e69-80c3-ab9730b6b3c1",
    is_hobby_farmer: "0fcf0d11-d59e-473a-a1fc-712fa594b38d",
    name: "a1206234-8d31-47b3-b587-6dfa0bb68cde",
    registration_number: "15afb71b-0b0e-4e51-b168-24f9c0ce2e0d",
    should_charge_gst: "b10e047a-991f-4078-b5b0-e9000878ecc9",
  },
  vendor_property: {
    pic: "8e5680e1-988a-435c-b69f-b11fb778af80",
  },
  yard: {
    name: "172a9f36-ac82-4666-9566-e51f320a236c",
  },
};
export const AgeColumnDef = {
  headerName: "Age",
  children: [
    {
      colId: SaleDataIds.age.name,
      field: "age.name",
      headerName: "Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
  ],
};

export const AnimalColumnDef = {
  headerName: "Animal",
  children: [
    {
      colId: SaleDataIds.animal.calculated_weight_grams,
      field: "animal.calculated_weight_grams",
      headerName: "Calculated Weight",
      type: ColumnType.WEIGHT_FROM_GRAMS,
    },
    {
      colId: SaleDataIds.animal.eid,
      field: "animal.eid",
      headerName: "EID",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
    {
      colId: SaleDataIds.animal.is_weighed,
      field: "animal.is_weighed",
      headerName: "Weighed?",
      type: ColumnType.BOOLEAN,
    },
    {
      colId: SaleDataIds.animal.total_weight_grams,
      field: "animal.total_weight_grams",
      headerName: "Weight",
      type: ColumnType.WEIGHT_FROM_GRAMS,
    },
  ],
};
export const AuctionPenColumnDef = {
  headerName: "Auction Pen",
  children: [
    {
      colId: SaleDataIds.auction_pen.name,
      field: "auction_pen.name",
      headerName: "Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
  ],
};
export const BreedColumnDef = {
  headerName: "Breed",
  children: [
    {
      colId: SaleDataIds.breed.name,
      field: "breed.name",
      headerName: "Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
  ],
};
export const BuyerColumnDef = {
  headerName: "Buyer",
  children: [
    {
      colId: SaleDataIds.buyer.buyer_way,
      field: "buyer.buyer_way",
      headerName: "Buyer Way",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },

    {
      colId: SaleDataIds.buyer.commission_offset,
      field: "buyer.commission_offset",
      headerName: "Commission Offset",
      type: ColumnType.PERCENTAGE,
    },

    {
      colId: SaleDataIds.buyer.effective_commission_rate,
      field: "buyer.effective_commission_rate",
      headerName: "Effective Commission",
      type: ColumnType.PERCENTAGE,
    },

    {
      colId: SaleDataIds.buyer.has_debtor_insurance,
      field: "buyer.has_debtor_insurance",
      headerName: "Debtor Insurance?",
      type: ColumnType.BOOLEAN,
    },
    {
      colId: SaleDataIds.buyer.has_transit_insurance,
      field: "buyer.has_transit_insurance_for_sales",
      headerName: "Transit Insurance?",
      type: ColumnType.BOOLEAN,
    },
    {
      colId: SaleDataIds.buyer.is_gst_registered,
      field: "buyer.is_gst_registered",
      headerName: "GST Registered?",
      type: ColumnType.BOOLEAN,
    },
    {
      colId: SaleDataIds.buyer.is_hobby_farmer,
      field: "buyer.is_hobby_farmer",
      headerName: "Hobby Farmer?",
      type: ColumnType.BOOLEAN,
    },
    {
      colId: SaleDataIds.buyer.is_meat_buyer,
      field: "buyer.is_meat_buyer",
      headerName: "Meat Buyer?",
      type: ColumnType.BOOLEAN,
    },
    {
      colId: SaleDataIds.buyer.name,
      field: "buyer.name",
      headerName: "Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },

    {
      colId: SaleDataIds.buyer.registration_number,
      field: "buyer.registration_number",
      headerName: "Registration Number",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },

    {
      colId: SaleDataIds.buyer.should_charge_gst,
      field: "buyer.should_charge_gst",
      headerName: "Should Charge GST",
      type: ColumnType.BOOLEAN,
    },
  ],
};
export const BuyerPropertyColumnDef = {
  headerName: "Buyer Property",
  children: [
    {
      colId: SaleDataIds.buyer_property.pic,
      field: "buyer_property.pic",
      headerName: "Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
  ],
};
export const ConsignmentColumnDef = {
  headerName: "Consignment",
  children: [
    {
      colId: SaleDataIds.consignment.total_hd_nominated,
      field: "consignment.total_hd_nominated",
      headerName: "Total Head",
      type: ColumnType.NUMERIC,
    },
  ],
};

export const LotColumnDef = {
  headerName: "Lot",
  children: [
    {
      colId: SaleDataIds.lot.animal_name,
      field: "lot.animal_name",
      headerName: "Animal Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },

    {
      colId: SaleDataIds.lot.average_weight_grams,
      field: "lot.average_weight_grams",
      headerName: "Average Weight",
      type: ColumnType.NUMERIC,
    },

    {
      colId: SaleDataIds.lot.cents_per_head,
      field: "lot.cents_per_head",
      headerName: "Cents Per Head",
      type: ColumnType.CURRENCY_FROM_CENTS,
    },

    {
      colId: SaleDataIds.lot.clearing_sale_status,
      field: "lot.clearing_sale_status",
      headerName: "Clearing Sale Status",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },

    {
      colId: SaleDataIds.lot.commercial_category,
      field: "lot.commercial_category",
      headerName: "Commercial Category",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },

    {
      colId: SaleDataIds.lot.effective_buyers_premium_cents,
      field: "lot.effective_buyers_premium_cents",
      headerName: "Effective Buyers Premium",
      type: ColumnType.CURRENCY_FROM_CENTS,
    },

    {
      colId: SaleDataIds.lot.effective_listing_fee_cents,
      field: "lot.effective_listing_fee_cents",
      headerName: "Effective Listing Fee",
      type: ColumnType.CURRENCY_FROM_CENTS,
    },

    {
      colId: SaleDataIds.lot.effective_vendor_commission_cents,
      field: "lot.effective_vendor_commission_cents",
      headerName: "Effective Vendor Commission",
      type: ColumnType.CURRENCY_FROM_CENTS,
    },

    {
      colId: SaleDataIds.lot.is_gst_exempt,
      field: "lot.is_gst_exempt",
      headerName: "GST Exempt?",
      type: ColumnType.BOOLEAN,
    },

    {
      colId: SaleDataIds.lot.is_no_sale,
      field: "lot.is_no_sale",
      headerName: "No Sale?",
      type: ColumnType.BOOLEAN,
    },
    {
      colId: SaleDataIds.lot.is_sold,
      field: "lot.is_sold",
      headerName: "Sold?",
      type: ColumnType.BOOLEAN,
    },

    {
      colId: SaleDataIds.lot.is_weighed,
      field: "lot.is_weighed",
      headerName: "Weighed?",
      type: ColumnType.BOOLEAN,
    },

    {
      colId: SaleDataIds.lot.lot_number,
      field: "lot.lot_number",
      headerName: "Hd Exc Progeny",
      type: ColumnType.NUMERIC,
    },

    {
      colId: SaleDataIds.lot.should_charge_gst,
      field: "lot.should_charge_gst",
      headerName: "Weighed?",
      type: ColumnType.BOOLEAN,
    },

    {
      colId: SaleDataIds.lot.tag_number,
      field: "lot.tag_number",
      headerName: "Tag Number",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },

    {
      colId: SaleDataIds.lot.title,
      field: "lot.title",
      headerName: "Title",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },

    {
      colId: SaleDataIds.lot.total_hd_exc_progeny,
      field: "lot.total_hd_exc_progeny",
      headerName: "Hd Exc Progeny",
      type: ColumnType.NUMERIC,
    },

    {
      colId: SaleDataIds.lot.total_hd_inc_progeny,
      field: "lot.total_hd_inc_progeny",
      headerName: "Hd Inc Progeny",
      type: ColumnType.NUMERIC,
    },

    {
      colId: SaleDataIds.lot.total_hd_progeny,
      field: "lot.total_hd_progeny",
      headerName: "Hd Progeny",
      type: ColumnType.NUMERIC,
    },

    {
      colId: SaleDataIds.lot.total_price_cents,
      field: "lot.total_price_cents",
      headerName: "Total Price",
      type: ColumnType.CURRENCY_FROM_CENTS,
    },

    {
      colId: SaleDataIds.lot.total_tags_used,
      field: "lot.total_tags_used",
      headerName: "Tags Used",
      type: ColumnType.NUMERIC,
    },

    {
      colId: SaleDataIds.lot.total_weight_grams,
      field: "lot.total_weight_grams",
      headerName: "Weight",
      type: ColumnType.WEIGHT_FROM_GRAMS,
    },
  ],
};
export const RoundColumnDef = {
  headerName: "Round",
  children: [
    {
      colId: SaleDataIds.round.name,
      field: "round.name",
      headerName: "Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
  ],
};
export const SaleColumnDef = {
  headerName: "Sale",
  children: [
    {
      colId: SaleDataIds.sale.code,
      field: "sale.code",
      headerName: "Code",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
    {
      colId: SaleDataIds.sale.date,
      field: "sale.date",
      headerName: "Date",
      type: ColumnType.STRING,
      enableRowGroup: true, // TODO - in a DD/MM/YYYY format...
    },

    {
      colId: SaleDataIds.sale.display_name,
      field: "sale.display_name",
      headerName: "Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
    {
      colId: SaleDataIds.sale.export_sites,
      field: "sale.export_sites",
      headerName: "Export Sites",
    },
    {
      colId: SaleDataIds.sale.sub_type,
      field: "sale.sub_type",
      headerName: "Sub Type",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
    {
      colId: SaleDataIds.sale.sub_type,
      field: "sale.type",
      headerName: "Type",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
  ],
};
export const SexColumnDef = {
  headerName: "Sex",
  children: [
    {
      colId: SaleDataIds.sex.name,
      field: "sex.name",
      headerName: "Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
  ],
};
export const SpeciesColumnDef = {
  headerName: "Species",
  children: [
    {
      colId: SaleDataIds.species.name,
      field: "species.name",
      headerName: "Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
  ],
};
export const VendorColumnDef = {
  headerName: "Vendor",
  children: [
    {
      colId: SaleDataIds.vendor.commission_offset,
      field: "vendor.commission_offset",
      headerName: "Commission Offset",
      type: ColumnType.PERCENTAGE,
    },

    {
      colId: SaleDataIds.vendor.effective_commission_per_head,
      field: "vendor.effective_commission_per_head",
      headerName: "Effective Commission Per Head",
      type: ColumnType.CURRENCY_FROM_CENTS,
    },
    {
      colId: SaleDataIds.vendor.effective_commission_percentage,
      field: "vendor.effective_commission_percentage",
      headerName: "Effective Commission Percentage",
      type: ColumnType.PERCENTAGE,
    },
    {
      colId: SaleDataIds.vendor.effective_commission_rate,
      field: "vendor.effective_commission_rate",
      headerName: "Effective Commission",
      type: ColumnType.PERCENTAGE,
    },

    {
      colId: SaleDataIds.vendor.has_debtor_insurance,
      field: "vendor.has_debtor_insurance",
      headerName: "Debtor Insurance?",
      type: ColumnType.BOOLEAN,
    },
    {
      colId: SaleDataIds.vendor.has_transit_insurance_for_sales,
      field: "vendor.has_transit_insurance_for_sales",
      headerName: "Sale TI?",
      headerTooltip: "Sale Transit Insurance",

      type: ColumnType.BOOLEAN,
    },
    {
      colId: SaleDataIds.vendor.has_transit_insurance_for_purchases,
      field: "vendor.has_transit_insurance_for_purchases",
      headerName: "Purchase TI?",
      headerTooltip: "Purchase Transit Insurance",

      type: ColumnType.BOOLEAN,
    },
    {
      colId: SaleDataIds.vendor.is_gst_registered,
      field: "vendor.is_gst_registered",
      headerName: "GST Registered?",
      type: ColumnType.BOOLEAN,
    },
    {
      colId: SaleDataIds.vendor.is_hobby_farmer,
      field: "vendor.is_hobby_farmer",
      headerName: "Hobby Farmer?",
      type: ColumnType.BOOLEAN,
    },

    {
      colId: SaleDataIds.vendor.name,
      field: "vendor.name",
      headerName: "Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },

    {
      colId: SaleDataIds.vendor.should_charge_gst,
      field: "vendor.should_charge_gst",
      headerName: "Should Charge GST",
      type: ColumnType.BOOLEAN,
    },
  ],
};
export const VendorPropertyColumnDef = {
  headerName: "Vendor Property",
  children: [
    {
      colId: SaleDataIds.vendor_property.pic,
      field: "vendor_property.pic",
      headerName: "PIC",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
  ],
};
export const YardColumnDef = {
  headerName: "Yard",
  children: [
    {
      colId: SaleDataIds.yard.name,
      field: "yard.name",
      headerName: "Name",
      type: ColumnType.STRING,
      enableRowGroup: true,
    },
  ],
};
