import queryString from "query-string";

import { SUNDRY_TEMPLATE } from "constants/actionTypes";

import { serializeSundryTemplate } from "lib/serializers/sundryTemplates";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/sundry-templates/", id, action),
    query: {
      changesSince,
    },
  });

export const SundryTemplateAction = offlineActionCreator(
  SUNDRY_TEMPLATE,
  serializeSundryTemplate,
  urlCreator,
  false,
);
