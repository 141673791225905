import * as Yup from "yup";
import { getCheckboxFieldName } from "components/BulkUpdateOptionalFieldsModal/util";

export const validationSchema = Yup.object().shape({
  // Validate fields that can't be null - not many of them.
  // This could be extended to the Nth.  How far to go?
  [getCheckboxFieldName("sale_round_id")]: Yup.boolean(),
  sale_round_id: Yup.string().when(getCheckboxFieldName("sale_round_id"), {
    is: true,
    then: Yup.string().required("Sale Round is required"),
  }),
});
