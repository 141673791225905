import { calculateTotalPriceCents, formatDecimal } from "lib";

export const summarizeLots = saleLots => {
  const { totalQuantity, totalWeightGrams, totalPriceCents } = saleLots.reduce(
    (acc, saleLot) => {
      acc.totalQuantity += saleLot.quantity || 0;
      acc.totalWeightGrams += +saleLot.total_mass_grams || 0;
      acc.totalPriceCents += calculateTotalPriceCents(saleLot) || 0;
      return acc;
    },
    { totalQuantity: 0, totalWeightGrams: 0, totalPriceCents: 0 },
  );

  const totalPriceDollars = totalPriceCents / 100;
  const totalKilograms = totalWeightGrams / 1000;

  const dollarsPerHead =
    totalQuantity > 0 ? totalPriceDollars / totalQuantity : 0;

  const averageWeightKg =
    totalWeightGrams > 0 && totalQuantity > 0
      ? totalKilograms / totalQuantity || 0
      : 0;

  const averageDollarsPerKilo =
    totalWeightGrams > 0
      ? formatDecimal(totalPriceDollars / totalKilograms)
      : 0;

  return {
    totalQuantity,
    totalWeightGrams,
    totalPriceCents,
    dollarsPerHead,
    averageDollarsPerKilo,
    averageWeightKg,
    totalPriceDollars,
  };
};
