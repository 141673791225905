import React, { memo } from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Grid from "@material-ui/core/Grid";
import { useFormikContext } from "formik";
import groupBy from "lodash/groupBy";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { IconButton } from "components/Form";
import { DeploymentSelectField } from "components/Form/Fields";
import { AgeField } from "components/Form/Fields/AgeField";
import { BreedField } from "components/Form/Fields/BreedField";
import { SexField } from "components/Form/Fields/SexField";
import {
  FormikAwareBusinessPICSelector,
  Input,
  Switch,
  withArrayNamespace,
  withNamespace,
} from "components/Form/FormikControls";
import { Error } from "components/Form/FormikControls/Error";
import { Row } from "components/Layout";
import { SmHeading } from "components/Text";

import { TRANSPORTER, VENDOR } from "constants/businesses";
import { ConsignmentModalSection } from "constants/navigation";

import { openEditConsignmentModal } from "lib/navigation";

import {
  getConsignableSaleById,
  getCurrentSale,
  getNominationTermsBySaleyardId,
  getSaleById,
} from "selectors";

import { useHasAddConsignmentPermission } from "hooks/useHasPermission";

const businessRoles = [VENDOR];
const transporterRoles = [TRANSPORTER];

function NominationDetails(props) {
  const { name, speciesId, readOnly, livestockSaleId } = props;
  const { saleyard_id } = useSelector(getCurrentSale) || {};
  const { saleyardId } =
    useSelector(getConsignableSaleById(livestockSaleId)) || {};

  const nominationTerms = useSelector(
    getNominationTermsBySaleyardId(saleyardId || saleyard_id),
  );
  const formikProps = useFormikContext();

  const {
    values: { deploymentId, consigningDeploymentId },
    errors,
  } = formikProps;
  const speciesNominationTerms = nominationTerms
    ? Object.values(nominationTerms)
        .filter(nominationTerm => nominationTerm.speciesId === speciesId)
        .sort((a, b) => a.order - b.order)
    : [];

  const nominationTermsByGroupName = Object.entries(
    groupBy(speciesNominationTerms, nominationTerm => nominationTerm.groupName),
  );

  return (
    <>
      {errors.nominationValues &&
      typeof errors.nominationValues === "string" ? (
        <Row fullWidth>
          <Error>{errors.nominationValues}</Error>
        </Row>
      ) : null}
      {speciesNominationTerms.length > 0 &&
        nominationTermsByGroupName.map(
          ([groupName, nominationTerms], index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <h2>{groupName || "Other"}</h2>
                <hr />
              </Grid>
              {nominationTerms.map(nominationTerm => (
                <React.Fragment key={nominationTerm.id}>
                  <Grid item xs={12}>
                    <SmHeading bold fontSize="charlie">
                      {nominationTerm.name}
                    </SmHeading>
                  </Grid>
                  <Grid item xs={2}>
                    <Input
                      emptyValue={null}
                      label="Head"
                      dataTour={`${nominationTerm.name}.head`}
                      name={withNamespace(
                        withArrayNamespace(name, nominationTerm.id),
                        "quantity",
                      )}
                      type="number"
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Input
                      emptyValue={null}
                      label="Est Weight"
                      dataTour={`${nominationTerm.name}.estWeight`}
                      name={withNamespace(
                        withArrayNamespace(name, nominationTerm.id),
                        "estimatedWeightGrams",
                      )}
                      type="number"
                      afterSymbol="Kg"
                      multiplier={1000}
                      disabled={readOnly}
                    />
                  </Grid>

                  <Grid item xs={7}>
                    <Input
                      emptyValue={null}
                      label="Notes"
                      dataTour={`${nominationTerm.name}.notes`}
                      name={withNamespace(
                        withArrayNamespace(name, nominationTerm.id),
                        "notes",
                      )}
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BreedField
                      label="Breed"
                      dataTour={`${nominationTerm.name}.breed`}
                      name={withNamespace(
                        withArrayNamespace(name, nominationTerm.id),
                        "breedId",
                      )}
                      disabled={readOnly}
                      deploymentId={
                        consigningDeploymentId ? null : deploymentId
                      }
                      speciesId={speciesId}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      <SexField
                        label="Sex"
                        dataTour={`${nominationTerm.name}.sex`}
                        name={withNamespace(
                          withArrayNamespace(name, nominationTerm.id),
                          "sexId",
                        )}
                        disabled={readOnly}
                        deploymentId={
                          consigningDeploymentId ? null : deploymentId
                        }
                        speciesId={speciesId}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      <AgeField
                        label="Age"
                        dataTour={`${nominationTerm.name}.age`}
                        name={withNamespace(
                          withArrayNamespace(name, nominationTerm.id),
                          "ageId",
                        )}
                        disabled={readOnly}
                        deploymentId={
                          consigningDeploymentId ? null : deploymentId
                        }
                        speciesId={speciesId}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </React.Fragment>
          ),
        )}
    </>
  );
}

function EditNominationFormComponent(props) {
  const { namespace: ns, nominationId, readOnly } = props;

  const formikProps = useFormikContext();

  const {
    values: { deploymentId, livestockSaleId },
  } = formikProps;

  const speciesId = useSelector(
    state =>
      getSaleById(livestockSaleId)(state)?.species_id ||
      getConsignableSaleById(livestockSaleId)(state)?.speciesId,
  );

  const hasAddConsignmentPermission = useHasAddConsignmentPermission();

  const onCreateConsignmentFromNomination = () =>
    openEditConsignmentModal(
      null,
      ConsignmentModalSection.GENERAL,
      false,
      true,
      window.location.hash,
      nominationId,
    );

  return (
    <>
      <Grid item xs={6}>
        <DeploymentSelectField
          deploymentId={deploymentId}
          label="Consigning Deployment"
          livestockSaleId={livestockSaleId}
          name={withNamespace(ns, "consigningDeploymentId")}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <FormikAwareBusinessPICSelector
          allowBusinessOnly
          allowPropertyOnly={false}
          businessFieldName={withNamespace(ns, "vendorId")}
          businessRoles={businessRoles}
          label="Vendor"
          required
          propertyFieldName={withNamespace(ns, "propertyId")}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <FormikAwareBusinessPICSelector
          allowBusinessOnly
          allowPropertyOnly={false}
          businessFieldName={withNamespace(ns, "transporterId")}
          businessRoles={transporterRoles}
          required={false}
          optional
          label="Transporter / Carrier"
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <Switch
          label="Nomination Arrived"
          name={withNamespace(ns, "hasArrived")}
          disabled={readOnly}
        />
      </Grid>
      {nominationId && (
        <Grid item xs={6}>
          <IconButton
            type="button"
            icon={faPlus}
            onClick={onCreateConsignmentFromNomination}
            disabled={readOnly || !hasAddConsignmentPermission}
            data-tour="createConsignment"
          >
            Create Consignment
          </IconButton>
        </Grid>
      )}
      <NominationDetails
        name={withNamespace(ns, "nominationValues")}
        speciesId={speciesId}
        readOnly={readOnly}
        livestockSaleId={livestockSaleId}
      />
    </>
  );
}

export const EditNominationForm = memo(EditNominationFormComponent);
EditNominationForm.propTypes = {
  namespace: PropTypes.string,
  speciesId: PropTypes.number,
  nominationId: PropTypes.string,
};
