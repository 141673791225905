import React, { useCallback, useMemo } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import { isEmpty } from "lodash";
import partition from "lodash/partition";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { addSaleLot, patchConsignment, patchSaleLot } from "actions";

import { ConfirmDialog } from "components/ConfirmDialog";
import { Button, SecondaryButton } from "components/Form";
import { Input } from "components/Form/FormikControls";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";

import { PricingTypes } from "constants/pricingTypes";
import { Species } from "constants/species";

import { calculateTotalPriceCents, calculateUnitPrice } from "lib";

import {
  getConsignmentById,
  getCurrentSale,
  getCurrentSpeciesId,
  getSaleLotById,
  getSaleLotIdsByConsignmentId,
} from "selectors";

import { getSkinsSexId } from "selectors/sexes";

import { useBoolean } from "hooks";
import { SkinsForm } from "./SkinsForm";
import { LotForm } from "./LotForm";
import { FormValues } from "./types";

const decimalValidation = Yup.number()
  .positive("Must be > 0")
  .typeError("Invalid number");

const quantityValidation = Yup.number()
  .required("Required")
  .typeError("Invalid number")
  .integer("Must be a whole number");

const validationSchema = Yup.object().shape({
  saleLots: Yup.array().of(
    Yup.object().shape({
      quantity: quantityValidation,
      total_mass_grams: Yup.number().when("pricing_type_id", {
        is: pricingTypeId => pricingTypeId === PricingTypes.PER_KILO,
        then: schema =>
          schema
            .positive("Must be > 0")
            .typeError("Invalid number")
            .required("Required!"),
        otherwise: schema => schema.nullable(),
      }),

      unitPrice: decimalValidation,
    }),
  ),
});

interface ModalProps {
  onClose: () => void;
  consignmentId: string;
}

interface FormInnerProps {
  onClose: () => void;
  dialogTitle: string;
  defaultSaleLot: any;
  defaultSkinsLot: any;
}

const FormInner = ({
  onClose,
  dialogTitle,
  defaultSaleLot,
  defaultSkinsLot,
}: FormInnerProps) => {
  const submitDisabled = false;

  const formikContext = useFormikContext();
  const [isConfirmDialogOpen, openConfirmDialog, closeConfirmDialog] =
    useBoolean();

  const onCloseConfirmWrapper = useCallback(() => {
    if (!isEmpty(formikContext.touched)) {
      openConfirmDialog();
    } else {
      onClose();
    }
  }, [formikContext.touched, onClose, openConfirmDialog]);

  const speciesId = useSelector(getCurrentSpeciesId);

  return (
    <>
      <ZoomyDialog open onClose={onCloseConfirmWrapper} maxWidth="lg" fullWidth>
        <Form>
          <DialogTitle onClose={onCloseConfirmWrapper}>
            {dialogTitle}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container item xs={12} spacing={2}>
              <LotForm defaultSaleLot={defaultSaleLot} />
              {speciesId === Species.SHEEP && (
                <SkinsForm defaultSkinsLot={defaultSkinsLot} />
              )}
              <Grid item xs={12}>
                <Input
                  label="External References/Kill Id"
                  name="consignment.billingReference"
                  maxLength={255}
                  tooltip="This value will appear in the header of related Invoices/RCTI's and line items on the Invoice may be grouped by this value."
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SecondaryButton onClick={onCloseConfirmWrapper} type="button">
              Close
            </SecondaryButton>
            <Button type="submit" disabled={submitDisabled}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </ZoomyDialog>
      <ConfirmDialog
        title="There are changes to the form that will be lost, are you sure?"
        isOpen={isConfirmDialogOpen}
        onCancel={closeConfirmDialog}
        onDelete={onClose}
        buttonMessage="Yes"
      />
    </>
  );
};

function Modal({ onClose, consignmentId }: ModalProps) {
  const consignment = useSelector(getConsignmentById(consignmentId));

  const saleLotIds =
    useSelector(getSaleLotIdsByConsignmentId(consignmentId)) || [];
  const skinsSexId = useSelector(getSkinsSexId);

  const consignmentLots = useSelector(state =>
    saleLotIds.map(saleLotId => getSaleLotById(saleLotId)(state)),
  );

  const [skinsLots, saleLots] = partition(
    consignmentLots,
    saleLot => saleLot.sex_id === skinsSexId,
  );

  const dispatch = useDispatch();

  const dialogTitle = `Kill Sheet Entry - Hd ${consignment?.quantity}`;

  const sale = useSelector(getCurrentSale);

  const defaultSaleLot = useMemo(
    () => ({
      quantity: null,
      total_mass_grams: null,
      cents_per_kilo: null,
      total_price_cents: null,
      pricing_type_id: sale.pricing_type_id,
      consignment_id: consignmentId,
      sale_round_id: sale.rounds[0],
      buyer_id: sale.default_buyer_id,
      unitPrice: 0,
    }),
    [consignmentId, sale.default_buyer_id, sale.pricing_type_id, sale.rounds],
  );

  const defaultSkinsLot = useMemo(
    () => ({
      ...defaultSaleLot,
      sex_id: skinsSexId,
      pricing_type_id: PricingTypes.PER_HEAD,
      total_mass_grams: 0,
    }),
    [defaultSaleLot, skinsSexId],
  );

  const withUnitPrice = saleLot => ({
    ...saleLot,
    unitPrice: calculateUnitPrice(saleLot),
  });

  const initialValues = useMemo(() => {
    const saleLotFormInitialValues =
      saleLots.length > 0 ? saleLots : [defaultSaleLot];

    const skinsLotFormInitialValues =
      skinsLots.length === 0 && sale.species_id === Species.SHEEP
        ? [defaultSkinsLot]
        : skinsLots;
    return {
      saleLots: saleLotFormInitialValues.map(withUnitPrice),
      skinsLots: skinsLotFormInitialValues.map(withUnitPrice),
      consignment,
    };
  }, [
    consignment,
    defaultSaleLot,
    defaultSkinsLot,
    sale.species_id,
    saleLots,
    skinsLots,
  ]);

  const handleSubmit = (values: FormValues) => {
    [...values.saleLots, ...values.skinsLots]
      .filter(saleLot => saleLot.quantity > 0)
      .forEach(saleLot => {
        const totalPriceCents = calculateTotalPriceCents(saleLot);

        const payload = {
          ...saleLot,
          total_price_cents: totalPriceCents,
          total_mass_grams: saleLot.total_mass_grams || 0,
        };

        if (payload.id) {
          // patching
          dispatch(patchSaleLot(payload));
        } else {
          const tempSaleLotId = uuidv4();
          dispatch(addSaleLot(payload, tempSaleLotId));
        }
      });
    if (values.consignment.billingReference) {
      dispatch(
        patchConsignment(
          { billingReference: values.consignment.billingReference },
          consignmentId,
        ),
      );
    }
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <FormInner
        onClose={onClose}
        dialogTitle={dialogTitle}
        defaultSaleLot={defaultSaleLot}
        defaultSkinsLot={defaultSkinsLot}
      />
    </Formik>
  );
}

export default Modal;
