import {
  BulkUpdateFieldType,
  UpdateInformationProps,
} from "components/BulkUpdateOptionalFieldsModal/types";
import React from "react";
import isEqual from "lodash/isEqual";
import { UpdateInformationWrapper } from "components/BulkUpdateOptionalFieldsModal/UpdateInformationComponents";
import { getMLASupplementaryData } from "selectors";
import { useSelector } from "react-redux";
import { getIn, useFormikContext } from "formik";
import { withNamespaces } from "components/Form/FormikControls";
import { getCheckboxFieldName } from "components/BulkUpdateOptionalFieldsModal/util";
import { pluralize } from "lib/pluralize";

// This bulk update modal is a bit different - it updates MLA Supplementary data, but
// references underlying sale lot data.
// Hence - the messaging is a little different to the bulk update lots - it will just tell you
// how many values are going to be different after the change - this is likely more meaningful to a user than
// something more complex involving explaining the two data sets.

// if the user has a selected value.
//   Compare those values to the selected value.
//   Display how many are different to that value.
// else
//   Display how many different values are within the dataset.

// Null/empty is valid - this CLEARS the MLA data.

const EXCLUDE_FROM_UPDATE_COUNT = "EXCLUDE_FROM_UPDATE_COUNT";

function resolveValue(mlaDataModel: any, field: BulkUpdateFieldType): string {
  const { fieldName, emptyValue } = field;
  // Simple - root of MLA to root of sale lot.
  if (["ageId", "breedId", "sexId"].includes(fieldName)) {
    return (
      mlaDataModel[fieldName] ||
      getIn(mlaDataModel, withNamespaces(["saleLot", fieldName]))
    );
  }

  // A  little less simple - root of mla to draft attributes on sale lot.
  if (
    [
      "shearing",
      "shearingStatus",
      "frameStructure",
      "joinedStart",
      "joinedEnd",
      "estCarcassWeight",
    ].includes(fieldName)
  ) {
    return (
      mlaDataModel[fieldName] ||
      getIn(
        mlaDataModel,
        withNamespaces(["saleLot", "draftingAttributes", fieldName]),
      )
    );
  }

  // Muscle and fat scores have multiple fields - find the most common.
  if (["muscleScore", "fatScore"].includes(fieldName)) {
    const mlaScore = mlaDataModel[fieldName];
    if (mlaScore) {
      // We have an override - that's our value.
      return mlaDataModel[fieldName];
    }
    // Find the most common value underneath - they're stored as, eg,
    // fatScore1: number of animals with a fat score of 1
    // fatScore2 - number of animals with a fat score of 2
    // ...
    const resolvedValue = [1, 2, 3, 4, 5, 6].reduce(
      ([score, highestFieldNumber], fieldNumber) => {
        const saleLotValue = getIn(
          mlaDataModel,
          withNamespaces([
            "saleLot",
            "draftingAttributes",
            `${fieldName}${fieldNumber}`,
          ]),
        );
        if (score === null || (saleLotValue && saleLotValue > score)) {
          return [fieldNumber, saleLotValue];
        }
        return [highestFieldNumber, fieldNumber];
      },
      [null, null],
    );
    if (resolvedValue[0]) {
      return resolvedValue[0];
    }
    return null;
  }

  // Dressing range an underlying check
  // The MLA value and the Sale Lot root entries are an ID referencing a value.
  // the draftingAttributes value is a VALUE.
  // In the feed, if a specific value has been entered for the attribute, that gets sent.
  // So we will NOT be overriding/setting it.
  // Hence - this value does not count toward a value you will be updating!
  // Return the SELECTED value to result in not saying it will be updated.

  if (fieldName === "dressingRangeId") {
    const draftingAttributesValue = getIn(
      mlaDataModel,
      withNamespaces(["saleLot", "draftingAttributes", "dressingPercent"]),
    );
    if (draftingAttributesValue) {
      return EXCLUDE_FROM_UPDATE_COUNT;
    }
    return (
      getIn(mlaDataModel, fieldName) ||
      getIn(mlaDataModel, withNamespaces(["saleLot", fieldName]))
    );
  }

  // Estimated Live Weight may fall upward to different values
  // It also has an underlying relevance - we may have an ACTUAL live weight!
  // The data feed will send ....
  // The actual live weight *
  // estimated_average_mass_grams *
  // min_mass_grams / max_mass_grams *
  // The MLA data
  // The Sale Lot's estimated weight.
  //
  // In the specific (*) values - this is better data than a broad sweep of MLA overrides
  // so we will not be "overwriting" the data.
  // In the last two instance (MLA data, Sale Lots estimated) we revert to the same considerations as other fields
  if (fieldName === "estimatedAverageWeightId") {
    // Do any of the * values exist?
    if (
      getIn(mlaDataModel, withNamespaces(["saleLot", "totalMassGrams"])) ||
      getIn(
        mlaDataModel,
        withNamespaces(["saleLot", "estimatedAverageMassGrams"]),
      ) ||
      (getIn(
        mlaDataModel,
        withNamespaces(["saleLot", "draftingAttributes", "maxMassGrams"]),
      ) &&
        getIn(
          mlaDataModel,
          withNamespaces(["saleLot", "draftingAttributes", "maxMassGrams"]),
        ))
    ) {
      return EXCLUDE_FROM_UPDATE_COUNT;
    }
    // Fallback to flat comparing mla to sale lot data.
    return (
      mlaDataModel[fieldName] ||
      getIn(mlaDataModel, withNamespaces(["saleLot", fieldName]))
    );
  }

  if (fieldName === "dressingRangeId") {
    const draftingAttributesValue = getIn(
      mlaDataModel,
      withNamespaces(["saleLot", "draftingAttributes", "dressingPercent"]),
    );
    if (draftingAttributesValue) {
      return EXCLUDE_FROM_UPDATE_COUNT;
    }
    //
    return (
      getIn(mlaDataModel, fieldName) ||
      getIn(mlaDataModel, withNamespaces(["saleLot", fieldName]))
    );
  }

  // This could be a raise - it's an unknown field.

  return emptyValue;
}

export function UpdateInformation(
  props: UpdateInformationProps,
): React.JSX.Element | null {
  const { field, modelIds } = props;
  const { fieldName } = field;
  const { values } = useFormikContext();

  const selectedValue = getIn(values, fieldName) || field.emptyValue;

  const isSelected = getIn(values, getCheckboxFieldName(fieldName));

  // Go through and find a resolved value for each entry.
  const mlaSupplementaryDatas = useSelector(getMLASupplementaryData) as Record<
    string,
    MLASupplementaryData
  >;

  const resolvedValues = modelIds.map(modelId =>
    resolveValue(mlaSupplementaryDatas[modelId], field),
  );
  const howManyDifferent = resolvedValues.filter(
    value =>
      value !== EXCLUDE_FROM_UPDATE_COUNT && !isEqual(selectedValue, value),
  ).length;

  if (isSelected) {
    return (
      <UpdateInformationWrapper>
        This will apply the value on {howManyDifferent}{" "}
        {pluralize("Sale Lot", howManyDifferent)}.
      </UpdateInformationWrapper>
    );
  }
  if (resolvedValues.length > 1) {
    return (
      <UpdateInformationWrapper>
        {resolvedValues.length} different values in {modelIds.length}{" "}
        {pluralize("Sale Lot", modelIds.length)}
      </UpdateInformationWrapper>
    );
  }
  return (
    <UpdateInformationWrapper>
      All Sale Lots share the same value.
    </UpdateInformationWrapper>
  );
}
