import PropTypes from "prop-types";

import { useSubmitHandler } from "./FormikControls/lib";

export function SubmitHandler(props) {
  const { allowInitialSubmit = false, setIsSubmitEnabled } = props;
  useSubmitHandler(allowInitialSubmit, setIsSubmitEnabled);
  return null;
}

SubmitHandler.propTypes = {
  setIsSubmitEnabled: PropTypes.func.isRequired,
  allowInitialSubmit: PropTypes.bool,
};
