import { get } from "lodash";

import { ColumnType, MAX, MIN } from "components/AgGrid/constants";
import { withNamespace } from "components/Form/FormikControls";
import {
  saleLotCommentCountColumnRenderer,
  saleLotCommentsColumnId,
} from "components/SaleLotsTable/columns/commentsColumn";

import {
  Column,
  ColumnDisplayName,
  LivestockSaleColumnDisplayName,
} from "constants/columns";
import { DocumentTypes } from "constants/documentTypes";
import { Accreditation } from "constants/draftingAttributes";
import { IntegrationTypes } from "constants/integrations";
import { NLISFileTypes } from "constants/nlis";
import { Species } from "constants/species";

import { erpStatusRenderer } from "lib/agGrid/columns/erpStatus";
import { euStatusRenderer } from "lib/agGrid/columns/euStatus";
import { lifetimeTraceableRenderer } from "lib/agGrid/columns/lifetimeTraceable";
import { nlisProgramsRenderer } from "lib/agGrid/columns/picStatus";
import { quantityRenderer } from "lib/agGrid/columns/quantity";
import { RelationshipAgentRenderer } from "lib/agGrid/columns/relationshipAgent";
import { weightComparator } from "lib/agGrid/columns/weight";
import {
  weightOutlierComparator,
  weightOutlierGetter,
  weightOutlierRenderer,
} from "lib/agGrid/columns/weightOutlier";
import { yearFormatter } from "lib/agGrid/columns/year";
import formatters, {
  dollarTotalsFilterValueGetter,
} from "lib/agGrid/formatters";
import { caseInsensitiveCompare } from "lib/compare";

import {
  agencyVendorNumFormatter,
  agencyVendorNumGetter,
} from "./columns/agencyVendorNumber";
import {
  BuyerAlternativesCellRenderer,
  VendorAlternativesCellRenderer,
} from "./columns/alternatives";
import { arrivedRenderer } from "./columns/arrived";
import {
  declarationAttachmentsRenderer,
  imageAttachmentsRenderer,
} from "./columns/attachments";
import { trueIcon } from "./columns/booleanRenderers";
import { buyerPaymentsRenderer } from "./columns/buyerPayments";
import {
  consignmentDiffFormatter,
  consignmentDiffRenderer,
} from "./columns/consignmentDiff";
import { dateFormatter } from "./columns/date";
import {
  diffFormatter,
  diffRenderer,
  kilogramDiffFormatter,
} from "./columns/diff";
import { erpColumnRenderer } from "./columns/erpMessages";
import { hasAddressRenderer } from "./columns/hasAddress";
import { LPAStatusRenderer } from "./columns/lpaStatus";
import { NLISFileRenderer, NlisStatusRenderer } from "./columns/nlisFile";
import {
  nominationDetailsCellRenderer,
  nominationDetailsValueGetter,
  nominationHdGetter,
} from "./columns/nomination";
import { NVDIDRenderer, NVDRecRenderer, NVDRenderer } from "./columns/nvd";
import { nvdHCFormatter } from "./columns/nvdHC";
import { penComparator, penGetter } from "./columns/pen";
import { PICRenderer } from "./columns/pic";
import {
  BusinessRenderer,
  IntegrationBusinessRenderer,
  SyRefBusinessRenderer,
} from "./columns/shortCode";
import { statusColumnRenderer } from "./columns/status";
import {
  kilogramsFormatter,
  kilogramsGetter,
  totalWeightKilogramsGetter,
} from "./columns/totalMassGrams";
import { transitRenderer } from "./columns/transitInsurance";
import { unitPriceHeaderGetter, unitPriceRenderer } from "./columns/unitPrice";
import {
  ConsignmentVendorSplitGetter,
  SaleLotVendorSplitGetter,
} from "./columns/vendorSplit";
import getters from "./getters";
import nlisIdRenderer from "./renderers/nlisIdRenderer";
import { saleLotScanLotRenderer } from "./renderers/scanLotrenderers";
import {
  booleanRenderer,
  booleanTickRenderer,
  nullableBooleanRenderer,
  nullableTickRenderer,
} from "./renderers/tickRenderer";
import { AbnCheckExplainer } from "./tooltips";

// There is a few extra options on a column definition

// valueGetter: (params) => { return parseInt(params.data.population) }

// TYPE - https://www.ag-grid.com/javascript-data-grid/column-definitions/#default-column-definitions
//        The type of column adds some styling eg. numericColumn aligns the cell text to the right

// FILTER - https://www.ag-grid.com/javascript-data-grid/filtering/#example-simple-filters
//          Lets you set what type of filter should be used for column eg.
//          agTextColumnFilter will enables text realted filter (starts with, ends with),
//          agNumberColumnFilter enables number realted filters (Greater than, less than)

// VALUEGETTER - https://www.ag-grid.com/javascript-data-grid/value-getters/
//               The value normally come from the field attr, however using a getter you can override the column value, eg. dollar getter changes cents to dollars
//               The sort, filter, csv and cell text is driven by what is returned from the getter
//               The returned type should match the filter/type, or there will be sorting issues

// VALUEFORMATTER - https://www.ag-grid.com/javascript-data-grid/value-formatters/
//                  Lets you format the cell text for display in aggrid eg. dollar formatter adds a $.
//                  Does not affect sorting, filtering or csv, just for UI

// CELLRENDERER - https://www.ag-grid.com/javascript-data-grid/component-cell-renderer/
//                Allows for more complex formatting changes to a cell, let you render react inside the cell eg. arrivedRenderer renders a tick or cross
//                Only use instead of valueFormatter if you need to display complex formatting. Like valueFormatter it doesn't affect sorting, filtering or csv.

// COMPARATOR - https://www.ag-grid.com/javascript-data-grid/row-sorting/#custom-sorting
//              Allows to use a custom sorting method, eg. penComparator is used to compare alphaNUMERICal pens, we also use caseInsensitiveCompare in a few places
//              This overwrites the default sort which sorts by the field type (which can be changed by valueGetter)

export const caseInsentitiveComparator = (a, b) => {
  if (!a && !b) {
    return 0;
  } else if (!b) {
    return 1;
  } else if (!a) {
    return -1;
  } else {
    return caseInsensitiveCompare(a, b);
  }
};

const ClearingSaleColumnDefinitions = [
  [Column.CLEARING_SALE_CATEGORY, ColumnType.STRING],
  [Column.CLEARING_SALE_COMMENTS, ColumnType.STRING],
  [Column.CLEARING_SALE_HOURS, ColumnType.NUMERIC],
  [Column.CLEARING_SALE_INSPECTION, ColumnType.STRING],
  [Column.CLEARING_SALE_ITEM_CONDITION, ColumnType.STRING],
  [Column.CLEARING_SALE_ITEM_TYPE, ColumnType.STRING],
  [Column.CLEARING_SALE_MAKE, ColumnType.STRING],
  [Column.CLEARING_SALE_MODEL, ColumnType.STRING],
  [Column.CLEARING_SALE_NEW_USED, ColumnType.STRING],
  [Column.CLEARING_SALE_ODOMETER, ColumnType.NUMERIC],
  [Column.CLEARING_SALE_PPSR_COMMENTS, ColumnType.STRING],
  [Column.CLEARING_SALE_PPSR_STATUS, ColumnType.STRING],
  [Column.CLEARING_SALE_PUBLIC_DESCRIPTION, ColumnType.STRING],
  [Column.CLEARING_SALE_REGISTRATION, ColumnType.STRING],
  [Column.CLEARING_SALE_SELL_SAFE_CATEGORY, ColumnType.STRING],
  [Column.CLEARING_SALE_STATUS, ColumnType.STRING],
  [Column.CLEARING_SALE_SUB_TYPE, ColumnType.STRING],
  [Column.CLEARING_SALE_TITLE, ColumnType.STRING],
  [Column.CLEARING_SALE_VIN, ColumnType.STRING],
  [Column.LISTING_ID, ColumnType.STRING],
].reduce((acc, [column, columnType]) => {
  acc[column] = {
    field: column,
    headerName: ColumnDisplayName[column],
    type: columnType,
  };
  return acc;
}, {});

ClearingSaleColumnDefinitions[Column.CLEARING_SALE_YEAR] = {
  field: Column.CLEARING_SALE_YEAR,
  headerName: ColumnDisplayName[Column.CLEARING_SALE_YEAR],
  valueFormatter: yearFormatter,
  filter: "agDateColumnFilter",
};

const booleanDraftingAttributeGetter = ({ colDef, data }) => {
  const attribute = get(data, colDef.field) || false;
  if (attribute === Accreditation.ALL || attribute === Accreditation.SOME) {
    return true;
  }
  return false;
};

const withAttributeNamespace = name =>
  withNamespace("draftingAttributes", name);

export const ConsignmentColumnDefinitions = {
  [Column.Consignment.ABN]: {
    field: Column.Consignment.ABN,
    colId: "vendor.abn",
    headerName: "ABN",
    width: 100,
  },
  [Column.Consignment.ABN_CHECK]: {
    colId: Column.Consignment.ABN_CHECK,
    field: "vendor.abn",
    headerName: "ABN Check",
    cellRenderer: booleanRenderer,
    valueGetter: getters.abnCheckGetter,
    width: 140,
    cellRendererParams: {
      tooltipComponent: AbnCheckExplainer,
      buildToolTipProps: data => ({
        business: data.vendor,
      }),
    },
  },
  [Column.Consignment.BUSINESS_DISPLAY_NAME]: {
    field: Column.Consignment.BUSINESS_DISPLAY_NAME,
    colId: "vendor.publicDisplayName",
    headerName: "Display Name",
    type: ColumnType.STRING,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.CREATED]: {
    field: Column.Consignment.CREATED,
    colId: "created",
    headerName: "Created",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    width: 130,
  },
  [Column.Consignment.VENDOR_NAME]: {
    field: Column.Consignment.VENDOR_NAME,
    headerName: "Vendor",
    colId: "vendor_name",
    type: ColumnType.STRING,
    width: 200,
    sortable: true,
    sort: "asc",
    comparator: caseInsentitiveComparator,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.IS_HOBBY_FARMER]: {
    field: Column.Consignment.IS_HOBBY_FARMER,
    colId: "vendor.isHobbyFarmer",
    headerName: "Hobby Farmer",
    cellRenderer: booleanTickRenderer,
    filter: "anBooleanColumnFilter",
    width: 130,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.QUANTITY]: {
    field: Column.Consignment.QUANTITY,
    colId: "quantity",
    headerName: "Hd",
    type: ColumnType.NUMERIC,
    cellRenderer: quantityRenderer,
    width: 65,
    enableValue: true,
  },
  [Column.Consignment.LAST_MODIFIED]: {
    field: Column.Consignment.LAST_MODIFIED,
    colId: "last_modified",
    headerName: "Last Modified",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    width: 130,
  },
  [Column.Consignment.AGENCY_NAME]: {
    field: Column.Consignment.AGENCY_NAME,
    colId: "livestockAgency.name",
    headerName: "Agency",
    type: ColumnType.STRING,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.SCANNED_COUNT]: {
    field: Column.Consignment.SCANNED_COUNT,
    colId: "scannedCount",
    headerName: "Sale Lot EIDS",
    type: ColumnType.NUMERIC,
    width: 115,
    cellRenderer: erpColumnRenderer,
  },
  [Column.Consignment.SCANNED_PERCENTAGE]: {
    field: Column.Consignment.SCANNED_PERCENTAGE,
    colId: "scannedPercentage",
    headerName: "Act. Level",
    type: ColumnType.PERCENTAGE,
    width: 80,
  },
  [Column.Consignment.VENDOR_PIC_LPA_NUMBER]: {
    colId: "vendor_property.lpaNumber",
    field: Column.Consignment.VENDOR_PIC_LPA_NUMBER,
    headerName: "LPA #",
    width: 125,
  },
  [Column.Consignment.VENDOR_PIC_MSA_NUMBER]: {
    colId: "vendor_property.msaNumber",
    field: Column.Consignment.VENDOR_PIC_MSA_NUMBER,
    headerName: "MSA #",
    width: 125,
  },
  [Column.Consignment.VENDOR_SHORT_CODE]: {
    field: Column.Consignment.VENDOR_SHORT_CODE,
    colId: "vendor_short_code",
    headerName: "Agent Ref",
    width: 100,
    cellRenderer: BusinessRenderer,
    cellRendererParams: {
      complainIfMissing: true,
      businessKey: "vendor_id",
    },
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.VENDOR_SHORT_CODE_SALEYARD]: {
    field: Column.Consignment.VENDOR_SHORT_CODE_SALEYARD,
    colId: "vendor.shortCodeSaleyard",
    headerName: "SY Ref",
    width: 100,
    cellRenderer: SyRefBusinessRenderer,
    cellRendererParams: {
      businessKey: "vendor_id",
    },
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.AGENT_RELATIONSHIP]: {
    colId: Column.Consignment.AGENT_RELATIONSHIP,
    field: "relationshipAgent",
    headerName: "Agent",
    width: 100,
    cellRenderer: RelationshipAgentRenderer,
  },
  [Column.Consignment.BRANDS]: {
    colId: Column.Consignment.BRANDS,
    field: "brands",
    headerName: "Brands",
    type: ColumnType.STRING,
    width: 100,
  },
  [Column.Consignment.VENDOR_NUM]: {
    field: Column.Consignment.VENDOR_NUM,
    colId: "vendorNumber",
    headerName: "Ven. #",
    width: 80,
    valueGetter: getters.integerOrDefaultGetter("-"),
    comparator: caseInsensitiveCompare,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.AGENCY_VENDOR_NUM]: {
    field: Column.Consignment.AGENCY_VENDOR_NUM,
    colId: "consignment.agencyVendorNumber",
    headerName: "Ven. #",
    width: 60,
    valueFormatter: agencyVendorNumFormatter,
    valueGetter: agencyVendorNumGetter,
    comparator: caseInsensitiveCompare,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.RECEIVE_REF]: {
    field: Column.Consignment.RECEIVE_REF,
    colId: "receiving_reference",
    headerName: "Rec. Ref",
    width: 125,
    comparator: caseInsensitiveCompare,
  },
  [Column.Consignment.RECEIVE_PEN]: {
    field: Column.Consignment.RECEIVE_PEN,
    colId: "receiving_pen",
    headerName: "Rec. Pen",
    width: 75,
    comparator: caseInsensitiveCompare,
  },
  [Column.Consignment.RECEIVAL_PENS]: {
    field: Column.Consignment.RECEIVAL_PENS,
    colId: "receivalPens",
    headerName: "Receival Pens",
    width: 150,
    comparator: caseInsensitiveCompare,
  },
  [Column.Consignment.RECEIVAL_LOTS_HEAD_COUNT]: {
    field: Column.Consignment.RECEIVAL_LOTS_HEAD_COUNT,
    headerName: "Receival Lots Head Count",
    width: 200,
  },
  [Column.Consignment.RECEIVAL_LOTS_SCAN_COUNT]: {
    field: Column.Consignment.RECEIVAL_LOTS_SCAN_COUNT,
    headerName: "Receival Lots Scan Count",
    width: 200,
  },
  [Column.Consignment.RECEIVAL_LOTS]: {
    field: Column.Consignment.RECEIVAL_LOTS,
    headerName: "Receival Lots",
    width: 300,
    cellRenderer: saleLotScanLotRenderer,
  },
  [Column.Consignment.NVD_RECEIVED]: {
    field: "NVD",
    colId: "isNVDAttached",
    headerName: ColumnDisplayName[Column.Consignment.NVD_RECEIVED],
    cellRenderer: NVDRecRenderer,
    width: 60,
  },
  [Column.Consignment.LPA_STATUS]: {
    field: Column.Consignment.PIC_PROGRAMS,
    colId: "lpaStatus",
    headerName: "LPA",
    width: 70,
    valueGetter: getters.lpaStatusGetter,
    cellRenderer: LPAStatusRenderer,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.CONSIGNMENT_DIFF]: {
    field: Column.Consignment.CONSIGNMENT_DIFF,
    colId: "quantity_diff",
    headerName: "Diff",
    type: ColumnType.NUMERIC,
    valueFormatter: consignmentDiffFormatter,
    cellRenderer: consignmentDiffRenderer,
    width: 55,
  },
  [Column.Consignment.NVD_HC]: {
    field: Column.Consignment.NVD_HC,
    colId: "quantity_NVD",
    headerName: "NVD Hd",
    valueFormatter: nvdHCFormatter,
    width: 70,
  },
  [Column.Consignment.NOMINATION]: {
    field: Column.Consignment.NOMINATION,
    colId: "nomination",
    headerName: "Nomination",
    marryChildren: true,
    children: [
      {
        field: Column.Consignment.NOMINATION_HAS_ARRIVED,
        colId: "nomination.hasArrived",
        headerName:
          ColumnDisplayName[Column.Consignment.NOMINATION_HAS_ARRIVED],
        cellRenderer: nullableBooleanRenderer,
        width: 100,
      },
      {
        field: Column.Consignment.NOMINATION_CONSIGNING_DEPLOYMENT,
        colId: "nominationConsigningDeployment.name",
        headerName:
          ColumnDisplayName[
            Column.Consignment.NOMINATION_CONSIGNING_DEPLOYMENT
          ],
        width: 300,
      },
      {
        field: Column.Consignment.NOMINATION_VENDOR,
        colId: "nominationVendor.name",
        headerName: ColumnDisplayName[Column.Consignment.NOMINATION_VENDOR],
        width: 300,
      },
      {
        field: Column.Consignment.NOMINATION_DETAILS,
        colId: "nominationDetails",
        headerName: ColumnDisplayName[Column.Consignment.NOMINATION_DETAILS],
        cellRenderer: nominationDetailsCellRenderer,
        valueGetter: nominationDetailsValueGetter,
        width: 300,
      },
    ],
  },
  [Column.Consignment.DELIVERY_DATE]: {
    field: Column.Consignment.DELIVERY_DATE,
    colId: "carrierCharge.deliveryDate",
    headerName: "Delivery Date",
    type: ColumnType.DATE,
    width: 90,
  },
  [Column.Consignment.CARRIER]: {
    field: Column.Consignment.CARRIER,
    colId: "carrierCharge.carrier",
    headerName: "Carrier",
    width: 200,
    sortable: true,
  },
  [Column.Consignment.TRANSIT_INSURANCE_FOR_SALES]: {
    field: "vendor.hasTransitInsuranceForSales",
    colId: Column.Consignment.TRANSIT_INSURANCE_FOR_SALES,
    headerName: "Sale TI",
    headerTooltip: "Sale Transit Insurance",
    cellRenderer: transitRenderer,
    width: 60,
  },
  [Column.Consignment.TRANSIT_INSURANCE_FOR_PURCHASES]: {
    field: "vendor.hasTransitInsuranceForPurchases",
    colId: Column.Consignment.TRANSIT_INSURANCE_FOR_PURCHASES,
    headerName: "Purchase TI",
    headerTooltip: "Purchase Transit Insurance",
    cellRenderer: transitRenderer,
    width: 60,
  },
  [Column.Consignment.GST_STATUS]: {
    field: Column.Consignment.GST_STATUS,
    colId: "vendor.isGSTRegistered",
    headerName: "GST Registered",
    filter: "anBooleanColumnFilter",
    filterParams: {
      showIndeterminate: true,
    },
    width: 120,
    cellRenderer: nullableTickRenderer,
  },
  [saleLotCommentsColumnId]: {
    colId: saleLotCommentsColumnId,
    field: "unresolvedSaleLotCommentCount",
    headerName: "Comments",
    width: 86,
    cellRenderer: saleLotCommentCountColumnRenderer,
    pinned: "right",
    menuTabs: [],
    filter: "agNumberColumnFilter",
  },
  [Column.Consignment.VENDOR_CONSIGNMENT_PIC]: {
    field: Column.Consignment.VENDOR_CONSIGNMENT_PIC,
    colId: "vendor_property.PIC",
    headerName: "Ven. PIC",
    width: 100,
    cellRenderer: PICRenderer,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.CONSIGNED_FROM]: {
    field: Column.Consignment.CONSIGNED_FROM,
    colId: "consignedFrom",
    headerName: "Consigned From",
    width: 175,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.BRANCH]: {
    field: Column.Consignment.BRANCH,
    colId: "branchName",
    headerName: "BRANCH",
    width: 125,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.Consignment.CONSIGNMENT_SCANNED_COUNT]: {
    field: Column.Consignment.CONSIGNMENT_SCANNED_COUNT,
    colId: "consignmentScannedCount",
    headerName: "Consignment EIDs",
    type: ColumnType.NUMERIC,
    width: 115,
  },
  [Column.Consignment.DRAFT_HC]: {
    colId: "drafted",
    field: Column.Consignment.DRAFT_HC,
    headerName: "Drafted Hd",
    width: 90,
  },
  [Column.Consignment.NVD_ID]: {
    field: Column.Consignment.NVD_ID,
    colId: "NVD",
    headerName: "NVD ID",
    cellRenderer: NVDIDRenderer,
    width: 80,
  },
  [Column.Consignment.ATTACHMENTS]: {
    field: Column.Consignment.ATTACHMENTS,
    colId: "attachments",
    headerName: "Attachments",
    cellRenderer: declarationAttachmentsRenderer,
    valueGetter: getters.attachmentsGetter,
    width: 125,
  },
  [Column.PIC_PROGRAMS]: {
    field: Column.PIC_PROGRAMS,
    colId: "nlisPrograms",
    headerName: "PIC Programs",
    width: 110,
    cellRenderer: nlisProgramsRenderer,
    valueGetter: getters.nlisProgramsGetter,
  },
  [Column.Consignment.HAS_ARRIVED]: {
    field: Column.Consignment.HAS_ARRIVED,
    colId: "hasArrived",
    headerName: "Has Arrived",
    cellRenderer: arrivedRenderer,
    filter: "anBooleanColumnFilter",
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.XERO_VENDOR]: {
    colId: "xeroVendor",
    field: Column.XERO_VENDOR,
    headerName: "Vendor (Xero)",
    width: 140,
    valueGetter: getters.xeroBusinessGetter,
    cellRenderer: IntegrationBusinessRenderer,
    cellRendererParams: {
      businessKeys: ["vendor"],
      complainIfMissing: true,
      integrationType: IntegrationTypes.Xero,
      useLogoAndTooltip: true,
    },
  },
  [Column.Consignment.VENDOR_SPLITS]: {
    colId: Column.Consignment.VENDOR_SPLITS,
    headerName: "Vendor Splits",
    valueGetter: ConsignmentVendorSplitGetter,
  },
  [Column.Consignment.UNIT_VALUE]: {
    field: "unitPrice",
    colId: Column.Consignment.UNIT_VALUE,
    headerName: "Avg. Unit Price",
    type: ColumnType.NUMERIC,
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
  },
  [Column.Consignment.TOTAL_VALUE]: {
    colId: Column.Consignment.TOTAL_VALUE,
    field: "totalPriceCents",
    headerName: "Sale Value",
    type: ColumnType.NUMERIC,
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
  },
  [Column.Consignment.SOLD_QUANTITY]: {
    colId: Column.Consignment.SOLD_QUANTITY,
    field: "soldQuantity",
    headerName: "Sold Quantity",
    type: ColumnType.NUMERIC,
  },
  [Column.Consignment.WARNING]: {
    colId: Column.Consignment.WARNING,
    field: "rowWarning",
    headerName: "Warning",
    type: ColumnType.STRING,
    width: 500,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_EU]: {
    colId: Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_EU,
    headerName: "EU Eligible",
    field: withAttributeNamespace("accreditationEU"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_GRASS_FED]:
    {
      colId:
        Column.DraftingAttributes.CattleDraftingAttributes
          .ACCREDITATION_GRASS_FED,
      headerName: "Grass Fed",
      field: withAttributeNamespace("accreditationGrassFed"),
      type: ColumnType.STRING,
    },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PTIC]: {
    colId:
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PTIC,
    headerName: "PTIC",
    field: withAttributeNamespace("accreditationPTIC"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PCAS]: {
    colId:
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PCAS,
    headerName: "PCAS",
    field: withAttributeNamespace("accreditationPCAS"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes
    .ACCREDITATION_ANTIBIOTIC_FREE]: {
    colId:
      Column.DraftingAttributes.CattleDraftingAttributes
        .ACCREDITATION_ANTIBIOTIC_FREE,
    headerName: "Antibiotic Free",
    field: withAttributeNamespace("accreditationAntibioticFree"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_NEE]: {
    colId: Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_NEE,
    headerName: "Never Ever",
    field: withAttributeNamespace("accreditationNEE"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_JBAS]: {
    colId:
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_JBAS,
    headerName: "JBAS",
    field: withAttributeNamespace("accreditationJBAS"),
    type: ColumnType.NUMERIC,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PTE]: {
    colId: Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PTE,
    headerName: "PTE",
    field: withAttributeNamespace("accreditationPTE"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_ORGANIC]: {
    colId:
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_ORGANIC,
    headerName: "Organic",
    field: withAttributeNamespace("accreditationOrganic"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes
    .ACCREDITATION_STATION_MATED]: {
    colId:
      Column.DraftingAttributes.CattleDraftingAttributes
        .ACCREDITATION_STATION_MATED,
    headerName: "Station Mated",
    field: withAttributeNamespace("stationMated"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes
    .ACCREDITATION_NOT_STATION_MATED]: {
    colId:
      Column.DraftingAttributes.CattleDraftingAttributes
        .ACCREDITATION_NOT_STATION_MATED,
    headerName: "Not Station Mated",
    field: withAttributeNamespace("notStationMated"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_FEEDER]: {
    colId:
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_FEEDER,
    headerName: "Feeder",
    field: withAttributeNamespace("feeder"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes
    .ACCREDITATION_BACKGROUNDED]: {
    colId:
      Column.DraftingAttributes.CattleDraftingAttributes
        .ACCREDITATION_BACKGROUNDED,
    headerName: "Backgrounded",
    field: withAttributeNamespace("accreditationBackgrounded"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes
    .ACCREDITATION_GRAIN_FED_DAYS]: {
    colId:
      Column.DraftingAttributes.CattleDraftingAttributes
        .ACCREDITATION_GRAIN_FED_DAYS,
    headerName: "Grain Fed Days",
    field: withAttributeNamespace("grainFedDays"),
    type: ColumnType.NUMERIC,
  },

  [Column.DraftingAttributes.SheepDraftingAttributes
    .ANIMAL_HEALTH_STATEMENT_OVERRIDE]: {
    colId:
      Column.DraftingAttributes.SheepDraftingAttributes
        .ANIMAL_HEALTH_STATEMENT_OVERRIDE,
    headerName: "Animal Health Statement",
    field: withAttributeNamespace("animalHealthStatementOverride"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.SIX_IN_ONE]: {
    colId: Column.DraftingAttributes.SheepDraftingAttributes.SIX_IN_ONE,
    headerName: "6 in 1",
    field: withAttributeNamespace("sixInOne"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.DRENCHED]: {
    colId: Column.DraftingAttributes.SheepDraftingAttributes.DRENCHED,
    headerName: "Drenched",
    field: withAttributeNamespace("drenched"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.OJD_VAC]: {
    colId: Column.DraftingAttributes.SheepDraftingAttributes.OJD_VAC,
    headerName: "OJD Vac (Gudair)",
    field: withAttributeNamespace("ojdVac"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.GUDAIR_APPROVED]: {
    colId: Column.DraftingAttributes.SheepDraftingAttributes.GUDAIR_APPROVED,
    headerName: "Gudair Approved",
    field: withAttributeNamespace("gudairApproved"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.B12_VAC]: {
    colId: Column.DraftingAttributes.SheepDraftingAttributes.B12_VAC,
    headerName: "B12 Vac",
    field: withAttributeNamespace("b12Vac"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.BACKLINE]: {
    colId: Column.DraftingAttributes.SheepDraftingAttributes.BACKLINE,
    headerName: "Backline / Dipped",
    field: withAttributeNamespace("backline"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.M_AND_TS]: {
    colId: Column.DraftingAttributes.SheepDraftingAttributes.M_AND_TS,
    headerName: "M & TS",
    field: withAttributeNamespace("MAndTS"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.SA_ELIGIBLE]: {
    colId: Column.DraftingAttributes.SheepDraftingAttributes.SA_ELIGIBLE,
    headerName: "SA Eligible",
    field: withAttributeNamespace("SAEligible"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.PAIN_RELIEF]: {
    colId: Column.DraftingAttributes.SheepDraftingAttributes.PAIN_RELIEF,
    headerName: "Pain Relief",
    field: withAttributeNamespace("painRelief"),
    type: ColumnType.STRING,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.ACCREDITATION_ORGANIC]: {
    colId:
      Column.DraftingAttributes.SheepDraftingAttributes.ACCREDITATION_ORGANIC,
    headerName: "Organic",
    field: withAttributeNamespace("accreditationOrganic"),
    type: ColumnType.STRING,
  },
};

export const BaseColumnDefinitions = {
  [Column.SALEYARD_NAME]: {
    colId: Column.SALEYARD_NAME,
    field: "saleyard_name",
    headerName: "Saleyard",
    width: 100,
  },
  [Column.ABN]: {
    colId: Column.ABN,
    field: "vendor.abn",
    headerName: "ABN",
    width: 100,
  },
  [Column.IS_ANGUS_VERIFIED]: {
    colId: Column.IS_ANGUS_VERIFIED,
    field: "isAngusVerified",
    headerName: ColumnDisplayName[Column.IS_ANGUS_VERIFIED],
    filter: "anBooleanColumnFilter",
    filterParams: {
      showIndeterminate: true,
    },
    width: 100,
    cellRenderer: nullableBooleanRenderer,
  },
  [Column.BRANDS]: {
    colId: Column.BRANDS,
    field: "brands",
    headerName: "Brands",
    width: 100,
  },
  [Column.NOT_ANGUS_VERIFIED_COUNT]: {
    colId: Column.NOT_ANGUS_VERIFIED_COUNT,
    field: "notAngusVerifiedCount",
    headerName: ColumnDisplayName[Column.NOT_ANGUS_VERIFIED_COUNT],
    width: 100,
  },

  [Column.PEN]: {
    colId: Column.PEN,
    field: Column.PEN,
    headerName: ColumnDisplayName[Column.PEN],
    comparator: penComparator,
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.LOT_NUMBER]: {
    colId: Column.LOT_NUMBER,
    field: Column.LOT_NUMBER,
    headerName: ColumnDisplayName[Column.LOT_NUMBER],
    type: ColumnType.NUMERIC,
    width: 65,
  },
  [Column.LOT_NUMBER_SUFFIX]: {
    colId: Column.LOT_NUMBER_SUFFIX,
    field: Column.LOT_NUMBER_SUFFIX,
    headerName: ColumnDisplayName[Column.LOT_NUMBER_SUFFIX],
    type: ColumnType.STRING,
    width: 30,
  },
  [Column.LOT_NUMBER_COMBINED]: {
    colId: Column.LOT_NUMBER_COMBINED,
    field: Column.LOT_NUMBER_COMBINED,
    headerName: ColumnDisplayName[Column.LOT_NUMBER_COMBINED],
    type: ColumnType.STRING,
    width: 80,
  },
  [Column.BUYER_NAME]: {
    colId: Column.BUYER_NAME,
    field: Column.BUYER_NAME,
    headerName: ColumnDisplayName[Column.BUYER_NAME],
    type: ColumnType.STRING,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.BUYER_ALTERNATIVES]: {
    colId: "1822faac-b04b-4fb6-b335-0f3982c8205c",
    field: Column.BUYER_ALTERNATIVES,
    headerName: "Buyer Alternatives",
    cellRenderer: BuyerAlternativesCellRenderer,
    width: 60,
    sortable: true,
    sort: "asc",
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.BUYER_PAYMENTS]: {
    colId: Column.BUYER_PAYMENTS,
    field: Column.BUYER_PAYMENTS,
    headerName: ColumnDisplayName[Column.BUYER_PAYMENTS],
    cellRenderer: buyerPaymentsRenderer,
    cellRendererParams: {
      businessId: "buyer_id",
    },
    width: 130,
  },
  [Column.BUSINESS_DISPLAY_NAME]: {
    colId: Column.BUSINESS_DISPLAY_NAME,
    field: Column.BUSINESS_DISPLAY_NAME,
    headerName: "Display Name",
    type: ColumnType.STRING,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.BUYER_SHORT_CODE]: {
    colId: Column.BUYER_SHORT_CODE,
    field: Column.BUYER_SHORT_CODE,
    headerName: ColumnDisplayName[Column.BUYER_SHORT_CODE],
    width: 90,
    cellRenderer: BusinessRenderer,
    cellRendererParams: {
      businessKey: "buyer_id",
      complainIfMissing: true,
    },
  },
  [Column.BUYER_SHORT_CODE_SALEYARD]: {
    colId: Column.BUYER_SHORT_CODE_SALEYARD,
    field: Column.BUYER_SHORT_CODE_SALEYARD,
    headerName: ColumnDisplayName[Column.BUYER_SHORT_CODE_SALEYARD],
    width: 110,
    cellRenderer: SyRefBusinessRenderer,
    cellRendererParams: {
      businessKey: "buyer_id",
    },
  },
  [Column.CONSIGNMENT_SCANNED_COUNT]: {
    colId: Column.CONSIGNMENT_SCANNED_COUNT,
    field: Column.CONSIGNMENT_SCANNED_COUNT,
    headerName: "Consignment EIDs",
    type: ColumnType.NUMERIC,
    width: 115,
  },
  [Column.CREATED]: {
    colId: Column.CREATED,
    field: Column.CREATED,
    headerName: "Created",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    width: 130,
  },
  [Column.THIRD_PARTY_NAME]: {
    colId: Column.THIRD_PARTY_NAME,
    field: Column.THIRD_PARTY_NAME,
    headerName: ColumnDisplayName[Column.THIRD_PARTY_NAME],
    type: ColumnType.STRING,
    width: 90,
  },
  [Column.CURRENT_PIC]: {
    colId: Column.CURRENT_PIC,
    field: Column.CURRENT_PIC,
    headerName: ColumnDisplayName[Column.CURRENT_PIC],
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.DESTINATION_PIC]: {
    colId: Column.DESTINATION_PIC,
    field: Column.DESTINATION_PIC,
    headerName: ColumnDisplayName[Column.DESTINATION_PIC],
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.DRAFT_DEVICE]: {
    colId: Column.DRAFT_DEVICE,
    field: Column.DRAFT_DEVICE,
    headerName: ColumnDisplayName[Column.DRAFT_DEVICE],
    width: 100,
    valueGetter: getters.draftingDeviceGetter,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.DRAFT_NAME]: {
    colId: Column.DRAFT_NAME,
    field: Column.DRAFT_NAME,
    headerName: ColumnDisplayName[Column.DRAFT_NAME],
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },

  [Column.SCAN_ERP_STATUS]: {
    colId: Column.SCAN_ERP_STATUS,
    field: Column.SCAN_ERP_STATUS,
    headerName: ColumnDisplayName[Column.SCAN_ERP_STATUS],
    width: 65,
    valueGetter: getters.erpGetter,
    cellRenderer: erpStatusRenderer,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.EXEMPTION_REASON]: {
    colId: Column.EXEMPTION_REASON,
    field: Column.EXEMPTION_REASON,
    headerName: ColumnDisplayName[Column.EXEMPTION_REASON],
    width: 135,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.BUYER_WAY_NAME]: {
    colId: Column.BUYER_WAY_NAME,
    field: Column.BUYER_WAY_NAME,
    headerName: ColumnDisplayName[Column.BUYER_WAY_NAME],
    type: ColumnType.STRING,
    comparator: caseInsensitiveCompare,
    filterParams: {
      keyCreator: params => {
        const buyerName = params.api.getValue(Column.BUYER_NAME, params.node);
        if (!params.value) {
          return buyerName || "";
        }
        return `${params.value}${buyerName ? ` - ${buyerName}` : ""}`;
      },
      valueFormatter: params => params.value.name,
    },
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.VENDOR_NAME]: {
    colId: Column.VENDOR_NAME,
    field: Column.VENDOR_NAME,
    headerName: "Vendor",
    type: ColumnType.STRING,
    width: 200,
    sortable: true,
    sort: "asc",
    comparator: caseInsentitiveComparator,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.VENDOR_ALTERNATIVES]: {
    field: Column.VENDOR_ALTERNATIVES,
    colId: "42f91ee6-8083-433c-bbcd-93a868745e15",
    headerName: "Vendor Alternatives",
    cellRenderer: VendorAlternativesCellRenderer,
    width: 60,
    sortable: true,
    sort: "asc",
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.UNIT_PRICE]: {
    colId: Column.UNIT_PRICE,
    field: Column.UNIT_PRICE,
    headerValueGetter: unitPriceHeaderGetter,
    type: ColumnType.NUMERIC,
    cellRenderer: unitPriceRenderer,
    width: 100,
  },
  [Column.BREED_NAME]: {
    colId: Column.BREED_NAME,
    field: Column.BREED_NAME,
    headerName: ColumnDisplayName[Column.BREED_NAME],
    width: 110,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.AGE_GROUP_NAME]: {
    colId: Column.AGE_GROUP_NAME,
    field: Column.AGE_GROUP_NAME,
    headerName: ColumnDisplayName[Column.AGE_GROUP_NAME],
    width: 140,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.GRADE_NAME]: {
    colId: Column.GRADE_NAME,
    field: Column.GRADE_NAME,
    headerName: ColumnDisplayName[Column.GRADE_NAME],
    width: 80,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.AVERAGE_WEIGHT]: {
    colId: Column.AVERAGE_WEIGHT,
    field: Column.AVERAGE_WEIGHT,
    headerName: ColumnDisplayName[Column.AVERAGE_WEIGHT],
    type: ColumnType.NUMERIC,
    width: 80,
    comparator: weightComparator,
  },
  [Column.AVERAGE_WEIGHT_OUTLIER]: {
    colId: Column.AVERAGE_WEIGHT_OUTLIER,
    field: "averageWeightGrams",
    headerName: ColumnDisplayName[Column.AVERAGE_WEIGHT_OUTLIER],
    width: 80,
    valueGetter: weightOutlierGetter,
    comparator: weightOutlierComparator,
    cellRenderer: weightOutlierRenderer,
  },
  [Column.TOTAL_MASS_GRAMS]: {
    colId: Column.TOTAL_MASS_GRAMS,
    field: Column.TOTAL_MASS_GRAMS,
    headerName: ColumnDisplayName[Column.TOTAL_MASS_GRAMS],
    width: 95,
    type: ColumnType.NUMERIC,
    valueGetter: kilogramsGetter,
    valueFormatter: kilogramsFormatter,
  },
  [Column.TOTAL_MASS_GRAMS_OUTLIER]: {
    colId: Column.TOTAL_MASS_GRAMS_OUTLIER,
    field: Column.TOTAL_MASS_GRAMS,
    headerName: ColumnDisplayName[Column.TOTAL_MASS_GRAMS_OUTLIER],
    width: 80,
    valueGetter: weightOutlierGetter,
    cellRenderer: weightOutlierRenderer,
    comparator: weightOutlierComparator,
  },
  [Column.SCAN_TIME_WEIGHED]: {
    colId: Column.SCAN_TIME_WEIGHED,
    field: Column.SCAN_TIME_WEIGHED,
    headerName: ColumnDisplayName[Column.SCAN_TIME_WEIGHED],
    width: 105,
    type: ColumnType.UTC_LOCAL_DATE_TIME,
  },
  [Column.TIME_BULK_WEIGHED]: {
    colId: Column.TIME_BULK_WEIGHED,
    field: Column.TIME_BULK_WEIGHED,
    headerName: ColumnDisplayName[Column.TIME_BULK_WEIGHED],
    width: 135,
    type: ColumnType.UTC_LOCAL_DATE_TIME,
  },
  [Column.IS_MANUALLY_WEIGHED]: {
    colId: Column.IS_MANUALLY_WEIGHED,
    field: "is_manually_weighed",
    headerName: ColumnDisplayName[Column.IS_MANUALLY_WEIGHED],
    filter: "anBooleanColumnFilter",
    width: 135,
    cellRenderer: trueIcon,
  },

  [Column.IS_NLIS_SIGHTED]: {
    colId: Column.IS_NLIS_SIGHTED,
    field: Column.IS_NLIS_SIGHTED,
    headerName: "Sighted",
    filter: "anBooleanColumnFilter",
    width: 135,
    cellRenderer: trueIcon,
  },

  [Column.IS_SELLABLE]: {
    colId: Column.IS_SELLABLE,
    field: Column.IS_SELLABLE,
    headerName: ColumnDisplayName[Column.IS_SELLABLE],
    filter: "anBooleanColumnFilter",
    width: 135,
    cellRenderer: trueIcon,
  },
  [Column.IS_TAKEABLE]: {
    colId: Column.IS_TAKEABLE,
    field: Column.IS_TAKEABLE,
    headerName: ColumnDisplayName[Column.IS_TAKEABLE],
    filter: "anBooleanColumnFilter",
    width: 135,
    cellRenderer: trueIcon,
  },
  [Column.DELIVERY_PEN]: {
    colId: Column.DELIVERY_PEN,
    field: Column.DELIVERY_PEN,
    headerName: ColumnDisplayName[Column.DELIVERY_PEN],
    comparator: penComparator,
    valueGetter: penGetter,
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.WEIGHED_COUNT]: {
    colId: Column.WEIGHED_COUNT,
    field: Column.WEIGHED_COUNT,
    headerName: ColumnDisplayName[Column.WEIGHED_COUNT],
    width: 80,
    type: ColumnType.NUMERIC,
  },
  [Column.OPEN_AUCTION_COUNT]: {
    colId: Column.OPEN_AUCTION_COUNT,
    field: Column.OPEN_AUCTION_COUNT,
    headerName: ColumnDisplayName[Column.OPEN_AUCTION_COUNT],
    width: 80,
    type: ColumnType.NUMERIC,
  },
  [Column.WEIGHT]: {
    colId: Column.WEIGHT,
    headerName: ColumnDisplayName[Column.WEIGHT],
    width: 80,
    type: ColumnType.WEIGHT_FROM_GRAMS,
    valueGetter: totalWeightKilogramsGetter,
  },
  [Column.WEIGHT_OUTLIER]: {
    colId: Column.WEIGHT_OUTLIER,
    field: "total_mass_grams",
    headerName: ColumnDisplayName[Column.WEIGHT_OUTLIER],
    width: 80,
    valueGetter: weightOutlierGetter,
    cellRenderer: weightOutlierRenderer,
    comparator: weightOutlierComparator,
  },
  [Column.SALE_ROUND_NAME]: {
    colId: Column.SALE_ROUND_NAME,
    field: Column.SALE_ROUND_NAME,
    headerName: ColumnDisplayName[Column.SALE_ROUND_NAME],
    width: 120,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.SEX_NAME]: {
    colId: Column.SEX_NAME,
    field: Column.SEX_NAME,
    headerName: ColumnDisplayName[Column.SEX_NAME],
    width: 140,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.CATEGORY_NAME]: {
    colId: Column.CATEGORY_NAME,
    field: Column.CATEGORY_NAME,
    headerName: ColumnDisplayName[Column.CATEGORY_NAME],
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.QUANTITY]: {
    colId: Column.QUANTITY,
    field: Column.QUANTITY,
    headerName: "Hd",
    type: ColumnType.NUMERIC,
    cellRenderer: quantityRenderer,
    width: 65,
    enableValue: true,
  },
  [Column.QUANTITY_DELIVERED]: {
    colId: Column.QUANTITY_DELIVERED,
    field: Column.QUANTITY_DELIVERED,
    headerName: ColumnDisplayName[Column.QUANTITY_DELIVERED],
    type: ColumnType.NUMERIC,
    width: 95,
  },
  [Column.QUANTITY_PROGENY]: {
    colId: Column.QUANTITY_PROGENY,
    field: Column.QUANTITY_PROGENY,
    headerName: ColumnDisplayName[Column.QUANTITY_PROGENY],
    type: ColumnType.NUMERIC,
    width: 95,
  },
  [Column.QUANTITY_TAGS_USED]: {
    colId: "7af34f17-ca42-499f-9471-745b27ff0dcd",
    field: Column.QUANTITY_TAGS_USED,
    headerName: "Total Tags Used",
    type: ColumnType.NUMERIC,
  },
  [Column.DIFF]: {
    colId: Column.DIFF,
    field: Column.DIFF,
    headerName: ColumnDisplayName[Column.DIFF],
    type: ColumnType.NUMERIC,
    valueFormatter: diffFormatter,
    cellRenderer: diffRenderer,
    width: 80,
  },
  [Column.EID]: {
    colId: Column.EID,
    field: Column.EID,
    headerName: ColumnDisplayName[Column.EID],
    width: 120,
  },
  [Column.LAST_MODIFIED]: {
    colId: Column.LAST_MODIFIED,
    field: Column.LAST_MODIFIED,
    headerName: "Last Modified",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
    width: 130,
  },
  [Column.NLIS_ID]: {
    colId: Column.NLIS_ID,
    field: Column.NLIS_ID,
    headerName: ColumnDisplayName[Column.NLIS_ID],
    width: 120,
    cellRenderer: nlisIdRenderer,
    valueGetter: getters.nlisIdGetter,
  },

  [Column.NLIS_TAKE_TRANSFER_STATUS]: {
    colId: Column.NLIS_TAKE_TRANSFER_STATUS,
    field: Column.NLIS_TAKE_TRANSFER_STATUS,
    headerName: "Status",
    enablePivot: true,
    enableRowGroup: true,
  },

  [Column.NLIS_TAKE_TRANSFER_MESSAGE]: {
    colId: Column.NLIS_TAKE_TRANSFER_MESSAGE,
    field: Column.NLIS_TAKE_TRANSFER_MESSAGE,
    headerName: "Message",
    enablePivot: true,
    enableRowGroup: true,
    valueFormatter: formatters.nlisTransferMessageFormatter,
  },

  [Column.NLIS_TAKE_TRANSFER_VENDOR_PIC]: {
    colId: Column.NLIS_TAKE_TRANSFER_VENDOR_PIC,
    field: Column.NLIS_TAKE_TRANSFER_VENDOR_PIC,
    headerName: "PIC",
  },

  [Column.NLIS_TAKE_TRANSFER_NVD]: {
    colId: Column.NLIS_TAKE_TRANSFER_NVD,
    field: Column.NLIS_TAKE_TRANSFER_NVD,
    headerName: "NVD",
  },

  [Column.NLIS_SELL_TRANSFER_STATUS]: {
    colId: Column.NLIS_SELL_TRANSFER_STATUS,
    field: Column.NLIS_SELL_TRANSFER_STATUS,
    headerName: "Status",
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.NLIS_SELL_TRANSFER_MESSAGE]: {
    colId: Column.NLIS_SELL_TRANSFER_MESSAGE,
    field: Column.NLIS_SELL_TRANSFER_MESSAGE,
    headerName: "Message",
    enablePivot: true,
    enableRowGroup: true,
    valueFormatter: formatters.nlisTransferMessageFormatter,
  },

  [Column.NLIS_SELL_TRANSFER_BUYER_PIC]: {
    colId: Column.NLIS_SELL_TRANSFER_BUYER_PIC,
    field: Column.NLIS_SELL_TRANSFER_BUYER_PIC,
    headerName: "PIC",
  },

  [Column.IS_TRANSFERRED]: {
    colId: Column.IS_TRANSFERRED,
    field: Column.IS_TRANSFERRED,
    headerName: "Transferred",
    enablePivot: true,
    enableRowGroup: true,
  },

  [Column.NLIS_TAKE_ID]: {
    colId: Column.NLIS_TAKE_ID,
    field: Column.NLIS_TAKE_ID,
    headerName: ColumnDisplayName[Column.NLIS_TAKE_ID],
    width: 120,
    enablePivot: true,
    enableRowGroup: true,
    cellRenderer: NLISFileRenderer,
    cellRendererParams: {
      nlisFileType: NLISFileTypes.NLISTakeFile,
    },
  },

  [Column.NLIS_SELL_ID]: {
    colId: Column.NLIS_SELL_ID,
    field: Column.NLIS_SELL_ID,
    headerName: ColumnDisplayName[Column.NLIS_SELL_ID],
    width: 120,
    enablePivot: true,
    enableRowGroup: true,
    cellRenderer: NLISFileRenderer,
    cellRendererParams: {
      nlisFileType: NLISFileTypes.NLISSellFile,
    },
  },
  [Column.NLIS_TAKE_STATUS]: {
    colId: Column.NLIS_TAKE_STATUS,
    field: Column.NLIS_TAKE_STATUS,
    headerName: ColumnDisplayName[Column.NLIS_TAKE_STATUS],
    width: 120,
    cellRenderer: NlisStatusRenderer,
    cellRendererParams: {
      transferType: "TAKE",
    },
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.NLIS_SELL_STATUS]: {
    colId: Column.NLIS_SELL_STATUS,
    field: Column.NLIS_SELL_STATUS,
    headerName: ColumnDisplayName[Column.NLIS_SELL_STATUS],
    width: 120,
    cellRenderer: NlisStatusRenderer,
    cellRendererParams: {
      transferType: "SELL",
    },
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.STATUS]: {
    colId: Column.STATUS,
    field: Column.STATUS,
    headerName: ColumnDisplayName[Column.STATUS],
    width: 175,
    cellRenderer: statusColumnRenderer,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.AGENCY_NAME]: {
    colId: Column.AGENCY_NAME,
    field: Column.AGENCY_NAME,
    headerName: "Agency",
    type: ColumnType.STRING,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.SCANNED_COUNT]: {
    colId: Column.SCANNED_COUNT,
    field: Column.SCANNED_COUNT,
    headerName: "Sale Lot EIDS",
    type: ColumnType.NUMERIC,
    width: 115,
    cellRenderer: erpColumnRenderer,
  },
  [Column.SCANNED_PERCENTAGE]: {
    colId: Column.SCANNED_PERCENTAGE,
    field: Column.SCANNED_PERCENTAGE,
    headerName: "Act. Level",
    type: ColumnType.PERCENTAGE,
    width: 80,
  },
  [Column.NVD]: {
    colId: Column.NVD,
    field: Column.NVD,
    headerName: ColumnDisplayName[Column.NVD],
    cellRenderer: NVDRenderer,
    width: 80,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.VENDOR_PIC]: {
    colId: Column.VENDOR_PIC,
    field: Column.VENDOR_PIC,
    headerName: ColumnDisplayName[Column.VENDOR_PIC],
    width: 125,
    enablePivot: true,
    enableRowGroup: true,
  },

  [Column.TOTAL_PRICE]: {
    colId: Column.TOTAL_PRICE,
    field: Column.TOTAL_PRICE,
    headerName: ColumnDisplayName[Column.TOTAL_PRICE],
    type: ColumnType.CURRENCY_FROM_CENTS,
    width: 125,
    enableValue: true,
  },
  [Column.VENDOR_SHORT_CODE]: {
    colId: Column.VENDOR_SHORT_CODE,
    field: Column.VENDOR_SHORT_CODE,
    headerName: "Agent Ref",
    width: 100,
    cellRenderer: BusinessRenderer,
    cellRendererParams: {
      complainIfMissing: true,
      businessKey: "vendor_id",
    },
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.VENDOR_SHORT_CODE_SALEYARD]: {
    colId: Column.VENDOR_SHORT_CODE_SALEYARD,
    field: Column.VENDOR_SHORT_CODE_SALEYARD,
    headerName: "SY Ref",
    width: 100,
    cellRenderer: SyRefBusinessRenderer,
    cellRendererParams: {
      businessKey: "vendor_id",
    },
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.AGENT_RELATIONSHIP]: {
    colId: Column.AGENT_RELATIONSHIP,
    field: "relationshipAgent",
    headerName: "Agent",
    width: 100,
    cellRenderer: RelationshipAgentRenderer,
  },
  [Column.VENDOR_NUM]: {
    colId: Column.VENDOR_NUM,
    field: Column.VENDOR_NUM,
    headerName: "Ven. #",
    width: 80,
    valueGetter: getters.integerOrDefaultGetter("-"),
    comparator: caseInsensitiveCompare,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.AGENCY_VENDOR_NUM]: {
    colId: Column.AGENCY_VENDOR_NUM,
    field: Column.AGENCY_VENDOR_NUM,
    headerName: "Ven. #",
    width: 60,
    valueFormatter: agencyVendorNumFormatter,
    valueGetter: agencyVendorNumGetter,
    comparator: caseInsensitiveCompare,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.DRAFT_HC]: {
    colId: Column.DRAFT_HC,
    field: Column.DRAFT_HC,
    headerName: "Drafted Hd",
    type: ColumnType.NUMERIC,
    width: 90,
  },
  [Column.NVD_RECEIVED]: {
    colId: Column.NVD_RECEIVED,
    field: "NVD",
    headerName: ColumnDisplayName[Column.NVD_RECEIVED],
    cellRenderer: NVDRecRenderer,
    width: 60,
  },
  [Column.NVD_ID]: {
    colId: Column.NVD_ID,
    field: Column.NVD_ID,
    headerName: "NVD ID",
    cellRenderer: NVDIDRenderer,
    width: 80,
  },
  [Column.ATTACHMENTS]: {
    colId: Column.ATTACHMENTS,
    field: Column.ATTACHMENTS,
    headerName: "Attachments",
    cellRenderer: declarationAttachmentsRenderer,
    valueGetter: getters.attachmentsGetter,
    width: 125,
  },
  [Column.CONSIGNED_FROM]: {
    colId: Column.CONSIGNED_FROM,
    field: Column.CONSIGNED_FROM,
    headerName: "Consigned From",
    width: 175,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.BRANCH]: {
    colId: Column.BRANCH,
    field: Column.BRANCH,
    headerName: "BRANCH",
    width: 125,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.PIC_PROGRAMS]: {
    colId: Column.PIC_PROGRAMS,
    field: Column.PIC_PROGRAMS,
    headerName: "PIC Programs",
    width: 110,
    cellRenderer: nlisProgramsRenderer,
    valueGetter: getters.nlisProgramsGetter,
  },
  [Column.LIFETIME_TRACEABILITY]: {
    colId: Column.LIFETIME_TRACEABILITY,
    field: Column.LIFETIME_TRACEABILITY,
    headerName: ColumnDisplayName[Column.LIFETIME_TRACEABILITY],
    width: 50,
    cellRenderer: lifetimeTraceableRenderer,
    valueGetter: getters.erpGetter,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.EU_STATUS]: {
    colId: Column.EU_STATUS,
    field: Column.EU_STATUS,
    headerName: ColumnDisplayName[Column.EU_STATUS],
    width: 50,
    cellRenderer: euStatusRenderer,
    valueGetter: getters.euGetter,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.LPA_STATUS]: {
    colId: Column.LPA_STATUS,
    field: "nlisPrograms",
    headerName: "LPA",
    width: 70,
    valueGetter: getters.lpaStatusGetter,
    cellRenderer: LPAStatusRenderer,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.HAS_ARRIVED]: {
    colId: Column.HAS_ARRIVED,
    field: Column.HAS_ARRIVED,
    headerName: "Has Arrived",
    cellRenderer: arrivedRenderer,
    filter: "anBooleanColumnFilter",
    width: 100,
    enablePivot: true,
    enableRowGroup: true,
  },
  [Column.CONSIGNMENT_DIFF]: {
    field: Column.CONSIGNMENT_DIFF,
    headerName: ColumnDisplayName[Column.CONSIGNMENT_DIFF],
    type: ColumnType.NUMERIC,
    valueFormatter: consignmentDiffFormatter,
    cellRenderer: consignmentDiffRenderer,
    width: 55,
  },
  [Column.NVD_HC]: {
    field: Column.NVD_HC,
    headerName: ColumnDisplayName[Column.NVD_HC],
    valueFormatter: nvdHCFormatter,
    width: 70,
  },
  [Column.NOMINATION]: {
    colId: Column.NOMINATION,
    field: Column.NOMINATION,
    headerName: "Nomination",
    marryChildren: true,
    children: [
      {
        field: Column.NOMINATION_HAS_ARRIVED,
        colId: Column.NOMINATION_HAS_ARRIVED,

        headerName: ColumnDisplayName[Column.NOMINATION_HAS_ARRIVED],
        cellRenderer: nullableBooleanRenderer,
        width: 100,
      },
      {
        field: Column.NOMINATION_CONSIGNING_DEPLOYMENT,
        colId: Column.NOMINATION_CONSIGNING_DEPLOYMENT,

        headerName: ColumnDisplayName[Column.NOMINATION_CONSIGNING_DEPLOYMENT],
        width: 300,
      },
      {
        field: Column.NOMINATION_VENDOR,
        colId: Column.NOMINATION_VENDOR,

        headerName: ColumnDisplayName[Column.NOMINATION_VENDOR],
        width: 300,
      },
      {
        field: Column.NOMINATION_DETAILS,
        colId: Column.NOMINATION_DETAILS,

        headerName: ColumnDisplayName[Column.NOMINATION_DETAILS],
        valueGetter: nominationDetailsValueGetter,
        cellRenderer: nominationDetailsCellRenderer,
        width: 300,
      },
      {
        field: Column.NOMINATION_HC,
        headerName: ColumnDisplayName[Column.NOMINATION_HC],
        valueGetter: nominationHdGetter,
        width: 90,
      },
    ],
  },
  [Column.DELIVERY_DATE]: {
    colId: Column.DELIVERY_DATE,
    field: Column.DELIVERY_DATE,
    headerName: "Delivery Date",
    valueFormatter: dateFormatter,
    width: 90,
  },
  [Column.CARRIER]: {
    colId: Column.CARRIER,
    field: Column.CARRIER,
    headerName: "Carrier",
    width: 200,
    sortable: true,
  },
  [Column.IMAGES]: {
    colId: Column.IMAGES,
    field: Column.IMAGES,
    headerName: ColumnDisplayName[Column.IMAGES],
    cellRenderer: imageAttachmentsRenderer,
    cellRendererParams: {
      type: DocumentTypes.IMAGE,
    },
    width: 125,
  },
  [Column.NOTE]: {
    colId: Column.NOTE,
    field: Column.NOTE,
    headerName: ColumnDisplayName[Column.NOTE],
    width: 200,
  },
  [Column.LABELS]: {
    colId: Column.LABELS,
    field: Column.LABELS,
    headerName: ColumnDisplayName[Column.LABELS],
    width: 200,
  },
  [Column.MARKS]: {
    colId: Column.MARKS,
    field: Column.MARKS,
    headerName: ColumnDisplayName[Column.MARKS],
    valueGetter: getters.marksGetter,
  },
  [Column.BIDDER_REGO_NUMBER]: {
    colId: Column.BIDDER_REGO_NUMBER,
    field: Column.BIDDER_REGO_NUMBER,
    headerName: ColumnDisplayName[Column.BIDDER_REGO_NUMBER],
    width: 75,
  },

  [Column.IS_GST_EXEMPT]: {
    colId: Column.IS_GST_EXEMPT,
    field: Column.IS_GST_EXEMPT,
    headerName: ColumnDisplayName[Column.IS_GST_EXEMPT],
    filter: "anBooleanColumnFilter",
    width: 75,
    cellRenderer: booleanRenderer,
  },
  [Column.GST_STATUS]: {
    colId: Column.GST_STATUS,
    field: Column.GST_STATUS,
    headerName: "GST Registered",
    filter: "anBooleanColumnFilter",
    filterParams: {
      showIndeterminate: true,
    },
    width: 120,
    cellRenderer: nullableTickRenderer,
  },

  [Column.ITEM_LOCATION]: {
    colId: Column.ITEM_LOCATION,
    field: Column.ITEM_LOCATION,
    headerName: ColumnDisplayName[Column.ITEM_LOCATION],
    width: 150,
  },

  [Column.HAS_PICKUP_ADDRESS]: {
    colId: Column.HAS_PICKUP_ADDRESS,
    field: Column.HAS_PICKUP_ADDRESS,
    headerName: ColumnDisplayName[Column.HAS_PICKUP_ADDRESS],
    width: 90,
    cellRenderer: hasAddressRenderer,
  },
  [Column.HAS_VENDOR_POSTAL_ADDRESS]: {
    colId: Column.HAS_VENDOR_POSTAL_ADDRESS,
    field: Column.HAS_VENDOR_POSTAL_ADDRESS,
    headerName: ColumnDisplayName[Column.HAS_VENDOR_POSTAL_ADDRESS],
    width: 90,
    cellRenderer: hasAddressRenderer,
  },
  [Column.HAS_BUYER_POSTAL_ADDRESS]: {
    colId: Column.HAS_BUYER_POSTAL_ADDRESS,
    field: Column.HAS_BUYER_POSTAL_ADDRESS,
    headerName: ColumnDisplayName[Column.HAS_BUYER_POSTAL_ADDRESS],
    width: 90,
    cellRenderer: hasAddressRenderer,
  },
  [Column.VENDOR_COMMISSION]: {
    colId: Column.VENDOR_COMMISSION,
    field: Column.VENDOR_COMMISSION,
    headerName: ColumnDisplayName[Column.VENDOR_COMMISSION],
    width: 100,
    type: ColumnType.NUMERIC,
  },
  [Column.EFFECTIVE_VENDOR_COMMISSION_PERCENT]: {
    colId: Column.EFFECTIVE_VENDOR_COMMISSION_PERCENT,
    field: Column.EFFECTIVE_VENDOR_COMMISSION_PERCENT,
    headerName: ColumnDisplayName[Column.EFFECTIVE_VENDOR_COMMISSION_PERCENT],
    type: ColumnType.PERCENTAGE,
    width: 120,
    valueGetterParams: { includeDecimals: true },
  },
  [Column.EFFECTIVE_VENDOR_COMMISSION_CENTS]: {
    colId: Column.EFFECTIVE_VENDOR_COMMISSION_CENTS,
    field: Column.EFFECTIVE_VENDOR_COMMISSION_CENTS,
    headerName: ColumnDisplayName[Column.EFFECTIVE_VENDOR_COMMISSION_CENTS],
    width: 120,
    type: ColumnType.NUMERIC,
    comparator: caseInsensitiveCompare,
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
  },
  [Column.BUYER_NUMBER]: {
    colId: Column.BUYER_NUMBER,
    field: Column.BUYER_NUMBER,
    headerName: ColumnDisplayName[Column.BUYER_NUMBER],
    width: 75,
  },
  [Column.LISTING_FEE]: {
    colId: Column.LISTING_FEE,
    field: Column.LISTING_FEE,
    headerName: ColumnDisplayName[Column.LISTING_FEE],
    width: 75,
    comparator: caseInsensitiveCompare,
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
    type: ColumnType.NUMERIC,
  },
  [Column.ORIGINAL_LISTING_PRICE]: {
    colId: Column.ORIGINAL_LISTING_PRICE,
    field: Column.ORIGINAL_LISTING_PRICE,
    headerName: ColumnDisplayName[Column.ORIGINAL_LISTING_PRICE],
    width: 75,
    comparator: caseInsensitiveCompare,
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
    type: ColumnType.NUMERIC,
  },
  [Column.EFFECTIVE_LISTING_FEE_CENTS]: {
    colId: Column.EFFECTIVE_LISTING_FEE_CENTS,
    field: Column.EFFECTIVE_LISTING_FEE_CENTS,
    headerName: ColumnDisplayName[Column.EFFECTIVE_LISTING_FEE_CENTS],
    width: 75,
    comparator: caseInsensitiveCompare,
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
    type: ColumnType.NUMERIC,
  },
  [Column.RESERVE_PRICE]: {
    colId: Column.RESERVE_PRICE,
    field: Column.RESERVE_PRICE,
    headerName: ColumnDisplayName[Column.RESERVE_PRICE],
    width: 75,
    comparator: caseInsensitiveCompare,
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
    type: ColumnType.NUMERIC,
  },
  [Column.STARTING_BID]: {
    colId: Column.STARTING_BID,
    field: Column.STARTING_BID,
    headerName: ColumnDisplayName[Column.STARTING_BID],
    width: 75,
    comparator: caseInsensitiveCompare,
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
    type: ColumnType.NUMERIC,
  },
  [Column.BID_INCREMENT]: {
    colId: Column.BID_INCREMENT,
    field: Column.BID_INCREMENT,
    headerName: ColumnDisplayName[Column.BID_INCREMENT],
    width: 75,
    comparator: caseInsensitiveCompare,
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
    type: ColumnType.NUMERIC,
  },
  [Column.BUYERS_PREMIUM]: {
    colId: Column.BUYERS_PREMIUM,
    field: Column.BUYERS_PREMIUM,
    headerName: ColumnDisplayName[Column.BUYERS_PREMIUM],
    width: 75,
    type: ColumnType.NUMERIC,
  },

  [Column.EFFECTIVE_BUYERS_PREMIUM_PERCENT]: {
    colId: Column.EFFECTIVE_BUYERS_PREMIUM_PERCENT,
    field: Column.EFFECTIVE_BUYERS_PREMIUM_PERCENT,
    headerName: ColumnDisplayName[Column.EFFECTIVE_BUYERS_PREMIUM_PERCENT],
    type: ColumnType.PERCENTAGE,
    width: 100,
  },

  [Column.EFFECTIVE_BUYERS_PREMIUM_CENTS]: {
    colId: Column.EFFECTIVE_BUYERS_PREMIUM_CENTS,
    field: Column.EFFECTIVE_BUYERS_PREMIUM_CENTS,
    headerName: ColumnDisplayName[Column.EFFECTIVE_BUYERS_PREMIUM_CENTS],
    width: 100,
    comparator: caseInsensitiveCompare,
    valueGetter: getters.dollarsGetter,
    valueFormatter: formatters.dollarsFormatter,
    type: ColumnType.NUMERIC,
  },
  [Column.SINGLE_WEIGH_SUM]: {
    colId: Column.SINGLE_WEIGH_SUM,
    field: Column.SINGLE_WEIGH_SUM,
    headerName: ColumnDisplayName[Column.SINGLE_WEIGH_SUM],
    width: 145,
    valueGetter: getters.kilogramsGetter,
    valueFormatter: formatters.kilogramsFormatter,
    type: ColumnType.NUMERIC,
  },
  [Column.SINGLE_WEIGH_SUM_OUTLIER]: {
    colId: Column.SINGLE_WEIGH_SUM_OUTLIER,
    field: Column.SINGLE_WEIGH_SUM,
    headerName: ColumnDisplayName[Column.SINGLE_WEIGH_SUM_OUTLIER],
    width: 80,
    valueGetter: weightOutlierGetter,
    comparator: weightOutlierComparator,
    cellRenderer: weightOutlierRenderer,
  },

  [Column.SINGLE_WEIGH_DIFF]: {
    colId: Column.SINGLE_WEIGH_DIFF,
    field: Column.SINGLE_WEIGH_DIFF,
    headerName: ColumnDisplayName[Column.SINGLE_WEIGH_DIFF],
    width: 140,
    valueFormatter: kilogramDiffFormatter,
    cellRenderer: diffRenderer,
    type: ColumnType.NUMERIC,
  },

  [Column.XERO_VENDOR]: {
    colId: Column.XERO_VENDOR,
    field: Column.XERO_VENDOR,
    headerName: ColumnDisplayName[Column.XERO_VENDOR],
    width: 140,
    valueGetter: getters.xeroBusinessGetter,
    cellRenderer: IntegrationBusinessRenderer,
    cellRendererParams: {
      businessKeys: ["vendor"],
      complainIfMissing: true,
      integrationType: IntegrationTypes.Xero,
      useLogoAndTooltip: true,
    },
  },
  [Column.XERO_VENDOR_NAME]: {
    colId: Column.XERO_VENDOR_NAME,
    field: Column.XERO_VENDOR,
    headerName: ColumnDisplayName[Column.XERO_VENDOR_NAME],
    width: 140,
    cellRenderer: IntegrationBusinessRenderer,
    valueGetter: getters.xeroBusinessGetter,
    cellRendererParams: {
      businessKeys: ["vendor"],
      complainIfMissing: true,
      integrationType: IntegrationTypes.Xero,
    },
  },

  [Column.XERO_BUYER]: {
    colId: Column.XERO_BUYER,
    field: Column.XERO_BUYER,
    headerName: ColumnDisplayName[Column.XERO_BUYER],
    width: 140,
    cellRenderer: IntegrationBusinessRenderer,
    valueGetter: getters.xeroBusinessGetter,
    cellRendererParams: {
      businessKeys: ["invoiceToBusiness", "buyer"],
      complainIfMissing: true,
      integrationType: IntegrationTypes.Xero,
      useLogoAndTooltip: true,
    },
  },

  [Column.XERO_BUYER_NAME]: {
    colId: Column.XERO_BUYER_NAME,
    field: Column.XERO_BUYER,
    headerName: ColumnDisplayName[Column.XERO_BUYER_NAME],
    width: 140,
    cellRenderer: IntegrationBusinessRenderer,
    valueGetter: getters.xeroBusinessGetter,
    cellRendererParams: {
      businessKeys: ["invoiceToBusiness", "buyer"],
      complainIfMissing: true,
      integrationType: IntegrationTypes.Xero,
    },
  },

  [Column.XERO_TARGET]: {
    colId: Column.XERO_TARGET,
    field: "xeroTarget",
    headerName: ColumnDisplayName[Column.XERO_TARGET],
    width: 150,
    cellRenderer: IntegrationBusinessRenderer,
    valueGetter: getters.xeroBusinessGetter,
    cellRendererParams: {
      businessKeys: ["targetBusiness"],
      complainIfMissing: true,
      integrationType: IntegrationTypes.Xero,
    },
  },

  ...ClearingSaleColumnDefinitions,

  [saleLotCommentsColumnId]: {
    colId: saleLotCommentsColumnId,
    field: "unresolvedSaleLotCommentCount",
    headerName: "Comments",
    width: 86,
    cellRenderer: saleLotCommentCountColumnRenderer,
    pinned: "right",
    menuTabs: [],
    filter: "agNumberColumnFilter",
  },

  [Column.OVERFLOW_PEN]: {
    colId: Column.OVERFLOW_PEN,
    field: Column.OVERFLOW_PEN,
    headerName: ColumnDisplayName[Column.OVERFLOW_PEN],
    width: 50,
  },
  [Column.INVOICE_TO_NAME]: {
    colId: Column.INVOICE_TO_NAME,
    headerName: "Invoice To",
    field: "invoiceToBusiness.name",
    cellRenderer: BusinessRenderer,
    cellRendererParams: {
      businessKey: "invoiceToBusinessId",
    },
  },
  [Column.INVOICE_TO_CODE]: {
    colId: Column.INVOICE_TO_CODE,
    headerName: "Invoice To (Code)",
    field: "invoiceToBusiness.shortCode",
    cellRenderer: BusinessRenderer,
    cellRendererParams: {
      businessKey: "invoiceToBusinessId",
      complainIfMissing: true,
    },
  },

  [Column.TARGET_BUSINESS_NAME]: {
    colId: Column.TARGET_BUSINESS_NAME,
    field: Column.TARGET_BUSINESS_NAME,
    headerName: ColumnDisplayName[Column.TARGET_BUSINESS_NAME],
    type: ColumnType.STRING,
    width: 200,
    sortable: true,
    sort: "asc",
    comparator: caseInsentitiveComparator,
    enablePivot: true,
    enableRowGroup: true,
  },

  [Column.SaleLot.ANIMAL_NAME]: {
    colId: Column.SaleLot.ANIMAL_NAME,
    headerName: "Animal Name",
    field: Column.SaleLot.ANIMAL_NAME,
    width: 200,
  },
  [Column.SaleLot.TAG_NUMBER]: {
    colId: Column.SaleLot.TAG_NUMBER,
    headerName: "Tag #",
    field: Column.SaleLot.TAG_NUMBER,
    width: 100,
    comparator: caseInsentitiveComparator,
  },
  [Column.SaleLot.ACCOUNT_NOTE]: {
    colId: Column.SaleLot.ACCOUNT_NOTE,
    headerName: "Account Note",
    field: "accountNote",
    width: 100,
  },
  [Column.SaleLot.UNIT_PRICE]: {
    colId: Column.SaleLot.UNIT_PRICE,
    filter: "agNumberColumnFilter",
    headerName: "Unit Price",
    type: "numericColumn",
    valueFormatter: formatters.unitPriceFormatter,
    valueGetter: getters.scanUnitPriceGetter,
  },

  [Column.SaleLot.PRICING_TYPE]: {
    colId: Column.SaleLot.PRICING_TYPE,
    field: "pricing_type_id",
    headerName: "Pricing Type",
    valueGetter: getters.pricingTypeGetter,
  },
  [Column.SaleLot.ESTIMATED_WEIGHT_RANGE]: {
    colId: Column.SaleLot.ESTIMATED_WEIGHT_RANGE,
    headerName: "Estimate Lwt Bracket",
    field: "weightRangeFormatted",
    type: [ColumnType.STRING, ColumnType.GROUPER],
    valueGetterParams: { blankText: "Bracket: Unknown" },
  },
  [Column.SaleLot.ESTIMATED_DRESSING_RANGE]: {
    colId: Column.SaleLot.ESTIMATED_DRESSING_RANGE,
    headerName: "Estimate Dressing % Bracket",
    field: "dressingRangeFormatted",
    type: [ColumnType.STRING, ColumnType.GROUPER],
    valueGetterParams: {
      preFixText: "Dressing:",
    },
  },

  [Column.Totals.LOW_DOLLARS_HEAD]: {
    field: "dollar_per_head",
    colId: Column.Totals.LOW_DOLLARS_HEAD,
    aggFunc: "min",
    headerName: "Low ($/hd)",
    type: ColumnType.TOTAL,
    valueFormatter: formatters.dollarTotalsFormatter,
    filterValueGetter: dollarTotalsFilterValueGetter,
  },
  [Column.Totals.AVG_DOLLARS_HEAD]: {
    field: "dollar_per_head",
    colId: Column.Totals.AVG_DOLLARS_HEAD,
    aggFunc: "avg",
    headerName: "Avg ($/hd)",
    type: ColumnType.TOTAL,
    valueFormatter: formatters.dollarTotalsFormatter,
    filterValueGetter: dollarTotalsFilterValueGetter,
  },
  [Column.Totals.HIGH_DOLLARS_HEAD]: {
    field: "dollar_per_head",
    colId: Column.Totals.HIGH_DOLLARS_HEAD,
    aggFunc: "max",
    headerName: "High ($/hd)",
    type: ColumnType.TOTAL,
    valueFormatter: formatters.dollarTotalsFormatter,
    filterValueGetter: dollarTotalsFilterValueGetter,
  },
  [Column.Totals.LOW_CENTS_KG]: {
    aggFunc: "min",
    field: "weightRange",
    headerName: "Low Wt. (¢/kg)",
    colId: Column.Totals.LOW_CENTS_KG,
    type: ColumnType.TOTAL,
    valueGetter: getters.centsPerKgTotalsGetter,
    valueGetterParams: MIN,
    valueFormatter: formatters.centsTotalsFormatter,
    filter: "agNumberColumnFilter",
  },
  [Column.Totals.AVG_CENTS_KG]: {
    aggFunc: "avg",
    field: "weightRange",
    headerName: "Avg Wt. (¢/kg)",
    colId: Column.Totals.AVG_CENTS_KG,
    type: ColumnType.TOTAL,
    valueGetter: getters.centsPerKgTotalsGetter,
    valueFormatter: formatters.centsTotalsFormatter,
    filter: "agNumberColumnFilter",
  },
  [Column.Totals.HIGH_CENTS_KG]: {
    aggFunc: "max",
    field: "weightRange",
    headerName: "High Wt. (¢/kg)",
    colId: Column.Totals.HIGH_CENTS_KG,
    type: ColumnType.TOTAL,
    valueGetter: getters.centsPerKgTotalsGetter,
    valueFormatter: formatters.centsTotalsFormatter,
    valueGetterParams: MAX,
    filter: "agNumberColumnFilter",
  },
  [Column.Totals.CENTS]: {
    field: "total_price_cents",
    aggFunc: "sum",
    headerName: "Total Dollars",
    type: ColumnType.TOTAL,
    valueGetterParams: "sum",
    valueFormatter: formatters.centsTotalsFormatter,
    valueFormatterParams: { returnDollars: true },
  },

  [Column.Totals.HEAD]: {
    colId: "eed5eba9-f85a-4cbb-8609-42ee509afd5f",
    field: "quantity",
    aggFunc: "sum",
    headerName: "Total Head",
    type: ColumnType.TOTAL,
    valueGetterParams: "sum",
  },
  [Column.SaleLot.PEN_SCAN_LOTS]: {
    colId: Column.SaleLot.PEN_SCAN_LOTS,
    field: "penScanLots",
    headerName: "Pen Scan Lots",
    cellRenderer: saleLotScanLotRenderer,
    width: 300,
  },
  [Column.SaleLot.WARNING]: {
    colId: Column.SaleLot.WARNING,
    field: "rowWarning",
    headerName: "Warning",
    type: ColumnType.STRING,
    width: 500,
  },
  [Column.SaleLot.VENDOR_SPLITS]: {
    colId: Column.SaleLot.VENDOR_SPLITS,
    headerName: "Splits",
    valueGetter: SaleLotVendorSplitGetter,
    width: 100,
  },

  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_EU]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_EU
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_GRASS_FED]:
    {
      ...ConsignmentColumnDefinitions[
        Column.DraftingAttributes.CattleDraftingAttributes
          .ACCREDITATION_GRASS_FED
      ],
      valueGetter: booleanDraftingAttributeGetter,
      type: ColumnType.BOOLEAN,
    },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PTIC]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PTIC
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PCAS]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PCAS
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes
    .ACCREDITATION_ANTIBIOTIC_FREE]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes
        .ACCREDITATION_ANTIBIOTIC_FREE
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_NEE]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_NEE
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_JBAS]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_JBAS
    ],
    type: ColumnType.NUMERIC,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PTE]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_PTE
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_ORGANIC]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_ORGANIC
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes
    .ACCREDITATION_STATION_MATED]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes
        .ACCREDITATION_STATION_MATED
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes
    .ACCREDITATION_NOT_STATION_MATED]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes
        .ACCREDITATION_NOT_STATION_MATED
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_FEEDER]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes.ACCREDITATION_FEEDER
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes
    .ACCREDITATION_BACKGROUNDED]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes
        .ACCREDITATION_BACKGROUNDED
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.CattleDraftingAttributes
    .ACCREDITATION_GRAIN_FED_DAYS]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.CattleDraftingAttributes
        .ACCREDITATION_GRAIN_FED_DAYS
    ],
  },

  [Column.DraftingAttributes.SheepDraftingAttributes
    .ANIMAL_HEALTH_STATEMENT_OVERRIDE]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.SheepDraftingAttributes
        .ANIMAL_HEALTH_STATEMENT_OVERRIDE
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.SIX_IN_ONE]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.SheepDraftingAttributes.SIX_IN_ONE
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.DRENCHED]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.SheepDraftingAttributes.DRENCHED
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.OJD_VAC]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.SheepDraftingAttributes.OJD_VAC
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.GUDAIR_APPROVED]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.SheepDraftingAttributes.GUDAIR_APPROVED
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.B12_VAC]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.SheepDraftingAttributes.B12_VAC
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.BACKLINE]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.SheepDraftingAttributes.BACKLINE
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.M_AND_TS]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.SheepDraftingAttributes.M_AND_TS
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.SA_ELIGIBLE]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.SheepDraftingAttributes.SA_ELIGIBLE
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.PAIN_RELIEF]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.SheepDraftingAttributes.PAIN_RELIEF
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
  [Column.DraftingAttributes.SheepDraftingAttributes.ACCREDITATION_ORGANIC]: {
    ...ConsignmentColumnDefinitions[
      Column.DraftingAttributes.SheepDraftingAttributes.ACCREDITATION_ORGANIC
    ],
    valueGetter: booleanDraftingAttributeGetter,
    type: ColumnType.BOOLEAN,
  },
};

export function livestockSaleColumnDefCreator(
  columnId,
  otherProps = { width: 150 },
) {
  return {
    field: columnId,
    colId: columnId,
    headerName: LivestockSaleColumnDisplayName[columnId],
    enableRowGroup: true,
    ...otherProps,
  };
}

export const speciesAccreditationChildColumns = (
  speciesId,
  ColumnDefinitions,
) => {
  if (speciesId === Species.CATTLE) {
    return Object.values(
      Column.DraftingAttributes.CattleDraftingAttributes,
    ).map(colId => ColumnDefinitions[colId]);
  } else if (speciesId === Species.SHEEP) {
    return Object.values(Column.DraftingAttributes.SheepDraftingAttributes).map(
      colId => ColumnDefinitions[colId],
    );
  }
  // returning an empty children list - hides the parent :okay_hand
  return [];
};
