import queryString from "query-string";

import { COMMENT, DEPLOYMENT_SALE_COMMENT } from "constants/actionTypes";

import { serializeComment } from "lib/serializers/comments";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const commentsUrlCreator = ({
  changesSince,
  livestockSaleId,
  commentType,
  id,
  action,
} = {}) => {
  const query = { changesSince };

  if (livestockSaleId) {
    query.livestock_sale_id = livestockSaleId;
  }

  if (commentType) {
    query.comment_type = commentType;
  }

  return queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction(`/v2/comments/`, id, action),
    query,
  });
};

export const CommentAction = offlineActionCreator(
  COMMENT,
  serializeComment,
  commentsUrlCreator,
  true,
);

// Deployment sale comments are used on the dashboard, where we are not within an individual sale,
// so we need to set livestockSaleSensitive to false for deployment sale comments. Instead of retrieving
// all comments specific to a sale, we are with a getting all comments with a type of DEPLOYMENT_SALE.
export const DeploymentSaleCommentAction = offlineActionCreator(
  DEPLOYMENT_SALE_COMMENT,
  serializeComment,
  commentsUrlCreator,
  false,
);
