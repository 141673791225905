import { buildSerializer, buildDeserializer } from "lib/serializerUtils";

/**
 * @typedef {Object} Payment
 * @property {string} billingDocumentId
 * @property {string} integrationId
 * @property {string} id
 * @property {string} fromBusinessId
 * @property {string} notes
 * @property {string} paymentDate ISO8601 date string without the time component
 * @property {PaymentMethod} paymentMethod
 * @property {string} paymentRef
 * @property {string} toBusinessId A MasterBusiness Id
 * @property {number} totalAmountCents An integer
 */

export const serializeMap = {
  billingDocumentId: "billing_document_id",
  integrationId: "integration_id",
  id: "id",
  fromBusinessId: "from_business_id",
  notes: "notes",
  paymentDate: "payment_date",
  paymentMethod: "payment_method",
  paymentRef: "payment_ref",
  toBusinessId: "to_business_id",
  totalAmountCents: "total_amount_cents",
};

export const serializePayment = buildSerializer(serializeMap, "payment");

/**
 * @type {function(Object, Object=): Payment}
 */
export const deserializePayment = buildDeserializer(serializeMap, "payment");
