import { useCallback, useState } from "react";
import type { Dispatch, SetStateAction } from "react";

type UseBooleanReturn = [
  /** The current boolean state value. */
  boolean,

  /** Function to set the boolean state to `true`. */
  () => void,
  /** Function to set the boolean state to `false`. */
  () => void,
  /** Function to set the boolean state directly. */
  Dispatch<SetStateAction<boolean>>,
  /** Function to toggle the boolean state. */
  () => void,
];

export function useBoolean(initialState = false): UseBooleanReturn {
  const [value, setValue] = useState(initialState);

  const setTrue = useCallback(() => setValue(true), [setValue]);
  const setFalse = useCallback(() => setValue(false), [setValue]);
  const toggle = useCallback(() => setValue(!value), [value]);

  return [value, setTrue, setFalse, setValue, toggle];
}
