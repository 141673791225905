import { BulkUpdateSaleLotModalProps } from "components/BulkUpdateOptionalFieldsModal/types";
import React from "react";
import { BulkUpdateOptionalFieldsModal } from "components/BulkUpdateOptionalFieldsModal/Modal";
import { bulkUpdateSaleLotsSerializer } from "actions";
import {
  baseLivestockFields,
  buyerFields,
  cattleAdvancedDraftingFields,
  cattleDraftingAttributes,
  commonAdvancedDraftingFields,
  EIDExemptionFields,
  sheepAdvancedDraftingFields,
  sheepDraftingAttributes,
  weightRangeAndDressingFields,
} from "components/BulkUpdateOptionalFieldsModal/SaleLots/Fields";
import { validationSchema } from "components/BulkUpdateOptionalFieldsModal/SaleLots/ValidationSchema";
import { Species } from "constants/species";
import { useSelector } from "react-redux";
import {
  getCurrentSale,
  selectAreWeightAndDressingFeaturesEnabled,
} from "selectors";
import { useHasSaleyardOrDeploymentPermission } from "hooks";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";

// "Configures" relevant fields for update - this can/will/should be extended to
// - include different fields for different sale (sub) types.
// - hoist (rootFieldOrder) some fields for some users.
// - permission hide/show relevent fields.
function useGetBulkUpdateFields() {
  // Drive what's shown based on the sale species.
  const sale = useSelector(getCurrentSale);
  const isSheepSale = sale.species_id === Species.SHEEP;
  const isWeightRangeAndDressingEnabled = useSelector(
    selectAreWeightAndDressingFeaturesEnabled,
  );

  const hasWeightRangePermission = useHasSaleyardOrDeploymentPermission(
    DeploymentPermissions.featureWeightRange,
    SaleyardPermissions.featureWeightRange,
  );

  if (isSheepSale) {
    const showWeightRangeAndDressing =
      isWeightRangeAndDressingEnabled && hasWeightRangePermission;

    return baseLivestockFields
      .concat(showWeightRangeAndDressing ? weightRangeAndDressingFields : [])
      .concat(EIDExemptionFields)
      .concat(sheepAdvancedDraftingFields)
      .concat(sheepDraftingAttributes)
      .concat(commonAdvancedDraftingFields)
      .concat(buyerFields);
  }
  // (probably) a cattle sale.
  return baseLivestockFields
    .concat(cattleAdvancedDraftingFields)
    .concat(cattleDraftingAttributes)
    .concat(commonAdvancedDraftingFields)
    .concat(buyerFields);
}

export function BulkUpdateSaleLotsModal(
  props: BulkUpdateSaleLotModalProps,
): React.JSX.Element {
  const { saleLotIds, onClose } = props;

  const bulkUpdateFields = useGetBulkUpdateFields();

  return (
    <BulkUpdateOptionalFieldsModal
      onClose={onClose}
      modelIds={saleLotIds}
      modelName="Sale Lot"
      bulkUpdateActionCreator={bulkUpdateSaleLotsSerializer}
      fields={bulkUpdateFields}
      initialValues={{}}
      validationSchema={validationSchema}
    />
  );
}
