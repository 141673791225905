import React from "react";

import Grid from "@material-ui/core/Grid";

import { CollapseHeader, FormCollapse } from "components/Form";
import { CheckBox, withNamespace } from "components/Form/FormikControls";

import { FinanceSubModalSection } from "constants/settings";

import { useSectionHelper } from "hooks";

export const GeneralSettings = ({ sectionHelper }) => {
  const { isOpen, onToggle } = useSectionHelper(
    sectionHelper,
    FinanceSubModalSection.GENERAL,
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<CollapseHeader title="General" />}
    >
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={12}>
          <CheckBox
            label="Auto accept cosmetic changes on Draft documents"
            name={withNamespace(
              "deploymentSettings",
              "autoAcceptCosmeticChangesInDraft",
            )}
            tooltip="If this is set, non-financial changes on documents in Draft status will be automatically accepted."
          />
        </Grid>
        <Grid item xs={12}>
          <CheckBox
            label="Auto accept financial changes on Draft documents"
            name={withNamespace(
              "deploymentSettings",
              "autoAcceptFinancialChangesInDraft",
            )}
            tooltip="If this is set, any changes on documents in Draft status will be automatically accepted."
          />
        </Grid>
      </Grid>
    </FormCollapse>
  );
};
