import React, { useCallback, useMemo } from "react";

import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { BillingDocumentAction } from "actions";

import AgGridTable from "components/AgGrid/AgGridContainer";
import { FlexWrapper } from "components/AgGrid/TableWrapper";

import { AgGridTables } from "constants/aggrid";
import {
  BillingDocumentColId,
  BillingDocumentColumnDef,
  BusinessColId,
  BusinessColumnDef,
  RetainedProceedsColId,
  RetainedProceedsColumnDef,
} from "constants/columnDefinitions";
import { SaleTypes } from "constants/sale";

import { makeSelectable } from "lib/agGrid";

import { getCurrentSale, selectBillingDocumentsAggridData } from "selectors";

import { useBillingDocumentAggridGetContextMenuItems } from "hooks/useBillingDocumentAggridGetContextMenuItems";

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: "agMultiColumnFilter",
  enableCellChangeFlash: true,
  floatingFilter: true,
  flex: true,
};

const billingTableColumnDefs = saleType => {
  const isReferenceSale = saleType === SaleTypes.REFERENCE_SALE;
  return [
    {
      headerName: "Documents",
      children: [
        makeSelectable(
          BillingDocumentColumnDef[BillingDocumentColId.DOCUMENT_TYPE],
        ),
        BillingDocumentColumnDef[BillingDocumentColId.DOCUMENT_NUMBER],
        BusinessColumnDef[BusinessColId.NAME],
        !isReferenceSale &&
          BusinessColumnDef[BusinessColId.XERO_INTEGRATION_BUSINESS],
        BillingDocumentColumnDef[BillingDocumentColId.STATUS],
        BillingDocumentColumnDef[BillingDocumentColId.DUE_DATE],
        !isReferenceSale &&
          BillingDocumentColumnDef[BillingDocumentColId.HAS_CHANGES],
        BillingDocumentColumnDef[BillingDocumentColId.BILLING_REFERENCE],
        BillingDocumentColumnDef[BillingDocumentColId.ACTIONS],
        !isReferenceSale &&
          BillingDocumentColumnDef[BillingDocumentColId.ACTIVITY],
      ].filter(Boolean),
    },
    !isReferenceSale && {
      headerName: "Retained Proceeds",
      children: [
        {
          ...RetainedProceedsColumnDef[
            RetainedProceedsColId.TOTAL_AMOUNT_CENTS
          ],
          hide: true,
        },
        {
          ...RetainedProceedsColumnDef[RetainedProceedsColId.NUMBER],
          hide: true,
        },
      ],
    },
  ].filter(Boolean);
};

// Highlight rows that need attention.
const rowClassRules = {
  "row-warning": params =>
    params.data?.billingDocument.hasFinancialChanges ||
    params.data?.billingDocument.hasCosmeticChanges ||
    params.data?.exceptions?.length > 0,
};

const rowSelectionId = "billingDocument.id";
const getRowId = params => get(params.data, rowSelectionId);

export const BillingDocumentsTable = ({
  onGridReady,
  setBillingDocumentId,
  setSelectedBillingDocuments,
}) => {
  const rowData = useSelector(selectBillingDocumentsAggridData);
  const dispatch = useDispatch();

  const saleType = useSelector(getCurrentSale)?.sale_type;

  const columnDefs = React.useMemo(
    () => billingTableColumnDefs(saleType),
    [saleType],
  );

  const setDocumentStatus = useCallback(
    (billingDocumentId, status) => {
      dispatch(
        BillingDocumentAction.update({
          id: billingDocumentId,
          status,
        }),
      );
    },
    [dispatch],
  );

  const onSelectionChanged = useCallback(
    selectedRows => {
      if (selectedRows.length === 1) {
        setBillingDocumentId(selectedRows[0].billingDocument.id);
      }
      setSelectedBillingDocuments(selectedRows.map(r => r.billingDocument));
    },
    [setBillingDocumentId, setSelectedBillingDocuments],
  );

  const getContextMenuItems = useBillingDocumentAggridGetContextMenuItems();

  const context = useMemo(() => ({ setDocumentStatus }), [setDocumentStatus]);

  return (
    <AgGridTable
      columnDefs={columnDefs}
      getRowId={getRowId}
      rowClassRules={rowClassRules}
      onGridReady={onGridReady}
      rowSelectionId={rowSelectionId}
      rowData={rowData}
      showGlobalSearchFilter={false}
      hideHeader
      WrapperComponent={FlexWrapper}
      rowSelection="multiple"
      onRowSelectionChanged={onSelectionChanged}
      defaultColDef={defaultColDef}
      getContextMenuItems={getContextMenuItems}
      context={context}
      groupSelectsChildren
      tableName={AgGridTables.BILLING_DOCUMENTS}
    />
  );
};
