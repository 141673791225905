import queryString from "query-string";

import { INTEREST_SETTING } from "constants/actionTypes";

import { serializeInterestSettings } from "lib/serializers/interest";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/interest-settings/", id, action),
    query: {
      changesSince,
    },
  });

export const InterestSettingsAction = offlineActionCreator(
  INTEREST_SETTING,
  serializeInterestSettings,
  urlCreator,
  false,
);
