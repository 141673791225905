import React from "react";

import { DatePicker as MuiDatePicker } from "@material-ui/pickers";
import { useField } from "formik";

import { Column } from "components/Layout";

import { formatISO8601DateString } from "lib/timeFormats";

import { Error } from "./Error";
import { Label } from "./Label";

export const DatePicker = ({
  name,
  label,
  disabled,
  required,
  tooltip,
  views,
  openTo,
  clearable = true,
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const error = meta.touched && meta.error;

  const changeValue = date => {
    if (!date) {
      setValue(null);
      return;
    }

    if (views.includes("day")) {
      setValue(formatISO8601DateString(date));
    } else if (views.includes("month")) {
      // Month and year
      date.setDate(1);
      setValue(formatISO8601DateString(date));
    } else {
      date.setDate(1);
      date.setMonth(1);
      // Just the year,
      setValue(formatISO8601DateString(date));
    }
  };
  const { onBlur, value = null } = field;

  return (
    <Column fullWidth>
      <Label
        htmlFor={name}
        required={required}
        error={!!error}
        tooltip={tooltip}
        data-tour={name}
      >
        {label}
      </Label>
      <MuiDatePicker
        views={views}
        openTo={openTo}
        data-tour={name}
        value={value}
        labelledby={name}
        onBlur={onBlur}
        onChange={changeValue}
        disabled={disabled}
        readOnly={disabled}
        required={required}
        name={name}
        clearable={clearable}
      />
      {error && <Error>{error}</Error>}
    </Column>
  );
};
