import reduceReducers from "reduce-reducers";

import { MLA_SUPPLEMENTARY_DATA } from "constants/actionTypes";

import {
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
} from "lib/reducers";
import { deserializeMLASupplementaryData } from "lib/serializers/mlaSupplementaryData";

const update = apiModelOfflineUpdateReducer(MLA_SUPPLEMENTARY_DATA, {
  deserializer: deserializeMLASupplementaryData,
});

const fetch = apiModelOfflineFetchReducer(MLA_SUPPLEMENTARY_DATA, {
  deserializer: deserializeMLASupplementaryData,
  clearOnRequest: true,
});

const mlaSupplementaryData = reduceReducers(update, fetch);

export default mlaSupplementaryData;
