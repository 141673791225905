import { getDollarPriceStringFromCents } from "lib";

export const billingDocumentActivityTitleAndColorMap = {
  created: {
    color: "billingDocumentActivityCreated",
    title: "Created",
    textColor: "black",
  },
  creditNotes: {
    color: "billingDocumentActivityPayment",
    title: "Credit Note",
    textColor: "white",
  },
  retainedProceeds: {
    color: "billingDocumentActivityModified",
    title: "Retained Proceeds",
    textColor: "white",
  },
  payments: {
    color: "billingDocumentActivityPayment",
    title: "Payment",
    textColor: "white",
  },
  interest: {
    color: "billingDocumentActivityInterest",
    title: "Interest",
    textColor: "white",
  },
  reversal: {
    color: "billingDocumentActivityReversal",
    title: "Reversal",
    textColor: "white",
  },
  modified: {
    color: "billingDocumentActivityModified",
    title: "Modified",
    textColor: "white",
  },
};

export const activityDetailsMap = activity => {
  return {
    created: `${activity.invoiceNumber} Created`,
    interest: activity.note,
    payments: `$${getDollarPriceStringFromCents(activity.totalAmountCents)} - ${activity.invoiceNumber} `,
    creditNotes: `$${getDollarPriceStringFromCents(activity.totalAmountCents)} - ${activity.invoiceNumber}`,
    retainedProceeds: `Retained Proceeds from ${activity.invoiceNumber}`,
    reversal: `Reversed by ${activity.invoiceNumber}`,
    modified: activity.historyChangeReason || "Document modified",
  };
};
