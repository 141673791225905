import { InsuranceApplicationTypes } from "constants/billing";

interface TransitInsuranceBusinessValues {
  hasTransitInsuranceForSales: boolean;
  hasTransitInsuranceForPurchases: boolean;
}

const isNilOrFalse = (value: Boolean): Boolean =>
  value == null || value === false;

export const mapDefaultDeploymentTransitInsuranceToBusinessValues = (
  deploymentDefault: string,
): TransitInsuranceBusinessValues => {
  switch (deploymentDefault) {
    case InsuranceApplicationTypes.N_A:
      return {
        hasTransitInsuranceForSales: false,
        hasTransitInsuranceForPurchases: false,
      };
    case InsuranceApplicationTypes.SELL_ONLY:
      return {
        hasTransitInsuranceForSales: true,
        hasTransitInsuranceForPurchases: false,
      };
    case InsuranceApplicationTypes.BUY_AND_SELL:
      return {
        hasTransitInsuranceForSales: true,
        hasTransitInsuranceForPurchases: true,
      };
    case InsuranceApplicationTypes.BUY_ONLY:
      return {
        hasTransitInsuranceForSales: false,
        hasTransitInsuranceForPurchases: true,
      };
    default:
      return {
        hasTransitInsuranceForSales: false,
        hasTransitInsuranceForPurchases: false,
      };
  }
};

export const mapBusinessValuesToInsuranceType = (
  business: Business,
): string | null => {
  switch (true) {
    case isNilOrFalse(business.hasTransitInsuranceForSales) &&
      isNilOrFalse(business.hasTransitInsuranceForPurchases):
      return InsuranceApplicationTypes.N_A;
    case business.hasTransitInsuranceForSales === true &&
      isNilOrFalse(business.hasTransitInsuranceForPurchases):
      return InsuranceApplicationTypes.SELL_ONLY;
    case business.hasTransitInsuranceForSales === true &&
      business.hasTransitInsuranceForPurchases === true:
      return InsuranceApplicationTypes.BUY_AND_SELL;
    case isNilOrFalse(business.hasTransitInsuranceForSales) &&
      business.hasTransitInsuranceForPurchases === true:
      return InsuranceApplicationTypes.BUY_ONLY;
    default:
      return null;
  }
};
