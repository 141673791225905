import { CommentAction } from "actions";
import { MLASupplementaryDataAction } from "actions/mlaSupplementaryData";

import {
  getActiveLivestockAgentDeployment,
  getIsFetchingComments,
  getIsFetchingDefaultVendorSplits,
  getIsFetchingDeployments,
  getIsFetchingMLASupplementaryData,
  getIsFetchingSundryTemplates,
  getIsFetchingTradingTerms,
  getIsLivestockAgent,
  getIsSaleyardAdmin,
  selectCurrentSaleyard,
} from "selectors";
import { hasPermission } from "lib/permissions";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { TradingTermAction } from "actions/tradingTerms";
import { SundryTemplateAction } from "actions/sundryTemplates";
import { InterestSettingsAction } from "actions/interest";
import { DefaultVendorSplitAction } from "actions/vendorSplits";

function commentActionFilter(
  state: State,
  changes: boolean = false,
): object | null {
  const enabled = getIsSaleyardAdmin(state) || getIsLivestockAgent(state);

  if (!enabled) {
    return null;
  }
  if (!changes) {
    return CommentAction.request();
  }
  if (changes && !getIsFetchingComments(state)) {
    return CommentAction.requestChanges({
      changesSince: state.comments.lastModifiedTimestamp,
    });
  }
  // we requested changes but are fetching already.
  return null;
}

function mlaSupplementaryDataActionFilter(
  state: State,
  changes: boolean = false,
): object | null {
  const enabled: boolean =
    getIsSaleyardAdmin(state) &&
    hasPermission(
      selectCurrentSaleyard(state),
      SaleyardPermissions.featureMLASupplementaryData,
    );

  if (enabled) {
    if (!changes) {
      return MLASupplementaryDataAction.request();
    }
    if (changes && !getIsFetchingMLASupplementaryData(state)) {
      return MLASupplementaryDataAction.requestChanges({
        changesSince: state.mlaSupplementaryData.lastModifiedTimestamp,
      });
    }
  }

  return null;
}

function tradingTermActionActionFilter(
  state: State,
  changes: boolean = false,
): object | null {
  const enabled = hasPermission(
    getActiveLivestockAgentDeployment(state),
    DeploymentPermissions.featureTradingTerms,
  );

  if (!enabled) {
    return null;
  }
  if (!changes) {
    return TradingTermAction.request();
  }
  if (changes && !getIsFetchingTradingTerms(state)) {
    return TradingTermAction.requestChanges({
      changesSince: state.tradingTerms.lastModifiedTimestamp,
    });
  }
  // we requested changes but are fetching already.
  return null;
}

function interestSettingsActionActionFilter(
  state: State,
  changes: boolean = false,
): object | null {
  const deployment = getActiveLivestockAgentDeployment(state);
  const hasInterestPermission = hasPermission(
    deployment,
    DeploymentPermissions.featureInterest,
  );
  if (!hasInterestPermission) {
    return null;
  }
  if (!changes) {
    return InterestSettingsAction.request();
  }
  if (changes && !getIsFetchingDeployments(state)) {
    return InterestSettingsAction.requestChanges({
      changesSince: state.interestSettings.lastModifiedTimestamp,
    });
  }
  // we requested changes but are fetching already.
  return null;
}

function defaultVendorSplitActionActionFilter(
  state: State,
  changes: boolean = false,
): object | null {
  const deployment = getActiveLivestockAgentDeployment(state);
  const hasPercentageVendorSplitPermission = hasPermission(
    deployment,
    DeploymentPermissions.featurePercentageVendorSplits,
  );
  if (!hasPercentageVendorSplitPermission) {
    return null;
  }
  if (!changes) {
    return DefaultVendorSplitAction.request();
  }
  if (changes && !getIsFetchingDefaultVendorSplits(state)) {
    return DefaultVendorSplitAction.requestChanges({
      changesSince: state.vendorSplits.default.lastModifiedTimestamp,
    });
  }
  // we requested changes but are fetching already.
  return null;
}

function sundryTemplateActionActionFilter(
  state: State,
  changes: boolean = false,
): object | null {
  const enabled = hasPermission(
    getActiveLivestockAgentDeployment(state),
    DeploymentPermissions.featureSundryTemplates,
  );

  if (!enabled) {
    return null;
  }
  if (!changes) {
    return SundryTemplateAction.request();
  }
  if (changes && !getIsFetchingSundryTemplates(state)) {
    return SundryTemplateAction.requestChanges({
      changesSince: state.sundryTemplates.lastModifiedTimestamp,
    });
  }
  // we requested changes but are fetching already.
  return null;
}

export const inSalePermissionCheckActionTypes = [
  CommentAction,
  TradingTermAction,
  SundryTemplateAction,
  MLASupplementaryDataAction,
];

export const deploymentPermissionCheckActionTypes = [
  InterestSettingsAction,
  DefaultVendorSplitAction,
  TradingTermAction,
  SundryTemplateAction,
];

export const actionFilters = {
  [CommentAction.dataType]: commentActionFilter,
  [DefaultVendorSplitAction.dataType]: defaultVendorSplitActionActionFilter,
  [InterestSettingsAction.dataType]: interestSettingsActionActionFilter,
  [MLASupplementaryDataAction.dataType]: mlaSupplementaryDataActionFilter,
  [SundryTemplateAction.dataType]: sundryTemplateActionActionFilter,
  [TradingTermAction.dataType]: tradingTermActionActionFilter,
};
