import React from "react";

import { faWrench } from "@fortawesome/pro-light-svg-icons";
import {
  faCircle,
  faCommentsDollar,
  faExclamationCircle,
  faEye,
  faPencil,
  faUpDown,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { get } from "lodash";
import maxBy from "lodash/maxBy";
import sumBy from "lodash/sumBy";
import { useSelector } from "react-redux";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";
import { ColumnType } from "components/AgGrid/constants";
import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { MultiButton, SlimButton } from "components/Button";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Row } from "components/Layout";

import { ACTION_COLUMN_NAME } from "constants/aggrid";
import { BillingScreens, GstMethod } from "constants/billing";
import {
  BillingDocumentIntegrationStatus,
  BillingDocumentIntegrationStatusColorMap,
  BillingDocumentReportJobStatus,
  BillingDocumentStatusColorMap,
  BillingDocumentType,
  BillingDocumentTypeColorMap,
} from "constants/billingDocuments";
import { IssuerColId, RecipientColId } from "constants/columns";
import { SystemLabelName } from "constants/deploymentLabels";
import { BusinessModalSection, ModalTypes } from "constants/navigation";
import { DeploymentPermissions } from "constants/permissions";
import { SaleTypes } from "constants/sale";
import { colors, space } from "constants/theme";

import formatters, { floatFormatter } from "lib/agGrid/formatters";
import getters from "lib/agGrid/getters";
import { emailCountRenderer } from "lib/agGrid/renderers";
import { hasChangesRenderer } from "lib/agGrid/renderers/changesRenderer";
import { StatusRenderer } from "lib/agGrid/renderers/statusRenderer";
import {
  getReportJobUrl,
  goToBillingStep,
  openEditBusinessModal,
  openModalLink,
} from "lib/navigation";
import { dateTimeStringToDateTime, getDaysDifferent } from "lib/timeFormats";
import toast from "lib/toast";

import {
  getActiveLivestockAgentDeployment,
  getCurrentSale,
  getCurrentSpeciesId,
  getLabels,
  getRoundingAdjustmentManualChargeIdsByBillingDocumentId,
} from "selectors";

import { useHasPermission } from "hooks";

export const RetainedProceedsColId = {
  GL_CODE: "51ef761a-dc91-49fe-ac0d-64ba7a9d6ca4",
  INTEGRATION_STATUS: "f5380ebf-34c0-48e8-8f3a-ce3e8f347f97",
  NUMBER: "b8e8505c-8386-495e-abfd-30de5676202a",
  TOTAL_AMOUNT_CENTS: "c268b47f-4973-4232-87f3-dfa0da8cd328",
};

function RetainedProceedsDocumentNumberRenderer(props) {
  const { node, value } = props;
  const deployment = useSelector(getActiveLivestockAgentDeployment) || {};
  if (!value || node.group) {
    return null;
  }

  return `${
    deployment.deploymentSettings.retainedProceedsPrefix || "RP"
  }${value}`;
}

export const RetainedProceedsColumnDef = {
  [RetainedProceedsColId.GL_CODE]: {
    colId: RetainedProceedsColId.GL_CODE,
    field: "billingDocument.proceedsRetainedByBillingDocument.glCode",
    headerName: "GL Code",
  },
  [RetainedProceedsColId.INTEGRATION_STATUS]: {
    colId: RetainedProceedsColId.INTEGRATION_STATUS,
    field:
      "billingDocument.proceedsRetainedByBillingDocument.integrationStatus",
    headerName: "Integration Status",
    cellRenderer: StatusRenderer,
    statusRendererProps: {
      colorMap: BillingDocumentIntegrationStatusColorMap,
    },
  },
  [RetainedProceedsColId.NUMBER]: {
    colId: RetainedProceedsColId.NUMBER,
    field: "billingDocument.proceedsRetainedByBillingDocument.number",
    headerName: "Document Number",
    cellRenderer: RetainedProceedsDocumentNumberRenderer,
  },
  [RetainedProceedsColId.TOTAL_AMOUNT_CENTS]: {
    colId: RetainedProceedsColId.TOTAL_AMOUNT_CENTS,
    field: "billingDocument.proceedsRetainedByBillingDocument.totalAmountCents",
    headerName: "Total Amount",
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
};

export const BillingDocumentColId = {
  ACTIONS: "f48332b5-0c17-4b02-8cdb-cdaebda4de2f",
  ACTION_FIX: "95c5f3ef-622a-4a32-8917-8c2bec82c2d5",
  ACTIVITY: "63b37729-0813-467e-977c-0d4222fe26be",
  BILLING_DOCUMENT: "d5d817c5-64aa-4ff9-9510-2554faa91625",
  BILLING_REFERENCE: "33607cf4-ef5e-4b34-876e-84ac45f14096",
  DOCUMENT_NUMBER: "02379ad0-3ee4-403e-b2bd-728373915909",
  DOCUMENT_TYPE: "22fe2f48-9804-42ea-815b-44489ffb2754",
  DUE_DATE: "b96f531a-7149-47d7-b412-29908d19adf3",
  HAS_CHANGES: "f7968824-9dd8-4a83-a2c2-f570f1f8ae1f",
  STATUS: "9f40bdef-cee7-4842-bda0-bf4632c12180",
  TARGET_BUSINESS: "dce81bb2-0fa8-4b1a-9c57-9ecdfaf11ee3",
  WARNINGS: "fcf46951-272e-464d-a87a-5547f76a6ec4",
  XERO_INVOICE_ID: "a6387c98-4404-4ec7-a0b6-41c7ba8399c4",
  EMAIL_COUNT: "4d12ed46-ab95-42f3-a7bb-0ef5e352f5d4",
  LIVESTOCK_SALES: "1bf5de22-9df4-42c7-a8e7-efacae66d39f",
  OPEN_IN_NEW_WINDOW: "3d584e61-ce27-4b4e-b6ad-c4fc69562058",
  TOTAL_AMOUNT_DOLLARS: "b383588c-a877-41fa-9b1a-b826f2d26504",
  TOTAL_TAX_AMOUNT_DOLLARS: "fbdc5d0f-3d23-4539-a0ef-1075833f557a",
  DOCUMENT_DOWNLOAD: "efc0b4e6-23b1-43b3-a69d-cd4481784da6",
  TO: "d488b4aa-ed0e-4717-bbb3-3c634ae4546a",
  INTEGRATION_STATUS: "f435180e-250f-4a9e-a5f8-138f6ebe87dd",
  PAID: "60f5f9fc-10d4-4846-9b84-f464d1f576ed",
  ISSUED: "a61e7e5a-6493-4ce0-93a4-7be9aaacea81",
  PAID_DATE: "4f53300d-bbf6-4846-9138-10f1f43595b2",
  DAYS_LATE: "c8a3bd08-596c-4c37-80b4-149306590e1e",
  DAYS_SINCE_PAYMENT: "d409b727-beb7-4594-b426-b583d7ae84c6",
  DAYS_SINCE_DUE: "27dc73e3-91ac-4ea3-9536-790386578caf",
  ACTION_TAKEN: "b31e8ab4-68bc-4557-a7f4-460c2783de94",
  CREATED: "549ac4c7-dc1c-47dd-8f3d-a0172ce374f7",
  LAST_MODIFIED: "b4274b19-7765-4340-8ba0-2618bc0453f6",
  LAST_PAID_INTEREST_DATE: "68a566cc-fe8d-4ce5-b522-5c8d7e81b500",
};

const BillingDocumentActionRenderer = params => {
  const { data, api, node } = params;

  const { billingDocument } = data || {};

  const hasRetainProceedsPermission = useHasPermission(
    getActiveLivestockAgentDeployment,
    DeploymentPermissions.featureRetainedProceeds,
  );

  const currentSpeciesId = useSelector(getCurrentSpeciesId);
  const labels = useSelector(getLabels);
  // If the user has these labels (by virtue of having ingested a rule book that uses them), they may be interested in using these controls.
  const RCTIRoundingAdjustmentLabel = Object.values(labels).find(
    label =>
      label.name === SystemLabelName.RCTI_ROUNDING_ADJUSTMENT &&
      label.species_id === currentSpeciesId,
  );
  const invoiceRoundingAdjustmentLabel = Object.values(labels).find(
    label =>
      label.name === SystemLabelName.INVOICE_ROUNDING_ADJUSTMENT &&
      label.species_id === currentSpeciesId,
  );

  const saleType = useSelector(getCurrentSale)?.sale_type;

  const isReferenceSale = saleType === SaleTypes.REFERENCE_SALE;

  const roundingManualAdjustmentIds = useSelector(
    getRoundingAdjustmentManualChargeIdsByBillingDocumentId(billingDocument.id),
  );

  if (!params.data) {
    return null;
  }

  const allowActions = !billingDocument.isFetching;

  const hasChanges =
    // Check the has changes column is in the current table
    api.getColumnDef(BillingDocumentColId.HAS_CHANGES)
      ? // When the has changes column in is the table, check if there are no changes
        api.getValue(BillingDocumentColId.HAS_CHANGES, node) !== "false-false"
      : // Otherwise don't allow the user to review the changes
        false;

  // When review is enabled, it should be the default action.
  // All other times - "View" should be the default action.
  const isReviewEnabled = !isReferenceSale && allowActions && hasChanges;

  const documentType = api.getValue(BillingDocumentColId.DOCUMENT_TYPE, node);

  function onClickEdit() {
    openModalLink(ModalTypes.EditBillingDocument, {
      id: billingDocument.id,
    });
  }

  function onClickReview() {
    openModalLink(ModalTypes.BillingDocumentReview, {
      id: billingDocument.id,
    });
  }

  function onClickRetainProceeds() {
    openModalLink(ModalTypes.RetainProceeds, {
      billingDocumentId: billingDocument.id,
    });
  }

  function onClickManageRoundingAdjustment() {
    // If we have an existing rounding adjustment, show it.
    const modalProps = {
      readOnlyFields: ["fromBusinessId", "toBusinessId", "labels"],
      gstMethod: undefined,
      fromBusinessId: undefined,
      toBusinessId: undefined,
      labels: undefined,
    };

    if (!roundingManualAdjustmentIds) {
      // If there's none existing, go prefill some values.
      modalProps.gstMethod = GstMethod.GST_FIXED;

      if (billingDocument.type === BillingDocumentType.INVOICE) {
        // Create a manual adjustment with
        // - the INVOICE_ROUNDING_ADJUSTMENT label
        // - from the recipient of the funds (the agency)
        // - to the issuer of the funds (the buyer)
        modalProps.fromBusinessId = billingDocument.recipientBusinessId;
        modalProps.toBusinessId = billingDocument.issuerBusinessId;
        modalProps.labels = [invoiceRoundingAdjustmentLabel.id];
      } else if (billingDocument.type === BillingDocumentType.RCTI) {
        // Create a manual adjustment with
        // - the RCTI_ROUNDING_ADJUSTMENT label
        // - from the recipient of the funds (the vendor)
        // - to the issuer of the funds (the agency)
        modalProps.fromBusinessId = billingDocument.recipientBusinessId;
        modalProps.toBusinessId = billingDocument.issuerBusinessId;
        modalProps.labels = [RCTIRoundingAdjustmentLabel.id];
      }
    } else if (roundingManualAdjustmentIds.length >= 1) {
      // If we have an id - it's an edit rather than a create.
      modalProps.id = roundingManualAdjustmentIds[0];
      if (roundingManualAdjustmentIds.length > 1) {
        // If there's multiple entries here, they've likely been manually creating them - let the user know
        // things are a bit off.
        toast.error(
          "Multiple Rounding Adjustment Sundries exist for that document.  Please remove any extra, irrelevant entries from the Sundries tab.",
        );
      }
    }
    openModalLink(ModalTypes.ManualAdjustmentForm, modalProps);
  }

  const buttons = [
    {
      dataTour: "viewBillingDocument",
      default: !isReviewEnabled,
      icon: faEye,
      isDisabled: false,
      onClick: undefined, // The button is a no-op but the event highlights the row
      title: "View",
      tooltip: "View the Billing Document",
    },
    !isReferenceSale && {
      dataTour: "editBillingDocument",
      default: false,
      icon: faPencil,
      isDisabled: false,
      onClick: onClickEdit,
      title: "Edit",
      tooltip: "Edit the Billing Document",
    },
    !isReferenceSale && {
      dataTour: "reviewBillingDocumentChanges",
      default: isReviewEnabled,
      icon: faExclamationCircle,
      isDisabled: !isReviewEnabled,
      onClick: onClickReview,
      title: "Review",
      tooltip: hasChanges
        ? "Review changes to the Billing Document"
        : "There are no changes to review",
    },
    !isReferenceSale &&
      documentType === BillingDocumentType.RCTI &&
      hasRetainProceedsPermission && {
        dataTour: "retainBillingDocumentProceeds",
        default: false,
        icon: faCommentsDollar,
        isDisabled: !allowActions,
        onClick: onClickRetainProceeds,
        title: "Retain Proceeds",
        tooltip: "Retain Proceeds for past due invoices",
      },

    ((!isReferenceSale &&
      documentType === BillingDocumentType.RCTI &&
      RCTIRoundingAdjustmentLabel) ||
      (documentType === BillingDocumentType.INVOICE &&
        invoiceRoundingAdjustmentLabel)) && {
      dataTour: "manageRoundingAdjustment",
      default: false,
      icon: faUpDown,
      isDisabled: !allowActions,
      onClick: onClickManageRoundingAdjustment,
      title: "Rounding Adjustment",
      tooltip: "Manage a rounding adjustment for this document",
    },
  ].filter(Boolean);

  return <MultiButton buttons={buttons} ButtonComponent={SlimButton} />;
};

const BillingDocumentActivity = {
  created: "Created",
  modified: "Modified",
  reversal: "Reversal",
  payment: "Payment",
  interest: "Interest Charged",
};

const openBillingDocumentActivityModal = billingDocumentId =>
  openModalLink(ModalTypes.BillingDocumentActivity, {
    billingDocumentId,
  });

const BillingDocumentActivityValueGetter = ({ data }) => {
  if (!data) {
    return null;
  }
  const { billingDocument, payments } = data;

  const { created, lastModified, hasBeenReversed, latestInterestChargeDate } =
    billingDocument;

  return [
    lastModified && lastModified > created
      ? BillingDocumentActivity.modified
      : null,
    hasBeenReversed ? BillingDocumentActivity.reversal : null,
    payments.length ? BillingDocumentActivity.payment : null,
    latestInterestChargeDate ? BillingDocumentActivity.interest : null,
  ];
};

const ConditionalActivityTooltip = ({ check, children }) => {
  if (check.shown) {
    return (
      <Tooltip
        title={check.info}
        iconPadding={space[0]}
        PopperProps={{ style: { marginTop: -18 } }}
      >
        {children}
      </Tooltip>
    );
  } else {
    return <div>{children}</div>;
  }
};

const BillingDocumentActivityRenderer = ({ data, value }) => {
  if (!data && !value) {
    return null;
  }

  const { billingDocument } = data;

  // If the value is a string, we are row grouping. This passes in a value like ",modified,reversal,,"
  // We need to split this string into and array to assign values and use the renderer
  if (typeof value === "string") {
    value = value.split(",");
  }

  const [modified, reversal, payment, interest] = value;

  const checks = [
    {
      // document exists so it has been created :)
      shown: true,
      color: colors.billingDocumentActivityCreated,
      info: BillingDocumentActivity.created,
    },
    {
      shown: !!modified,
      color: colors.billingDocumentActivityModified,
      info: BillingDocumentActivity.modified,
    },
    {
      shown: !!reversal,
      color: colors.billingDocumentActivityReversal,
      info: BillingDocumentActivity.reversal,
    },
    {
      shown: !!payment,
      color: colors.billingDocumentActivityPayment,
      info: BillingDocumentActivity.payment,
    },
    {
      shown: !!interest,
      color: colors.billingDocumentActivityInterest,
      info: BillingDocumentActivity.interest,
    },
  ];

  return (
    <Row
      fullWidth
      className="justify-start cursor-pointer"
      onClick={() => openBillingDocumentActivityModal(billingDocument.id)}
    >
      {checks.map(check => (
        <>
          <ConditionalActivityTooltip check={check}>
            <FaIcon
              className={`m-2 text-12 ${check.shown ? "visible" : "invisible"}`}
              icon={faCircle}
              color={check.color}
            />
          </ConditionalActivityTooltip>
        </>
      ))}
    </Row>
  );
};

export const BillingDocumentColumnDef = {
  [BillingDocumentColId.DOCUMENT_NUMBER]: {
    colId: BillingDocumentColId.DOCUMENT_NUMBER,
    field: "billingDocument.number",
    headerName: "#",
    enableRowGroup: true,
    enablePivot: true,
    width: 50,
    filterParams: {
      keyCreator: ({ api, node, value }) => {
        return `${api.getValue(
          BillingDocumentColId.DOCUMENT_TYPE,
          node,
        )}:${value} | ${api.getValue(
          IssuerColId.ISSUER_NAME,
          node,
        )} -> ${api.getValue(RecipientColId.RECIPIENT_NAME, node)}`;
      },
      valueFormatter: params => params.value.name,
    },
  },
  [BillingDocumentColId.EMAIL_COUNT]: {
    colId: BillingDocumentColId.EMAIL_COUNT,
    field: "billingDocument.emailIds",
    headerName: "Sent Emails",
    valueGetter: getters.arrayLengthGetter,
    valueFormatter: emailCountRenderer,
    cellRenderer: "agGroupCellRenderer",
  },
  [BillingDocumentColId.LIVESTOCK_SALES]: {
    colId: BillingDocumentColId.LIVESTOCK_SALES,
    field: "billingDocument.livestockSaleIds",
    headerName: "Sale Id(s)",
    enableRowGroup: true,
  },

  [BillingDocumentColId.XERO_INVOICE_ID]: {
    colId: BillingDocumentColId.XERO_INVOICE_ID,
    field: "billingDocument.xeroInvoiceId",
    headerName: "Xero Invoice",
    enableRowGroup: true,
    // TODO: Deep linking to xero.
    valueGetter: ({ data }) => {
      if (!data?.billingDocument) {
        return null;
      }
      return data.billingDocument.xeroInvoiceId ? "Yes" : "";
    },
  },

  [BillingDocumentColId.DOCUMENT_TYPE]: {
    colId: BillingDocumentColId.DOCUMENT_TYPE,
    field: "billingDocument.type",
    headerName: "Type",
    cellRenderer: StatusRenderer,
    statusRendererProps: {
      colorMap: BillingDocumentTypeColorMap,
    },
    width: 90,
  },

  [BillingDocumentColId.STATUS]: {
    colId: BillingDocumentColId.STATUS,
    field: "billingDocument.status",
    headerName: "Status",
    enableRowGroup: true,
    cellRenderer: StatusRenderer,
    statusRendererProps: {
      colorMap: BillingDocumentStatusColorMap,
    },
  },

  [BillingDocumentColId.INTEGRATION_STATUS]: {
    headerName: "Integration Status",
    colId: BillingDocumentColId.INTEGRATION_STATUS,
    field: "billingDocument.integrationStatus",
    enableRowGroup: true,
    cellRenderer: StatusRenderer,
    statusRendererProps: {
      colorMap: BillingDocumentIntegrationStatusColorMap,
    },
  },
  [BillingDocumentColId.WARNINGS]: {
    colId: BillingDocumentColId.WARNINGS,
    field: "billingDocument.warnings",
    headerName: "Warnings",
  },
  [BillingDocumentColId.DUE_DATE]: {
    colId: BillingDocumentColId.DUE_DATE,
    field: "billingDocument.dueDate",
    headerName: "Due Date",
    width: 90,
    type: ColumnType.DATE,
  },
  [BillingDocumentColId.HAS_CHANGES]: {
    colId: BillingDocumentColId.HAS_CHANGES,
    field: "billingDocument.hasFinancialChanges",
    valueGetter: ({ data }) => {
      if (!data?.billingDocument) {
        return null;
      }
      return [
        data.billingDocument.hasFinancialChanges,
        data.billingDocument.hasCosmeticChanges,
      ].join("-");
    },
    headerName: "Has Changes",
    enableRowGroup: true,
    cellRenderer: hasChangesRenderer,
  },
  [BillingDocumentColId.ACTIONS]: {
    colId: BillingDocumentColId.ACTIONS,
    field: "billingDocument.id",
    headerName: ACTION_COLUMN_NAME,
    valueGetter: ({ data }) => {
      if (!data?.billingDocument) {
        return null;
      }
      return [
        data.billingDocument.id,
        data.billingDocument.status,
        data.billingDocument.hasFinancialChanges,
        data.billingDocument.hasCosmeticChanges,
      ].join("-");
    },
    cellRenderer: BillingDocumentActionRenderer,
    pinned: "right",
    width: 150,
  },
  [BillingDocumentColId.OPEN_IN_NEW_WINDOW]: {
    colId: BillingDocumentColId.OPEN_IN_NEW_WINDOW,
    field: "billingDocument.id",
    headerName: "Document Number",
    enableRowGroup: true,
    valueGetter: ({ data }) => {
      if (!data?.billingDocument) {
        return null;
      }
      const { invoiceNumber } = data.billingDocument || {};
      return invoiceNumber;
    },
    cellRenderer: ({ data, context, value }) => {
      if (!data?.billingDocument) {
        return null;
      }

      const id = data.billingDocument.reportJobs?.find(
        rj => rj.documentStatus === BillingDocumentReportJobStatus.COMMITTED,
      )?.id;

      if (id) {
        const documentPreviewUrl = getReportJobUrl(id, context.userRoleSlug);

        return (
          <ActionButtonContainer>
            <ActionButton
              onClick={() => {
                window.open(documentPreviewUrl);
              }}
            >
              {value}
            </ActionButton>
          </ActionButtonContainer>
        );
      }
      return value;
    },
  },
  [BillingDocumentColId.TOTAL_AMOUNT_DOLLARS]: {
    colId: BillingDocumentColId.TOTAL_AMOUNT_DOLLARS,
    field: "billingDocument.totalAmountDollars",
    headerName: "Amount ($)",
    type: ColumnType.CURRENCY,
    valueFormatter: formatters.dollarsCreditDebitFormatter,
  },

  [BillingDocumentColId.TOTAL_TAX_AMOUNT_DOLLARS]: {
    colId: BillingDocumentColId.TOTAL_TAX_AMOUNT_DOLLARS,
    field: "billingDocument.totalTaxAmountDollars",
    headerName: "Tax ($)",
    type: ColumnType.RIGHT_ALIGNED,
    valueFormatter: floatFormatter,
    valueFormatterParams: {
      decimalPlaces: 2,
      formatToMinTwoDecimalPlaces: true,
    },
  },
  [BillingDocumentColId.DOCUMENT_DOWNLOAD]: {
    colId: BillingDocumentColId.DOCUMENT_DOWNLOAD,
    field: "billingDocument.reportJobs",
    headerName: "Transaction document",
    valueGetter: () => "", // For the export to CSV
    cellRenderer: ({ data, context }) => {
      if (!data?.billingDocument) {
        return null;
      }

      const id = data.billingDocument.reportJobs?.find(
        rj => rj.documentStatus === BillingDocumentReportJobStatus.COMMITTED,
      )?.id;

      const documentPreviewUrl = getReportJobUrl(id, context.userRoleSlug);
      return (
        <ActionButtonContainer>
          <ActionButton
            onClick={() => {
              window.open(documentPreviewUrl);
            }}
          >
            Download
          </ActionButton>
        </ActionButtonContainer>
      );
    },
  },
  [BillingDocumentColId.PAID]: {
    headerName: "Paid ($)",
    colId: BillingDocumentColId.PAID,
    field: "payments",
    type: ColumnType.CURRENCY_FROM_CENTS,
    valueFormatter: formatters.dollarsCreditDebitFormatter,
    valueFormatterParams: {
      decimalPlaces: 2,
    },
    valueGetter: ({ data }) => {
      if (!data) {
        return null;
      }
      const { payments } = data;
      return sumBy(payments, "totalAmountCents") * 0.01;
    },
  },
  [BillingDocumentColId.ISSUED]: {
    headerName: "Exported",
    colId: BillingDocumentColId.ISSUED,
    field: "billingDocument.issuedDate",
    type: ColumnType.DATE,
  },
  [BillingDocumentColId.PAID_DATE]: {
    headerName: "Payment Date",
    colId: BillingDocumentColId.PAID_DATE,
    field: "payments",
    valueGetter: ({ data }) => {
      if (!data) {
        return null;
      }
      const payments = get(data, "payments") || [];
      if (payments.length > 0) {
        return new Date(maxBy(payments, "paymentDate").paymentDate);
      }
      return null;
    },
    type: ColumnType.DATE,
  },
  [BillingDocumentColId.DAYS_LATE]: {
    headerName: "Days Late",
    colId: BillingDocumentColId.DAYS_LATE,
    valueGetter: ({ api, data, node }) => {
      if (!data) {
        return null;
      }
      const { billingDocument } = data;
      // If the invoice was paid, how far before/after the due date was it?
      // NB - the paid date is the last date of payment - if this was PARTIAL payment,
      // the value may be somewhat misleading.

      const paidDate = api.getValue(BillingDocumentColId.PAID_DATE, node);

      if (
        paidDate &&
        billingDocument.dueDate &&
        billingDocument.integrationStatus ===
          BillingDocumentIntegrationStatus.PAID
      ) {
        return getDaysDifferent(
          dateTimeStringToDateTime(paidDate),
          dateTimeStringToDateTime(billingDocument.dueDate),
        );
      }
      // Otherwise, if we have a due date, but it's not paid, "today" is how overdue the invoice is
      if (billingDocument.dueDate) {
        return getDaysDifferent(
          new Date(),
          dateTimeStringToDateTime(billingDocument.dueDate),
        );
      }
      return null;
    },
    type: ColumnType.NUMERIC,
  },

  [BillingDocumentColId.DAYS_SINCE_PAYMENT]: {
    headerName: "Days Since Payment",
    colId: BillingDocumentColId.DAYS_SINCE_PAYMENT,
    valueGetter: ({ api, data, node }) => {
      if (!data?.billingDocument) {
        return null;
      }
      const { billingDocument } = data;
      // How many days since we paid?
      const paidDate = api.getValue(BillingDocumentColId.PAID_DATE, node);

      if (
        paidDate &&
        billingDocument.integrationStatus ===
          BillingDocumentIntegrationStatus.PAID
      ) {
        return getDaysDifferent(new Date(), dateTimeStringToDateTime(paidDate));
      }
      return null;
    },
    type: ColumnType.NUMERIC,
  },

  [BillingDocumentColId.DAYS_SINCE_DUE]: {
    headerName: "Days Since Due",
    colId: BillingDocumentColId.DAYS_SINCE_DUE,
    valueGetter: ({ data }) => {
      if (!data?.billingDocument) {
        return null;
      }
      const { billingDocument } = data;
      // How many days since we this was due?
      if (billingDocument.dueDate) {
        return getDaysDifferent(
          new Date(),
          dateTimeStringToDateTime(billingDocument.dueDate),
        );
      }
      return null;
    },
    type: ColumnType.NUMERIC,
  },

  [BillingDocumentColId.CREATED]: {
    colId: BillingDocumentColId.CREATED,
    field: "billingDocument.created",
    headerName: "Created",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
  },

  [BillingDocumentColId.LAST_MODIFIED]: {
    colId: BillingDocumentColId.LAST_MODIFIED,
    field: "billingDocument.lastModified",
    headerName: "Last Modified",
    type: ColumnType.UTC_LOCAL_DATE_TIME,
  },
  [BillingDocumentColId.LAST_PAID_INTEREST_DATE]: {
    headerName: "Last Paid Interest",
    colId: BillingDocumentColId.LAST_PAID_INTEREST_DATE,
    field: "billingDocument.latestInterestChargeDate",
    type: ColumnType.DATE,
  },
  [BillingDocumentColId.ACTIVITY]: {
    headerName: "Activity",
    colId: BillingDocumentColId.ACTIVITY,
    field: "billingDocument.activity",
    cellRenderer: BillingDocumentActivityRenderer,
    valueGetter: BillingDocumentActivityValueGetter,
    filter: "agSetColumnFilter",
    filterParams: {
      values: [
        BillingDocumentActivity.modified,
        BillingDocumentActivity.reversal,
        BillingDocumentActivity.payment,
        BillingDocumentActivity.interest,
      ],
    },
    enablePivot: true,
    enableRowGroup: true,
    width: 110,
  },
  [BillingDocumentColId.BILLING_REFERENCE]: {
    headerName: "Billing reference",
    colId: BillingDocumentColId.BILLING_REFERENCE,
    field: "billingDocument.billingReference",
    type: ColumnType.STRING,
  },
};

export const FixWarningsActionColumn = {
  colId: BillingDocumentColId.ACTION_FIX,
  field: "business.id",
  headerName: ACTION_COLUMN_NAME,
  cellRenderer: ({ colDef, data }) => {
    if (!data) {
      return null;
    }

    const businessId = get(data, colDef.field);

    const onClick = () => {
      if (
        data.billingDocument.exportErrors ||
        data.billingDocument.exportWarnings
      ) {
        goToBillingStep(BillingScreens.EXPORT);
      } else {
        openEditBusinessModal(
          businessId,
          null,
          BusinessModalSection.FINANCE_AND_ACCOUNTING,
        );
      }
    };
    return (
      <ActionButtonContainer>
        <ActionButton onClick={onClick}>
          <FontAwesomeIcon icon={faWrench} />
          &nbsp;Fix
        </ActionButton>
      </ActionButtonContainer>
    );
  },
};
