import React from "react";

import Grid from "@material-ui/core/Grid";

import { SubtleBadge } from "components/Badge";
import { FormCollapse } from "components/Form";
import { Row } from "components/Layout";

import { DeploymentPermissions } from "constants/permissions";
import { FinanceSubModalSection } from "constants/settings";

import { GeneralSettings } from "containers/Settings/Deployment/Finance/General";
import { InsuranceSettings } from "containers/Settings/Deployment/Finance/Insurance";
import { InterestSettings } from "containers/Settings/Deployment/Finance/Interest";
import { InvoiceSettings } from "containers/Settings/Deployment/Finance/Invoice";
import { PaymentSettings } from "containers/Settings/Deployment/Finance/Payments";
import { RCTISettings } from "containers/Settings/Deployment/Finance/RCTI";
import { RetainedProceedsSettings } from "containers/Settings/Deployment/Finance/RetainedProceeds";
import { TradingTermsSection } from "containers/Settings/Deployment/Finance/TradingTerms";
import { VendorCommissionSettings } from "containers/Settings/Deployment/Finance/VendorCommission";
import {
  DeploymentSettingsModalSection,
  FormColumn,
} from "containers/Settings/Deployment/index";

import { useHasDeploymentPermission, useSectionToggle } from "hooks";

const header = title => (
  <Row>
    <SubtleBadge>{title}</SubtleBadge>
  </Row>
);

export function FinanceSection(props) {
  const { sectionHelper, ns } = props;

  const modalSection = DeploymentSettingsModalSection.FINANCE_REPORTS;
  const [section, setSection] = sectionHelper;

  const isOpen = section === modalSection;
  const onToggle = () => setSection(modalSection);

  const subSectionHelper = useSectionToggle(FinanceSubModalSection.GENERAL);

  const hasTradingTermsFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureTradingTerms,
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={header("Finance")}
    >
      <Grid container spacing={2}>
        <FormColumn>
          <GeneralSettings sectionHelper={subSectionHelper} namespace={ns} />
          <RCTISettings sectionHelper={subSectionHelper} namespace={ns} />
          <InvoiceSettings sectionHelper={subSectionHelper} namespace={ns} />
          <RetainedProceedsSettings
            sectionHelper={subSectionHelper}
            namespace={ns}
          />
          <PaymentSettings sectionHelper={subSectionHelper} namespace={ns} />
          <InsuranceSettings sectionHelper={subSectionHelper} namespace={ns} />

          {hasTradingTermsFeature && (
            <TradingTermsSection
              sectionHelper={subSectionHelper}
              namespace={ns}
            />
          )}
          <VendorCommissionSettings
            sectionHelper={subSectionHelper}
            namespace={ns}
          />
          <InterestSettings sectionHelper={subSectionHelper} namespace={ns} />
        </FormColumn>
      </Grid>
    </FormCollapse>
  );
}
