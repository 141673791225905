import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Button, SecondaryButton } from "components/Form";
import { Column, Row } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";
import { BoldText } from "components/Text";

export const MESSAGE_CANT_BE_UNDONE = "This can't be undone.";
export const BUTTON_DELETE = "Delete";

export function createModalTitle(deleteObjectName) {
  return `Confirm you want to delete ${deleteObjectName}`;
}

const PreMessageIcon = styled(FontAwesomeIcon)`
  color: ${({ theme, color }) => theme.colors[color]};
`;

const MessageColumn = styled(Column)`
  white-space: pre-line;
`;

export function ConfirmDialog(props) {
  const {
    isOpen = false,
    message = MESSAGE_CANT_BE_UNDONE,
    buttonMessage = BUTTON_DELETE,
    onCancel = undefined,
    onDelete,
    title,
    includeDeleteConfirmation,
    messageIcon,
    messageIconColor = "warning",
    question,
  } = props;

  function onClickCancel() {
    typeof onCancel === "function" && onCancel();
  }

  function onClickDelete() {
    typeof onDelete === "function" && onDelete();
  }

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(
    includeDeleteConfirmation,
  );

  return (
    <Dialog
      data-tour="confirmDialog"
      open={!!isOpen}
      onClose={onClickCancel}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={onClickCancel}>{title}</DialogTitle>
      <DialogContent dividers form>
        <Column padding={2}>
          <Row justifyBetween alignCenter>
            {messageIcon && (
              <Column className="p-2">
                <PreMessageIcon
                  icon={messageIcon}
                  color={messageIconColor}
                  size="3x"
                />{" "}
              </Column>
            )}
            <MessageColumn>
              {question && <BoldText>{question}</BoldText>}
              {message}
            </MessageColumn>
          </Row>
          {includeDeleteConfirmation && (
            <div>
              Type <strong>delete</strong> to confirm
              <Input
                onChange={e => {
                  if (e.target.value.toLowerCase() === "delete") {
                    setIsSubmitDisabled(false);
                  } else {
                    setIsSubmitDisabled(true);
                  }
                }}
              />
            </div>
          )}
        </Column>
      </DialogContent>
      <DialogActions>
        <SecondaryButton data-tour="cancel" onClick={onClickCancel}>
          Cancel
        </SecondaryButton>
        <Button
          data-tour="confirm"
          onClick={onClickDelete}
          disabled={isSubmitDisabled}
        >
          {buttonMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  title: PropTypes.string.isRequired,
  buttonMessage: PropTypes.string,
};
