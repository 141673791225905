import React from "react";

import { useField, useFormikContext } from "formik";
import { sortBy } from "lodash";
import { intersection } from "lodash/array";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { withArrayNamespace } from "components/Form/FormikControls";
import { Tooltip } from "components/Form/FormikControls/Tooltip";

import {
  ForNotExternalAgentSale,
  ForNotExternalAgentSaleNorClearingSale,
} from "containers/ForSaleType";
import { ForLivestockAgent, ForSaleyardAdmin } from "containers/ForUserType";

import {
  getActiveRoleDeployments,
  selectAgencyByDeploymentIdLookup,
} from "selectors";

import { DeploymentSaleInterfaceOption } from "./DeploymentSaleInterfaceOption";

const Table = styled.table`
  width: 100%;
`;

export const DeploymentSaleInterfaceOptionsForm = ({
  namespace: ns,
  isCreateMode,
  deploymentIds,
  readOnly,
}) => {
  const formikProps = useFormikContext();
  const selectedSaleType = formikProps.values.sale_type;
  const activeDeployments = useSelector(getActiveRoleDeployments);

  const agencyByDeploymentIdLookup = useSelector(
    selectAgencyByDeploymentIdLookup,
  );

  const [{ value: deploymentSales = [] }] = useField(ns);

  const visibleDeploymentSaleDeploymentIds = isCreateMode
    ? intersection(
        activeDeployments.map(deployment => deployment.id),
        deploymentIds,
      )
    : deploymentSales.map(deploymentSale => deploymentSale.deployment_id);

  return (
    <Table>
      {isCreateMode ? (
        <colgroup>
          <ForNotExternalAgentSale selectedSaleType={selectedSaleType}>
            <ForSaleyardAdmin>
              <col width="20%" /> {/* Agency */}
            </ForSaleyardAdmin>
            <col width="45%" /> {/* Default Export Sites for Sale Lots */}
          </ForNotExternalAgentSale>
          <ForLivestockAgent>
            <col width="10%" /> {/* Vendor Terms */}
            <col width="10%" /> {/* Buyer Terms */}
          </ForLivestockAgent>
          <ForNotExternalAgentSaleNorClearingSale
            selectedSaleType={selectedSaleType}
          >
            <col width="15%" /> {/* Auto Pen */}
          </ForNotExternalAgentSaleNorClearingSale>
        </colgroup>
      ) : (
        <colgroup>
          <ForNotExternalAgentSale>
            <ForSaleyardAdmin>
              <col width="20%" /> {/* Agency */}
            </ForSaleyardAdmin>
            <col width="30%" /> {/* Default Export Sites for Sale Lots */}
            <col width="15%" /> {/* Manual Vendor Numbering */}
            <col width="15%" /> {/* Actions */}
          </ForNotExternalAgentSale>
          <ForLivestockAgent>
            <col width="10%" /> {/* Vendor Terms */}
            <col width="10%" /> {/* Buyer Terms */}
          </ForLivestockAgent>
          <ForNotExternalAgentSaleNorClearingSale
            selectedSaleType={selectedSaleType}
          >
            <col width="15%" /> {/* Auto Pen */}
          </ForNotExternalAgentSaleNorClearingSale>
        </colgroup>
      )}
      <thead>
        <tr>
          <ForNotExternalAgentSale selectedSaleType={selectedSaleType}>
            <ForSaleyardAdmin>
              <th className="text-left">Agency</th>
            </ForSaleyardAdmin>
            <th className="text-left">Default Export Sites For Sale Lots</th>
            {!isCreateMode && (
              <>
                <th className="text-center">Manual Vendor Numbering</th>
                <th className="text-center">Actions</th>
              </>
            )}
          </ForNotExternalAgentSale>
          <ForLivestockAgent>
            <th className="text-center">
              Vendor Terms{" "}
              <Tooltip title="How many days after the sale is payment due to vendors.  Value is set on sale creation using default values from agency settings." />
            </th>
            <th className="text-center">
              Buyer Terms{" "}
              <Tooltip title="How many days after the sale is payment due from buyers. Value is set on sale creation using default values from agency settings." />
            </th>
          </ForLivestockAgent>
          <ForNotExternalAgentSaleNorClearingSale
            selectedSaleType={selectedSaleType}
          >
            <th className="text-center">Auto Pen (with Lot No.)</th>
          </ForNotExternalAgentSaleNorClearingSale>
        </tr>
      </thead>
      <tbody>
        {sortBy(
          visibleDeploymentSaleDeploymentIds,
          deploymentId => agencyByDeploymentIdLookup[deploymentId]?.name,
        ).map(deploymentId => {
          const deploymentSaleIndex = deploymentSales.findIndex(
            deploymentSale => deploymentSale.deployment_id === deploymentId,
          );
          if (deploymentSaleIndex < 0) {
            return null;
          }

          return (
            <DeploymentSaleInterfaceOption
              key={deploymentId}
              isCreateMode={isCreateMode}
              deploymentId={deploymentId}
              namespace={withArrayNamespace(ns, deploymentSaleIndex)}
              readOnly={readOnly}
            />
          );
        })}
      </tbody>
    </Table>
  );
};
