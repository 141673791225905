import React, { useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { BillingDocumentAction } from "actions";

import { BillingDocumentsTable } from "components/BillingWorkFlow/BillingDocumentsTable";
import { LedgerEntriesTable } from "components/BillingWorkFlow/LedgerEntriesTable";
import { BottomSticky, Button } from "components/Form";
import { Column, Row } from "components/Layout";
import { ResizableWrapper } from "components/Layout/ResizableWrapper";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import MessageBox from "components/MessageBox";

import {
  BillingDocumentReportJobStatus,
  BillingDocumentStatus,
} from "constants/billingDocuments";
import { ApiModel } from "constants/loading";
import { Settings } from "constants/settings";

import { ForReferenceSale, ForNotReferenceSale } from "containers/ForSaleType";

import { getReportJobUrl } from "lib/navigation";

import {
  getActiveRole,
  getBillingDocumentById,
  getCurrentSale,
  getIsReadOnlyByBillingDocumentId,
} from "selectors";

import { PDFPreview } from "./PDFPreview";

const FlexTableContainer = styled.div(
  ({ theme }) => `
  position: relative;
  flex: 1;
  border-top: 2px solid ${theme.colors.gray44};
  resize: vertical; 
  overflow: auto;
`,
);

const RightFrame = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  border-left: 2px solid ${theme.colors.gray44};
  flex-grow: 1; 
`,
);

export const Review = ({ setSelectedBillingDocuments }) => {
  const [billingDocumentId, setBillingDocumentId] = useState(null);

  const isReadOnly = useSelector(
    getIsReadOnlyByBillingDocumentId(billingDocumentId),
  );

  const { status, reportJobs = [] } =
    useSelector(getBillingDocumentById(billingDocumentId)) || {};

  const userRoleSlug = useSelector(state => getActiveRole(state).slug);
  const id = reportJobs.find(
    rj => rj.documentStatus === BillingDocumentReportJobStatus.COMMITTED,
  )?.id;

  const documentPreviewUrl = getReportJobUrl(id, userRoleSlug);

  const saleType = useSelector(getCurrentSale)?.sale_type;

  const dispatch = useDispatch();

  const setDocumentStatus = useCallback(
    status =>
      dispatch(
        BillingDocumentAction.update({
          id: billingDocumentId,
          status,
        }),
      ),

    [billingDocumentId, dispatch],
  );

  const [grid, setGrid] = useState(null);

  const previewNext = useCallback(() => {
    const { api } = grid;
    const selectedRows = api.getSelectedNodes();

    if (selectedRows.length === 1) {
      const selectedRow = selectedRows[0];
      selectedRow.setSelected(false);
      api.forEachNode(node => {
        if (node.rowIndex === selectedRow.rowIndex + 1) {
          node.setSelected(true);
        }
      });
    }
  }, [grid]);

  return (
    <Row full>
      <WaitForSync
        requiredData={[
          ApiModel.BILLING_RUNS,
          ApiModel.BILLING_DOCUMENTS,
          ApiModel.LEDGER_ENTRIES,
        ]}
      >
        <ResizableWrapper
          settingKey={Settings.resizableSections.billingReviewHorizontal}
        >
          <ForNotReferenceSale selectedSaleType={saleType}>
            <ResizableWrapper
              isVertical
              settingKey={Settings.resizableSections.billingReviewVertical}
            >
              <FlexTableContainer>
                <BillingDocumentsTable
                  onGridReady={setGrid}
                  setBillingDocumentId={setBillingDocumentId}
                  setSelectedBillingDocuments={setSelectedBillingDocuments}
                />
              </FlexTableContainer>
            </ResizableWrapper>

            <FlexTableContainer data-tour="reviewContainer">
              <LedgerEntriesTable
                billingDocumentId={billingDocumentId}
                key={billingDocumentId}
              />
            </FlexTableContainer>
          </ForNotReferenceSale>

          <ForReferenceSale selectedSaleType={saleType}>
            <FlexTableContainer>
              <BillingDocumentsTable
                onGridReady={setGrid}
                setBillingDocumentId={setBillingDocumentId}
                setSelectedBillingDocuments={setSelectedBillingDocuments}
              />
            </FlexTableContainer>
          </ForReferenceSale>
        </ResizableWrapper>

        <RightFrame>
          {billingDocumentId ? (
            <>
              <PDFPreview url={documentPreviewUrl} />
              <BottomSticky>
                <Row>
                  <ForNotReferenceSale selectedSaleType={saleType}>
                    {status === BillingDocumentStatus.DRAFT && (
                      <Button
                        disabled={isReadOnly}
                        onClick={() =>
                          setDocumentStatus(BillingDocumentStatus.APPROVED)
                        }
                      >
                        Approve
                      </Button>
                    )}
                    {status === BillingDocumentStatus.APPROVED && (
                      <Button
                        disabled={isReadOnly}
                        onClick={() =>
                          setDocumentStatus(BillingDocumentStatus.DRAFT)
                        }
                      >
                        Unapprove
                      </Button>
                    )}
                  </ForNotReferenceSale>
                  <Button onClick={previewNext}>Open Next</Button>
                </Row>
              </BottomSticky>
            </>
          ) : (
            <Column full>
              <MessageBox>Select a document to preview</MessageBox>
            </Column>
          )}
        </RightFrame>
      </WaitForSync>
    </Row>
  );
};
