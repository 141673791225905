import React, { useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { patchSaleLot } from "actions";

import { BaseTable } from "components/Importer/BaseTable";
import { saleLotColumns } from "components/Importer/columnDefinitions";

import { getCombinedLotNumber } from "lib/saleLot";

import { getSaleLots, selectSaleLotIdsByLotNumberLookup } from "selectors";

export function findSaleLotByLotNumber(
  lotNumber = "",
  lotNumberSuffix = "",
  saleLotIdsByLotNumberLookup,
  saleLotByIdLookup,
) {
  const normalisedLotSuffix = lotNumberSuffix?.toLowerCase();
  const saleLotIds = saleLotIdsByLotNumberLookup[lotNumber];
  if (!saleLotIds) {
    return null;
  }

  return saleLotIds
    .map(saleLotId => saleLotByIdLookup[saleLotId])
    .find(
      saleLot => saleLot.lotNumberSuffix.toLowerCase() === normalisedLotSuffix,
    );
}

export function LotImporter({ importLots }) {
  const saleLotIdsByLotNumberLookup = useSelector(
    selectSaleLotIdsByLotNumberLookup,
  );
  const agrinousLots = useSelector(getSaleLots);

  const dispatch = useDispatch();

  const updateLot = data => {
    const patch = {
      lot_number: data.number,
      lotNumberSuffix: data.lotNumberSuffix,
      id: agrinousLots[data.agrinousLotNumber.value].id,
    };
    dispatch(patchSaleLot(patch));
  };

  const lotOptions = useMemo(
    () =>
      Object.values(agrinousLots).map(lot => ({
        label: `${lot.lot_number}: ${lot.clearingSaleAttributes?.title}`,
        value: lot.id,
      })),
    [agrinousLots],
  );

  const lotData = importLots.map(importLot => {
    const saleLot = findSaleLotByLotNumber(
      importLot.number,
      importLot.lotNumberSuffix,
      saleLotIdsByLotNumberLookup,
      agrinousLots,
    );
    return {
      ...importLot,
      agrinousLot: saleLot,
      agrinousLotNumber: {
        label: saleLot ? getCombinedLotNumber(saleLot) : "",
        value: saleLot?.id,
      },
    };
  });

  const onCellValueChanged = e => {
    // Fired when a option is selected to merge in the selected attributes data into the rowdata.
    const selectedLot = agrinousLots?.[e.newValue.value];
    e.node.setData({
      ...e.node.data,
      agrinousLot: selectedLot,
      agrinousLotNumber: {
        label: selectedLot ? getCombinedLotNumber(selectedLot) : "",
        value: selectedLot?.id,
      },
    });
    e.api.redrawRows(e.node);
  };

  return (
    <BaseTable
      columns={saleLotColumns(lotOptions)}
      data={lotData}
      onCellValueChanged={onCellValueChanged}
      context={{
        update: updateLot,
      }}
    />
  );
}
