import { createLookupCombiner, createLookupSelectors } from "selectors/lib";

import { getPayments } from "selectors/root";
import { selectPaymentIdsByBillingDocumentIdLookup } from "selectors/indexes";

export const getPaymentById =
  (paymentId: string) =>
  (state: State): Payment | null =>
    getPayments(state)[paymentId] || null;

export const getPaymentIdsByBillingDocumentId =
  (billingDocumentId: string) => (state: State) =>
    selectPaymentIdsByBillingDocumentIdLookup(state)[billingDocumentId] || null;

export const [
  selectPaymentsByBillingDocumentIdLookup,
  getPaymentsByBillingDocumentId,
] = createLookupSelectors(
  [selectPaymentIdsByBillingDocumentIdLookup, getPayments],
  createLookupCombiner(
    (paymentIds: string[], paymentByIdLookup: Record<string, Payment>) =>
      paymentIds.map((paymentId: string) => paymentByIdLookup[paymentId]),
  ),
) as [
  (state: State) => Record<string, Payment[]>,
  (billingDocumentId: string) => (state: State) => Payment[] | null,
];
