import { maxBy, minBy } from "lodash";

import { NLIS_NOT_FOUND } from "constants/nlis";
import { ScanStatus, ScanStatusColor } from "constants/scanner";

import {
  dateTimeStringToDateTime,
  formatDateTimeString,
} from "lib/timeFormats";

import { findOutlierParameters, formatPercentage } from "./index";

export const getPicFromScan = scan => {
  const pic =
    scan.animal && scan.animal.nlis_id && scan.animal.nlis_id.substring(0, 8);
  return pic || "";
};

const getTimeDelta = val => val.timeDelta;

export const getUnallocatedScanGroups = scans => {
  if (!Array.isArray(scans) || !scans.length) {
    return [];
  }

  // Helper function to return either the PIC or "Unknown PIC"
  const getPICTitle = scan => scan.picFromNLISId || "Unknown PIC";

  // Algorithm function to perform the actual grouping, calls the getGroupTitleFn on every scan
  // and groups according to the title.
  // returns an array of groups with their relevant scans
  const groupScansByTitle = (scans, getGroupTitleFn) =>
    scans.reduce((previous, currentScan) => {
      const title = getGroupTitleFn(currentScan);

      // try and find an existing group by title
      let scanGroup = previous.find(groupedScan => groupedScan.title === title);

      // create a new group when an existing group with that title can't be found
      if (!scanGroup) {
        scanGroup = {
          title,
          scans: [currentScan],
        };
        // add the new scan group to the list to be returned
        previous.push(scanGroup);
      } else {
        // the group already exists, just add this scan to it
        scanGroup.scans.push(currentScan);
      }
      return previous;
    }, []);

  let result;
  if (scans.length < 5) {
    result = groupScansByTitle(scans, getPICTitle);
  } else {
    // transforms the created property on each scan to a JS date object so that they may be compared
    // also sorted the list by scan creation date
    const sortedScans = scans
      .map(scan => {
        scan.created = new Date(scan.created);
        return scan;
      })
      .sort((a, b) => a.created - b.created);

    // gets a sorted list of the time delta between the current scan and the previous scan.
    // Pairs the associated scan with the scan time delta
    const scanByTimeDelta = sortedScans
      .map((scan, index) => {
        const timeDelta =
          index === 0 ? 0 : scan.created - sortedScans[index - 1].created;
        scan.timeDelta = timeDelta;
        return {
          timeDelta,
          scan,
        };
      })
      .sort((a, b) => a.timeDelta - b.timeDelta);

    // get the 5 number summary from the scan time deltas

    const { upperLimit } = findOutlierParameters(scanByTimeDelta, getTimeDelta);

    // capture the initial scan time for the first group
    let scanStartTime = dateTimeStringToDateTime(sortedScans[0].created);
    let lastPIC = "";

    // group the scans by PIC and scan time
    result = groupScansByTitle(sortedScans, scan => {
      const scanPIC = getPICTitle(scan);
      const isNewPICGroup = scanPIC !== lastPIC;
      // create a new group when the scan time is a long way away (large delta time) from the previous scan
      // and make sure that we update the time when we have started scanning at a new PIC

      // Outlier is set to a minimum of 60000 milliseconds (1 minute)
      if (scan.timeDelta > Math.max(upperLimit, 60000) || isNewPICGroup) {
        scanStartTime = formatDateTimeString(scan.created);
        lastPIC = scanPIC;
      }

      return `${scanStartTime} (${scanPIC})`;
    });
  }
  return result;
};

export const getScanStatusColour = scanStatus =>
  ScanStatusColor[scanStatus] || ScanStatusColor[ScanStatus.ERROR];

export const scanHasIssues = (scan = {}, vendorPic = null) =>
  scan.lifetime_traceability !== "Y" || scan.current_pic !== vendorPic;

export const getScanPercentDisplayValue = (
  expected,
  scannedCount,
  defaultValue = "-",
) => {
  return expected && scannedCount > 0
    ? formatPercentage(scannedCount / expected)
    : defaultValue;
};

export function mapUnassignedScanToSaleLotScan(unassignedScan) {
  const {
    created,
    deviceId,
    deviceName,
    draftName,
    EID,
    NLISID,
    ignoreDuplicate,
  } = unassignedScan;
  return {
    EID,
    created,
    device_id: deviceId,
    draft_name: draftName,
    device_name: deviceName,
    NLISID,
    remove_from_salelot: ignoreDuplicate,
  };
}

const sanitizeForHash = toSanitize =>
  (toSanitize || "UNKNOWN").replace(/_/g, "\\_");
export const getDraftingInformationHash = (deviceName, deviceId, draftName) =>
  `${sanitizeForHash(deviceName)}__${sanitizeForHash(
    deviceId,
  )}__${sanitizeForHash(draftName)}`;

export function isScanWeighed(scan) {
  return !!scan.total_mass_grams;
}

export function getDraftInformationFieldsFromScan(scan, deviceLookup) {
  const deviceName =
    scan.device_name ||
    scan.deviceName ||
    deviceLookup?.[scan.device_id || scan.deviceId]?.name ||
    "";
  const deviceId = scan.device_id || scan.deviceId;
  const draftName = scan.draft_name || scan.draftName || "";
  return {
    deviceName,
    deviceId,
    draftName,
    draftingInformationHash: getDraftingInformationHash(
      deviceName,
      deviceId,
      draftName,
    ),
  };
}

export const getFirstAndLastScans = (objArray, dateTimeField) => {
  return {
    firstScan: minBy(objArray, dateTimeField)?.[dateTimeField],
    lastScan: maxBy(objArray, dateTimeField)?.[dateTimeField],
  };
};

// saleyard tags are recognised by the first 8 digits of the
// nlis id being equal to saleyard pic
export const getSaleyardTags = (scans, saleyardPIC) =>
  scans.filter(scan => scan.animal?.nlis_id?.substr(0, 8) === saleyardPIC);

export const getNotRegisteredTags = scans =>
  scans.filter(scan => scan.animal?.nlis_id === NLIS_NOT_FOUND);
