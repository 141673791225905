import { SaleLotType } from "constants/saleLots";

import { draftingAttributesMap } from "lib/serializers/draftingAttributes";
import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

import { addressSerializeMap } from "./address";

export const saleLotDefaults = {
  commentIds: [],
  draftingAttributes: null,
  earliestPickupDate: null,
  estimatedAverageMassGrams: 0,
  first_sold: null,
  latestPickupDate: null,
  listingId: "",
  marks: [],
  note: "",
  pickupAddress: null,
  pickupNotes: "",
  quantity: 0,
  quantity_delivered: 0,
  quantityProgeny: 0,
  quantityTagsUsed: 0,
  saleLotType: SaleLotType.AUCTION,
  total_price_cents: 0,
  total_mass_grams: 0,
  thirdPartyId: null,
  youtube_link: "",
};

const draftSightingSerializeMap = {
  lastSeenAtTime: "last_seen_at_time",
  lastSeenAtDraftId: "last_seen_at_draft_id",
};

const saleLotSerializeMap = {
  accountNote: "account_note",
  age_id: "age_id",
  bidIncrement: "bid_increment",
  breed_id: "breed_id",
  buyerNumber: "buyer_number",
  buyer_id: "buyer_id",
  buyer_way: "buyer_way",
  buyersPremium: "buyers_premium",
  category_id: "category_id",
  clearingSaleAttributes: {
    category: "category",
    comments: "comments",
    hours: "hours",
    inspection: "inspection",
    itemCondition: "item_condition",
    itemType: "item_type",
    make: "make",
    model: "model",
    newUsed: "new_used",
    odometer: "odometer",
    ppsrComments: "ppsr_comments",
    ppsrStatus: "ppsr_status",
    publicDescription: "public_description",
    registration: "registration",
    sellSafeCategory: "sell_safe_category",
    status: "status",
    subType: "sub_type",
    title: "title",
    vin: "vin",
    year: "year",
  },
  commentIds: "comment_ids",
  deliveryPenId: "delivery_pen_id",
  dentitionId: "dentition_id",
  destination_property_id: "destination_property_id",
  draftingAttributes: draftingAttributesMap,
  dressingRangeId: "dressing_range_id",
  earliestPickupDate: "earliest_pickup_date",
  estimatedAverageMassGrams: "estimated_average_mass_grams",
  estimatedAverageWeightId: "estimated_average_weight_id",
  exemption_id: "exemption_id",
  exportSites: "export_sites",
  id: "id",
  invoiceToBusinessId: "invoice_to_business_id",
  isGSTExempt: "is_gst_exempt",
  latestPickupDate: "latest_pickup_date",
  listingFee: "listing_fee",
  listingId: "listing_id",
  lotNumberSuffix: "lot_number_suffix",
  lot_number: "lot_number",
  note: "note",
  originalListingPrice: "original_listing_price",
  overflowPen: "overflow_pen",
  overflowQuantity: "overflow_quantity",
  parentId: "parent_id",
  pickupAddress: addressSerializeMap,
  pickupNotes: "pickup_notes",
  quantity: "quantity",
  quantityProgeny: "quantity_progeny",
  quantityTagsUsed: "quantity_tags_used",
  quantity_delivered: "quantity_delivered",
  reservePrice: "reserve_price",
  saleLotType: "salelot_type",
  sale_round_id: "sale_round_id",
  sex_id: "sex_id",
  startingBid: "starting_bid",
  subLotNumber: "sub_lot_number",
  thirdPartyId: "third_party_id",
  timeWeighed: "time_weighed",
  total_price_cents: "total_price_cents",
  ...draftSightingSerializeMap,
};

// Define any nested relationships/objects
const nestedObjectMap = {
  drafting_attributes: "draftingAttributes",
  clearing_sale_attributes: "clearingSaleAttributes",
  pickup_address: "pickupAddress",
};

export const serializeSaleLot = buildSerializer(
  saleLotSerializeMap,
  "saleLot",
  nestedObjectMap,
  {},
);
export const deserializeSaleLot = buildDeserializer(
  saleLotSerializeMap,
  "saleLot",
  nestedObjectMap,
  saleLotDefaults,
);

const saleyardScanSaleLotSerializeMap = {
  consignmentId: "consignment_id",
  id: "id",
  livestockSaleId: "livestocksale_id",
  pricingTypeId: "pricing_type_id",
  quantity: "quantity",
  saleRoundId: "sale_round_id",
  saleLotType: "salelot_type",
};

/**
 * @param {SerialisedSaleyardScanSaleLot} serialisedSaleLot
 * @return {SaleyardScanSaleLot}
 */
export const serializeSaleyardScanSaleLot = buildSerializer(
  saleyardScanSaleLotSerializeMap,
  "saleLot",
);

/**
 * @typedef {Object} SerialisedSaleyardScanSaleLot
 * @property {string} id
 * @property {string} consignment_id
 * @property {number} livestocksale_id
 * @property {number} quantity
 * @property {"saleyard_scan"} salelot_type
 */

/**
 * @typedef {Object} SaleyardScanSaleLot
 * @property {string} id
 * @property {string} consignmentId
 * @property {number} livestockSaleId
 * @property {number} quantity
 * @property {number} saleRoundId
 * @property {number} pricingTypeId
 * @property {"saleyard_scan"} saleLotType
 */

/**
 * @param {SaleyardScanSaleLot} saleLot
 * @return {SerialisedSaleyardScanSaleLot}
 */

export const deserializeSaleyardScanSaleLot = buildDeserializer(
  saleyardScanSaleLotSerializeMap,
  "saleLot",
  {
    quantity: 0,
    saleLotType: SaleLotType.SALEYARD_SCAN,
  },
);

const bulkUpdateSaleLotSerializeMap = [
  // Must be a subset of the saleLotSerializeMap, otherwise state will be incorrect.
  "age_id",
  "breed_id",
  "buyer_id",
  "buyer_way",
  "category_id",
  "clearingSaleAttributes",
  "deliveryPenId",
  "destination_property_id",
  "draftingAttributes",
  "dressingRangeId",
  "estimatedAverageWeightId",
  "exemption_id",
  "exportSites",
  "id",
  "invoiceToBusinessId",
  "isGSTExempt",
  "note",
  "quantity_delivered",
  "reservePrice",
  "sale_round_id",
  "sex_id",
  "startingBid",
  "thirdPartyId",
  "total_price_cents",
].reduce((acc, key) => {
  if (!saleLotSerializeMap[key]) {
    throw new Error(`Key ${key} is missing from saleLotSerializeMap`);
  }

  acc[key] = saleLotSerializeMap[key];
  return acc;
}, {});

const bulkUpdateNestedObjectMap = {
  clearing_sale_attributes: "clearingSaleAttributes",
  drafting_attributes: "draftingAttributes",
};

export const serializeBulkUpdateSaleLot = buildSerializer(
  bulkUpdateSaleLotSerializeMap,
  "saleLot",
  bulkUpdateNestedObjectMap,
  {},
);

export const deserializeBulkUpdateSaleLot = buildDeserializer(
  bulkUpdateSaleLotSerializeMap,
  "saleLot",
  bulkUpdateNestedObjectMap,
);

export const serializeDraftSighting = buildSerializer(
  draftSightingSerializeMap,
  "draftSighting",
);

export const deserializeDraftSighting = buildDeserializer(
  draftSightingSerializeMap,
  "draftSighting",
);
