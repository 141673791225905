import { autoCompleteColumn } from "components/AgGrid/AutoComplete";
import { BusinessTableConfiguration } from "components/MapBusinessModal/Table";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";

import { datetimeFormatter } from "lib/agGrid/columns/datetime";
import { externalLinkFormatter } from "lib/agGrid/columns/externalLink";
import { nullableBooleanRenderer } from "lib/agGrid/renderers/tickRenderer";

import {
  actionsColumnRendererFactory,
  envdMappingAction,
  mappingAction,
  saleLotMappingAction,
} from "./Actions";

const defaultBooleanColumnDefinition = {
  cellRenderer: nullableBooleanRenderer,
  filter: "anBooleanColumnFilter",
  filterParams: {
    showIndeterminate: true,
  },
};

export const breedColumns = options => [
  {
    headerName: "Data in XML",

    children: [
      {
        headerName: "Code",
        field: "strgBreedCode",
        width: 80,
      },
      {
        headerName: "Name",
        field: "strgBreedDesc",
      },
    ],
  },
  {
    headerName: "Data in AgriNous",
    children: [
      autoCompleteColumn("Name", "name", options),

      { headerName: "Agent Code", field: "code" },
      { headerName: "Saleyard Code", field: "saleyard_code" },
      { headerName: "Quick Code", field: "quick_code" },
    ],
  },

  {
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: mappingAction,
    pinned: "right",
  },
];

export const productColumns = options => [
  {
    headerName: "Data in XML",
    children: [
      { headerName: "Code", field: "strgSexCode", width: 80 },
      { headerName: "Description", field: "strgSexDesc" },
    ],
  },
  {
    headerName: "Agrinous",
    children: [
      autoCompleteColumn("Description", "name", options),
      { headerName: "Code", field: "code", width: 80 },
      { headerName: "Saleyard Code", field: "saleyard_code", width: 80 },
      { headerName: "Quick Code", field: "quick_code" },
    ],
  },
  {
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: mappingAction,
    pinned: "right",
  },
];

export const xmlBusinessColumns = [
  {
    headerName: "Data in XML",
    children: [
      { headerName: "Short Code", field: "businessShortCode" },
      { headerName: "Name", field: "businessName" },
      { headerName: "PIC", field: "businessPIC" },
    ],
  },
  {
    headerName: "Business in AgriNous",
    children: [
      { headerName: "Name", field: "agrinousBusiness.name" },
      { headerName: "Agent Code", field: "agrinousBusiness.shortCode" },
      {
        headerName: "SY Code",
        field: "agrinousBusiness.shortCodeSaleyard",
        sort: "asc",
      },
      {
        headerName: "Properties",
        valueGetter: ({ data }) =>
          data.agrinousBusiness?.properties?.map(p => p.PIC) || null,
      },
    ],
  },

  {
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: actionsColumnRendererFactory(
      BusinessTableConfiguration.SG8XML,
    ),
    pinned: "right",
  },
];

export const auctionsPlusCSVBusinessColumns = [
  {
    headerName: "Business in CSV",
    children: [
      { headerName: "AuctionsPlus Short Code", field: "auctionsPlusId" },
      {
        headerName: "Business Name",
        field: "businessName",
        colId: "businessName",
      },
      { headerName: "Town", field: "locality" },
      { headerName: "State", field: "state" },
    ],
  },
  {
    headerName: "Business in AgriNous",
    children: [
      {
        headerName: "AuctionsPlus Short Code",
        field: "agrinousBusiness.shortCodeAuctionsPlus",
      },
      {
        headerName: "Name",
        field: "agrinousBusiness.name",
        colId: "agrinousBusiness.name",
      },
      { headerName: "Town", field: "agrinousBusiness.address.locality" },
      { headerName: "State", field: "agrinousBusiness.address.state" },
    ],
  },

  {
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: actionsColumnRendererFactory(
      BusinessTableConfiguration.AuctionsPlusCSV,
    ),
    pinned: "right",
  },
];

export const saleLotColumns = options => [
  {
    headerName: "Lot in CSV",
    children: [
      { headerName: "Lot Number", field: "number" },
      { headerName: "Sub Lot", field: "lotNumberSuffix" },
      { headerName: "Category", field: "category" },
      { headerName: "Description", field: "description" },
      { headerName: "Status", field: "status" },
    ],
  },
  {
    headerName: "Lot in Agrinous",
    children: [
      autoCompleteColumn("Lot Number", "agrinousLotNumber", options),
      {
        headerName: "Category",
        field: "agrinousLot.clearingSaleAttributes.category",
      },

      {
        headerName: "Description",
        field: "agrinousLot.clearingSaleAttributes.title",
      },
      {
        headerName: "Status",
        field: "agrinousLot.clearingSaleAttributes.status",
      },
    ],
  },

  {
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: saleLotMappingAction,
    pinned: "right",
  },
];

export const EnvdDocumentColumns = ({ saleOptions, deploymentSaleOptions }) => [
  {
    headerName: "Basic Details",
    children: [
      { headerName: "Mapping Status", field: "mappingStatus", rowGroup: true },
      { headerName: "Serial #", field: "envdDocument.serialNumber", width: 90 },
      {
        headerName: "PDF",
        field: "envdDocument.pdfUrl",
        cellRenderer: externalLinkFormatter,
        width: 50,
      },
      {
        headerName: "Species",
        field: "species.name",
        width: 80,
      },
      {
        headerName: "Status",
        field: "envdDocument.status",
        width: 80,
        rowGroup: true,
      },

      { headerName: "Qty", field: "envdDocument.quantity", width: 60 },
    ],
  },
  {
    headerName: "Origin",
    children: [
      { headerName: "From PIC", field: "envdDocument.fromPIC", width: 100 },
      {
        headerName: "From Property",
        field: "envdDocument.fromPropertyName",
        width: 160,
      },
      { headerName: "Owner Name", field: "envdDocument.ownerName", width: 160 },
      {
        headerName: "Declaration Name",
        field: "envdDocument.declarationName",
        width: 160,
      },
    ],
  },
  {
    headerName: "Destination",
    children: [
      { headerName: "To PIC", field: "envdDocument.toPIC", width: 100 },
      {
        headerName: "To Property",
        field: "envdDocument.toPropertyName",
        width: 230,
      },
      {
        headerName: "Consignee Name",
        field: "envdDocument.consigneeName",
        width: 230,
      },
    ],
  },

  {
    headerName: "Other Details",
    children: [
      {
        headerName: "Created",
        field: "envdDocument.nlisCreatedAt",
        valueFormatter: datetimeFormatter,
        filter: "agDateColumnFilter",
        width: 150,
      },
      {
        headerName: "Updated",
        field: "envdDocument.nlisUpdatedAt",
        valueFormatter: datetimeFormatter,
        filter: "agDateColumnFilter",
        width: 150,
      },
      {
        headerName: "Consignment #",
        field: "envdDocument.consignmentNumber",
        width: 120,
      },
      { headerName: "Type", field: "envdDocument.formType", width: 80 },
    ],
  },

  {
    headerName: "Validation",

    children: [
      {
        headerName: "Is Complete",
        field: "envdDocument.isComplete",
        ...defaultBooleanColumnDefinition,
      },
      {
        headerName: "Missing Signature",
        field: "envdDocument.isMissingSignature",
        ...defaultBooleanColumnDefinition,
      },
      {
        headerName: "Missing Answers",
        field: "envdDocument.isMissingAnswers",
        ...defaultBooleanColumnDefinition,
      },
      {
        headerName: "Missing Animal Count",
        field: "envdDocument.isMissingAnimalCount",
        ...defaultBooleanColumnDefinition,
      },
    ],
  },

  {
    headerName: "Sale Mapping",
    pinned: "right",
    children: [
      // If a sale is selected, map to all deployment sales
      autoCompleteColumn(
        "Sale",
        "livestockSaleOption",
        saleOptions,
        undefined,
        undefined,
        "right",
      ),
      autoCompleteColumn(
        "Agency",
        "deploymentSaleOption",
        deploymentSaleOptions,
        false,
        (providedOptions, data) =>
          providedOptions[data?.livestockSaleOption?.value] || [],
        "right",
      ),
      {
        colId: ACTION_COLUMN_ID,
        headerName: ACTION_COLUMN_NAME,
        cellRenderer: envdMappingAction,
        pinned: "right",
        suppressCellFlash: true,
      },
    ],
  },
];
