import React from "react";

import { Grid } from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { deleteSaleLot } from "actions";

import { SubtleBadge } from "components/Badge";
import { SlimSecondaryButton } from "components/Button";
import SlimButton from "components/Button/SlimButton";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { FormCollapse } from "components/Form";
import { ProductSelectField } from "components/Form/Fields";
import { BreedField } from "components/Form/Fields/BreedField";
import {
  ControlledLifeCycleInput,
  Input,
  KilogramDisplayGramsInputProps,
  Label,
  OptionTogglerField,
  UnitPrice,
} from "components/Form/FormikControls";
import { summarizeLots } from "components/KillSheet/lib";
import { Row } from "components/Layout";

import { PricingTypes } from "constants/pricingTypes";

import { formatNumber } from "lib";

import { getCurrentSpeciesId, getEffectiveDeploymentId } from "selectors";
import { uniqBy } from "lodash";
import { FormValues } from "./types";

const NonSkinSummary = () => {
  const { values } = useFormikContext();

  const { saleLots } = values as any;

  const {
    totalQuantity,
    totalWeightGrams,
    totalPriceCents,
    dollarsPerHead,
    averageWeightKg,
    averageDollarsPerKilo,
  } = summarizeLots(saleLots);

  return (
    <>
      <Grid item xs={4}>
        <Input
          disabled
          name="total_quantity"
          align="right"
          label="Total Hd"
          overrideValue={formatNumber(totalQuantity, 0)}
        />
      </Grid>

      <Grid item xs={4}>
        <Input
          disabled
          name="total_weight"
          align="right"
          label="Total Weight"
          afterSymbol="kg"
          overrideValue={formatNumber(totalWeightGrams / 1000)}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          disabled
          name="total_price"
          align="right"
          label="Total Price"
          beforeSymbol="$"
          overrideValue={formatNumber(totalPriceCents / 100)}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          disabled
          name="dollarsPerHead"
          align="right"
          label="$/Hd"
          beforeSymbol="$"
          overrideValue={formatNumber(dollarsPerHead)}
        />
      </Grid>

      <Grid item xs={4}>
        <Input
          disabled
          name="averageWeight"
          align="right"
          label="Avg Weight"
          afterSymbol="kg"
          overrideValue={formatNumber(averageWeightKg)}
        />
      </Grid>

      <Grid item xs={4}>
        <Input
          disabled
          name="averagePricePerKg"
          align="right"
          beforeSymbol="$"
          label="Avg $/kg"
          overrideValue={averageDollarsPerKilo}
        />
      </Grid>
    </>
  );
};

export const LotForm = ({ defaultSaleLot }: { defaultSaleLot: any }) => {
  const deploymentId = useSelector(getEffectiveDeploymentId);
  const speciesId = useSelector(getCurrentSpeciesId);
  const dispatch = useDispatch();

  const formikProps = useFormikContext<FormValues>();
  const { values } = formikProps;
  const { saleLots } = values;

  const setPricingType = pricingTypeId => {
    formikProps.setFieldValue(
      "saleLots",
      values.saleLots.map(saleLot => ({
        ...saleLot,
        pricing_type_id: pricingTypeId,
      })),
    );
  };
  const [confirmDeleteSaleLot, setConfirmDeleteSaleLot] = React.useState(null);
  const removeSaleLotAtIndex = index => {
    const nextValue = values.saleLots.filter((s, i) => i !== index);
    // If there is nothing left, add the default.
    const nextOrDefault = nextValue.length === 0 ? [defaultSaleLot] : nextValue;

    formikProps.setFieldValue("saleLots", nextOrDefault);
  };

  const handleClickDelete = (saleLot, index) => {
    if (!saleLot.id) {
      removeSaleLotAtIndex(index);
    } else {
      setConfirmDeleteSaleLot({ saleLot, index });
    }
  };

  const closeConfirmDialog = () => setConfirmDeleteSaleLot(null);

  const handleDelete = () => {
    const { saleLot, index } = confirmDeleteSaleLot;
    dispatch(deleteSaleLot(saleLot));
    removeSaleLotAtIndex(index);
    closeConfirmDialog();
  };

  const isPricingTypeDifferent = uniqBy(saleLots, "pricing_type_id").length > 1;

  const labelOrSpace = (index, label) => {
    // Logic to show/hide/blank a label for each row of the form.
    // Always show the label on the top row.
    if (index === 0) {
      return label;
    }
    // If we have different pricing types, return a space, so the form aligns well
    // (the label for the pricing type will always be included).
    if (isPricingTypeDifferent) {
      return <>&nbsp;</>;
    }
    // Otherwise hide the label.
    return "";
  };

  return (
    <FormCollapse header={<SubtleBadge>Livestock</SubtleBadge>}>
      <Grid xs={12} item>
        <OptionTogglerField
          name="saleLots[0].pricing_type_id"
          options={PricingTypes.all()}
          onChangeExtra={setPricingType}
        />
      </Grid>

      <FieldArray name="saleLots">
        {arrayHelpers => (
          <>
            {saleLots.map((saleLot, i) => (
              <React.Fragment key={saleLot.id || i}>
                <Grid item xs={1}>
                  <Input
                    label={labelOrSpace(i, "Headcount")}
                    name={`saleLots[${i}]quantity`}
                    required={i === 0}
                    type="number"
                  />
                </Grid>
                <Grid item xs={2}>
                  <ControlledLifeCycleInput
                    {...KilogramDisplayGramsInputProps}
                    label={labelOrSpace(i, "Weight (kg)")}
                    name={`saleLots[${i}]total_mass_grams`}
                  />
                </Grid>
                <Grid item xs={2}>
                  <UnitPrice
                    name={`saleLots[${i}]unitPrice`}
                    pricingTypeField={`saleLots[${i}]pricing_type_id`}
                    showLabel={i === 0 || isPricingTypeDifferent}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ProductSelectField
                    ns={`saleLots[${i}]`}
                    hideQuickSelect
                    deploymentId={deploymentId}
                    bestMatchAutoSelect
                    label={labelOrSpace(i, "Description")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <BreedField
                    name={`saleLots[${i}]breed_id`}
                    label={labelOrSpace(i, "Breed")}
                    speciesId={speciesId}
                    deploymentId={deploymentId}
                    showQuickCode
                  />
                </Grid>

                <Grid item xs={1} justifyContent="center" alignItems="center">
                  {/* To make it align nicer on the first row */}
                  {(i === 0 || isPricingTypeDifferent) && <Label>&nbsp;</Label>}
                  <SlimSecondaryButton
                    type="button"
                    fullWidth
                    color="red"
                    disabled={i === 0 && !saleLot.id}
                    onClick={() => handleClickDelete(saleLot, i)}
                  >
                    Delete
                  </SlimSecondaryButton>
                </Grid>
              </React.Fragment>
            ))}

            <Grid item xs={12}>
              <Row justifyEnd>
                <SlimButton
                  onClick={() =>
                    arrayHelpers.push({
                      ...saleLots[saleLots.length - 1],
                      quantity: "",
                      total_mass_grams: "",
                      cents_per_kilo: "",
                      unitPrice: "",
                      id: undefined,
                    })
                  }
                  type="button"
                >
                  Add line
                </SlimButton>
              </Row>
            </Grid>
          </>
        )}
      </FieldArray>

      <NonSkinSummary />
      <ConfirmDialog
        title={createModalTitle("this item")}
        isOpen={confirmDeleteSaleLot}
        onCancel={closeConfirmDialog}
        onDelete={handleDelete}
      />
    </FormCollapse>
  );
};
