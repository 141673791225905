import { validatePIC } from "./PICValidator";

export const DeviceType = {
  CATTLE_EAR: 0,
  CATTLE_RUMEN: 1,
  CATTLE_POST_BREEDER_EAR: 2,
  CATTLE_POST_BREEDER_RUMEN: 3,
  SHEEP: 4,
  SHEEP_POST_BREEDER: 5,
  GOAT: 6,
  GOAT_POST_BREEDER: 7,
  ALPACA: 8,
};

const DeviceTypeLookup = {
  B: DeviceType.CATTLE_EAR,
  C: DeviceType.CATTLE_RUMEN,
  E: DeviceType.CATTLE_POST_BREEDER_EAR,
  F: DeviceType.CATTLE_POST_BREEDER_RUMEN,
  S: DeviceType.SHEEP,
  T: DeviceType.SHEEP_POST_BREEDER,
  K: DeviceType.GOAT,
  L: DeviceType.GOAT_POST_BREEDER,
  A: DeviceType.ALPACA,
};

export function getNvdSheepWarnings(declaration) {
  const nvdErrors = [];
  if (declaration.veterinary_treatment) {
    nvdErrors.push("WHP Warning");
  }
  if (declaration.consumed_material_in_withholding_period) {
    nvdErrors.push("Feed WHP Warning");
  }
  if (declaration.fed_feed_containing_animal_fats) {
    nvdErrors.push("Animal Fat Warning");
  }
  if (declaration.owned_since_birth) {
    nvdErrors.push("Vendor Bred");
  }
  return nvdErrors;
}

export function getNvdCattleWarnings(declaration) {
  const nvdErrors = [];
  // EU cattle may not have treated with hgp on declaration
  if (declaration?.treated_with_hgp) {
    nvdErrors.push("HGP Warning");
  }
  if (declaration.fed_feed_containing_animal_fats) {
    nvdErrors.push("Animal Fat Warning");
  }
  if (declaration.fed_byproduct_stockfeeds) {
    nvdErrors.push("By-product Warning");
  }
  if (declaration.veterinary_treatment) {
    nvdErrors.push("WHP Warning");
  }
  if (declaration.consumed_material_in_withholding_period) {
    nvdErrors.push("Feed WHP Warning");
  }
  if (declaration.spray_drift_risk) {
    nvdErrors.push("Spray Risk");
  }
  if (declaration.chemical_residue_restrictions) {
    nvdErrors.push("ERP Warning");
  }
  if (declaration.owned_since_birth) {
    nvdErrors.push("Vendor Bred");
  }
  return nvdErrors;
}

export function parseNlisId(nlisId) {
  if (!(typeof nlisId === "string" && nlisId.length === 16)) {
    // TODO implement 15 digit NLIS Id - not likely ever going to come across one, though as they stopped manufacturing them ~2000 and it's now 2025
    return null;
  }

  const pic = nlisId.substr(0, 8);
  const manufacturerRaw = nlisId.substr(8, 1);
  const deviceTypeRaw = nlisId.substr(9, 1);
  const yearRaw = nlisId.substr(10, 1);
  const manufacturerNominatedRaw = nlisId.substr(11, 1);
  const uniqueIdRaw = nlisId.substr(12, 4);

  if (!validatePIC(pic)) {
    return null;
  }
  const deviceType = DeviceTypeLookup[deviceTypeRaw];
  if (deviceType === undefined) {
    return null;
  }

  if (!yearRaw.match(/^[A-Z]$/)) {
    return null;
  }

  if (!manufacturerNominatedRaw.match(/^[A-HJ-NP-Z\d]$/)) {
    return null;
  }

  if (!uniqueIdRaw.match(/^\d{4}$/)) {
    return null;
  }

  return {
    pic,
    manufacturerRaw,
    deviceTypeRaw,
    yearRaw,
    manufacturerNominatedRaw,
    uniqueIdRaw,
  };
}
