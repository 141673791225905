import queryString from "query-string";

import { TRADING_TERM } from "constants/actionTypes";

import { serializeTradingTerm } from "lib/serializers/tradingTerms";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/trading-terms/", id, action),
    query: {
      changesSince,
    },
  });

export const TradingTermAction = offlineActionCreator(
  TRADING_TERM,
  serializeTradingTerm,
  urlCreator,
  false,
);
