// -----------------------------------------------
// Agency State Selectors
// -----------------------------------------------
import { EMPTY_OBJECT } from "lib";

export const getAgencies = state => state.agencies.byId;
export const getIsFetchingAgencies = state => state.agencies.isFetching;

// -----------------------------------------------
// Auction Pens State Selectors
// -----------------------------------------------
export const getAuctionPens = state => state.auctionPens.byId;
export const getIsFetchingAuctionPens = state => state.auctionPens.isFetching;

// -----------------------------------------------
// Auth State Selectors
// -----------------------------------------------
export const getAuth = state => state.auth;
export const getAuthentication = state => state.auth.authentication;

export const getIsAuthenticated = state =>
  getAuthentication(state)?.isAuthenticated || false;
export const getIsFetchingAuth = state =>
  getAuthentication(state)?.isFetching || false;
export const getIsFetchingNLISAuth = state =>
  getAuthentication(state).isNLISFetching;
export const getConcurrentUserBlock = state =>
  getAuthentication(state).concurrentUserBlock;
export const getGeoBlock = state => getAuthentication(state).geoBlock;

export const getCurrentUser = state => state.auth.currentUser;
export const getFirstName = state => getCurrentUser(state).first_name;
export const getLastName = state => getCurrentUser(state).last_name;
export const getEmail = state => getCurrentUser(state).email;

export const getActiveRole = state => state.auth?.activeRole || EMPTY_OBJECT;

export const getReportFavourites = state =>
  state.auth?.activeRole.reportFavourites || [];

export const getSaleyardAdminRoles = state => state.auth.saleyardAdmins.byId;
export const getIsFetchingSaleyardAdminRoles = state =>
  state.auth.saleyardAdmins.isFetching;
export const getLivestockAgentRoles = state => state.auth.livestockAgents.byId;
export const getIsFetchingLivestockAgentRoles = state =>
  state.auth.livestockAgents.isFetching;
export const getSaleWatcherRoles = state => state.auth.saleWatchers.byId;
export const getIsFetchingSaleWatcherRoles = state =>
  state.auth.saleWatchers.isFetching;
export const getScaleOperatorRoles = state => state.auth.scaleOperators.byId;
export const getIsFetchingScaleOperatorRoles = state =>
  state.auth.scaleOperators.isFetching;
export const getBusinessUserRoles = state => state.auth.businessUsers.byId;
export const getIsFetchingBusinessUserRoles = state =>
  state.auth.businessUsers.isFetching;

export const getActiveNLISAgentCredentials = state =>
  getActiveRole(state)?.nlis_agent_credentials;

export const getActiveNLISSaleyardCredentials = state =>
  getActiveRole(state)?.nlis_saleyard_credentials;

// -----------------------------------------------
// Bidder Registrations State Selectors
// -----------------------------------------------
export const getBidders = state => state.bidderRegistrations.registrations;
export const getIsFetchingBidders = state =>
  state.bidderRegistrations.isFetching;

// -----------------------------------------------
// Billing Data State Selectors
// -----------------------------------------------

export const getBillingData = state => state.billingData.data;
export const getIsFetchingBillingData = state => state.billingData.isFetching;

// -----------------------------------------------
// Billing Tag State Selectors
// -----------------------------------------------

export const getBillingTags = state => state.billingTags.byId;
export const getIsFetchingBillingTags = state => state.billingTags.isFetching;

// -----------------------------------------------
// Billing Documents State Selectors
// -----------------------------------------------
export const getBillingDocuments = state => state.billingDocuments.byId;
export const getIsFetchingBillingDocuments = state =>
  state.billingDocuments.isFetching;

// -----------------------------------------------
// Billing Document Activity State Selectors
// -----------------------------------------------
export const getBillingDocumentActivity = state =>
  state.billingDocumentActivity.byId;
export const getIsFetchingBillingDocumentActivity = state =>
  state.billingDocumentActivity.isFetching;

// -----------------------------------------------
// Contact Billing Documents State Selectors
// -----------------------------------------------
export const getContactBillingDocuments = state =>
  state.contactBillingDocuments.byId;
export const getIsFetchingContactBillingDocuments = state =>
  state.contactBillingDocuments.isFetching;

// -----------------------------------------------
// Billing Runs State Selectors
// -----------------------------------------------
export const getBillingRuns = state => state.billingRuns.byId;
export const getIsFetchingBillingRuns = state => state.billingRuns.isFetching;

export const getIsFetchingAnyBilling = state =>
  state.billingRuns.isFetching ||
  state.billingDocuments.isFetching ||
  state.ledgerEntries.isFetching;

export const getIsBillingRunCheckingForChanges = state =>
  state.billingRuns.isCheckingForChanges;

// -----------------------------------------------
// Businesses State Selectors
// -----------------------------------------------

export const getBusinesses = state => state.businessesV2.byId;
export const getIsFetchingBusinesses = state => state.businessesV2.isFetching;

// -----------------------------------------------
// Global Businesses State Selectors
// -----------------------------------------------

export const getGlobalBusinesses = state => state.globalBusinesses.byId;
export const getIsFetchingGlobalBusinesses = state =>
  state.globalBusinesses.isFetching;

// -----------------------------------------------
// Carrier Charges State Selectors
// -----------------------------------------------
export const getCarrierCharges = state => state.carrierCharges.charges;

// -----------------------------------------------
// Comments State Selectors
// -----------------------------------------------
export const getComments = state => state.comments.byId;
export const getIsFetchingComments = state => state.comments.isFetching;

// -----------------------------------------------
// Consignments State Selectors
// -----------------------------------------------
export const getConsignments = state => state.consignments.consignments;
export const getVendorSplitConsignments = state =>
  state.consignments.vendorSplitConsignments;
export const getIsFetchingConsignments = state => state.consignments.isFetching;
export const getIsFetchingNVDUploads = state =>
  state.consignments.nvdUploads.isFetching;
export const getNVDUploads = state => state.consignments.nvdUploads.data;

// -----------------------------------------------
// Deployment Attribute State Selectors
// -----------------------------------------------
export const getDeploymentAttributes = state => state.deploymentAttributes;
export const getLabels = state => state.deploymentAttributes.labels;
export const getMarks = state => state.deploymentAttributes.marks;
export const getDeploymentDentition = state =>
  state.deploymentAttributes.dentition;

// -----------------------------------------------
// Deployment State Selectors
// -----------------------------------------------
export const getDeployments = state => state.deployments.byId;
export const getIsFetchingDeployments = state => state.deployments.isFetching;

// -----------------------------------------------
// Drafting Information State Selectors
// -----------------------------------------------

export const getDraftingInformation = state => state.draftingInformation.byId;
export const getIsFetchingDraftingInformation = state =>
  state.draftingInformation.isFetching;

// -----------------------------------------------
// eContract State Selectors
// -----------------------------------------------

export const getIsFetchingEContracts = state => state.eContracts.isFetching;
export const getEContracts = state => state.eContracts.byId;

// -----------------------------------------------
// Emails State Selectors
// -----------------------------------------------

export const getEmails = state => state.email.byId;
export const getEmailFilters = state => state.email.filters;
export const getIsFetchingEmail = state => state.email.isFetching;
export const getEmailLastModified = state => state.email.lastModifiedTimestamp;

// -----------------------------------------------
// ENVDocument State Selectors
// -----------------------------------------------
export const getEnvdDocuments = state => state.envdDocuments.byId;
export const getIsFetchingENVDDocuments = state =>
  state.envdDocuments.isFetching;

// -----------------------------------------------
// Files (Attachments) State Selectors
// -----------------------------------------------
export const getFiles = state => state.files.files;
export const getIsFetchingFiles = state => state.files.isFetching;

// -----------------------------------------------
// Integration Credentials
// -----------------------------------------------
export const getIntegrationCredentials = state =>
  state.integrationCredentials.byId;
export const getIsFetchingIntegrationCredentials = state =>
  state.integrationCredentials.isFetching;

// -----------------------------------------------
// Integration Businesses State Selectors
// -----------------------------------------------
export const getIntegrationBusinesses = state =>
  state.integrationBusinesses.byId;
export const getIsFetchingIntegrationBusinesses = state =>
  state.integrationBusinesses.isFetching;

// -----------------------------------------------
// Ledger Entry State Selectors
// -----------------------------------------------
export const getLedgerEntries = state => state.ledgerEntries.byId;
export const getIsFetchingLedgerEntries = state =>
  state.ledgerEntries.isFetching;

// -----------------------------------------------
// Permission Group State Selectors
// -----------------------------------------------
export const getPermissionGroups = state => state.permissionGroups.byId;
export const getIsFetchingPermissionGroups = state =>
  state.permissionGroups.isFetching;

// -----------------------------------------------
// LivestockAgent State Selectors
// -----------------------------------------------
export const getLivestockAgents = state => state.livestockAgents.byId;
export const getIsFetchingLivestockAgents = state =>
  state.livestockAgents.isFetching;

// -----------------------------------------------
// Master LabelState Selectors
// -----------------------------------------------
export const getMasterLabels = state => state.masterLabels.byId;
export const getIsFetchingMasterLabels = state => state.masterLabels.isFetching;

// -----------------------------------------------
// MLA Supplementary Data
// -----------------------------------------------
export const getMLASupplementaryData = state => state.mlaSupplementaryData.byId;
export const getIsFetchingMLASupplementaryData = state =>
  state.mlaSupplementaryData.isFetching;

// -----------------------------------------------
// Nominations State Selectors
// -----------------------------------------------
export const getNominations = state => state.nominations.byId;
export const getIsFetchingNominations = state => state.nominations.isFetching;
export const getNominationTerms = state => state.nominationTerms.byId;

// -----------------------------------------------
// Checkpoints State Selectors
// -----------------------------------------------
export const getCheckpoints = state => state.checkpoints.byId;
export const getIsFetchingCheckpoints = state => state.checkpoints.isFetching;

// -----------------------------------------------
// Ledger Account State Selectors
// -----------------------------------------------
export const getLedgerAccounts = state => state.ledgerAccounts.byId;
export const getIsFetchingLedgerAccounts = state =>
  state.ledgerAccounts.isFetching;

export const getMasterLedgerAccounts = state => state.masterLedgerAccounts.byId;
export const getIsFetchingMasterLedgerAccounts = state =>
  state.masterLedgerAccounts.isFetching;

// -----------------------------------------------
// Manual Adjustment State Selectors
// -----------------------------------------------
export const getManualAdjustments = state => state.manualAdjustments.byId;
export const getIsFetchingManualAdjustments = state =>
  state.manualAdjustments.isFetching;

// -----------------------------------------------
// Modal Context State Selectors
// -----------------------------------------------
export const getModalContexts = state => state.modals;

// -----------------------------------------------
// Offline State Selectors
// -----------------------------------------------
export const getIsOnline = state => state.offline.online;
export const getChangesOutbox = state => state.offline.outbox;

// -----------------------------------------------
// Payments State Selectors
// -----------------------------------------------
export const getPayments = state => state.payments.byId;
export const getIsFetchingPayments = state => state.payments.isFetching;

// -----------------------------------------------
// Pen Archetype State Selectors
// -----------------------------------------------

export const getPenArchetypes = state => state.penArchetypes.byId;
export const getIsFetchingPenArchetypes = state =>
  state.penArchetypes.isFetching;

// -----------------------------------------------
// Products State Selectors
// -----------------------------------------------

export const getAllProducts = state => state.products.products;

// -----------------------------------------------
// Properties State Selectors
// -----------------------------------------------
export const getProperties = state => state.properties.properties;
export const getIsFetchingProperties = state => state.properties.isFetching;

// -----------------------------------------------
// Reports State Selectors
// -----------------------------------------------
export const getReports = state => state.reports.reports;
export const getIsFetchingReports = state => state.reports.isFetching;

// -----------------------------------------------
// Sale Lots State Selectors
// -----------------------------------------------
export const getSaleLots = state => state.saleLots.saleLots;
export const getVendorSplitSaleLots = state =>
  state.saleLots.vendorSplitSaleLots;
export const getIsFetchingSaleLots = state => state.saleLots.isFetching;
export const getSaleyardScanSaleLots = state =>
  state.saleLots.saleyardScanSaleLots;

// -----------------------------------------------
// Sale Definitions State Selectors
// -----------------------------------------------
export const getSaleTypes = state => state.saleDefinitions.saleTypes;
export const getIsFetchingSaleDefinitions = state =>
  state.saleDefinitions.isFetching;
export const getDefaultRounds = state => state.saleDefinitions.defaultRounds;

// -----------------------------------------------
// Sales State Selectors
// -----------------------------------------------
export const getSales = state => state.sales.byId;
export const getIsFetchingSales = state => state.sales.isFetching;
export const getConsignableSalesIsFetching = state =>
  state.sales.consignableSales.isFetching;
export const getConsignableSales = state => state.sales.consignableSales.data;

// -----------------------------------------------
// SaleyardAdmin State Selectors
// -----------------------------------------------
export const getSaleyardAdmins = state => state.saleyardAdmins.byId;
export const getIsFetchingSaleyardAdmins = state =>
  state.saleyardAdmins.isFetching;

// -----------------------------------------------
// SaleyardAdmin State Selectors
// -----------------------------------------------
export const getSaleyards = state => state.saleyards.byId;
export const getIsFetchingSaleyards = state => state.saleyards.isFetching;

// -----------------------------------------------
// SavedViews State Selectors
// -----------------------------------------------
export const getSavedViews = state => state.savedViews.byId;
export const getIsFetchingSavedViews = state => state.savedViews.isFetching;

// -----------------------------------------------
// Settings State Selectors
// -----------------------------------------------
export const getSettings = state => state.settings;
export const getRefreshIntervalMs = state => state.system.refreshIntervalMs;
export const getServerTimeDriftMs = state => state.system.serverTimeDriftMs;
export const getGlobalAnnouncement = state => state.system.globalAnnouncement;
export const getBulkEmailLimit = state => state.system.bulkEmailLimit || 0;

// -----------------------------------------------
// Scans State Selectors
// -----------------------------------------------
export const getScans = state => state.scanners.scans;
export const getUnassignedScans = state => state.scanners.unassignedScans;
export const getImportedScans = state => state.scanners.importedScans;
export const getImportPendingScans = state => state.scanners.importPendingScans;
export const getUploadedScans = state => state.scanners.uploadedScans;
export const getManualInputScans = state => state.scanners.manualInputScans;
export const getScanBuffer = state => state.scanners.scanBuffer;
export const getConnectedDeviceId = state => state.scanners.connectedDeviceId;
export const getConnectedScales = state =>
  state.scanners.context.connectedScales;
export const getConnectedPLCs = state => state.scanners.context.connectedPLCs;
export const getConnectedMtHostSessions = state =>
  state.scanners.context.connectedMtHostSessions.byDeviceId;
export const getIsImportingMtHostSessions = state =>
  state.scanners.context.connectedMtHostSessions.isImporting;

export const getAvailableDevices = state => state.scanners.availableDevices;
export const getAvailablePLCs = state => state.scanners.availablePLCs;
export const getWeightHistory = state => state.scanners.weightHistory;
export const getIsHubConnected = state => state.scanners.isHubConnected;
export const getIsImportRunning = state => state.scanners.importRunning;
export const getIsFetchingScans = state => state.scanners.isFetching;

// -----------------------------------------------
// NLIS Files State Selectors
// -----------------------------------------------

export const getNLISFiles = state => state.nlisFiles;
export const getSellFiles = state => state.nlisFiles.sellFiles.byId;
export const getIsFetchingSellFiles = state =>
  state.nlisFiles.sellFiles.isFetching;
export const getIsFetchingSightingFiles = state =>
  state.nlisFiles.sightingFiles.isFetching;

export const getTakeCorrectionFiles = state =>
  state.nlisFiles.takeCorrections.byId;

export const getTakeFiles = state => state.nlisFiles.takeFiles.byId;
export const getIsFetchingTakeFiles = state =>
  state.nlisFiles.takeFiles.isFetching;
export const getTakeReversals = state => state.nlisFiles.takeReversals.byId;
export const getIsFetchingTakeReversals = state =>
  state.nlisFiles.takeReversals.isFetching;
export const getSellReversals = state => state.nlisFiles.sellReversals.byId;
export const getIsFetchingSellReversals = state =>
  state.nlisFiles.sellReversals.isFetching;

export const getSightingFiles = state => state.nlisFiles.sightingFiles.byId;
export const getP2PFiles = state => state.nlisFiles.p2pFiles.byId;
export const getP2PReversals = state => state.nlisFiles.p2pReversals.byId;
export const getIsFetchingP2PReversals = state =>
  state.nlisFiles.p2pReversals.isFetching;
export const getNlisMessageGroups = state => state.nlisMessageGroups.byId;

// -----------------------------------------------
// RuleBook State Selectors
// -----------------------------------------------
export const getMasterRuleBooks = state => state.masterRuleBooks.byId;
export const getIsFetchingMasterRuleBooks = state =>
  state.masterRuleBooks.isFetching;
export const getRuleBooks = state => state.ruleBooks.byId;
export const getIsFetchingRuleBooks = state => state.ruleBooks.isFetching;

// -----------------------------------------------
// Rule State Selectors
// -----------------------------------------------
export const getMasterRules = state => state.masterRules.byId;
export const getIsFetchingMasterRules = state => state.masterRules.isFetching;
export const getRules = state => state.rules.byId;
export const getIsFetchingRules = state => state.rules.isFetching;

// -----------------------------------------------
// Single Weigh State Selectors
// -----------------------------------------------
export const getSingleWeighs = state => state.singleWeighs.byId;
export const getIsFetchingSingleWeighs = state => state.singleWeighs.isFetching;
export const getSingleWeighsContext = state => state.singleWeighs.context;
export const getCurrentSingleWeighId = state =>
  state.singleWeighs.currentSingleWeighId;

// -----------------------------------------------
// Species Attribute State Selectors
// -----------------------------------------------
export const getSpeciesAttributes = state => state.speciesAttributes;
export const getSexes = state => state.speciesAttributes.sexes;
export const getAges = state => state.speciesAttributes.ages;
export const getBreeds = state => state.speciesAttributes.breeds;
export const getCategories = state => state.speciesAttributes.categories;
export const getExemptions = state => state.speciesAttributes.exemptions;
export const getGrades = state => state.speciesAttributes.grades;
export const getRounds = state => state.speciesAttributes.rounds.byId;
export const getSpecies = state => state.speciesAttributes.species;
export const getDentition = state => state.speciesAttributes.dentition;
export const getIsFetchingRounds = state =>
  state.speciesAttributes.rounds.isFetching;
export const getIsFetchingSpecies = state =>
  // WORKAROUND: species doesn't yet store whether it is fetching or not
  Object.values(state.speciesAttributes.species).length === 0;

export const selectNull = () => null;

// -----------------------------------------------
// Sale Subtypes State Selectors
// -----------------------------------------------
export const getSaleSubTypes = state => state.saleSubTypes.byId;

// -----------------------------------------------
// User Level State Selectors
// -----------------------------------------------
export const getUserLevels = state => state.userLevels.byId;
export const getIsFetchingUserLevels = state => state.userLevels.isFetching;

// -----------------------------------------------
// Vendor Commission Bands State Selectors
// -----------------------------------------------
export const getVendorCommissionBands = state =>
  state.vendorCommissionBands.byId;
export const getIsFetchingVendorCommissionBands = state =>
  state.vendorCommissionBands.isFetching;

// -----------------------------------------------
// Interest State Selectors
// -----------------------------------------------
export const getInterestSettings = state => state.interestSettings.byId;
export const getIsFetchingInterestSettings = state =>
  state.interestSettings.isFetching;

// -----------------------------------------------
// Receival Lot State Selectors
// -----------------------------------------------
export const getReceivalLots = state => state.receivalLots.byId;
export const getIsFetchingReceivalLots = state => state.receivalLots.isFetching;

// -----------------------------------------------
// Pen Scan Lot State Selectors
// -----------------------------------------------
export const getPenScanLots = state => state.penScanLots.byId;
export const getIsFetchingPenScanLots = state => state.penScanLots.isFetching;

// -----------------------------------------------
// Report Job State Selectors
// -----------------------------------------------

export const getReportJobs = state => state.reportJobs.byId;
export const getIsFetchingReportJobs = state => state.reportJobs.isFetching;

// -----------------------------------------------
// SundryTemplate State Selectors
// -----------------------------------------------
export const getSundryTemplates = state => state.sundryTemplates.byId;
export const getIsFetchingSundryTemplates = state =>
  state.sundryTemplates.isFetching;

// -----------------------------------------------
// Trading Term State Selectors
// -----------------------------------------------
export const getTradingTerms = state => state.tradingTerms.byId;
export const getIsFetchingTradingTerms = state => state.tradingTerms.isFetching;

// -----------------------------------------------
// Weight Range State Selectors
// -----------------------------------------------
export const getWeightRanges = state => state.weightRanges.byId;

export const getDressingRanges = state => state.dressingRanges.byId;

// -----------------------------------------------
// Weigh Lot State Selectors
// -----------------------------------------------
export const getWeighLots = state => state.weighLots.byId;
export const getIsFetchingWeighLots = state => state.weighLots.isFetching;

// -----------------------------------------------
// Weigh Lot Scan State Selectors
// -----------------------------------------------
export const getWeighLotScans = state => state.weighLotScans.byId;
export const getIsFetchingWeighLotScans = state =>
  state.weighLotScans.isFetching;

// -----------------------------------------------
// Vendor Split State Selectors
// -----------------------------------------------
export const getDefaultVendorSplits = state => state.vendorSplits.default.byId;
export const getIsFetchingDefaultVendorSplits = state =>
  state.vendorSplits.default.isFetching;

export const getSaleVendorSplits = state => state.vendorSplits.sale.byId;
export const getIsFetchingSaleVendorSplits = state =>
  state.vendorSplits.sale.isFetching;

// -----------------------------------------------
// System Selectors
// -----------------------------------------------
export const getAppUpdateStatus = state => state.system.appUpdateStatus;
