import React, { memo } from "react";

import { useSelector } from "react-redux";

import { SaleTypes } from "constants/sale";

import { getCurrentSale } from "selectors";

import { useIsFullScreenWithRouteCheck } from "hooks";

import ScaleOperatorTabs from "./BobbyCalfSaleTabs";
import ClearingSaleTabs from "./ClearingSaleTabs";
import LivestockSaleTabs from "./LivestockSaleTabs";
import PrivateSaleTabs from "./PrivateSaleTabs";
import ReferenceSaleTabs from "./ReferenceSaleTabs";
import SundrySaleTabs from "./SundrySaleTabs";

function SaleTabsComponent() {
  const { sale_type: saleType } = useSelector(getCurrentSale);

  const isFullScreen = useIsFullScreenWithRouteCheck();

  if (isFullScreen) {
    return null;
  }

  switch (saleType) {
    case SaleTypes.CLEARING:
      return <ClearingSaleTabs />;
    case SaleTypes.BOBBYCALF:
      return <ScaleOperatorTabs />;
    case SaleTypes.OVER_HOOKS:
      return <PrivateSaleTabs />;
    case SaleTypes.PADDOCK:
      return <PrivateSaleTabs />;
    case SaleTypes.SUNDRY_SALE:
      return <SundrySaleTabs />;
    case SaleTypes.REFERENCE_SALE:
      return <ReferenceSaleTabs />;
    default:
      return <LivestockSaleTabs />;
  }
}

export default memo(SaleTabsComponent);
