import {
  DELETE_SALE_LOT_FROM_SOCKET,
  DELETE_SALE_LOT_NO_LONGER_EXISTS,
  GET_SALE_LOT_SUCCESS,
  IMPORT_AUCTIONS_PLUS_CSV,
  SALE_LOT,
} from "constants/actionTypes";

export function receiveSaleLot(saleLot) {
  return {
    type: GET_SALE_LOT_SUCCESS,
    saleLot,
  };
}

export const deleteSaleLotFromSocket = message => ({
  type: DELETE_SALE_LOT_FROM_SOCKET,
  meta: {
    saleLot: {
      id: message.id,
    },
  },
});

export const deleteSaleLotNoLongerExists = id => ({
  type: DELETE_SALE_LOT_NO_LONGER_EXISTS,
  meta: {
    saleLot: {
      id,
    },
  },
});

export const importAuctionsPlusCSV = payload => ({
  type: IMPORT_AUCTIONS_PLUS_CSV,
  payload,
});

export const addSaleLotWithPens = (saleLotId, payload) => ({
  type: SALE_LOT.CREATE.ACTION,
  saleLotId,
  payload,
});

export const splitSingleWeighedSaleLot = (
  saleLotId,
  shouldSplitToMultipleLots,
  shouldSubtractFromLotWeight,
) => ({
  type: SALE_LOT.SPLIT_SINGLE_WEIGHED.ACTION,
  saleLotId,
  shouldSplitToMultipleLots,
  shouldSubtractFromLotWeight,
});

export const patchSaleLot = (patch, options) => ({
  type: SALE_LOT.UPDATE.ACTION,
  patch,
  options,
});
export const setSaleLotOfflineValues = (id, values) => ({
  type: SALE_LOT.SET_OFFLINE_VALUES.ACTION,
  id,
  values,
});

export const sellWithBidderRegistration = (patch, options) => ({
  type: SALE_LOT.SELL_WTH_BIDDER_REGISTRATION.ACTION,
  patch,
  options,
});
