import { BILLING_DOCUMENT_ACTIVITY } from "constants/actionTypes";

import { billingDocumentUrlCreator } from "./billingDocuments";
import { offlineActionCreator } from "./lib";

const billingDocumentActivityUrlCreator = ({ changesSince, id, userRole }) => {
  return billingDocumentUrlCreator({
    action: "activity",
    changesSince,
    id,
    userRole,
  });
};

export const BillingDocumentActivityAction = offlineActionCreator(
  BILLING_DOCUMENT_ACTIVITY,
  {},
  billingDocumentActivityUrlCreator,
  false,
);
