import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import styled from "styled-components/macro";

import { setCurrentLivestockSaleId } from "actions";

import { patchDeploymentSale } from "actions/offline/deploymentSale";

import Badge from "components/Badge";
import { ClickableCommentIcon } from "components/Comments/Icon";
import { PreventPropagation } from "components/Events";
import { Column, Row } from "components/Layout";
import Select from "components/SearchableSelector/ReactSelect";
import { Text } from "components/Text";

import { CommentTypes } from "constants/comments";
import { SaleStatus, SaleStatusColors, SaleStatusLabels } from "constants/sale";
import { getIconForSpeciesId } from "constants/species";

import { getDateDrivenSaleStatus } from "lib/sale";
import { formatHeaderDateString } from "lib/timeFormats";

import { getIsLivestockAgent, getSaleById } from "selectors";

const { SingleValue } = components;

const IconContainer = styled.div`
  font-size: 20px;
  width: 42px;
  height: 42px;
  background: ${({ theme }) => theme.colors.grayDisabled};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
`;

const SaleRowColumns = styled.div`
  font-size: 14px;
  font-weight: normal;
  margin: auto 12px;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}px) {
    margin: auto 6px;
  }
  color: ${({ theme }) => theme.colors.gray40};
  flex-basis: 0;
  flex-grow: 1;
  justify-content: space-between;
`;

const SaleRow = styled(Row)`
  box-shadow: ${({ theme }) => theme.shadows[1]};
  padding: 10px;
  margin: ${({ isMobile }) => (!isMobile ? "4px 0px 4px" : "2px -20px 2px")};
  position: relative;
  align-items: center;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.primaryHighlight}20;
  }
`;

const BlueBar = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  width: 10px;
  margin-right: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;

const StatusDropdown = styled(Select)`
  .react-select__value-container {
    padding: 0px;
  }
  .react-select__control {
    width: ${({ isMobile }) => (isMobile ? "105px" : "110px")};
  }
  .react-select__single-value {
    overflow: auto;
    max-width: none;
  }
  .react-select__indicator {
    padding: 0px;
  }
`;

const useStyles = makeStyles(theme => ({
  gridItem: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "none !important",
      flexBasis: "auto !important",
    },
  },

  gridStatus: {
    [theme.breakpoints.down("xs")]: {
      width: "34%",
    },
  },
  gridSaleDate: {
    [theme.breakpoints.down("xs")]: {
      width: "17%",
    },
  },
  gridSaleType: {
    [theme.breakpoints.down("xs")]: {
      width: "20%",
    },
  },
  gridSaleCode: {
    [theme.breakpoints.down("xs")]: {
      width: "17%",
    },
  },
  gridSaleComment: {
    [theme.breakpoints.down("xs")]: {
      width: "12%",
    },
  },
}));

const DashboardSaleCard = React.memo(({ saleId, isMobile }) => {
  const dispatch = useDispatch();
  const livestockSale = useSelector(getSaleById(saleId));
  const saleDate = new Date(livestockSale.date);
  const namedDate = saleDate ? formatHeaderDateString(saleDate) : "";
  const saleTitle = livestockSale.sale_title || livestockSale.saleyard_name;
  const saleStatus = getDateDrivenSaleStatus(saleDate);

  const classes = useStyles();

  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const deploymentSale = livestockSale.deployment_sales[0] || {};

  const selectedValue = deploymentSale?.status ? deploymentSale.status : null;

  const goToSale = () =>
    dispatch(setCurrentLivestockSaleId(saleId, true, false));

  const BadgeValue = ({ data, ...props }) => {
    const { label, value } = data;
    return (
      <SingleValue {...props}>
        <Badge
          width="84"
          textAlign="center"
          display="block"
          margin="auto"
          color={SaleStatusColors[value || saleStatus]}
        >
          {label}
        </Badge>
      </SingleValue>
    );
  };

  const handleSelectChange = option => {
    const updatedDeploymentSale = {
      status: option.value,
    };

    dispatch(
      patchDeploymentSale(
        updatedDeploymentSale,
        deploymentSale.deployment_sale_id,
        livestockSale.livestocksale_id,
      ),
    );
  };

  const handleParentClick = event => {
    if (!event.defaultPrevented) {
      goToSale();
    }
  };

  const StatusSelect = () => {
    const options = [
      { value: null, label: SaleStatusLabels[saleStatus] },
      {
        value: SaleStatus.COMPLETED,
        label: SaleStatusLabels[SaleStatus.COMPLETED],
      },
    ];

    const selectedOption = options.find(o => o.value === selectedValue);

    return (
      <PreventPropagation>
        <StatusDropdown
          options={options}
          components={{
            SingleValue: BadgeValue,
          }}
          value={selectedOption}
          onChange={handleSelectChange}
          isMobile={isMobile}
        />
      </PreventPropagation>
    );
  };

  return (
    <Column data-tour="saleCard">
      <SaleRow
        data-tour={livestockSale.sale_code}
        sale={livestockSale}
        isMobile={isMobile}
        onClick={handleParentClick}
      >
        {!isMobile && <BlueBar />}
        <IconContainer isMobile={isMobile}>
          <FontAwesomeIcon
            icon={getIconForSpeciesId(livestockSale?.species_id)}
          />
        </IconContainer>
        <Grid alignItems="center" container>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Text marginHorizontal={1} fontSize="charlie">
              {saleTitle}
            </Text>
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            md={2}
            lg={2}
            className={`${classes.gridSaleDate} ${classes.gridItem}`}
          >
            <SaleRowColumns>{namedDate}</SaleRowColumns>
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            md={2}
            lg={2}
            className={`${classes.gridSaleType} ${classes.gridItem}`}
          >
            <SaleRowColumns>{livestockSale.sale_type}</SaleRowColumns>
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            md={1}
            lg={1}
            className={`${classes.gridSaleCode} ${classes.gridItem}`}
          >
            <SaleRowColumns>{livestockSale.sale_code}</SaleRowColumns>
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            className={`${classes.gridSaleComment} ${classes.gridItem}`}
          >
            <SaleRowColumns>
              {isLivestockAgent && (
                <PreventPropagation>
                  <ClickableCommentIcon
                    commentType={CommentTypes.DEPLOYMENT_SALE}
                    commentTypeId={deploymentSale.deployment_sale_id}
                    returnTo={window.location.hash}
                  />
                </PreventPropagation>
              )}
            </SaleRowColumns>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={3}
            lg={3}
            className={`${classes.gridStatus} ${classes.gridItem}`}
          >
            <SaleRowColumns>
              {isLivestockAgent ? (
                <StatusSelect />
              ) : (
                <Badge
                  width="90"
                  display="block"
                  margin="auto"
                  textAlign="center"
                  color={SaleStatusColors[saleStatus]}
                >
                  {SaleStatusLabels[saleStatus]}
                </Badge>
              )}
            </SaleRowColumns>
          </Grid>
        </Grid>
      </SaleRow>
    </Column>
  );
});

export default DashboardSaleCard;
