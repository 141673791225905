import reduceReducers from "reduce-reducers";

import { BILLING_DOCUMENT_ACTIVITY } from "constants/actionTypes";

import { apiModelOfflineFetchReducer } from "lib/reducers";
import { deserializeBillingDocumentActivity } from "lib/serializers/billingDocumentActivity";

const fetch = apiModelOfflineFetchReducer(BILLING_DOCUMENT_ACTIVITY, {
  deserializer: deserializeBillingDocumentActivity,
  clearOnRequest: true,
});

const billingDocumentActivityReducer = reduceReducers(fetch);

export default billingDocumentActivityReducer;
