import React from "react";
import { useSelector } from "react-redux";
import { SelectField, withNamespace } from "components/Form/FormikControls";

import { getSpeciesAttributeOptions } from "selectors";

interface AnimalHealthFieldProps {
  label?: string;
  name?: string;
  ns?: string;
  isClearable?: boolean;
  disabled?: boolean;
}
export function AnimalHealthField(
  props: AnimalHealthFieldProps,
): React.JSX.Element {
  const {
    ns,
    name = "category_id",
    label = "Animal Health",
    isClearable = false,
    disabled = false,
  } = props;

  const { categories } = useSelector(getSpeciesAttributeOptions);

  return (
    <SelectField
      label={label}
      name={withNamespace(ns, name)}
      options={categories}
      isClearable={isClearable}
      disabled={disabled}
      menuPortalTarget={document.body}
    />
  );
}
