import React from "react";

import * as Yup from "yup";
import { bulkUpdateBillingDocuments } from "actions";

import { BillingDocumentStatusOptions } from "constants/billingDocuments";
import { SelectField } from "components/Form/FormikControls";
import { DateInput } from "components/Form";
import { BulkUpdateOptionalFieldsModal } from "components/BulkUpdateOptionalFieldsModal/Modal";
import { getCheckboxFieldName } from "components/BulkUpdateOptionalFieldsModal/util";
import {
  BulkUpdateBillingDocumentModalProps,
  BulkUpdateFieldType,
} from "components/BulkUpdateOptionalFieldsModal/types";

const validationSchema = Yup.object().shape({
  [getCheckboxFieldName("status")]: Yup.boolean(),
  status: Yup.string().when(getCheckboxFieldName("status"), {
    is: true,
    then: Yup.string().required("Status is required"),
  }),
  [getCheckboxFieldName("dueDate")]: Yup.boolean(),
  dueDate: Yup.date().when(getCheckboxFieldName("dueDate"), {
    is: true,
    then: Yup.date().required("Due Date is required"),
  }),
});

const bulkUpdateFields: BulkUpdateFieldType[] = [
  {
    fieldName: "status",
    Component: SelectField,
    componentProps: {
      options: BillingDocumentStatusOptions,
      menuPortalTarget: document.body,
      label: "Status",
      required: true,
    },
  },

  {
    fieldName: "dueDate",
    Component: DateInput,
    componentProps: {
      label: "Due Date",
      required: true,
    },
  },
];

function BulkUpdateBillingDocumentModal(
  props: BulkUpdateBillingDocumentModalProps,
): React.JSX.Element {
  const { billingDocumentIds, onClose, initialValues } = props;
  return (
    <BulkUpdateOptionalFieldsModal
      onClose={onClose}
      modelIds={billingDocumentIds}
      modelName="Billing Document"
      bulkUpdateActionCreator={bulkUpdateBillingDocuments}
      fields={bulkUpdateFields}
      initialValues={initialValues}
      validationSchema={validationSchema}
    />
  );
}

export default BulkUpdateBillingDocumentModal;
