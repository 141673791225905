import { isArray, isEmpty, orderBy } from "lodash";
import { createSelector } from "reselect";

import {
  BillingDocumentException,
  BillingDocumentStatus,
  BillingDocumentType,
  BillingWarningsWhiteList,
} from "constants/billingDocuments";
import { IntegrationTypes } from "constants/integrations";

import { EMPTY_ARRAY, EMPTY_OBJECT } from "lib";

import { billingDocumentActivityTitleAndColorMap } from "lib/billingDocumentActivity";
import { getAbnWarningsListFromBusiness } from "lib/businesses";

import {
  createLookupCombiner,
  createLookupSelectors,
  getBillingDocumentActivity,
  getBillingDocuments,
  getBusinesses,
  getLedgerEntries,
  selectBillingLedgerEntryIdsByBillingDocumentIdLookup,
  selectIsReadOnlyByBillingRunId,
} from "selectors";

import { selectShouldChargeGSTByBusinessIdLookup } from "selectors/businesses";

import { selectActiveInterestRateSettingsByBusinessIdLookup } from "./interest";

export const getBillingDocumentById = billingDocumentId => state =>
  getBillingDocuments(state)[billingDocumentId] || null;

export const NotSetBusiness = {
  name: "(Not Set)",
};
export const UnknownBusiness = {
  name: "(Unknown Business)",
};

export const selectBillingDocumentOptions = createSelector(
  [getBillingDocuments, getBusinesses],
  (billingDocumentByIdLookup, businessByIdLookup) =>
    Object.values(billingDocumentByIdLookup).map(billingDocument => {
      const issuerBusiness = billingDocument.issuerBusinessId
        ? businessByIdLookup[billingDocument.issuerBusinessId] ||
          UnknownBusiness
        : NotSetBusiness;
      const recipientBusiness = billingDocument.recipientBusinessId
        ? businessByIdLookup[billingDocument.recipientBusinessId] ||
          UnknownBusiness
        : NotSetBusiness;
      const fromBusiness =
        billingDocument.type === BillingDocumentType.INVOICE
          ? issuerBusiness
          : recipientBusiness;
      const toBusiness =
        billingDocument.type === BillingDocumentType.INVOICE
          ? recipientBusiness
          : issuerBusiness;
      return {
        label: `${billingDocument.type} ${billingDocument.number}: ${fromBusiness.name} -> ${toBusiness.name}`,
        value: billingDocument.id,
      };
    }),
);

const ledgerEntryByLedgerEntryIdReducer = (ledgerEntryIds, ledgerEntryLookup) =>
  ledgerEntryIds.map(billingLedgerEntryId => ({
    ledgerEntry: ledgerEntryLookup[billingLedgerEntryId],
  }));

export const [selectLedgerEntriesByDocumentId, getLedgerEntriesByDocumentId] =
  createLookupSelectors(
    [selectBillingLedgerEntryIdsByBillingDocumentIdLookup, getLedgerEntries],
    createLookupCombiner(ledgerEntryByLedgerEntryIdReducer),
    EMPTY_ARRAY,
  );

export const [
  selectIsReadOnlyByBillingDocumentId,
  getIsReadOnlyByBillingDocumentId,
] = createLookupSelectors(
  [getBillingDocuments, selectIsReadOnlyByBillingRunId],
  createLookupCombiner(
    (billingDocument, isReadOnlyByBillingRunIdLookup) =>
      isReadOnlyByBillingRunIdLookup[billingDocument.billingRunId],
  ),
);

export const selectWarningsByBillingDocumentIdLookup = createSelector(
  [getBillingDocuments, getBusinesses, selectShouldChargeGSTByBusinessIdLookup],
  (
    billingDocumentByIdLookup,
    businessByIdLookup,
    shouldChargeGSTByBusinessIdLookup,
  ) => {
    return Object.entries(billingDocumentByIdLookup).reduce(
      (acc, [billingDocumentId, billingDocument]) => {
        const warningsList = [];

        if (billingDocument.type === BillingDocumentType.RCTI) {
          const vendor =
            businessByIdLookup[billingDocument.recipientBusinessId] || {};

          const { bankingDetails } = vendor;

          const vendorAbnWarningsList = getAbnWarningsListFromBusiness(vendor);

          if (vendorAbnWarningsList.length) {
            vendorAbnWarningsList.forEach(warning => {
              warningsList.push(warning);
            });
          }

          if (isEmpty(bankingDetails)) {
            warningsList.push(
              BillingDocumentException.VENDOR_MISSING_BANKING_DETAILS,
            );
          }

          // Show any vendor where the GST status is unknown:
          // true - charging gst.
          // false - not charging gst.
          // anything else - unknown status.
          if (
            typeof shouldChargeGSTByBusinessIdLookup[vendor?.id] !== "boolean"
          ) {
            warningsList.push(
              BillingDocumentException.BUSINESSES_WITH_UNKNOWN_TAX_STATUS,
            );
          }
          // Do not allow business to invoice itself
          if (
            billingDocument.recipientBusinessId ===
              billingDocument.issuerBusinessId &&
            billingDocument.totalAmountDollars
          ) {
            warningsList.push(BillingDocumentException.MISSING_INVOICE_TO);
          }
        }
        if (billingDocument.exportErrors) {
          warningsList.push(billingDocument.exportErrors);
        }
        if (billingDocument.exportWarnings) {
          warningsList.push(billingDocument.exportWarnings);
        }

        acc[billingDocumentId] = warningsList;
        return acc;
      },
      {},
    );
  },
);

export const selectBusinessForBillingDocumentByIdLookup = createSelector(
  [getBusinesses, getBillingDocuments],
  (businessByIdLookup, billingDocumentByIdLookup) =>
    Object.values(billingDocumentByIdLookup).reduce((acc, billingDocument) => {
      const issuerBusiness = billingDocument.issuerBusinessId
        ? businessByIdLookup[billingDocument.issuerBusinessId] ||
          UnknownBusiness
        : NotSetBusiness;
      const recipientBusiness = billingDocument.recipientBusinessId
        ? businessByIdLookup[billingDocument.recipientBusinessId] ||
          UnknownBusiness
        : NotSetBusiness;
      const business =
        billingDocument.type === BillingDocumentType.INVOICE
          ? issuerBusiness
          : recipientBusiness;
      acc[billingDocument.id] = business;
      return acc;
    }, {}),
);

export const selectCanSendToXeroByBillingDocumentIdLookup = createSelector(
  [
    selectWarningsByBillingDocumentIdLookup,
    getBillingDocuments,
    selectBusinessForBillingDocumentByIdLookup,
  ],
  (
    warningsByBillingDocumentIdLookup,
    billingDocumentByIdLookup,
    businessForBillingDocumentByIdLookup,
  ) => {
    return Object.entries(billingDocumentByIdLookup).reduce(
      (acc, [billingDocumentId, billingDocument]) => {
        const business =
          businessForBillingDocumentByIdLookup[billingDocumentId];
        const integrationBusiness = business.integrationBusinesses?.find(
          ib => ib.type === IntegrationTypes.Xero,
        );
        const warnings = warningsByBillingDocumentIdLookup[billingDocumentId];

        const filteredWarnings = warnings.filter(
          warning => !BillingWarningsWhiteList.includes(warning),
        );

        const canSend =
          billingDocument.status === BillingDocumentStatus.APPROVED &&
          !isEmpty(integrationBusiness) &&
          isEmpty(filteredWarnings);

        acc[billingDocumentId] = canSend;
        return acc;
      },
      {},
    );
  },
);

export const selectInterestSetttingsByBillingDocumentIdLookup = createSelector(
  [
    getBillingDocuments,
    selectActiveInterestRateSettingsByBusinessIdLookup,
    selectBusinessForBillingDocumentByIdLookup,
  ],
  (
    billingDocumentByIdLookup,
    interestSettingsByBusinessIdLookup,
    businessByBillingDocumentIdLookup,
  ) => {
    return Object.values(billingDocumentByIdLookup).reduce(
      (acc, billingDocument) => {
        const business = businessByBillingDocumentIdLookup[billingDocument.id];
        const interestSettings =
          interestSettingsByBusinessIdLookup[business.id]?.[0] || EMPTY_OBJECT;

        acc[billingDocument.id] = interestSettings;
        return acc;
      },
      {},
    );
  },
);

export const [
  selectSortedBillingDocumentActivityByIdLookup,
  getSortedBillingDocumentActivityById,
] = createLookupSelectors(
  [getBillingDocumentActivity],
  createLookupCombiner(activityData => {
    const billingDocumentActivityDataList = [];

    const addActivity = (key, activity, title) => {
      billingDocumentActivityDataList.push({
        key,
        title,
        ...activity,
      });
    };
    const documentSnapshots = activityData.activity;
    documentSnapshots.forEach(documentSnapshot => {
      Object.entries(documentSnapshot).forEach(([key, activityData]) => {
        if (activityData) {
          const title = billingDocumentActivityTitleAndColorMap[key]?.title;
          // activity data shapes differ as we are collecting
          // both one to one fields as well as many to many.
          // an example of an array is a list of payments
          // an example of an object is a single linked document
          if (isArray(activityData) && activityData.length > 0) {
            activityData.map(activityDatum =>
              addActivity(key, activityDatum, title),
            );
          }
          if (typeof activityData === "object" && !isArray(activityData)) {
            addActivity(key, activityData, title);
          }
        }
      });
    });
    return orderBy(
      billingDocumentActivityDataList,
      ["date", "title"],
      ["desc", "desc"],
    );
  }),
);
