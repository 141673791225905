import React from "react";

import { Divider } from "@material-ui/core";
import { uniq } from "lodash";
import styled from "styled-components/macro";

import { SecondaryButton } from "components/Form";
import {
  DialogContent,
  DialogTitle,
  ZoomyDialog,
  DialogActions,
} from "components/MaterialDialog";

import { findErrorMessagesFromResponse } from "lib/errors";

import { ImportStatus } from "./constants";

const NoBulletListItem = styled.li`
  list-style-type: none;
`;

export function ImportSummaryModal(props) {
  const { action, iterateErrorMessage, errors, type, onClose } = props;
  const listOfErrors = errors;

  if (iterateErrorMessage) {
    if (action?.payload?.errors) {
      listOfErrors.push(
        ...uniq(findErrorMessagesFromResponse(action.payload.errors, [], [])),
      );
    } else {
      // If comes back with a non validation issue on the backend then we hit here
      // Which means it is probably on our end, not theirs.
      listOfErrors.push(
        "An unknown error has occurred during the Import. Please contact support.",
      );
    }
  }

  return (
    <ZoomyDialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>Import {type}</DialogTitle>
      <DialogContent dividers>
        {type === ImportStatus.WARNING ? (
          <div>
            The Import was successful, however, we discovered invalid or
            incomplete data within the file. This data has been omitted. We have
            created the Sale Lots and Consignment relating to that data, but
            suggest you review it thoroughly.
          </div>
        ) : (
          <div>
            There was a problem trying to complete the Import. Please try to
            address the following issues and re-upload the file.
          </div>
        )}
        <Divider />
        <ul>
          {listOfErrors.map(error => (
            <NoBulletListItem key={error}>{error}</NoBulletListItem>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Close</SecondaryButton>
      </DialogActions>
    </ZoomyDialog>
  );
}
