import React from "react";

import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  styled as materialStyling,
  Tooltip as MuiTooltip,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import styled from "styled-components/macro";

import { Column } from "components/Layout";

import { colors, space } from "constants/theme";

export const ToolTipIcon = styled(FontAwesomeIcon).attrs({
  icon: faQuestionCircle,
  size: "1x",
})(({ theme }) => `color: ${theme.colors.primary};`);

export const ToolTipIconWrapper = styled.div(
  ({ iconPadding }) => `
    display: inline-block;
  
    /* increase the target size for fat fingers */
    padding: ${iconPadding}px;
  
    /* but don't increase the element size */
    margin: -${iconPadding}px;
  `,
);

const StyledTooltip = materialStyling(({ className, ...props }) => (
  <MuiTooltip
    {...props}
    ref={props.innerRef}
    arrow
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${"MuiTooltip-tooltip"}`]: {
    maxWidth: "none",
    background: colors.materialToolTipBackground,
  },
}));

export const Tooltip = props => {
  const {
    title,
    clickable,
    onClose,
    arrow,
    placement,
    children,
    iconPadding = space[2],
    PopperProps = {},
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      {!clickable ? (
        <MuiTooltip
          title={title}
          enterTouchDelay={150}
          leaveTouchDelay={2000}
          arrow={arrow}
          placement={placement}
          PopperProps={PopperProps}
        >
          {/* Tooltip requires a HTML element as it's first (and only) child */}
          <ToolTipIconWrapper iconPadding={iconPadding}>
            {children || <ToolTipIcon />}
          </ToolTipIconWrapper>
        </MuiTooltip>
      ) : (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <ToolTipIconWrapper iconPadding={iconPadding}>
            <StyledTooltip
              arrow={arrow}
              title={title}
              onClose={onClose}
              open={open}
              placement={placement}
              interactive
              PopperProps={PopperProps}
            >
              <ToolTipIconWrapper
                iconPadding={iconPadding}
                data-tour="toolTipIcon"
                onClick={event => {
                  handleTooltipToggle();
                  // This stops multiple tooltips staying open in the business form.
                  if (!children) {
                    event.stopPropagation();
                  }
                }}
              >
                {children || <ToolTipIcon />}
              </ToolTipIconWrapper>
            </StyledTooltip>
          </ToolTipIconWrapper>
        </ClickAwayListener>
      )}
    </>
  );
};

export const RuleFieldToolTip = ({ children, title }) => {
  return (
    <Column full textAlignRight>
      <div>
        <Tooltip title={title} />
      </div>
      {children}
    </Column>
  );
};
