import React from "react";

import { useField } from "formik";

import {
  LivestockSalePricingTypeDisplayNameLkp,
  PricingTypes,
} from "constants/pricingTypes";

import {
  ControlledLifeCycleInput,
  DecimalStringInputProps,
} from "./ControlledLifecycleInput";

interface UnitPriceProps {
  disabled?: boolean;
  autoFocus?: boolean;
  name: string;
  pricingTypeField: string;
  showLabel: boolean;
}

export const UnitPrice = (props: UnitPriceProps): React.JSX.Element => {
  const {
    disabled = false,
    autoFocus = false,
    name,
    pricingTypeField = "pricingTypeId",
    showLabel = true,
  } = props;

  const [{ value: pricingTypeId }] = useField(pricingTypeField);

  const pricingTypeAttributesLookup = {
    [PricingTypes.PER_KILO]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.PER_KILO],
      afterSymbol: "¢",
    },
    [PricingTypes.GROSS]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.GROSS],
      beforeSymbol: "$",
    },
    [PricingTypes.PER_HEAD]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.PER_HEAD],
      beforeSymbol: "$",
    },
  };

  const pricingTypeAttributes = pricingTypeAttributesLookup[pricingTypeId];

  return (
    <ControlledLifeCycleInput
      {...DecimalStringInputProps}
      label={showLabel && (pricingTypeAttributes?.label || "Price")}
      name={name}
      autoFocus={autoFocus}
      disabled={disabled}
    />
  );
};
