import queryString from "query-string";

import { DEFAULT_VENDOR_SPLIT, SALE_VENDOR_SPLIT } from "constants/actionTypes";

import { serializeVendorSplit } from "lib/serializers/vendorSplits";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreatorSale = ({ changesSince, id, action, livestockSaleId } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/vendor-splits/", id, action),
    query: {
      changesSince,
      livestock_sale_id: livestockSaleId,
    },
  });

const urlCreatorDefault = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/vendor-splits/", id, action),
    query: { changesSince, is_default: true },
  });

export const DefaultVendorSplitAction = offlineActionCreator(
  DEFAULT_VENDOR_SPLIT,
  serializeVendorSplit,
  urlCreatorDefault,
  false,
);

export const SaleVendorSplitAction = offlineActionCreator(
  SALE_VENDOR_SPLIT,
  serializeVendorSplit,
  urlCreatorSale,
  true,
);
