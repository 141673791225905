import sumBy from "lodash/sumBy";
import { v4 as uuidv4 } from "uuid";

import { SystemLabelName } from "constants/deploymentLabels";
import { PricingTypes, pricingTypeString } from "constants/pricingTypes";
import { saleLotStatuses } from "constants/sale";
import { ScanStatus } from "constants/scanner";
import { Species } from "constants/species";

import {
  calculateUnitPriceCents,
  formatDecimal,
  formatWeightKg,
  getUnitPriceString,
} from "lib";

import { getAuctionPenDisplayName } from "lib/auctionPens";
import { pluralize } from "lib/pluralize";
import { getDefaultPropertyId } from "lib/properties";

const isCounted = quantity => typeof quantity === "number" && quantity > 0;

export const isNoSale = (vendorId, buyerId) =>
  Boolean(buyerId) && vendorId === buyerId;

export const hasBuyer = saleLot => Boolean(saleLot.buyer_id);

export const hasDestinationProperty = saleLot =>
  Boolean(saleLot.destination_property_id);

export const isSold = (buyerId, vendorId) =>
  Boolean(buyerId) && vendorId !== buyerId;

export const isUnsold = buyerId => !buyerId;

export const isLabelledWithdrawn = deploymentLabels =>
  deploymentLabels.some(
    deploymentLabel => deploymentLabel.name === SystemLabelName.WITHDRAWN,
  );

export const isLabelledDeceased = deploymentLabels =>
  deploymentLabels.some(
    deploymentLabel => deploymentLabel.name === SystemLabelName.DECEASED,
  );

export const hasWeight = weightGrams =>
  typeof weightGrams === "number" && weightGrams !== 0;

export function getProgenyDisplayCount(progenyCount) {
  return typeof progenyCount === "number" && progenyCount > 0
    ? `(x ${progenyCount})`
    : "";
}

export const getQuantityFromSaleLots = saleLots => {
  return sumBy(saleLots, "quantity") || 0;
};

export const getProgenyCountFromSaleLots = saleLots => {
  return sumBy(saleLots, "quantityProgeny") || 0;
};

export const getDeliveredCountFromSaleLots = saleLots => {
  return sumBy(saleLots, "quantity_delivered") || 0;
};

export const getIsWeighed = (saleLot, scans) => {
  if (!saleLot?.total_mass_grams) {
    // Not weighed at all
    return false;
  }

  if (scans?.every(s => !s.total_mass_grams > 0)) {
    // Bulk weighed
    return true;
  }

  if (saleLot?.quantity !== scans?.length) {
    // Partially single weighed or partially scanned?  Kinda unknown?
    return false;
  }

  if (scans?.every(scan => scan.total_mass_grams > 0)) {
    // All single weighed
    return true;
  }

  return false;
};

export const isPenned = auctionPenId => Boolean(auctionPenId);

const isDelivered = deliveredCount =>
  typeof deliveredCount === "number" && deliveredCount > 0;

const isBalanced = (headCount, deliveredCount, progenyCount) =>
  headCount + progenyCount === deliveredCount;

export const isSaleLotCounted = saleLot => isCounted(saleLot.quantity);

export const isSaleLotNoSale = (saleLot, consignment) =>
  isNoSale(consignment.vendor_id, saleLot.buyer_id);

export const isSaleLotSold = (saleLot, consignment) =>
  isSold(saleLot.buyer_id, consignment.vendor_id);

export const isSaleLotPenned = saleLot => isPenned(saleLot.auction_pen_id);

export const isSaleLotDelivered = saleLot =>
  isDelivered(saleLot.quantity_delivered);

export const isSaleLotBalanced = saleLot =>
  isBalanced(
    saleLot.quantity,
    saleLot.quantity_delivered,
    saleLot.quantityProgeny,
  );

export const doesSaleLotHaveWeight = saleLot =>
  hasWeight(saleLot.total_mass_grams);

// A sale lot is in progress if it is penned and ready to sell.
export const isSaleLotInProgress = (saleLot, consignment) =>
  isSaleLotPenned(saleLot) &&
  !isSaleLotDelivered(saleLot) &&
  !isSaleLotSold(saleLot, consignment) &&
  !isSaleLotNoSale(saleLot, consignment);

export const getSaleLotStatus = (saleLot, consignment) => {
  if (!isSaleLotCounted(saleLot)) {
    return saleLotStatuses.NOT_COUNTED;
  }
  if (isSaleLotDelivered(saleLot)) {
    return saleLotStatuses.DELIVERED;
  } else if (!consignment) {
    return saleLotStatuses.NOT_PENNED;
  } else if (isSaleLotNoSale(saleLot, consignment)) {
    return saleLotStatuses.NO_SALE;
  } else if (isSaleLotSold(saleLot, consignment)) {
    return saleLotStatuses.SOLD;
  } else if (isSaleLotPenned(saleLot)) {
    return saleLotStatuses.PENNED;
  }
  return saleLotStatuses.NOT_PENNED;
};

const ScanStatusThreshold = {
  [Species.SHEEP]: 0.9,
  [Species.CATTLE]: 1,
};

const maxThreshold = 1;

export const getScanStatusThreshold = (speciesId, saleyard) => {
  const saleyardSpecies = saleyard?.saleyardSpecies?.find(
    species => species.species_id === speciesId,
  );
  if (saleyardSpecies) {
    return +saleyardSpecies.rate;
  }
  return ScanStatusThreshold[speciesId] || 1;
};

export const getSaleLotScannedStatus = (
  saleLot,
  scanCount = 0,
  passThreshold,
) => {
  const lotHasExemption = !!saleLot?.exemption_id;
  const expectedScanCount =
    (saleLot?.quantity || 0) + (saleLot?.quantityProgeny || 0);

  const value = scanCount / expectedScanCount;

  if (lotHasExemption) {
    return ScanStatus.PASS;
  }

  if (value < passThreshold) {
    return ScanStatus.ERROR;
  } else if (value > maxThreshold) {
    return ScanStatus.WARNING;
  }

  return ScanStatus.PASS;
};

export const getScansForSaleLots = (saleLots, scansBySaleLot) => {
  let scans = [];
  if (saleLots && saleLots.length) {
    saleLots.forEach(saleLot => {
      const saleLotScans = scansBySaleLot[saleLot.id];
      if (saleLotScans && saleLotScans.length) {
        scans = [...scans, ...saleLotScans];
      }
    });
  }
  return scans;
};

export const saleLotHasException = saleLot =>
  saleLot.destinationProperty === "";

export const getDeliverPatchInfo = (saleLot, buyer, saleyardId) => {
  // If the delivered HC is set, dont change it.
  // If its not set, set it to sold HC including progeny.
  const quantity_delivered =
    saleLot.quantity_delivered || saleLot.quantity + saleLot.quantityProgeny;

  const buyerWayName = saleLot.buyer_way?.name || saleLot.buyerWayName;

  // Keep the destination property if already defined.
  // Otherwise, pull out the id of the default property
  const destination_property_id =
    saleLot.destination_property_id ||
    saleLot.destinationPropertyId ||
    getDefaultPropertyId(buyer, saleyardId, buyerWayName) ||
    null;

  return {
    quantity_delivered,
    destination_property_id,
  };
};

export const formatSaleLotOverflow = (overflowPenName, overflowCount) => {
  let result = "";
  if (overflowCount || overflowPenName) {
    result += "o/f";
  }
  if (overflowPenName) {
    result += ` ${overflowPenName}`;
  }
  if (typeof overflowCount === "number" && overflowCount > 0) {
    result += ` (${overflowCount})`;
  }
  return result;
};

export const getAverageWeightGrams = lot => {
  if (lot?.total_mass_grams) {
    return lot.quantity > 0 ? lot.total_mass_grams / lot.quantity : 0;
  } else {
    return null;
  }
};

export const getAverageWeightKg = lot => {
  const averageWeight = getAverageWeightGrams(lot);
  return averageWeight === null ? null : averageWeight / 1000;
};

export const getAverageWeightFormatted = (lot, options = { round: false }) => {
  const averageWeight = getAverageWeightKg(lot);
  const decimalPlaces = options.round ? 0 : 2;
  if (averageWeight) {
    return formatDecimal(averageWeight, decimalPlaces);
  } else if (lot?.estimatedAverageMassGrams) {
    const indicativeValue = formatDecimal(
      lot.estimatedAverageMassGrams / 1000,
      decimalPlaces,
    );

    return `~${indicativeValue}`;
  } else {
    return null;
  }
};

export const getTotalWeightFormatted = lot => {
  if (lot.total_mass_grams) {
    return formatDecimal(lot.total_mass_grams / 1000);
  } else if (lot.estimatedAverageMassGrams) {
    const indicativeValue = formatDecimal(
      lot.quantity > 0
        ? (lot.estimatedAverageMassGrams * lot.quantity) / 1000
        : 0,
    );
    return `~${indicativeValue}`;
  } else {
    return null;
  }
};

// calculates alternative costing depending on available data.
// (eg. if price is cost per head return cost per kilo and visa versa)
export const getEstimatedAlternativeCostPerUnit = saleLot => {
  const { priceUnits, indCentsPerKilo, centsPerKilo, dollarsPerHead } = saleLot;

  let altPriceUnits;
  let altPrice;

  if (priceUnits === pricingTypeString()(PricingTypes.PER_KILO)) {
    if (dollarsPerHead) {
      altPrice = Number(dollarsPerHead).toFixed(2);
    }
    altPriceUnits = pricingTypeString()(PricingTypes.PER_HEAD);
  } else if (priceUnits === pricingTypeString()(PricingTypes.PER_HEAD)) {
    if (centsPerKilo) {
      altPrice = Math.round(centsPerKilo);
    } else if (indCentsPerKilo) {
      altPrice = Math.round(indCentsPerKilo);
    }
    altPriceUnits = pricingTypeString()(PricingTypes.PER_KILO);
  }
  if (altPrice) {
    return { price: altPrice, priceUnits: altPriceUnits };
  } else {
    return null;
  }
};

export const getBuyerHashFromSaleLot = saleLot =>
  `${saleLot.buyer_id}_${saleLot.buyer_way?.name}`;

export const getBuyerHashFromPenOwner = penOwner =>
  `${penOwner.businessId}_${penOwner.buyerWay?.name}`;

export const getBuyerHashFromBusinessAndBuyerWay = (business, buyerWay) =>
  `${business.id}_${buyerWay.name}`;

export const generateListingId = () => uuidv4().substring(0, 6).toUpperCase();

export const lotNumberRegex = /^(\D*?)?(\d+)(.*)$/;

export function expandLotNumber(lotNumberStr) {
  const match = lotNumberRegex.exec(lotNumberStr);
  if (match) {
    return {
      lotNumberPrefix: match[1] || "",
      lotNumber: match[2],
      lotNumberSuffix: match[3] || "",
    };
  }
  return null;
}

export function getCombinedLotNumber(saleLot) {
  /* Builds a full lot number out - should handle all the different snake/camels for the fields */
  const lotNumber = saleLot.lotNumberSuffix
    ? `${saleLot.lot_number || saleLot.lotNumber}${saleLot.lotNumberSuffix}`
    : `${saleLot.lot_number || saleLot.lotNumber}`;
  if (saleLot.subLotNumber) {
    return `${lotNumber}.${saleLot.subLotNumber}`;
  }
  return lotNumber;
}

export const getSplitComment = (saleLot, splitCount, auctionPen) => {
  const lotDetails = [""];
  if (auctionPen) {
    lotDetails.push(`Pen: ${getAuctionPenDisplayName(auctionPen)}.`);
  }
  lotDetails.push(`Original quantity: ${saleLot.quantity}.`);
  if (saleLot.total_mass_grams) {
    lotDetails.push(
      `Original weight: ${formatWeightKg(saleLot.total_mass_grams)}.`,
    );
  }
  return `${splitCount} ${pluralize(
    "animal",
    splitCount,
  )} split from Sale Lot #${getCombinedLotNumber(saleLot)}.\n${lotDetails.join(
    "\n",
  )}\nAny weights and EIDs remain on that lot.`;
};
export const doesSaleLotHaveOverflowPen = saleLot =>
  !!saleLot.overflowPen && saleLot.overflowPen.length > 0;

export const getMarkDetails = saleLot => {
  return saleLot.marks?.map(mark => mark.location).join("");
};

export const getSortedMarkDetails = saleLot => {
  return saleLot.marks
    .map(mark => mark.location)
    .sort()
    .join(", ");
};

export function getMarksLocationString(saleLot) {
  let mark = "";
  if (saleLot?.marks && saleLot.marks.length > 0) {
    for (const x in saleLot.marks) {
      mark += `${saleLot.marks[x]?.location}/`;
    }
  }
  return mark.slice(0, -1);
}

export const isOpenAuctionLot = saleLot =>
  saleLot.pricing_type_id === PricingTypes.PER_HEAD ||
  saleLot.pricing_type_id === PricingTypes.GROSS;

export const isLiveWeightLot = saleLot =>
  saleLot.pricing_type_id === PricingTypes.PER_KILO;

export const getOpenAuctionCount = saleLot =>
  isOpenAuctionLot(saleLot) ? saleLot.quantity : 0;

// this is logic from formatSaleLot and formattedSaleLot,
// if you want to use the base saleLot selector this logic is not present
// so using this we can calclate it for the base selector
export const getLotPricingDetails = saleLot => {
  if (saleLot) {
    const unitPriceString = getUnitPriceString(saleLot);
    let dollarsPerHead;
    if (saleLot.pricing_type_id === PricingTypes.PER_HEAD) {
      dollarsPerHead = unitPriceString;
    } else if (saleLot.quantity) {
      dollarsPerHead = `${saleLot.total_price_cents / saleLot.quantity / 100}`;
    }
    let centsPerKilo;
    if (saleLot.pricing_type_id === PricingTypes.PER_KILO) {
      centsPerKilo = calculateUnitPriceCents(saleLot);
    } else if (saleLot.total_mass_grams) {
      centsPerKilo = Math.floor(
        saleLot.total_price_cents / (saleLot.total_mass_grams / 1000),
      );
    }

    return {
      price: unitPriceString,
      priceCents: calculateUnitPriceCents(saleLot),
      priceUnits: PricingTypes.toString(saleLot.pricing_type_id),
      indCentsPerKilo: saleLot.estimatedAverageMassGrams
        ? Math.floor(
            saleLot.total_price_cents /
              ((saleLot.estimatedAverageMassGrams / 1000) * saleLot.quantity),
          )
        : undefined,
      dollarsPerHead,
      centsPerKilo,
    };
  } else {
    return {};
  }
};
