const actionCreator = action => {
  return {
    // Used for prompting the request.
    REQUEST: `${action}_REQUEST`,
    // Used to propagate the result of the request.
    SUCCESS: `${action}_SUCCESS`,
    FAILURE: `${action}_FAILURE`,
    // Used for events that have corresponding pusher actions
    FROM_SOCKET: `${action}_FROM_SOCKET`,
    // Used to signify the result of a OFFLINE actions - they are almost corresponding with SUCCESS and FAILURE
    // but imply data changes should solidified from the action, rather than the specific result of the request.
    OFFLINE: `${action}_OFFLINE`,
    COMMIT: `${action}_COMMIT`,
    ROLLBACK: `${action}_ROLLBACK`,
  };
};

const deploymentActionCreator = (action, url) => {
  return {
    ...actionCreator(action),
    url,
  };
};

const subActionCreator = (dataType, verb) => ({
  ACTION: `${verb}_${dataType}_ACTION`,
  REQUEST: `${verb}_${dataType}_REQUEST`,
  SUCCESS: `${verb}_${dataType}_SUCCESS`,
  FAILURE: `${verb}_${dataType}_FAILURE`,
  FROM_SOCKET: `${verb}_${dataType}_FROM_SOCKET`,
  RESET: `${verb}_${dataType}_RESET`,
});

const dataTypeActionCreator = dataType => ({
  dataType,
  FETCH: subActionCreator(dataType, "FETCH"),
  FETCH_BULK: subActionCreator(dataType, "FETCH_BULK"),
  FETCH_SOME: subActionCreator(dataType, "FETCH_SOME"),
  FETCH_CHANGES: subActionCreator(dataType, "FETCH_CHANGES"),
  UPDATE: subActionCreator(dataType, "UPDATE"),
  UPDATE_BULK: subActionCreator(dataType, "UPDATE_BULK"),
  CREATE: subActionCreator(dataType, "CREATE"),
  CREATE_BULK: subActionCreator(dataType, "CREATE_BULK"),
  DELETE: subActionCreator(dataType, "DELETE"),
  DELETE_BULK: subActionCreator(dataType, "DELETE_BULK"),
  COMMENT: subActionCreator(dataType, "COMMENT"),
  BULK_UPDATE_OR_CREATE: subActionCreator(dataType, "BULK_UPDATE_OR_CREATE"),
});

export const API_RESPONSE = dataTypeActionCreator("API_RESPONSE");
export const REFRESH_INTERVAL = subActionCreator("REFRESH_INTERVAL", "UPDATE");
export const SERVER_HEARTBEAT = dataTypeActionCreator("SERVER_HEARTBEAT");

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const CLEAR_LOGIN_FAILURE = "CLEAR_LOGIN_FAILURE";
export const CHECK_USER_AUTH = actionCreator("CHECK_USER_AUTH");

export const CURRENT_USER = dataTypeActionCreator("CURRENT_USER");
CURRENT_USER.REQUEST_ACCOUNT_DELETION = subActionCreator(
  "CURRENT_USER",
  "REQUEST_ACCOUNT_DELETION",
);
export const USER_ROLES = {
  DEFAULT_ROLE: dataTypeActionCreator("DEFAULT_USER"),
  SALEYARD_ADMIN: dataTypeActionCreator("USER_ROLE_SALEYARD_ADMIN"),
  LIVESTOCK_AGENT: dataTypeActionCreator("USER_ROLE_LIVESTOCK_AGENT"),
  SALE_WATCHER: dataTypeActionCreator("USER_ROLE_SALE_WATCHER"),
  SCALE_OPERATOR: dataTypeActionCreator("USER_ROLE_SCALE_OPERATOR"),
  BUSINESS_USER: dataTypeActionCreator("USER_ROLE_BUSINESS_USER"),
};

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const CLEAR_LOCAL_STATE = "CLEAR_LOCAL_STATE";

export const REQUEST_WATCHER_ENTRY = "CHECK_WATCHER_ENTRY";
export const RECEIVE_WATCHER_ENTRY = "RECEIVE_WATCHER_ENTRY";

export const NLIS_SIGN_UP_REQUEST = "NLIS_SIGN_UP_REQUEST";
export const NLIS_SIGN_UP_SUCCESS = "NLIS_SIGN_UP_SUCCESS";
export const NLIS_SIGN_UP_FAILURE = "NLIS_SIGN_UP_FAILURE";

export const GET_DUPLICATE_BUSINESS_SUGGESTIONS = actionCreator(
  "GET_DUPLICATE_BUSINESS_SUGGESTIONS",
);
export const MERGE_BUSINESSES = actionCreator("MERGE_BUSINESSES");

export const BUSINESS = dataTypeActionCreator("BUSINESS");
BUSINESS.SUGGEST_BUYER_WAY_PROPERTY = subActionCreator(
  "BUSINESS",
  "SUGGEST_BUYER_WAY_PROPERTY",
);

export const GLOBAL_BUSINESSES = dataTypeActionCreator("GLOBAL_BUSINESSES");

export const VENDOR_COMMISSION_BAND = dataTypeActionCreator(
  "VENDOR_COMMISSION_BAND",
);

export const TRADING_TERM = dataTypeActionCreator("TRADING_TERM");
export const SUNDRY_TEMPLATE = dataTypeActionCreator("SUNDRY_TEMPLATE");

export const REPORT_JOB = dataTypeActionCreator("REPORT_JOB");
REPORT_JOB.EMAIL = subActionCreator("REPORT_JOB", "EMAIL");
REPORT_JOB.PUSHER = subActionCreator("REPORT_JOB", "PUSHER");

export const INTEREST_SETTING = dataTypeActionCreator("INTEREST_SETTING");

export const EMAIL = dataTypeActionCreator("EMAIL");

export const GET_SPECIES = actionCreator("GET_SPECIES");

export const GET_DENTITION = actionCreator("GET_DENTITION");

export const IMPORT_DEPLOYMENT_SALE = actionCreator("IMPORT_DEPLOYMENT_SALE");
export const IMPORT_AUCTIONS_PLUS_CSV = "IMPORT_AUCTIONS_PLUS_CSV";

export const EXTERNAL_AGENT_XML = dataTypeActionCreator("EXTERNAL_AGENT_XML");
EXTERNAL_AGENT_XML.PROCESS = subActionCreator("EXTERNAL_AGENT_XML", "PROCESS");
EXTERNAL_AGENT_XML.IMPORT = subActionCreator("EXTERNAL_AGENT_XML", "IMPORT");

export const PRE_SALE_CSV = dataTypeActionCreator("PRE_SALE_CSV");
PRE_SALE_CSV.PROCESS = subActionCreator("PRE_SALE_CSV", "PROCESS");
PRE_SALE_CSV.IMPORT = subActionCreator("PRE_SALE_CSV", "IMPORT");
PRE_SALE_CSV.DOWNLOAD_SAMPLE = subActionCreator(
  "PRE_SALE_CSV",
  "DOWNLOAD_SAMPLE",
);

export const DEPLOYMENT_SALE = dataTypeActionCreator("DEPLOYMENT_SALE");

DEPLOYMENT_SALE.BULK_UPDATE_LOT_NO_TO_PEN_NO = subActionCreator(
  "DEPLOYMENT_SALE",
  "BULK_UPDATE_LOT_NO_TO_PEN_NO",
);

DEPLOYMENT_SALE.SEND_VENDOR_REPORTS = subActionCreator(
  "DEPLOYMENT_SALE",
  "SEND_VENDOR_REPORTS",
);

export const ADD_TEMP_BUYER_WAY = "ADD_TEMP_BUYER_WAY";

export const DELETE_BUSINESS_NO_LONGER_EXISTS =
  "DELETE_BUSINESS_NO_LONGER_EXISTS";

export const SALE = dataTypeActionCreator("SALE");
SALE.FETCH_CONSIGNABLE = subActionCreator("SALE", "FETCH_CONSIGNABLE");
SALE.UPDATE_WITH_DEPLOYMENT_SALES = subActionCreator(
  "SALE",
  "UPDATE_WITH_DEPLOYMENT_SALES",
);
SALE.CHECK_AND_DELETE_CURRENT = subActionCreator(
  "SALE",
  "CHECK_AND_DELETE_CURRENT",
);

export const GET_CONSIGNMENTS = "GET_CONSIGNMENTS";
export const GET_CONSIGNMENTS_CHANGES = "GET_CONSIGNMENTS_CHANGES";
export const GET_CONSIGNMENTS_CHANGES_SUCCESS =
  "GET_CONSIGNMENTS_CHANGES_SUCCESS";
export const GET_CONSIGNMENTS_SUCCESS = "GET_CONSIGNMENTS_SUCCESS";
export const GET_CONSIGNMENTS_FAILURE = "GET_CONSIGNMENTS_FAILURE";

export const CLEAR_NVD_UPLOADS = "CLEAR_NVD_UPLOADS";
export const GET_ALL_NVD_UPLOADS = actionCreator("GET_ALL_NVD_UPLOADS");
export const GET_NVD_UPLOAD = actionCreator("GET_NVD_UPLOAD");
export const SUBMIT_NVD_UPLOAD_OFFLINE = actionCreator(
  "SUBMIT_NVD_UPLOAD_OFFLINE",
);
export const REFRESH_NVD_UPLOAD = "REFRESH_NVD_UPLOAD";

export const TAKE_REVERSAL = dataTypeActionCreator("TAKE_REVERSAL");
export const REFRESH_TAKE_REVERSAL = "REFRESH_TAKE_REVERSAL";

export const SELL_REVERSAL = dataTypeActionCreator("SELL_REVERSAL");

export const P2P_REVERSAL = dataTypeActionCreator("P2P_REVERSAL");

export const REFRESH_SELL_REVERSAL = "REFRESH_SELL_REVERSAL";

export const TAKE_FILE = dataTypeActionCreator("TAKE_FILE");
TAKE_FILE.AUTO = subActionCreator("TAKE_FILE", "AUTO");
export const REFRESH_TAKE_FILE = "REFRESH_TAKE_FILE";

export const SELL_FILE = dataTypeActionCreator("SELL_FILE");
SELL_FILE.AUTO = subActionCreator("SELL_FILE", "AUTO");
export const REFRESH_SELL_FILE = "REFRESH_SELL_FILE";

export const SIGHTING_FILE = dataTypeActionCreator("SIGHTING_FILE");
export const P2P_FILE = dataTypeActionCreator("P2P_FILE");
// P2P_FILE.AUTO = subActionCreator("P2P_FILE", "AUTO");
export const NLIS_MESSAGE_GROUPS = dataTypeActionCreator("NLIS_MESSAGE_GROUPS");

export const ADD_CONSIGNMENT_OFFLINE = "ADD_CONSIGNMENT_OFFLINE";
export const ADD_CONSIGNMENT_COMMIT = "ADD_CONSIGNMENT_COMMIT";
export const ADD_CONSIGNMENT_ROLLBACK = "ADD_CONSIGNMENT_ROLLBACK";

export const ADD_CONSIGNMENT_TO_OTHER_SALE = "ADD_CONSIGNMENT_TO_OTHER_SALE";

export const PATCH_CONSIGNMENT_OFFLINE = "PATCH_CONSIGNMENT_OFFLINE";
export const PATCH_CONSIGNMENT_COMMIT = "PATCH_CONSIGNMENT_COMMIT";
export const PATCH_CONSIGNMENT_ROLLBACK = "PATCH_CONSIGNMENT_ROLLBACK";

export const DELETE_CONSIGNMENT_OFFLINE = "DELETE_CONSIGNMENT_OFFLINE";
export const DELETE_CONSIGNMENT_COMMIT = "DELETE_CONSIGNMENT_COMMIT";
export const DELETE_CONSIGNMENT_ROLLBACK = "DELETE_CONSIGNMENT_ROLLBACK";
export const DELETE_CONSIGNMENT_NO_LONGER_EXISTS =
  "DELETE_CONSIGNMENT_NO_LONGER_EXISTS";

export const ADD_CONSIGNMENT_FROM_SOCKET = "ADD_CONSIGNMENT_FROM_SOCKET";
export const PATCH_CONSIGNMENT_FROM_SOCKET = "PATCH_CONSIGNMENT_FROM_SOCKET";
export const DELETE_CONSIGNMENT_FROM_SOCKET = "DELETE_CONSIGNMENT_FROM_SOCKET";

export const SET_SALEYARD = "SET_SALEYARD";

export const SCREEN_RESIZE = "SCREEN_RESIZE";

export const GET_SALE_LOT_SUCCESS = "GET_SALE_LOT_SUCCESS";

export const ADD_SALE_LOT_OFFLINE = "ADD_SALE_LOT_OFFLINE";
export const ADD_SALE_LOT_COMMIT = "ADD_SALE_LOT_COMMIT";
export const ADD_SALE_LOT_ROLLBACK = "ADD_SALE_LOT_ROLLBACK";

export const COPY_SALE_LOT_TO_SALE = actionCreator("COPY_SALE_LOT_TO_SALE");

export const DELETE_SALE_LOT_OFFLINE = "DELETE_SALE_LOT_OFFLINE";
export const DELETE_SALE_LOT_COMMIT = "DELETE_SALE_LOT_COMMIT";
export const DELETE_SALE_LOT_ROLLBACK = "DELETE_SALE_LOT_ROLLBACK";
export const DELETE_SALE_LOT_NO_LONGER_EXISTS =
  "DELETE_SALE_LOT_NO_LONGER_EXISTS";

export const DELETE_SALE_LOT_FROM_SOCKET = "DELETE_SALE_LOT_FROM_SOCKET";

export const MERGE_SALE_LOT_OFFLINE = "MERGE_SALE_LOT_OFFLINE";
export const MERGE_SALE_LOT_COMMIT = "MERGE_SALE_LOT_COMMIT";
export const MERGE_SALE_LOT_ROLLBACK = "MERGE_SALE_LOT_ROLLBACK";

export const GET_PROPERTIES = "GET_PROPERTIES";
export const GET_PROPERTIES_CHANGES = "GET_PROPERTIES_CHANGES";
export const GET_PROPERTIES_SUCCESS = "GET_PROPERTIES_SUCCESS";
export const GET_PROPERTIES_CHANGES_SUCCESS = "GET_PROPERTIES_CHANGES_SUCCESS";
export const GET_PROPERTIES_FAILURE = "GET_PROPERTIES_FAILURE";
export const ADD_PROPERTY_ROLLBACK = "ADD_PROPERTY_ROLLBACK";
export const ADD_BUSINESS_PROPERTY_OFFLINE = "ADD_BUSINESS_PROPERTY_OFFLINE";
export const ADD_BUSINESS_PROPERTY_COMMIT = "ADD_BUSINESS_PROPERTY_COMMIT";
export const ADD_BUSINESS_PROPERTY_ROLLBACK = "ADD_BUSINESS_PROPERTY_ROLLBACK";

export const UPDATE_PROPERTIES = "UPDATE_PROPERTIES";

export const PROPERTY = dataTypeActionCreator("PROPERTY");
PROPERTY.ERP_QUERY = subActionCreator("PROPERTY", "ERP_QUERY");

export const UPDATE_DECLARATION = "UPDATE_DECLARATION";
export const UPDATE_DECLARATION_SUCCESS = "UPDATE_DECLARATION_SUCCESS";
export const UPDATE_DECLARATION_FAILURE = "UPDATE_DECLARATION_FAILURE";
export const SET_ADDITIONAL_PICS = "SET_ADDITIONAL_PICS";
export const SET_ADDITIONAL_PICS_SUCCESS = "SET_ADDITIONAL_PICS_SUCCESS";

export const SEND_EMAIL_OFFLINE = "SEND_EMAIL_OFFLINE";
export const SEND_EMAIL_COMMIT = "SEND_EMAIL_COMMIT";
export const SEND_EMAIL_ROLLBACK = "SEND_EMAIL_ROLLBACK";

export const UPLOAD_REQUEST = "UPLOAD_REQUEST";
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAILURE = "UPLOAD_FAILURE";

export const ATTACHMENT = dataTypeActionCreator("ATTACHMENT");
ATTACHMENT.CREATE_WITH_PLACEHOLDER = subActionCreator(
  "ATTACHMENT",
  "CREATE_WITH_PLACEHOLDER",
);
export const SALE_LOT = dataTypeActionCreator("SALE_LOT");
SALE_LOT.SPLIT_SINGLE_WEIGHED = subActionCreator(
  "SALE_LOT",
  "SPLIT_SINGLE_WEIGHED",
);
SALE_LOT.DRAFT_SIGHTING = subActionCreator("SALE_LOT", "DRAFT_SIGHTING");
SALE_LOT.SET_OFFLINE_VALUES = subActionCreator(
  "SALE_LOT",
  "SET_OFFLINE_VALUES",
);
SALE_LOT.BULK_DELIVER_ALL = subActionCreator("SALE_LOT", "BULK_DELIVER_ALL");

SALE_LOT.SELL_WTH_BIDDER_REGISTRATION = subActionCreator(
  "SALE_LOT",
  "SELL_WTH_BIDDER_REGISTRATION",
);

export const COMMENT = dataTypeActionCreator("COMMENT");
export const DEPLOYMENT_SALE_COMMENT = dataTypeActionCreator(
  "DEPLOYMENT_SALE_COMMENT",
);

export const LEDGER_ACCOUNT = dataTypeActionCreator("LEDGER_ACCOUNT");
export const MASTER_LEDGER_ACCOUNT = dataTypeActionCreator(
  "MASTER_LEDGER_ACCOUNT",
);
export const MANUAL_ADJUSTMENT = dataTypeActionCreator("MANUAL_ADJUSTMENT");

export const PAYMENT = dataTypeActionCreator("PAYMENT");
export const ENVD_DOCUMENT = dataTypeActionCreator("ENVD_DOCUMENT");
ENVD_DOCUMENT.REFRESH = subActionCreator("ENVD_DOCUMENT", "REFRESH");

export const RULE_BOOK = dataTypeActionCreator("RULE_BOOK");
RULE_BOOK.COPY = subActionCreator("RULE_BOOK", "COPY");
RULE_BOOK.UNSUBSCRIBE_FROM_MASTER_RULE_BOOK = subActionCreator(
  "RULE_BOOK",
  "UNSUBSCRIBE_FROM_MASTER_RULE_BOOK",
);
RULE_BOOK.SUBSCRIBE_TO_MASTER_RULE_BOOK = subActionCreator(
  "RULE_BOOK",
  "SUBSCRIBE_TO_MASTER_RULE_BOOK",
);

export const MASTER_RULE_BOOK = dataTypeActionCreator("MASTER_RULE_BOOK");
MASTER_RULE_BOOK.COPY = subActionCreator("MASTER_RULE_BOOK", "COPY");
MASTER_RULE_BOOK.UPDATE_MANAGED_RULE_BOOK = subActionCreator(
  "MASTER_RULE_BOOK",
  "UPDATE_MANAGED_RULE_BOOK",
);
export const MASTER_RULE = dataTypeActionCreator("MASTER_RULE");
MASTER_RULE.COPY = subActionCreator("MASTER_RULE", "COPY");
MASTER_RULE.MOVE_UP = subActionCreator("MASTER_RULE", "MOVE_UP");
MASTER_RULE.MOVE_DOWN = subActionCreator("MASTER_RULE", "MOVE_DOWN");

export const RULE = dataTypeActionCreator("RULE");
RULE.COPY = subActionCreator("RULE", "COPY");
RULE.MOVE_UP = subActionCreator("RULE", "MOVE_UP");
RULE.MOVE_DOWN = subActionCreator("RULE", "MOVE_DOWN");

export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAILURE = "GET_REPORTS_FAILURE";

export const BILLING_DATA = dataTypeActionCreator("BILLING_DATA");
export const BILLING_DOCUMENT = dataTypeActionCreator("BILLING_DOCUMENT");
BILLING_DOCUMENT.EMAIL = subActionCreator("BILLING_DOCUMENT", "EMAIL");
BILLING_DOCUMENT.ACCEPT_CHANGES = subActionCreator(
  "BILLING_DOCUMENT",
  "ACCEPT_CHANGES",
);
BILLING_DOCUMENT.ACCEPT_AND_REVERSE = subActionCreator(
  "BILLING_DOCUMENT",
  "ACCEPT_AND_REVERSE",
);
BILLING_DOCUMENT.REJECT_CHANGES = subActionCreator(
  "BILLING_DOCUMENT",
  "REJECT_CHANGES",
);
BILLING_DOCUMENT.BULK_ACCEPT_CHANGES = subActionCreator(
  "BILLING_DOCUMENT",
  "BULK_ACCEPT_CHANGES",
);

BILLING_DOCUMENT.FETCH_XERO_BILL_CSV = subActionCreator(
  "BILLING_DOCUMENT",
  "FETCH_XERO_BILL_CSV",
);
BILLING_DOCUMENT.FETCH_XERO_INVOICE_CSV = subActionCreator(
  "BILLING_DOCUMENT",
  "FETCH_XERO_INVOICE_CSV",
);
BILLING_DOCUMENT.EXPORT_XERO_INVOICE = subActionCreator(
  "BILLING_DOCUMENT",
  "EXPORT_XERO_INVOICE",
);

BILLING_DOCUMENT.FETCH_MYOB_CUSTOMER_EXPORT = subActionCreator(
  "BILLING_DOCUMENT",
  "FETCH_MYOB_CUSTOMER_EXPORT",
);
BILLING_DOCUMENT.FETCH_MYOB_SUPPLIER_EXPORT = subActionCreator(
  "BILLING_DOCUMENT",
  "FETCH_MYOB_SUPPLIER_EXPORT",
);
BILLING_DOCUMENT.FETCH_MYOB_SALES_EXPORT = subActionCreator(
  "BILLING_DOCUMENT",
  "FETCH_MYOB_SALES_EXPORT",
);
BILLING_DOCUMENT.FETCH_MYOB_PURCHASES_EXPORT = subActionCreator(
  "BILLING_DOCUMENT",
  "FETCH_MYOB_PURCHASES_EXPORT",
);
BILLING_DOCUMENT.FETCH_MYOB_CLASSIC_SALES_EXPORT = subActionCreator(
  "BILLING_DOCUMENT",
  "FETCH_MYOB_CLASSIC_SALES_EXPORT",
);
BILLING_DOCUMENT.FETCH_MYOB_CLASSIC_PURCHASES_EXPORT = subActionCreator(
  "BILLING_DOCUMENT",
  "FETCH_MYOB_CLASSIC_PURCHASES_EXPORT",
);

BILLING_DOCUMENT.FETCH_SALEYARD_BULK_BILLING_XML = subActionCreator(
  "BILLING_DOCUMENT",
  "FETCH_SALEYARD_BULK_BILLING_XML",
);

BILLING_DOCUMENT.FETCH_AGENT_BULK_BILLING_XML = subActionCreator(
  "BILLING_DOCUMENT",
  "FETCH_AGENT_BULK_BILLING_XML",
);
BILLING_DOCUMENT.RETAIN_PROCEEDS = subActionCreator(
  "BILLING_DOCUMENT",
  "RETAIN_PROCEEDS",
);

export const BILLING_DOCUMENT_ACTIVITY = dataTypeActionCreator(
  "BILLING_DOCUMENT_ACTIVITY",
);

export const CONTACT_BILLING_DOCUMENT = dataTypeActionCreator(
  "CONTACT_BILLING_DOCUMENT",
);

export const BILLING_RUN = dataTypeActionCreator("BILLING_RUN");
BILLING_RUN.DISABLE = subActionCreator("BILLING_RUN", "DISABLE");
BILLING_RUN.ENABLE = subActionCreator("BILLING_RUN", "ENABLE");
BILLING_RUN.SUBSCRIBE = subActionCreator("BILLING_RUN", "SUBSCRIBE");
BILLING_RUN.UNSUBSCRIBE = subActionCreator("BILLING_RUN", "UNSUBSCRIBE");
BILLING_RUN.CHECK_FOR_CHANGES = subActionCreator(
  "BILLING_RUN",
  "CHECK_FOR_CHANGES",
);

export const LEDGER_ENTRY = dataTypeActionCreator("LEDGER_ENTRY");
LEDGER_ENTRY.DEDUPLICATE = subActionCreator("LEDGER_ENTRY", "DEDUPLICATE");

export const BILLING_TAGS = dataTypeActionCreator("BILLING_TAGS");

export const GET_SPECIES_ATTRIBUTES = "GET_SPECIES_ATTRIBUTES";
export const GET_SPECIES_ATTRIBUTES_FAILURE = "GET_SPECIES_ATTRIBUTES_FAILURE";

export const CREATE_CONSIGNMENT_PEN = "CREATE_CONSIGNMENT_PEN";
export const CREATE_CONSIGNMENT_PEN_OFFLINE = "CREATE_CONSIGNMENT_PEN_OFFLINE";
export const CREATE_CONSIGNMENT_PEN_SUCCESS = "CREATE_CONSIGNMENT_PEN_SUCCESS";
export const CREATE_CONSIGNMENT_PEN_FAILURE = "CREATE_CONSIGNMENT_PEN_FAILURE";

export const CREATE_UNKNOWN_CONSIGNMENT_PEN = "CREATE_UNKNOWN_CONSIGNMENT_PEN";
export const CREATE_UNKNOWN_CONSIGNMENT_PEN_SUCCESS =
  "CREATE_UNKNOWN_CONSIGNMENT_PEN_SUCCESS";
export const CREATE_UNKNOWN_CONSIGNMENT_PEN_FAILURE =
  "CREATE_UNKNOWN_CONSIGNMENT_PEN_FAILURE";

export const ALLOCATE_CONSIGNMENT_EIDS_TO_PEN =
  "ALLOCATE_CONSIGNMENT_EIDS_TO_PEN";
export const ALLOCATE_CONSIGNMENT_EIDS_TO_PEN_OFFLINE =
  "ALLOCATE_CONSIGNMENT_EIDS_TO_PEN_OFFLINE";
export const ALLOCATE_CONSIGNMENT_EIDS_TO_PEN_FAILURE =
  "ALLOCATE_CONSIGNMENT_EIDS_TO_PEN_FAILURE";

export const GET_DEPLOYMENT_ATTRIBUTES = "GET_DEPLOYMENT_ATTRIBUTES";
export const GET_DEPLOYMENT_ATTRIBUTES_FAILURE =
  "GET_DEPLOYMENT_ATTRIBUTES_FAILURE";

export const UPDATE_DEPLOYMENT_BREEDS = deploymentActionCreator(
  "UPDATE_DEPLOYMENT_BREEDS",
  "/v2/deployment_breeds/",
);
export const GET_DEPLOYMENT_BREEDS = deploymentActionCreator(
  "GET_DEPLOYMENT_BREEDS",
  "/v2/deployment_breeds/",
);

export const GET_DEPLOYMENT_SEXES = deploymentActionCreator(
  "GET_DEPLOYMENT_SEXES",
  "/v2/deployment_sexes/",
);
export const UPDATE_DEPLOYMENT_SEXES = deploymentActionCreator(
  "UPDATE_DEPLOYMENT_SEXES",
  "/v2/deployment_sexes/",
);

export const GET_DEPLOYMENT_AGES = deploymentActionCreator(
  "GET_DEPLOYMENT_AGES",
  "/v2/deployment_ages/",
);
export const UPDATE_DEPLOYMENT_AGES = deploymentActionCreator(
  "UPDATE_DEPLOYMENT_AGES",
  "/v2/deployment_ages/",
);

export const GET_DEPLOYMENT_LABELS = deploymentActionCreator(
  "GET_DEPLOYMENT_LABELS",
  "/v2/deployment_labels/",
);
export const UPDATE_DEPLOYMENT_LABELS = deploymentActionCreator(
  "UPDATE_DEPLOYMENT_LABELS",
  "/v2/deployment_labels/",
);

export const GET_DEPLOYMENT_MARKS = deploymentActionCreator(
  "GET_DEPLOYMENT_MARKS",
  "/v2/deployment_marks/",
);
export const UPDATE_DEPLOYMENT_MARKS = deploymentActionCreator(
  "UPDATE_DEPLOYMENT_MARKS",
  "/v2/deployment_marks/",
);
export const DELETE_DEPLOYMENT_MARKS = deploymentActionCreator(
  "DELETE_DEPLOYMENT_MARKS",
  "/v2/deployment_marks/",
);

export const GET_DEPLOYMENT_DENTITION = deploymentActionCreator(
  "GET_DEPLOYMENT_DENTITION",
  "/v2/deployment_dentition/",
);
export const UPDATE_DEPLOYMENT_DENTITION = deploymentActionCreator(
  "UPDATE_DEPLOYMENT_DENTITION",
  "/v2/deployment_dentition/",
);

export const GET_DEFAULT_ROUNDS_AND_SALE_TYPES =
  "GET_DEFAULT_ROUNDS_AND_SALE_TYPES";

export const GET_BREEDS_SUCCESS = "GET_BREEDS_SUCCESS";

export const GET_SEXES_SUCCESS = "GET_SEXES_SUCCESS";

export const GET_AGES_SUCCESS = "GET_AGES_SUCCESS";

export const GET_GRADES_SUCCESS = "GET_GRADES_SUCCESS";

export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

export const GET_EXEMPTIONS_SUCCESS = "GET_EXEMPTIONS_SUCCESS";

export const GET_ROUNDS = actionCreator("GET_ROUNDS");

export const GET_SALE_DEFINITIONS = "GET_SALE_DEFINITIONS";
export const GET_SALE_DEFINITIONS_SUCCESS = "GET_SALE_DEFINITIONS_SUCCESS";
export const GET_SALE_DEFINITIONS_FAILURE = "GET_SALE_DEFINITIONS_FAILURE";

export const RECEIVE_SOCKET_ID = "RECEIVE_SOCKET_ID";

export const AUCTION_PEN = dataTypeActionCreator("AUCTION_PEN");
AUCTION_PEN.MOVE_SCANS = subActionCreator("AUCTION_PEN", "MOVE_SCANS");
AUCTION_PEN.BULK_RESET_AUTO_DRAFT = subActionCreator(
  "AUCTION_PEN",
  "BULK_RESET_AUTO_DRAFT",
);
AUCTION_PEN.ORDER_AFTER = subActionCreator("AUCTION_PEN", "ORDER_AFTER");
AUCTION_PEN.ORDER_BEFORE = subActionCreator("AUCTION_PEN", "ORDER_BEFORE");
AUCTION_PEN.ORDER_SELLING_NOW = subActionCreator(
  "AUCTION_PEN",
  "ORDER_SELLING_NOW",
);

AUCTION_PEN.SET_SELLING_ORDER = subActionCreator(
  "AUCTION_PEN",
  "SET_SELLING_ORDER",
);

AUCTION_PEN.OWNER = dataTypeActionCreator("OWNER");

export const PEN_ARCHETYPE = dataTypeActionCreator("PEN_ARCHETYPE");
export const SINGLE_WEIGH = dataTypeActionCreator("SINGLE_WEIGH");
SINGLE_WEIGH.INITIALIZE = subActionCreator("SINGLE_WEIGH", "INITIALIZE");
SINGLE_WEIGH.ACTIVATE = subActionCreator("SINGLE_WEIGH", "ACTIVATE");
SINGLE_WEIGH.DEACTIVATE = subActionCreator("SINGLE_WEIGH", "DEACTIVATE");
SINGLE_WEIGH.RESET = subActionCreator("SINGLE_WEIGH", "RESET");
SINGLE_WEIGH.CHOOSE_EID = subActionCreator("SINGLE_WEIGH", "CHOOSE_EID");

const timerActionCreator = name => {
  return {
    START: `${name}_START`,
    CANCEL: `${name}_CANCEL`,
  };
};

SINGLE_WEIGH.UNSTEADY_WEIGHT_TIMER = timerActionCreator("UNSTEADY_WEIGHT");
SINGLE_WEIGH.NO_EID_TIMER = timerActionCreator("NO_EID");

export const ADD_SCAN_FROM_SCANNER = "ADD_SCANS_FROM_SCANNER";
export const ADD_SCANS_FROM_SCANNER = "ADD_SCAN_FROM_SCANNER";
export const ADD_SCANS_FROM_SOCKET = "ADD_SCANS_FROM_SOCKET";
export const ADD_SCANS_FROM_FILE = "ADD_SCANS_FROM_FILE";
export const ADD_SCANS_FROM_MANUAL_INPUT = "ADD_SCANS_FROM_MANUAL_INPUT";

export const ACCEPT_IMPORT_PENDING_SCANS = "ACCEPT_IMPORT_PENDING_SCANS";
export const CLEAR_IMPORTED_SCANS = "CLEAR_IMPORTED_SCANS";

export const ADD_WEIGHT_FROM_SCALES = "ADD_WEIGHT_FROM_SCALES";
export const ADD_CONSIGNMENT_SALEYARD_SCANS = "ADD_CONSIGNMENT_SALEYARD_SCANS";

export const ADD_SCANS_FROM_BUFFER = "ADD_SCANS_FROM_BUFFER";

export const SET_AVAILABLE_DEVICES = "SET_AVAILABLE_DEVICES";
export const GET_AVAILABLE_DEVICES = "GET_AVAILABLE_DEVICES";
export const CONNECT_TO_HUB = "CONNECT_TO_HUB";
export const HUB_CONNECTED = "HUB_CONNECTED";
export const HUB_DISCONNECTED = "HUB_DISCONNECTED";
export const CONNECT_TO_DEVICE = "CONNECT_TO_DEVICE";
export const CONNECT_TO_SCALES = "CONNECT_TO_SCALES";
export const CONNECT_TO_PLC = "CONNECT_TO_PLC";
export const DISCONNECT_FROM_DEVICE = "DISCONNECT_FROM_DEVICE";
export const DEVICE_CONNECTED = "DEVICE_CONNECTED";
export const PING_FROM_APP = "PING_FROM_APP";
export const SEND_DEVICE_MESSAGE = "SEND_DEVICE_MESSAGE";
export const SEND_DEVICE_REQUEST_LIST_WEIGHING_SESSIONS =
  "SEND_DEVICE_REQUEST_LIST_WEIGHING_SESSIONS";
export const SEND_DEVICE_REQUEST_READ_WEIGHING_SESSION =
  "SEND_DEVICE_REQUEST_READ_WEIGHING_SESSION";
export const IMPORT_MT_HOST_SESSIONS = "IMPORT_MT_HOST_SESSIONS";
export const SEND_RAW_HUB_PAYLOAD = "SEND_RAW_HUB_PAYLOAD";
export const DEVICE_MESSAGE_RESPONSE = "DEVICE_MESSAGE_RESPONSE";
export const SET_WEIGHING_SESSIONS = "SET_WEIGHING_SESSIONS";

export const UPDATE_DEVICE_PROTOCOL_STATUS = "UPDATE_DEVICE_PROTOCOL_STATUS";
export const DRAFTING_DECISION = {
  UPDATE: subActionCreator("DRAFTING_DECISION", "UPDATE"),
  CHECK: subActionCreator("DRAFTING_DECISION", "CHECK"),
  ACTION: subActionCreator("DRAFTING_DECISION", "ACTION"),
};

export const TLABPLC_HANDSHAKE = actionCreator("TLABPLC_HANDSHAKE");
export const TLABPLC_DRAFTING_RESET = actionCreator("TLABPLC_DRAFTING_RESET");

export const TLABPLC_DRAFTING_QUERY = actionCreator("TLABPLC_DRAFTING_QUERY");
export const TLABPLC_DRAFTING_DECISION = actionCreator(
  "TLABPLC_DRAFTING_DECISION",
);

export const CONFIRM_SET_DEVICE_TIME = "CONFIRM_SET_DEVICE_TIME";
export const SET_DEVICE_TIME = "SET_DEVICE_TIME";
export const SET_DEVICE_TIME_RESULT = "SET_DEVICE_TIME_RESULT";

export const GET_DEVICE_TIME = "GET_DEVICE_TIME";
export const GET_DEVICE_TIME_RESULT = "GET_DEVICE_TIME_RESULT";

export const READ_SAVED_SCANS = "READ_SAVED_SCANS";
export const READ_SAVED_SCANS_RESULT = "READ_SAVED_SCANS_RESULT";

export const CONFIRM_ERASE_SAVED_SCANS = "CONFIRM_ERASE_SAVED_SCANS";
export const ERASE_SAVED_SCANS = "ERASE_SAVED_SCANS";
export const ERASE_SAVED_SCANS_RESULT = "ERASE_SAVED_SCANS_RESULT";

export const SET_DEVICE_STATUS = "SET_DEVICE_STATUS";

export const CONFIRM_RESET_DEVICE = "CONFIRM_RESET_DEVICE";
export const RESET_DEVICE = "RESET_DEVICE";

export const DELETE_SCANS_FROM_UNASSIGNED = "DELETE_SCANS_FROM_UNASSIGNED";
export const DELETE_ALL_SCANS_FROM_UNASSIGNED =
  "DELETE_ALL_SCANS_FROM_UNASSIGNED";
export const KEEP_SCANS_IN_UNASSIGNED = "KEEP_SCANS_IN_UNASSIGNED";
export const ADD_SCANS_IN_UNASSIGNED = "ADD_SCANS_IN_UNASSIGNED";

export const DELETE_SCANS_FROM_DEVICE_IMPORT =
  "DELETE_SCANS_FROM_DEVICE_IMPORT";
export const KEEP_SCANS_IN_DEVICE_IMPORT = "KEEP_SCANS_IN_DEVICE_IMPORT";

export const DELETE_SCANS_FROM_FILE_IMPORT = "DELETE_SCANS_FROM_FILE_IMPORT";
export const KEEP_SCANS_IN_FILE_IMPORT = "KEEP_SCANS_IN_FILE_IMPORT";

export const UPLOAD_DRAFT = "UPLOAD_DRAFT";

export const UPLOAD_SCANS_ACTION = "UPLOAD_SCANS_ACTION";
export const UPLOAD_SCANS_OFFLINE = "UPLOAD_SCANS_OFFLINE";
export const UPLOAD_SCANS_COMMIT = "UPLOAD_SCANS_COMMIT";
export const UPLOAD_SCANS_ROLLBACK = "UPLOAD_SCANS_ROLLBACK";

export const UPLOAD_SCANS_AND_DRAFTING_INFORMATION =
  "UPLOAD_SCANS_AND_DRAFTING_INFORMATION";

export const GENERIC_UPLOAD_SCANS_AND_DRAFTING_INFORMATION =
  "GENERIC_UPLOAD_SCANS_AND_DRAFTING_INFORMATION";

export const BULK_MOVE_SCANS = "BULK_MOVE_SCANS";
export const BULK_MOVE_SCANS_OFFLINE = "BULK_MOVE_SCANS_OFFLINE";
export const BULK_MOVE_SCANS_COMMIT = "BULK_MOVE_SCANS_COMMIT";
export const BULK_MOVE_SCANS_ROLLBACK = "BULK_MOVE_SCANS_ROLLBACK";

export const UPDATE_SCAN_NLIS_OFFLINE = "UPDATE_SCAN_NLIS_OFFLINE";
export const UPDATE_SCAN_NLIS_COMMIT = "UPDATE_SCAN_NLIS_COMMIT";
export const UPDATE_SCAN_NLIS_ROLLBACK = "UPDATE_SCAN_NLIS_ROLLBACK ";

export const SET_ANIMAL_DECEASED_OFFLINE = "SET_ANIMAL_DECEASED_OFFLINE";
export const SET_ANIMAL_DECEASED_COMMIT = "SET_ANIMAL_DECEASED_COMMIT";
export const SET_ANIMAL_DECEASED_ROLLBACK = "SET_ANIMAL_DECEASED_ROLLBACK";

export const DELETE_SALE_LOT_SCANS_ACTION = "DELETE_SALE_LOT_SCANS_ACTION";
export const DELETE_SALE_LOT_SCANS_OFFLINE = "DELETE_SALE_LOT_SCANS_OFFLINE";
export const DELETE_SALE_LOT_SCANS_COMMIT = "DELETE_SALE_LOT_SCANS_COMMIT";
export const DELETE_SALE_LOT_SCANS_ROLLBACK = "DELETE_SALE_LOT_SCANS_ROLLBACK";

export const GET_SCANS = "GET_SCANS";
export const GET_SCANS_CHANGES = "GET_SCANS_CHANGES";
export const GET_SCANS_SUCCESS = "GET_SCANS_SUCCESS";
export const GET_SCANS_CHANGES_SUCCESS = "GET_SCANS_CHANGES_SUCCESS";
export const GET_SCANS_FAILURE = "GET_SCANS_FAILURE";
export const START_DRAFT = "START_DRAFT";
export const RESEND_SCAN = "RESEND_SCAN";
export const CONFIRM_START_DRAFT = "CONFIRM_START_DRAFT";

export const SCAN = dataTypeActionCreator("SCAN");
SCAN.BULK_UPDATE_NLIS_STATUS = subActionCreator(
  "SCAN",
  "BULK_UPDATE_NLIS_STATUS",
);
SCAN.ADD_TO_UNKNOWN_SALELOT = subActionCreator(
  "SCAN",
  "ADD_TO_UNKNOWN_SALELOT",
);

export const NOMINATION = dataTypeActionCreator("NOMINATION");
export const NOMINATION_TERM = dataTypeActionCreator("NOMINATION_TERM");
export const RECEIVAL_LOT = dataTypeActionCreator("RECEIVAL_LOT");
RECEIVAL_LOT.UPDATE_OR_CREATE_WITH_PEN =
  "RECEIVAL_LOT_UPDATE_OR_CREATE_WITH_PEN";
RECEIVAL_LOT.HANDLE_MARK_CONFLICTS = "RECEIVAL_LOT_HANDLE_MARK_CONFLICTS";

export const PEN_SCAN_LOT = dataTypeActionCreator("PEN_SCAN_LOT");
PEN_SCAN_LOT.UPDATE_OR_CREATE_WITH_PEN = subActionCreator(
  "PEN_SCAN_LOT",
  "UPDATE_OR_CREATE_WITH_PEN",
);
PEN_SCAN_LOT.ALLOCATE = subActionCreator("PEN_SCAN_LOT", "ALLOCATE");
PEN_SCAN_LOT.MOVE_SCANS_TO_ALLOCATED_LOT = subActionCreator(
  "PEN_SCAN_LOT",
  "MOVE_SCANS_TO_ALLOCATED_LOT",
);
PEN_SCAN_LOT.UPDATE_MAPPING = subActionCreator(
  "PEN_SCAN_LOT",
  "UPDATE_MAPPING",
);

export const WEIGH_LOT = dataTypeActionCreator("WEIGH_LOT");
WEIGH_LOT.PROCESS = subActionCreator("WEIGH_LOT", "PROCESS");
WEIGH_LOT.IMPORT = subActionCreator("WEIGH_LOT", "IMPORT");
WEIGH_LOT.UPDATE_MAPPED_WEIGH_LOT_SCAN_STATUS = subActionCreator(
  "WEIGH_LOT",
  "UPDATE_MAPPED_WEIGH_LOT_SCAN_STATUS",
);
WEIGH_LOT.KEEP = subActionCreator("WEIGH_LOT", "KEEP");
WEIGH_LOT.IGNORE = subActionCreator("WEIGH_LOT", "IGNORE");
WEIGH_LOT.DELETE_WITH_SCANS = subActionCreator(
  "WEIGH_LOT",
  "DELETE_WITH_SCANS",
);

export const WEIGH_LOT_SCAN = dataTypeActionCreator("WEIGH_LOT_SCAN");
WEIGH_LOT_SCAN.KEEP = subActionCreator("WEIGH_LOT_SCAN", "KEEP");
WEIGH_LOT_SCAN.IGNORE = subActionCreator("WEIGH_LOT_SCAN", "IGNORE");
WEIGH_LOT_SCAN.ADD_SCAN_TO_SALE = subActionCreator(
  "WEIGH_LOT_SCAN",
  "ADD_SCAN_TO_SALE",
);
WEIGH_LOT_SCAN.MANUALLY_MAP_TO_SCAN = subActionCreator(
  "WEIGH_LOT_SCAN",
  "MANUALLY_MAP_TO_SCAN",
);
WEIGH_LOT_SCAN.ADD_WEIGH_TO_SALE_LOT = subActionCreator(
  "WEIGH_LOT_SCAN",
  "ADD_WEIGH_TO_SALE_LOT",
);

export const CHECKPOINT = dataTypeActionCreator("CHECKPOINT");
export const SALE_SUB_TYPE = dataTypeActionCreator("SALE_SUB_TYPE");
export const WEIGHT_RANGE = dataTypeActionCreator("WEIGHT_RANGE");
export const DRESSING_RANGE = dataTypeActionCreator("DRESSING_RANGE");

export const DELETE_SCAN_FROM_SOCKET = "DELETE_SCAN_FROM_SOCKET";

export const UPLOAD_NLIS_ID_OFFLINE = "UPLOAD_NLIS_ID_OFFLINE";
export const UPLOAD_NLIS_ID_COMMIT = "UPLOAD_NLIS_ID_COMMIT";
export const UPLOAD_NLIS_ID_ROLLBACK = "UPLOAD_NLIS_ID_ROLLBACK";

export const GET_LIVESTOCK_SALE_DATA_CHANGES =
  "GET_LIVESTOCK_SALE_DATA_CHANGES";
export const REFRESH_CONTEXT_DATA = "REFRESH_CONTEXT_DATA";

export const REQUEST_NEW_JWT_TOKEN = "REQUEST_NEW_JWT_TOKEN";
export const REQUEST_NEW_JWT_TOKEN_OFFLINE = "REQUEST_NEW_JWT_TOKEN_OFFLINE";
export const REQUEST_NEW_JWT_TOKEN_COMMIT = "REQUEST_NEW_JWT_TOKEN_COMMIT";
export const REQUEST_NEW_JWT_TOKEN_ROLLBACK = "REQUEST_NEW_JWT_TOKEN_ROLLBACK";

export const OPEN_CONFIRM_MODAL = "OPEN_CONFIRM_MODAL";
export const CLOSE_CONFIRM_MODAL = "CLOSE_CONFIRM_MODAL";
export const MODAL_CONTEXT = dataTypeActionCreator("MODAL_CONTEXT");
MODAL_CONTEXT.SET = subActionCreator("MODAL_CONTEXT", "SET");
MODAL_CONTEXT.OPEN = subActionCreator("MODAL_CONTEXT", "OPEN");

export const SET_ACTIVE_ROLE = subActionCreator("ACTIVE_ROLE", "SET");
export const SEND_TO_USER_OVERVIEW = "SEND_TO_USER_OVERVIEW";

export const SET_CURRENT_LIVESTOCKSALE = subActionCreator(
  "CURRENT_LIVESTOCK_SALE",
  "SET",
);

export const SET_SETTING = "SET_SETTING";
export const SET_TABLE_SETTING = "SET_TABLE_SETTING";
export const SET_TABLE_VIEW = "SET_TABLE_VIEW";
export const UPDATE_TABLE_VIEW = "UPDATE_TABLE_VIEW";
export const INVALIDATE_TABLE_VIEW = "INVALIDATE_TABLE_VIEW";
export const SET_SALE_PROGRESS_SETTING = "SET_SALE_PROGRESS_SETTING";

export const PRINT_RECEIPT = "PRINT_RECEIPT";
export const PRINT_BUYER_SUMMARY = "PRINT_BUYER_SUMMARY";
export const PRINT_BUYER_WAY_PICK_LIST = "PRINT_BUYER_WAY_PICK_LIST";

export const UPDATE_CONSIGNMENT_MEGA = "UPDATE_CONSIGNMENT_MEGA";
export const PRINT_VENDOR_SUMMARY = "PRINT_VENDOR_SUMMARY";

export const PRINT_SALE_LOT_WEIGHT_SUMMARY = "PRINT_SALE_LOT_WEIGHT_SUMMARY";
export const PRINT_WEIGH_PEN_CARD_PORTRAIT = "PRINT_WEIGH_PEN_CARD_PORTRAIT";
export const PRINT_WEIGH_PEN_CARD_LANDSCAPE = "PRINT_WEIGH_PEN_CARD_LANDSCAPE";

export const PRINT_PLACARD = "PRINT_PLACARD";

export const PRINT_PLACARD_V2 = "PRINT_PLACARD_V2";

export const PRINT_PLACARD_V3 = "PRINT_PLACARD_V3";
export const PRINT_PLACARD_V4 = "PRINT_PLACARD_V4";

export const PRINT_PLACARD_V5 = "PRINT_PLACARD_V5";

export const PRINT_SHEEP_PLACARD = "PRINT_SHEEP_PLACARD";
export const PRINT_SHEEP_PEN_STORE_CARD = "PRINT_SHEEP_PEN_STORE_CARD";

export const QUEUE_SOCKET_MESSAGE = "QUEUE_SOCKET_MESSAGE";
export const CLEAR_SOCKET_BUFFER = "CLEAR_SOCKET_BUFFER";
export const PROCESS_SOCKET_MESSAGE = "PROCESS_SOCKET_MESSAGE";

export const BIDDER_REGISTRATION = dataTypeActionCreator("BIDDER_REGISTRATION");

BIDDER_REGISTRATION.CREATE_WITHOUT_INCREMENTING_BIDDER_NUMBER =
  subActionCreator(
    "BIDDER_REGISTRATION",
    "CREATE_WITHOUT_INCREMENTING_BIDDER_NUMBER",
  );

export const CREATE_BUSINESS_FROM_IMPORT = "CREATE_BUSINESS_FROM_IMPORT";
export const PATCH_BUSINESS_FROM_IMPORT = "PATCH_BUSINESS_FROM_IMPORT";

export const REQUERY_ABN_FOR_BUSINESS = actionCreator(
  "REQUERY_ABN_FOR_BUSINESS",
);

export const SIMULATE_REDUX_SAGA_ERROR = "SIMULATE_REDUX_SAGA_ERROR ";

export const GET_PRODUCTS = actionCreator("GET_PRODUCTS");
export const ADD_PRODUCT = actionCreator("ADD_PRODUCT");
export const UPDATE_PRODUCT = actionCreator("UPDATE_PRODUCT");
export const DELETE_PRODUCT = actionCreator("DELETE_PRODUCT");

export const STORE_GEO_DATA = "STORE_GEO_DATA";
export const FETCH_GEO_DATA = "FETCH_GEO_DATA";

export const SET_GEO_BLOCK = "SET_GEO_BLOCK";
export const SET_CONCURRENT_USER_BLOCK = "SET_CONCURRENT_USER_BLOCK";

export const GET_AUDIT_LOG = actionCreator("GET_AUDIT_LOG");

export const TEST_RULEBOOK = actionCreator("TEST_RULEBOOK");

export const NOTIFY_FETCH_REQUEST_RETRYING = "NOTIFY_FETCH_REQUEST_RETRYING";
export const NOTIFY_MUTATE_REQUEST_RETRYING = "NOTIFY_MUTATE_REQUEST_RETRYING";
export const REQUEST_FAILURE = "REQUEST_FAILURE";

export const GET_DEPLOYMENTS = actionCreator("GET_DEPLOYMENTS");
export const DEPLOYMENT = dataTypeActionCreator("DEPLOYMENT");
export const GET_AGENCIES = actionCreator("GET_AGENCIES");

export const MASTER_LABEL = dataTypeActionCreator("MASTER_LABEL");
export const SALEYARD = dataTypeActionCreator("SALEYARD");

export const DRAFTING_INFORMATION = dataTypeActionCreator(
  "DRAFTING_INFORMATION",
);

export const ECONTRACT = dataTypeActionCreator("ECONTRACT");
ECONTRACT.VOID = subActionCreator("ECONTRACT", "VOID");
ECONTRACT.EMAIL = subActionCreator("ECONTRACT", "EMAIL");

export const MLA_SUPPLEMENTARY_DATA = dataTypeActionCreator(
  "MLA_SUPPLEMENTARY_DATA",
);

export const LIVESTOCK_AGENT = dataTypeActionCreator("LIVESTOCK_AGENT");
LIVESTOCK_AGENT.REINVITE = subActionCreator("LIVESTOCK_AGENT", "REINVITE");
LIVESTOCK_AGENT.ADD_REPORT_FAVOURITE = subActionCreator(
  "LIVESTOCK_AGENT",
  "ADD_REPORT_FAVOURITE",
);
LIVESTOCK_AGENT.REMOVE_REPORT_FAVOURITE = subActionCreator(
  "LIVESTOCK_AGENT",
  "REMOVE_REPORT_FAVOURITE",
);

export const SALEYARD_ADMIN = dataTypeActionCreator("SALEYARD_ADMIN");
SALEYARD_ADMIN.REINVITE = subActionCreator("SALEYARD_ADMIN", "REINVITE");
SALEYARD_ADMIN.ADD_REPORT_FAVOURITE = subActionCreator(
  "SALEYARD_ADMIN",
  "ADD_REPORT_FAVOURITE",
);
SALEYARD_ADMIN.REMOVE_REPORT_FAVOURITE = subActionCreator(
  "SALEYARD_ADMIN",
  "REMOVE_REPORT_FAVOURITE",
);

export const PERMISSION_GROUP = dataTypeActionCreator("PERMISSION_GROUP");
export const USER_LEVEL = dataTypeActionCreator("USER_LEVEL");

export const SAVED_VIEW = dataTypeActionCreator("SAVED_VIEW");

export const DEFAULT_VENDOR_SPLIT = dataTypeActionCreator(
  "DEFAULT_VENDOR_SPLIT",
);
export const SALE_VENDOR_SPLIT = dataTypeActionCreator("SALE_VENDOR_SPLIT");

export const SYNC_NLIS = "SYNC_NLIS";

export const XERO_ACCOUNTING_SETTINGS = dataTypeActionCreator(
  "XERO_ACCOUNTING_SETTINGS",
);

export const INTEGRATION_CREDENTIAL = dataTypeActionCreator(
  "INTEGRATION_CREDENTIAL",
);
INTEGRATION_CREDENTIAL.ACTIVATE = subActionCreator(
  "INTEGRATION_CREDENTIAL",
  "ACTIVATE",
);
INTEGRATION_CREDENTIAL.LOAD_BUSINESSES = subActionCreator(
  "INTEGRATION_CREDENTIAL",
  "LOAD_BUSINESSES",
);
INTEGRATION_CREDENTIAL.SYNC_DOCUMENTS = subActionCreator(
  "INTEGRATION_CREDENTIAL",
  "SYNC_DOCUMENTS",
);

INTEGRATION_CREDENTIAL.SYNC_LEDGER_ACCOUNTS = subActionCreator(
  "INTEGRATION_CREDENTIAL",
  "SYNC_LEDGER_ACCOUNTS",
);

INTEGRATION_CREDENTIAL.PUSH_LEDGER_ACCOUNTS = subActionCreator(
  "INTEGRATION_CREDENTIAL",
  "PUSH_LEDGER_ACCOUNTS",
);

INTEGRATION_CREDENTIAL.CREATE_BUSINESS = subActionCreator(
  "INTEGRATION_CREDENTIAL",
  "CREATE_BUSINESS",
);
INTEGRATION_CREDENTIAL.CREATE_TAX_RATES = subActionCreator(
  "INTEGRATION_CREDENTIAL",
  "CREATE_TAX_RATES",
);

export const INTEGRATION_BUSINESS = dataTypeActionCreator(
  "INTEGRATION_BUSINESS",
);

INTEGRATION_BUSINESS.LINK_TO_BUSINESS = subActionCreator(
  "INTEGRATION_BUSINESS",
  "LINK_TO_BUSINESS",
);

INTEGRATION_BUSINESS.UNLINK_FROM_BUSINESS = subActionCreator(
  "INTEGRATION_BUSINESS",
  "UNLINK_FROM_BUSINESS",
);

export const APP_UPDATE_STATUS = {
  LOGOUT_REQUIRED: "LOGOUT_REQUIRED",
  REFRESH_REQUIRED: "REFRESH_REQUIRED",
  REFRESH_OPTIONAL: "REFRESH_OPTIONAL",
  INVISIBLE: "INVISIBLE",
  COMPLETE: "COMPLETE",
};

export const RETRYABLE_ACTIONS = [
  ATTACHMENT.FETCH_BULK.REQUEST,
  AUCTION_PEN.FETCH.REQUEST,
  AUCTION_PEN.FETCH_BULK.REQUEST,
  BIDDER_REGISTRATION.FETCH_BULK.REQUEST,
  BUSINESS.FETCH.REQUEST,
  BUSINESS.FETCH_BULK.REQUEST,
  BUSINESS.FETCH_CHANGES.REQUEST,
  DEPLOYMENT_SALE.FETCH_BULK.REQUEST,
  DRAFTING_INFORMATION.FETCH_BULK.REQUEST,
  GET_AGENCIES.REQUEST,
  GET_ALL_NVD_UPLOADS.REQUEST,
  GET_CONSIGNMENTS,
  GET_DEPLOYMENTS.REQUEST,
  GET_NVD_UPLOAD.REQUEST,
  GET_PRODUCTS.REQUEST,
  GET_PROPERTIES,
  GET_PROPERTIES_CHANGES,
  GET_REPORTS,
  GET_SALE_DEFINITIONS,
  GET_SCANS,
  GET_SCANS_CHANGES,
  GET_SPECIES_ATTRIBUTES,
  REFRESH_SELL_FILE,
  REFRESH_SELL_REVERSAL,
  REFRESH_TAKE_FILE,
  REFRESH_TAKE_REVERSAL,
  SALE.FETCH_BULK.REQUEST,
  SALE.FETCH_CONSIGNABLE.REQUEST,
  SALE_LOT.FETCH_BULK.REQUEST,
  SELL_FILE.FETCH.REQUEST,
  SELL_FILE.FETCH_BULK.REQUEST,
  SELL_REVERSAL.FETCH_BULK.REQUEST,
  TAKE_FILE.FETCH_BULK.REQUEST,
  TAKE_REVERSAL.FETCH_BULK.REQUEST,
];

// These actions will not be sent to LogRocket at all, as they're overly frequent - eg noise from scales.
export const LOGROCKET_IGNORE_ACTIONS = [
  ADD_SCANS_FROM_BUFFER,
  ADD_SCANS_FROM_SCANNER,
  ADD_WEIGHT_FROM_SCALES,
  API_RESPONSE.FETCH.ACTION,
  API_RESPONSE.FETCH.FAILURE,
  API_RESPONSE.FETCH.SUCCESS,
  CLEAR_SOCKET_BUFFER,
  DEVICE_MESSAGE_RESPONSE,
  PING_FROM_APP,
  SEND_DEVICE_MESSAGE,
];

export const REQUEST_LIVESTOCK_SALE_DATA = "REQUEST_LIVESTOCK_SALE_DATA";
export const REQUEST_USER_ROLE_DATA = "REQUEST_USER_ROLE_DATA";
export const REQUEST_SYSTEM_DATA = "REQUEST_SYSTEM_DATA";
export const RESTORE_SESSION = "RESTORE_SESSION";

// _CHANGES covers dataTypeActionCreator, and older style manual entries (eg FETCH_PROPERTIES_CHANGES)
export const isActionFetchBulk = action =>
  (action.type.includes("FETCH_BULK") ||
    (action.type.startsWith("GET_") && !action.type.includes("_CHANGES"))) &&
  !action.type.includes("HEARTBEAT");
export const isActionFetchChanges = action => action.type.includes("_CHANGES");
export const isActionMutative = action =>
  !isActionFetchBulk(action) &&
  !isActionFetchChanges(action) &&
  !(action.type === SERVER_HEARTBEAT.FETCH_BULK);

export const DELETE_OFFLINE_ACTION = "DELETE_OFFLINE_ACTION";
