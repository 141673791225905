import React from "react";

import { faCheckCircle as faCheckCircleHollow } from "@fortawesome/pro-light-svg-icons";
import {
  faCheckCircle,
  faExclamationCircle,
  faPencil,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ActionButton } from "components/AgGrid/ActionButton";
import { FaIcon } from "components/AgGridButtonIcon/agGridButtonIcon";
import { Row } from "components/Layout";

import {
  openEditBusinessModal,
  openMapBusinessHashRoute,
} from "lib/navigation";
import { getCombinedLotNumber } from "lib/saleLot";

export function actionsColumnRendererFactory(tableConfiguration) {
  function actionsColumnRenderer(props) {
    const { data } = props;
    if (!data.agrinousBusiness) {
      return (
        <ActionButton
          data-tour="action-map-business"
          onClick={() =>
            openMapBusinessHashRoute({
              ...data,
              tableConfiguration,
            })
          }
        >
          <FontAwesomeIcon icon={faPencil} />
          &nbsp;Map to business
        </ActionButton>
      );
    } else {
      return (
        <ActionButton
          data-tour="action-edit-business"
          onClick={() => openEditBusinessModal(data.agrinousBusiness.id)}
        >
          <FontAwesomeIcon icon={faPencil} />
          &nbsp;Edit
        </ActionButton>
      );
    }
  }
  return actionsColumnRenderer;
}

export const mappingAction = ({ data, context }) => {
  /** *
   * If the selected option
   * - has no saleyard_code, MAP
   * - Has a different saleyard_code, OVERWRITE
   * - has the same saleyard_code DISABLED
   */
  let action = null;
  // DISABLE if there is no option selected.
  if (!data.name.value) {
    action = null;
  }
  // MAP if there is no saleyard_code for the selected option.
  else if (!data.saleyard_code) {
    action = "Map";
  } else if (data.saleyard_code !== data[context.mappingColumn]) {
    action = "Overwrite";
  }

  if (action) {
    return (
      <ActionButton
        onClick={() => {
          context.update(data);
        }}
      >
        <FontAwesomeIcon icon={faPencil} />
        &nbsp;{action}
      </ActionButton>
    );
  } else {
    return null;
  }
};

export const saleLotMappingAction = ({ data, context }) => {
  let action = null;
  // DISABLE if there is no option selected.
  if (!data.agrinousLotNumber.value || !data.agrinousLot) {
    action = null;
  } else if (!data.agrinousLot.lot_number) {
    // Apply a lot number if none entered (Not sure this will ever be the case?)
    action = "Add Lot Number";
  } else if (
    getCombinedLotNumber(data.agrinousLot).toLowerCase() !==
    `${data.number}${data.lotNumberSuffix.toLowerCase()}`
  ) {
    // Otherwise, we're overwriting an old one... not the greatest thing, but could be done.
    action = "Overwrite Lot Number";
  }

  if (action) {
    return (
      <ActionButton
        onClick={() => {
          context.update(data);
        }}
      >
        <FontAwesomeIcon icon={faPencil} />
        &nbsp;{action}
      </ActionButton>
    );
  } else {
    return null;
  }
};

export const envdMappingAction = ({ data, context }) => {
  let action = null;

  if (!data) {
    return null;
  }

  if (
    data.livestockSaleOption &&
    data.speciesId &&
    context.sales[data.livestockSaleOption.value].species_id !== data.speciesId
  ) {
    return (
      <Row fullWidth alignCenter>
        <FaIcon icon={faExclamationCircle} color="error" />
        &nbsp;Species mismatch
      </Row>
    );
  } else if (
    data.deploymentSaleOption &&
    data.deploymentSaleOption.value !== data.envdDocument.deploymentSaleId
  ) {
    action = "Map To Single Agency";
  } else if (
    data.livestockSaleOption &&
    data.livestockSaleOption.value !== data.envdDocument.livestockSaleId
  ) {
    action = "Map To All Agencies";
  } else if (data.envdDocument.deploymentSaleId) {
    return (
      <Row fullWidth alignCenter>
        <FaIcon icon={faCheckCircle} color="success" />
        &nbsp;Mapped to Sale &amp; Agency
      </Row>
    );
  } else if (data.envdDocument.livestockSaleId) {
    return (
      <Row fullWidth alignCenter>
        <FaIcon icon={faCheckCircleHollow} color="success" />
        &nbsp;Mapped to Sale
      </Row>
    );
  }

  if (action) {
    return (
      <ActionButton
        onClick={() => {
          context.update(data);
        }}
      >
        <FontAwesomeIcon icon={faPencil} />
        &nbsp;{action}
      </ActionButton>
    );
  } else {
    return null;
  }
};
