export const SaleLotModalSection = {
  ACTIONS: "ACTIONS",
  AUCTION_PEN: "AUCTION_PEN",
  EIDS: "EIDS",
  GENERAL: "GENERAL",
  INTEGRATIONS: "INTEGRATIONS",
  INTERFACES: "INTERFACES",
  MARKING: "MARKING",
  MEDIA: "MEDIA",
  PICKUP_DETAILS: "PICKUP_DETAILS",
  SCANNING: "SCANNING",
  SELLING: "SELLING",
  VENDOR_SPLITS: "VENDOR_SPLITS",
  LEGACY_VENDOR_SPLITS: "LEGACY_VENDOR_SPLITS",
};

export const BusinessModalSection = {
  ADDRESS: "ADDRESS",
  GENERAL: "GENERAL",
  PROPERTIES: "PROPERTIES",
  BUYER_WAYS: "BUYER_WAYS",
  EMAIL_RECIPIENTS: "EMAIL_RECIPIENTS",
  BUSINESS_RELATIONSHIPS: "BUSINESS_RELATIONSHIPS",
  FINANCE_AND_ACCOUNTING: "FINANCE_AND_ACCOUNTING",
  INTEGRATIONS: "INTEGRATIONS",
  VENDOR_COMMISSION_BANDS: "VENDOR_COMMISSION_BANDS",
  TRANSACTIONS: "TRANSACTIONS",
};

export const ConsignmentModalSection = {
  ADVANCED_DRAFTING: "ADVANCED_DRAFTING",
  CARRIER_CHARGE: "CARRIER_CHARGE",
  DRAFTING: "DRAFTING",
  EIDS: "EIDS",
  FEES_AND_CHARGES: "FEES_AND_CHARGES",
  GENERAL: "GENERAL",
  MEDIA: "MEDIA",
  NLIS: "NLIS",
  PICKUP_DETAILS: "PICKUP_DETAILS",
  SALE_LOTS: "SALE_LOTS",
  NOTES: "NOTES",
  VENDOR_SPLITS: "VENDOR_SPLITS",
  LEGACY_VENDOR_SPLITS: "LEGACY_VENDOR_SPLITS",
};

export const ModalTypes = {
  AllocatePenScanLots: "allocate-pen-scan-lots",
  AuditLog: "audit-log",
  BillingDocumentActivity: "billing-document-activity",
  BillingDocumentReview: "billing-document-review",
  BusinessPayments: "business-payments",
  Comments: "comments",
  ConsignToSale: "consign-to-sale",
  Consignment: "edit-consignment",
  ConsignmentCarrierCharge: "edit-consignment-carrier-charge",
  CreateIntegrationBusiness: "create-integration-business",
  DefaultBuyerWayProperty: "default-buyer-way-property",
  DeliverSaleLot: "deliver-sale-lot",
  DeliveryPen: "delivery-pen",
  DeliveryPenOwners: "delivery-pen-owners",
  DeploymentSaleOptions: "deployment-sale-options",
  DuplicateBusinessSuggestions: "duplicate-businesses",
  EContract: "econtract",
  EditBidder: "edit-bidder",
  EditBillingDocument: "edit-billing-document",
  EditBillingRun: "edit-billing-run",
  EditBusiness: "edit-business",
  EditCheckpoint: "edit-checkpoint",
  EditIntegrationBusiness: "edit-integration-business",
  EditLedgerEntry: "edit-ledger-entry",
  EditLedgerAccount: "edit-ledger-account",
  EditMasterRule: "edit-master-rule",
  EditNomination: "edit-nomination",
  EditPayment: "edit-payment",
  EditReceivalLot: "edit-receival-lot",
  EditRule: "edit-rule",
  EditPenScanLot: "edit-pen-scan-lot",
  EditScanningPen: "edit-scanning-pen",
  EditSaleLot: "edit-sale-lot",
  EditSaleLotCarrierCharge: "edit-sale-lot-carrier-charge",
  EditUserRole: "edit-user-role",
  EmailReportModal: "email-report",
  EmailReportJobModal: "email-report-job",
  ForceTransfer: "force-transfer",
  ImportWeighLots: "import-weigh-lots",
  InlineCreateSaleLot: "inline-create-salelot",
  Interest: "generate-interest",
  ImportSummary: "import-summary",
  KillSheet: "kill-sheet",
  ManualAdjustmentForm: "manual-adjustment-form",
  ManualAdjustmentTable: "manual-adjustment-table",
  MapBusiness: "map-business",
  MergeSaleLot: "merge-sale-lot",
  MultiSaleReports: "multi-sale-reports",
  NLISTransferReceipt: "nlis-transfer-receipt",
  RetainProceeds: "retain-proceeds",
  RuleTester: "rule-tester",
  RuleLibrary: "rule-library",
  PendingChanges: "pending-changes",
  RuleBook: "rule-book",
  Sale: "sale",
  SaleLot: "sale-lot",
  Scan: "scan",
  ScanToPen: "scan-to-pen",
  SelectSyncData: "select-sync-data",
  SendVendorReports: "send-vendor-reports",
  SingleWeighSettings: "single-weigh-settings",
  SundryTemplate: "sundry-template",
  SplitSaleLot: "split-sale-lot",
  UpdateBuyer: "update-buyer",
  VendorSplitConsignment: "vendor-split-consignment",
  VendorSplitSaleLot: "vendor-split-salelot",
  VendorSplit: "vendor-split",
  WeightSettings: "weight-settings",
};
