import React from "react";
import { useSelector } from "react-redux";
import { SelectField, withNamespace } from "components/Form/FormikControls";

import { getSpeciesAttributeOptions } from "selectors";

interface EIDExemptionReasonFieldProps {
  label?: string;
  name?: string;
  ns?: string;
  isClearable?: boolean;
  disabled?: boolean;
  tooltip?: string;
}
export function EIDExemptionReasonField(
  props: EIDExemptionReasonFieldProps,
): React.JSX.Element {
  const {
    ns,
    name = "exemption_id",
    label = "EID Exemption Reason",
    isClearable = false,
    disabled = false,
    tooltip = "Applicable exemption from meeting EID scanning requirements.",
  } = props;

  const { exemptions } = useSelector(getSpeciesAttributeOptions);

  return (
    <SelectField
      label={label}
      name={withNamespace(ns, name)}
      options={exemptions}
      isClearable={isClearable}
      disabled={disabled}
      tooltip={tooltip}
      menuPortalTarget={document.body}
    />
  );
}
