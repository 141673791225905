import React from "react";

import Grid from "@material-ui/core/Grid";

import { CollapseHeader, FormCollapse } from "components/Form";
import { HTMLTemplateTextField } from "components/Form/Fields/DetailsText";
import {
  CheckBox,
  Input,
  SelectField,
  withNamespace,
  withNamespaces,
} from "components/Form/FormikControls";
import { DescriptiveOption } from "components/SearchableSelector";

import {
  FinanceSubModalSection,
  HeaderLayoutOptions,
  RCTIFooterOptions,
} from "constants/settings";

import { useSectionHelper } from "hooks";

export const RCTISettings = ({ sectionHelper, namespace: ns }) => {
  const { isOpen, onToggle } = useSectionHelper(
    sectionHelper,
    FinanceSubModalSection.RCTI,
  );

  return (
    <FormCollapse
      isOpen={isOpen}
      onToggle={onToggle}
      header={<CollapseHeader title="RCTI" />}
    >
      <Grid item xs={12} md={6}>
        <Input
          label="Account Sale/RCTI Prefix"
          name={withNamespace(ns, "accountSalePrefix")}
          tooltip="The prefix used for Account Sale/RCTI documents.  EG RCTI-1234"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Next Account Sale/RCTI Number"
          name={withNamespace(ns, "nextAccountSaleNumber")}
          tooltip="The next Account Sale/RCTI document created by your organization will use this number, combined with your current Account Sale/RCTI Prefix."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Primary Color"
          name={withNamespaces([ns, "rctiSettings"], "primaryColor")}
          type="color"
          tooltip="This colour is used in the header of several reports."
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label="Secondary Color"
          name={withNamespaces([ns, "rctiSettings"], "secondaryColor")}
          type="color"
          tooltip="This colour is used in the header of several reports."
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          label="Header Layout"
          name={withNamespaces([ns, "rctiSettings"], "headerLayout")}
          options={HeaderLayoutOptions}
          tooltip="Select the header layout of the document"
          components={{ Option: DescriptiveOption }}
        />
        <SelectField
          label="Footer Layout"
          name={withNamespaces([ns, "rctiSettings"], "footerLayout")}
          options={RCTIFooterOptions}
          tooltip="Select the footer layout of the logo on the document"
          components={{ Option: DescriptiveOption }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <HTMLTemplateTextField
          name={withNamespaces([ns, "rctiSettings"], "detailsText")}
        />
      </Grid>
      <Grid item xs={12}>
        <CheckBox
          label="Show Vendor Split detail on RCTI"
          name={withNamespace("deploymentSettings", "showSplitDetailsOnRCTI")}
          tooltip="Controls whether or not to show the whole amount and the split % on a RCTI that is part of a vendor split."
        />
      </Grid>
    </FormCollapse>
  );
};
