import { useReducer } from "react";

type UseSectionToggleReturn = [
  /** The current section value. */
  string,

  /** Function to set the current section. */
  (string) => void,
];

function sectionReducer(state: any, section: string): string | null {
  if (state !== section) {
    return section;
  }
  return null;
}

export function useSectionToggle(
  initialSection = null,
): UseSectionToggleReturn {
  return useReducer(sectionReducer, initialSection);
}

type UseSectionHelperReturn = {
  isOpen: boolean;
  onToggle: () => void;
};

export function useSectionHelper(
  sectionHelper: UseSectionToggleReturn,
  section: string | null,
): UseSectionHelperReturn {
  const [currentSection, setSection] = sectionHelper;
  const isOpen = currentSection === section;
  const onToggle = () => setSection(section);

  return { isOpen, onToggle };
}
